import EventBus from 'js-event-bus';

export default class CustomEventBus {
    constructor() {
        this.eventBus = new EventBus()
    }

    $on(eventName,functionReference){
        if (functionReference) {
            this.eventBus.on(eventName, functionReference);
        }else{
            console.trace('ERROR: No function reference provided ' + eventName);
        }
       
    }

    $off(eventName,functionReference){
        if(functionReference){
            this.eventBus.detach(eventName,functionReference)
        }else{
            this.eventBus.detach(eventName);
        }

    }

    $emit(eventName,payload){
        this.eventBus.emit(eventName,null,payload);
    }

}