<template>
	<div style="width: 100%; background-color: var(--v-gray_10-base)" class="proposal-viewer">
		<div class="header-buttons d-print-none row-format centered gap-2 py-4">
			<comments-menu
				v-if="proposal"
				:comments="proposal.comments"
				:proposal-id="proposal.id"
				:client-id="proposal.clientId"
				:proposal-service="proposalService"
				:client-mode="true"
				:viewed-timestamps="proposal.viewedTimestamps"
			></comments-menu>
			<v-btn v-if="$vuetify.breakpoint.smAndUp" class="primary-action" @click="print()">
				<div class="row-format align-center">
					<v-icon small>$paperClip</v-icon>
					<div class="ml-1">{{ $t('proposals.print-save-pdf') }}</div>
				</div>
			</v-btn>
			<v-btn class=" primary-action">
				<language-switcher
					:label="$t('settings.language')"
					:children="countries.getCountriesTranslate()"
					icon="translate"
					@selected="switchLocale($event)"
				></language-switcher>
			</v-btn>
		</div>
		<proposal-renderer
			v-if="isReady"
			:proposal="proposal"
			:account="account"
			:proposal-service="proposalService"
			:edit-mode="false"
			:pod-service="null"
			:client-service="null"
			:need-save="false"
			:saving="false"
			:client-mode="true"
			:is-locked="isLocked"
			:card-payment-failed="cardPaymentFailed"
			@deliverable-selected="deliverableSelected($event)"
			@client-signed="clientFinalized($event)"
			@client-accepted="clientFinalized($event)"
		></proposal-renderer>
	</div>
</template>

<script>
	import PubNub from 'pubnub';
	import qs from 'qs';
	import ProposalViewerService from './ProposalViewerService';
	import ProposalRenderer from '@/modules/proposal/proposal-renderer/ProposalRenderer';
	import CommentsMenu from '@/modules/proposal/proposal-renderer/CommentsMenu';
	import LanguageSwitcher from '@/components/LanguageSwitcher';
	import Countries from '@/modules/utils/Countries';

	export default {
		name: 'ProposalViewer.vue',
		components: { CommentsMenu, ProposalRenderer, LanguageSwitcher },

		props: ['id'],

		data: function() {
			return {
				countries: new Countries(),
				httpClient: null,
				token: null,
				docId: null,
				contactId: null,
				proposal: null,
				account: null,
				isReady: false,
				proposalService: null,
				selectedPackageIds: [],
				theirSignature: null,
				pubNub: null,
				cardPaymentFailed: false,
			};
		},

		mounted: function() {
			this.$vuetify.theme.dark = false;
			setTimeout(() => (this.$vuetify.theme.dark = false), 50);

			if (this.id) {
				this.token = this.id;
				this.docId = this.id;
				this.proposalService = new ProposalViewerService(this.token, this.$store.getters.getPodUrl);
			} else {
				let queryString = qs.parse(self.location.search.slice(1));
				let request = JSON.parse(atob(queryString.token.split('.')[0]));
				this.token = queryString.token;
				this.docId = request.docId;
				this.contactId = request.contactId;
				this.proposalService = new ProposalViewerService(this.token, request.podUrl);
			}

			this.proposalService
				.getProposal()
				.then((res) => {
					this.proposal = res.data;
					this.account = this.proposal.accountMini;
					this.$store.commit('setAccountId', this.proposal.accountId);
					this.$store.commit('setUserId', this.contactId ? this.contactId : this.docId);
					this.proposalService.setLastViewed();
					this.isReady = true;
					this.setupPubNub();
				})
				.catch((err) => {
					console.log(err);
					this.notFound = true;
					this.$store.commit('error', err.response.data.message);
				});
		},

		beforeDestroy() {
			this.proposalService.setLastViewed();
		},

		methods: {
			print: function() {
				window.print();
			},

			setupPubNub: function() {
				let uuid = this.docId;
				let authKey = this.contactId ? this.contactId : this.docId;
				let channel = 'c-' + this.proposal.accountId + '.p-' + this.proposal.id;

				this.pubNub = new PubNub({
					subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE,
					publishKey: process.env.VUE_APP_PUBNUB_PUBLISH,
					uuid: uuid,
					authKey: authKey,
					ssl: true,
				});

				this.pubNub.addListener({
					message: this.onMessage,
				});

				this.pubNub.subscribe({
					channels: [channel],
				});
			},

			onMessage: function(msg) {
				this.$store.state.eventBus.$emit(msg.channel, msg);
			},

			deliverableSelected: function(deliverable) {
				if (this.proposal.allowMultipleDeliverables) {
					if (deliverable.selectedPackage) {
						this.selectedPackageIds.push(deliverable.id);
					} else {
						let ix = this.selectedPackageIds.findIndex((p) => p === deliverable.id);
						if (ix > -1) {
							this.selectedPackageIds.splice(ix, 1);
						}
					}
				} else {
					this.selectedPackageIds.splice(0, this.selectedPackageIds.length);
					this.selectedPackageIds.push(deliverable.id);
				}
			},

			clientFinalized: function(payload) {
				let block = payload.block;

				if (this.requirePackageSelection && this.selectedPackageIds.length === 0) {
					this.$store.commit('error', this.$t('error.package-not-selected'));
				} else {
					let result = {
						selectedPackageIds: this.selectedPackageIds,
						cardTokenId: payload.cardTokenId,
						upFrontAmount: payload.upFrontAmount,
					};

					if (block.blockType === 'SignatureBlock') {
						result.signature = block.them;
					} else {
						result.clientAccept = true;
					}

					this.$store.commit('startLoading');
					this.proposalService
						.executeProposal(result)
						.then((res) => {
							this.cardPaymentFailed = false;
							this.proposal = res.data;
						})
						.catch((err) => {
							if (result.cardTokenId) {
								this.cardPaymentFailed = true;
							}
							this.$store.commit('error', err.response.data.message);
						})
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			switchLocale: function(lang) {
				localStorage.setItem('lang', lang);
				this.$store.state.selectLang = lang;
				this.$store.state.eventBus.$emit('lang-changed', lang);
				window.location.reload();
				console.log('Im hit!');
			},
		},

		computed: {
			signatureBlock: function() {
				for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
					if (this.proposal.contentBlocks[i].blockType === 'SignatureBlock') {
						return this.proposal.contentBlocks[i];
					}
				}

				return null;
			},

			acceptBlock: function() {
				for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
					if (this.proposal.contentBlocks[i].blockType === 'AcceptBlock') {
						return this.proposal.contentBlocks[i];
					}
				}

				return null;
			},

			requirePackageSelection: function() {
				if (this.proposal.forceAllDeliverables) {
					return false;
				}

				let deliverableCount = 0;
				for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
					if (this.proposal.contentBlocks[i].blockType === 'Deliverables') {
						deliverableCount = deliverableCount + 1;
					}
				}

				return deliverableCount > 1;
			},

			isLocked: function() {
				let signature = this.signatureBlock;
				if (signature) {
					return !this.$validations.isEmpty(signature.them.signature);
				} else if (this.acceptBlock) {
					return this.acceptBlock.accepted ? true : false;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style lang="scss">
	* {
		::-webkit-scrollbar {
			display: unset !important;
		}
	}

	.header-buttons {
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 500px) {
			flex-direction: column;
		}
	}
</style>
