<template>
	<div  @click="$emit('click',$event)" class="row-format centered" :style="`--d-weight: ${item.weight}px; --d-style: ${item.style}; --d-color: ${item.color}; --d-width: ${item.width}%; padding-top:${item.pt}px!important; padding-bottom:${item.pb}px!important`">
		<hr class="form-divider">
	</div>
</template>

<script>
import RendererMixin from "@/modules/forms/v2/schema/RendererMixin";

export default {
	name: "Divider",

	props: ['item'],

	mixins: [RendererMixin],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">
	.form-divider {
		border-radius: 4px;
		border-left: 0;
		border-right: 0;
		border-bottom: 0;
		border-top: var(--d-weight) var(--d-style) var(--d-color);
		width: var(--d-width);
	}
</style>