<style lang="scss">
	#app-area {
		width: 100%;
		height: 100%;
	}

	#left-nav {
		border-left: 4px solid var(--v-primary-base);
		transition: all 150ms linear;
		background-color: var(--v-sidebar-base);
	}

	.left-nav-expanded {
		width: 200px;
		min-width: 200px;
		max-width: 200px;
	}

	.left-nav-collapsed {
		width: 64px;
		min-width: 64px;
		max-width: 64px;
	}

	#right-section {
		flex: 1;
	}

	#right-bar {
		min-width: var(--v-right-nav-width);
		max-width: var(--v-right-nav-width);
		width: var(--v-right-nav-width);
		transition: all 250ms;
		background-color: var(--v-white-base);
		box-shadow: 0px 1px 0px rgba(98, 123, 148, 0.25), 0px 0px 12px rgba(98, 123, 148, 0.1);
		border-radius: 16px 0px 0px 0px;
	}

	#main-content {
		flex: 1;
		max-height: calc(var(--vh) - 54px);
		min-height: calc(var(--vh) - 54px);
		height: calc(var(--vh) - 54px);
		border-radius: 16px 16px 0px 0px;
		background-color: var(--v-white-base);
		//border: 1px solid rgba(0, 0, 0, .10);
		overflow: auto;
		//box-shadow: 0px 1px 0px rgba(98, 123, 148, 0.25), 0px 0px 12px rgba(98, 123, 148, 0.1);
	}

	#main-app {
		flex: 1;
		//max-height: calc(var(--vh) - 53px);
		//min-height: calc(var(--vh) - 53px);
		max-height: calc(var(--vh) - 54px);
		min-height: calc(var(--vh) - 54px);
		height: calc(var(--vh) - 54px);
		min-width: calc(100vw - var(--v-left-nav-width));
		max-width: calc(100vw - var(--v-left-nav-width));
		overflow-y: auto;
		overflow-x: auto;
    background-color: var(--v-sidebar-base);
	}

	:root {
		--v-recaptcha-visibility: visible;
	}

	.grecaptcha-badge {
		visibility: var(--v-recaptcha-visibility) !important;
	}
</style>

<template>
	<v-app data-app :style="`--primary-font: ${primaryFont}; height:100%;`" v-if="isReady">
		<div class="column-format fill-all" v-if="$store.state.isLoggedIn">
			<div
				class="row-format"
				id="app-area"
				:style="`--v-right-nav-width:${rightNavWidth}; --v-left-nav-width:${leftNavWidth}; overflow-y: hidden`"
			>
				<div
					id="left-nav"
					class="d-none d-sm-flex"
					:class="this.$store.state.leftNavExpanded ? 'left-nav-expanded' : 'left-nav-collapsed'"
				>
					<left-nav></left-nav>
				</div>
				<div id="right-section" class="column-format">
					<top-nav></top-nav>
					<div id="main-app">
						<div class="row-format">
							<div id="main-content">
								<v-main>
									<router-view />
								</v-main>
							</div>
							<div id="right-bar" class="ml-4" v-show="$store.state.rightNavExpanded || !mobile">
								<right-nav></right-nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else style="height: 100%">
			<router-view></router-view>
		</div>
		<v-snackbar id="main-snackbar" v-model="show" :color="color" :timeout="timeout" :top="true" style="font-size: 1.1em">
			<div>{{ message }}</div>
			<v-btn dark icon @click="show = false" id="main-snackbar-close">
				<v-icon>close</v-icon>
			</v-btn>
		</v-snackbar>
		<v-overlay style="z-index: 1000" :value="$store.state.loadingState">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<global-modal-controller ref="gmc"></global-modal-controller>
	</v-app>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';
	import WebFontLoader from 'webfontloader';
	import LeftNav from '@/views/nav/LeftNav.vue';
	import RightNav from '@/views/nav/RightNav';
	import GlobalModalController from '@/modules/utils/GlobalModalController';
	//import NotificationStateManager from '@/modules/productivity/notifications/NotificationStateManager';
	import CustomFontService from '@/modules/utils/CustomFontService';
	import { Settings as LuxonSettings } from 'luxon';
	import TopNav from '@/views/nav/TopNav.vue';

	export default {
		name: 'App',
		components: {
			TopNav,
			LeftNav,
			RightNav,
			GlobalModalController,
		},

		data() {
			return {
				mobile: true,
				windowWidth: null,
				show: false,
				message: null,
				timeout: -1,
				color: 'success',
				isReady: false,
				showFullApp: false,
				primaryFont: null,
				timeouts: { error: 0, warning: 10, info: 7, success: 7 },
			};
		},

		created() {
			window.addEventListener('resize', this.checkScreen);
			this.checkScreen();
		},

		mounted() {
			if (navigator.languages && navigator.languages.length) {
				this.$store.state.locale = navigator.languages[0];
				console.log('Setting locale to ' + this.$store.state.locale);
			}

			let selectedClientId = localStorage.getItem('SELECTED_CLIENT');
			if (selectedClientId) {
				this.$store.state.selectedClientId = selectedClientId;
			}

			let domain;

			if (document.location.host === 'localhost:3001' || document.location.host === 'hectic.ngrok.io') {
				//domain = 'austin-morrow1.hecticshare.dev';
				domain = 'clients1.geoffreymina.com';
        //domain = 'cjschneider.moxieapp.dev'
			} else {
				domain = document.location.host;
			}

			AuthService.getDomainSettings(domain)
				.then((res) => {
					if (res) {
						this.$store.commit('setDomain', res);
					}
					this.initLocale();
					this.initBranding();
					this.initDarkMode();
					this.initFonts();
				})
				.catch((err) => {
					console.log('Error loading branding', err);
					this.initBranding();
				});

			this.setMaxVh();
			window.addEventListener('resize', () => {
				this.setMaxVh();
			});
		},

		methods: {
			setMaxVh: function() {
				let vh = window.innerHeight;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			},

			initLocale: function() {
				let availableLocales = this.$i18n.availableLocales;
				let usersLanguage = window.navigator.language;
				if (localStorage.getItem('lang')) {
					this.$i18n.locale = localStorage.getItem('lang');
				} else if (availableLocales.includes(usersLanguage)) {
					this.$i18n.locale = usersLanguage;
				} else {
					this.$i18n.locale = 'en';
				}

				LuxonSettings.defaultLocale = navigator.languages[0];
				console.log(LuxonSettings.defaultLocale);
			},

			initBranding: function() {
				if (this.$store.state.domain && this.$store.state.domain.customBranding) {
					let branding = this.$store.state.domain.customBranding;
					this.$vuetify.theme.themes.light.primary = branding.primaryColor;
					this.$vuetify.theme.themes.light.sidebar = branding.navColor;

          if(branding.primaryColorDark){
            this.$vuetify.theme.themes.dark.primary = branding.primaryColorDark
          }

          if(branding.navColorDark){
            this.$vuetify.theme.themes.dark.sidebar = branding.navColorDark;
          }

					document.title = this.$store.state.domain.accountName;

          if(branding.favicon){
            let link = document.createElement('link');
            link.rel = 'icon';
            link.href = branding.favicon;
            document.head.appendChild(link);
          }

				} else {
					console.log('applying default branding');
				}
				this.isReady = true;
			},

			initFonts: function() {
				if (this.$store.state.domain) {
					let customFontService = new CustomFontService(this.$store.state.domain.podUrl);
					customFontService.getCustomFonts(this.$store.state.domain.accountId).then((res) => {
						if (res.data && res.data.length) {
							this.$store.state.accountFonts.push(...res.data);
							this.loadCustomFonts(res.data);
						} else {
							this.loadGoogleFont();
						}
					});
				}
			},

			loadCustomFonts: function(fonts) {
				let p = [];

				for (let i = 0; i < fonts.length; i++) {
					let font = fonts[i];
					let newFont = new FontFace(font.fontName, `url(${font.fontUrl})`);
					p.push(newFont.load());
				}

				Promise.all(p)
					.then((loadedFonts) => {
						for (let i = 0; i < loadedFonts.length; i++) {
							console.log('loaded font', loadedFonts[i]);
							document.fonts.add(loadedFonts[i]);
						}
					})
					.finally(() => this.setFont());
			},

			loadGoogleFont: function() {
				if (this.$store.state.domain && this.$store.state.domain.customBranding) {
					let branding = this.$store.state.domain.customBranding;
					WebFontLoader.load({
						google: {
							families: [`${branding.font}:200,300,400,500,600`],
						},
						active: this.setFont,
					});
				}
			},

			setFont: function() {
				this.primaryFont = this.$store.state.domain.customBranding.font;
			},

			initDarkMode() {
				this.darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
				let darkModePref = localStorage.getItem('DARK_MODE');
				this.$store.state.eventBus.$on('dark-mode-changed', this.updateDarkMode);

				if (darkModePref) {
					this.$store.state.darkMode = darkModePref;
				} else if (this.$store.state.domain && this.$store.state.domain.customBranding) {
					this.$store.state.darkMode = this.$store.state.domain.customBranding.darkMode.toLowerCase();
				}

				this.updateDarkMode(this.$store.state.darkMode);
			},

			updateDarkMode: function(darkModePref) {
				if (darkModePref === 'automatic') {
					this.enableAutomaticDarkMode();
					if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
						setTimeout(() => (this.$vuetify.theme.dark = true), 0);
					} else {
						setTimeout(() => (this.$vuetify.theme.dark = false), 0);
					}
				} else if (darkModePref === 'never') {
					this.disableAutomaticDarkMode();
					setTimeout(() => (this.$vuetify.theme.dark = false), 0);
				} else if (darkModePref === 'always') {
					this.disableAutomaticDarkMode();
					setTimeout(() => (this.$vuetify.theme.dark = true), 0);
				}
			},

			enableAutomaticDarkMode: function() {
				this.darkMediaQuery.addEventListener('change', this.toggleDarkMode);
			},

			disableAutomaticDarkMode: function() {
				this.darkMediaQuery.removeEventListener('change', this.toggleDarkMode);
			},

			toggleDarkMode() {
				this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			},

			checkScreen() {
				this.windowWidth = window.innerWidth;
				if (this.windowWidth <= 599) {
					this.mobile = true;
					return;
				}
				this.mobile = false;
				this.mobileNav = false;
			},
		},

		computed: {
			alert() {
				return this.$store.state.alert;
			},

			customEmbed: function() {
				return this.$store.state.customEmbed;
			},

			leftNavWidth: function() {
				if (this.$vuetify.breakpoint.xsOnly) {
					return '0px';
				} else {
					return this.$store.state.leftNavExpanded ? '200px' : '64px';
				}
			},

			rightNavWidth: function() {
				return this.$store.state.rightNavExpanded ? '300px' : '0px';
			},

			notificationWindow: {
				get: function() {
					return this.$store.state.notificationWindowOpenState;
				},

				set: function(val) {
					this.$store.commit('notificationWindow', val);
				},
			},
		},

		watch: {
			$route(to, from) {
				console.log('Route changed from ' + from.fullPath + ' to ' + to.fullPath);
				if (to.meta && to.meta.insecure) {
					this.showFullApp = false;
				} else {
					this.showFullApp = true;
				}
			},

			customEmbed: function(val) {
				if (val) {
					let script_tag = document.createElement('script');
					script_tag.type = 'text/javascript';
					script_tag.text = val;
					document.body.appendChild(script_tag);
				}
			},

			alert(newAlert) {
				this.message = newAlert.message;
				this.color = newAlert.type;
				this.show = true;
				this.timeout = this.timeouts[newAlert.type] * 1000;
			},
		},
	};
</script>

<style lang="scss">
	@import './assets/css/fonts.css';
	@import './assets/css/vuetify.scss';
	@import './assets/css/brand.scss';
	@import './assets/css/svg.css';

	#main-snackbar .v-snack__wrapper.v-sheet .v-snack__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		div {
			flex: 1 1 auto;
			font-size: 16px;
		}
		#main-snackbar-close {
			flex: 0 0 32px;
			margin: 0;
			margin-right: -12px;
		}
	}
</style>
