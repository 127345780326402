<template>
	<div id="top-nav-wrapper" ref="topNavWrapper" class="row-format align-center">
		<div id="top-nav" ref="topNav" style="width:100%">
      <div v-if="!mobile" class="row-format" style="width:100%">
        <div id="left-side" class="row-format align-center">
          <tabs
            :tab-config="currentTabsConfig"
            :current-path="currentPath"
            :offset-width="$refs.rightSide ? $refs.rightSide.clientWidth : 508"
          ></tabs>
        </div>
        <div id="right-side" ref="rightSide" class="ml-auto">
          <div
            style="justify-content: space-evenly; border-left: 1px solid var(--v-gray_50-base)"
            :class="`mr-2 right ${$store.state.rightNavExpanded ? 'right-big' : 'right-small'}`"
          >
            <div
              :class="`pointer top-button ${$store.state.rightNavFocus === 'notifications' ? 'active-top' : ''}`"
              @click="openRightNav('notifications')"
            >
              <v-icon
                :class="$store.state.rightNavFocus === 'notifications' ? '' : 'material-symbols-outlined'"
                :color="$store.state.rightNavFocus === 'notifications' ? 'primary' : 'secondary'"
                >notifications_active</v-icon
              >
              <div class="top-umi" v-if="$store.state.actionCount">
                <div>{{ $store.state.actionCount }}</div>
              </div>
            </div>
            <v-menu content-class="user-menu" offset-y>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <div class="profile-pic">
                    <span class="white--text">{{ initials }}</span>
                  </div>
                </div>
              </template>
              <user-menu></user-menu>
            </v-menu>
          </div>
        </div>
      </div>

			<!--Mobile Navigation-->
			<div class="row-format align-center" style="width:100%" v-if="mobile">
				<div class="ml-2 pointer">
          <img :src="logo" style="max-width: 120px; max-height: 50px"  @click="$router.push('/welcome')"/>
        </div>
        <div class="ml-auto row-format gap-2">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div style="position: relative" v-on="on">
                <v-btn icon v-bind="attrs">
                  <v-icon size="30" color="black" class="material-symbols-outlined">notifications_active</v-icon>
                </v-btn>
                <div class="top-umi" v-if="$store.state.actionCount">
                  <div>{{ $store.state.actionCount }}</div>
                </div>
              </div>
            </template>
            <div style="width:300px; height: calc(100vh - 120px); background-color: var(--v-white-base)" class="column-format align-center">
              <notifications></notifications>
            </div>
          </v-menu>
          <v-menu offset-y class="pages">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" icon v-bind="attrs" v-on="on">
                <v-icon size="30" color="black">menu</v-icon>
              </v-btn>
            </template>
            <MobileNav></MobileNav>
          </v-menu>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
	import Tabs from '@/views/nav/Tabs.vue';
	import MobileNav from '@/views/nav/MobileNav.vue';
	import UserMenu from '@/views/nav/UserMenu';
	import NavConfig from '@/views/nav/NavConfig.json';
	import ConfirmModal from '@/components/ConfirmModal.vue';
	import AuthService from '@/modules/auth/AuthService';
	import PodService from '@/services/PodService';
  import Notifications from '@/views/nav/Notifications';

	export default {
		name: 'TopNav',
		props: {},
		emits: ['set-page'],
		components: { Notifications, UserMenu, MobileNav, Tabs },
		data() {
			return {
				userMenuOpen: false,
				isOpen: false,
				navConfig: NavConfig,
				currentTabsConfig: { tabs: [], title: '' },
				currentPath: null,
				mobile: true,
				mobileNav: null,
				windowWidth: null,
			};
		},

		created() {
			window.addEventListener('resize', this.checkScreen);
			this.checkScreen();
		},

		mounted() {
			this.setNavStateFromLocalStorage();
		},

		beforeDestroy() {},

		methods: {
			openRightNav: function (focus) {
				if (this.$store.state.rightNavExpanded && this.$store.state.rightNavFocus === focus) {
					this.$store.state.rightNavExpanded = false;
					this.$store.state.rightNavFocus = null;
				} else {
					this.$store.state.rightNavExpanded = true;
					this.$store.state.rightNavFocus = focus;
				}
				this.saveNavState();
			},

			saveNavState: function () {
				let navState = {
					rightNavExpanded: this.$store.state.rightNavExpanded,
					rightNavFocus: this.$store.state.rightNavFocus,
				};

				localStorage.setItem('RIGHT_NAV_STATE', JSON.stringify(navState));
			},

			setNavStateFromLocalStorage: function () {
				let strNavState = localStorage.getItem('RIGHT_NAV_STATE');
				if (strNavState) {
					let navState = JSON.parse(strNavState);
					this.$store.state.rightNavExpanded = navState.rightNavExpanded;
					this.$store.state.rightNavFocus = navState.rightNavFocus;
				}
			},

			setLeftNavExpanded: function (state) {
				this.$store.state.leftNavExpanded = state;

				let leftNavState = {
					state: state,
				};

				localStorage.setItem('LEFT_NAV_STATE', JSON.stringify(leftNavState));
			},

			confirmLogout() {
				let binding = {
					icon: 'logout',
					severity: 'info',
          headingText: this.$t('nav.logout-confirm'),
        };
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.doLogout();
					}
				});
			},

			doLogout() {
				this.dialog = false;
				try {
					window.google.accounts.id.disableAutoSelect();
				} catch (err) {
					console.log(err);
				}

				AuthService.logout()
					.then(() => {
						console.log('Logout Success');
						this.podLogout();
					})
					.catch((err) => {
						console.log(err);
						this.podLogout();
					});
			},

			podLogout() {
				let podService = new PodService();
				podService
					.clearCookies()
					.then(() => {
						this.finalizeLogout();
					})
					.catch(() => {
						this.finalizeLogout();
					});
			},

			finalizeLogout() {
				this.$store.commit('logOut');
				location.reload();
			},
			toggleMobileNav() {
				this.mobileNav = !this.mobileNav;
			},

			checkScreen() {
				this.windowWidth = window.innerWidth;
				if (this.windowWidth <= 599) {
					this.mobile = true;
					return;
				}
				this.mobile = false;
				this.mobileNav = false;
			},

			handlePageClick(page) {
				this.$emit('set-page', page.value);
			},
		},

		watch: {
			$route: {
				deep: true,
				immediate: true,
				handler(newVal) {
					console.log(newVal);
					this.currentPath = newVal.fullPath;
					let parts = newVal.path.split('/');
					if (parts.length < 2) {
						Object.assign(this.currentTabsConfig, { tabs: [] });
						return;
					}

					let currentRootPath = '/' + parts[1];
					if (!this.navConfig[currentRootPath]) {
						Object.assign(this.currentTabsConfig, { tabs: [] });
					} else {
						Object.assign(this.currentTabsConfig, this.navConfig[currentRootPath]);
					}
				},
			},
		},

		computed: {
			moreVerb() {
				if (this.visibleTabs.length < this.tabs.length) {
					let tab = this.menuTabs.find((t) => t.uri === this.currentPath);
					if (tab) {
						return tab.label;
					}
				}
				return this.$t('global.more');
			},

      logo: function() {
        if (this.$vuetify.theme.dark && this.$store.state.domain.logoDark) {
          return this.$store.state.domain.logoDark;
        } else {
          return this.$store.state.domain.logo;
        }
      },

			clientInitials() {
				let ignoreMe = ['a', 'an', 'as', 'to', 'and', 'of', 'if', 'in', '-'];
				let accountName = this.$store.getters.getClientName;
				if (!accountName) {
					return '?';
				}
				let words = this.$store.getters.getClientName.toLowerCase().split(' ');
				let ret = [];
				for (let i = 0; i < words.length; i++) {
					if (ignoreMe.indexOf(words[i]) > -1) continue;
					ret[i] = words[i].substring(0, 1);
					if (ret.length == 3) break;
				}
				return ret.join('').toUpperCase();
			},

			features: function () {
				return this.$store.getters.getFeatures;
			},

			initials() {
				if (this.$store.state.loggedInUser) {
					let firstName = this.$store.state.loggedInUser.firstName;
					let lastName = this.$store.state.loggedInUser.lastName;
					let initials = '';

					if (firstName) {
						initials = initials + firstName.slice(0, 1).toUpperCase();
					}

					if (lastName) {
						initials = initials + lastName.slice(0, 1).toUpperCase();
					}

					return initials;
				} else {
					return null;
				}
			},

			// showMenu() {
			// 	return !isEmpty(this.addNewItems);
			// },
		},
	};
</script>

<style lang="scss">
	.settings-menu {
		filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
		box-shadow: none !important;
		border-radius: 8px !important;
		position: absolute;
		background-color: var(--v-white-base);
		z-index: 1000 !important;
	}

	.right {
		display: flex;
		flex-direction: row;
		align-items: center;
		transition: width 200ms linear;
	}

	.right-small {
		width: 110px;
	}

	.right-big {
		width: 150px;
	}

	.top-button {
		width: 40px;
		height: 40px;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.top-umi {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		position: absolute;
		top: 4px;
		right: 2px;
		background-color: var(--v-error-base);
		color: var(--v-white-base);
		width: 16px;
		height: 16px;
		border-radius: 16px;
	}

	.active-top {
		background-color: var(--v-white-base);
	}

	.active {
		color: var(--v-primary-base) !important;
		background-color: var(--v-white-base);
		border: none;
	}

	.user-menu {
		filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
		box-shadow: none !important;
		border-radius: 8px !important;
		background-color: var(--v-white-base);
	}

	#top-nav-wrapper {
		min-height: 54px;
		max-height: 54px;
		width: 100%;
	}

	#top-nav {
		height: 56px;
    max-width: calc(100vw - var(--v-left-nav-width));
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: var(--v-sidebar-base);

		#left-side {
		}

		#right-side {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			img {
				cursor: pointer;
			}

			//.v-image.v-responsive {
			//	width: 40px !important;
			//	height: 40px !important;
			//}
			.profile-pic {
				margin-left: 20px;
				cursor: pointer;
				background-position: center center;
				background-size: contain;
				height: 30px;
				width: 30px;
				border-radius: 15px;
				background-color: var(--v-primary-base);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
