<template>
	<div>
		<v-menu
			ref="menu"
			v-model="dateMenu"
			:close-on-content-click="true"
			transition="scale-transition"
			offset-y
			:disabled="disabled"
			min-width="290px"
		>
			<template v-slot:activator="{ on }">
				<div v-if="minimal" v-on="on" class="pointer">
					<div v-if="dateFormattedMinimal" :class="contentClass" style="white-space: nowrap">
						{{ dateFormattedMinimal }}
					</div>
					<v-icon v-else size="20">$calendarMono</v-icon>
				</div>
				<div v-else-if="micro" v-on="on" :class="`pointer ${microFont ? microFont : 'font-12'}`">
					<div v-if="dateFormattedMinimal" :class="contentClass" style="white-space: nowrap">
						{{ dateFormattedMinimal }}
					</div>
					<div v-else v-html="label"></div>
				</div>
				<v-text-field
					v-else-if="standard"
					:value="dateFormatted"
					readonly
					v-on="on"
					:label="label"
					:disabled="disabled"
					hide-details
					dense
					outlined
					:persistent-placeholder="label ? true : false"
					:class="(contentClass ? contentClass : '') + ' pointer'"
					append-icon="event"
				>
				</v-text-field>
				<v-text-field
					v-else
					:value="dateFormatted"
					readonly
					v-on="on"
					:label="label"
					:disabled="disabled"
					hide-details
					:persistent-placeholder="label ? true : false"
					:class="(contentClass ? contentClass : '') + ' h-outline pointer'"
					append-icon="$calendar"
				>
				</v-text-field>
			</template>
			<v-date-picker class="datepicker" v-model="localDate" no-title scrollable @change="dateUpdated">
				<template v-slot:default>
					<div style="width: 100%" class="row-format centered">
						<div
							class="input-wrapper font-14 font-primary py-1 px-2 pointer"
							@click="
								localDate = null;
								dateUpdated();
							"
						>
							{{ $t('global.clear') }}
						</div>
					</div>
				</template>
			</v-date-picker>
		</v-menu>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';

	export default {
		name: 'DateSelector',

		props: ['date', 'label', 'disabled', 'contentClass', 'minimal', 'micro', 'standard', 'microFont', 'showYear'],

		components: {},

		data: function () {
			return {
				localDate: this.date,
				dateMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			dateUpdated: function () {
				this.$emit('change', this.localDate);
			},
		},

		computed: {
			dateFormatted: function () {
				if (this.localDate) {
					return DateTime.fromISO(this.localDate).toLocaleString(DateTime.DATE_SHORT);
				} else {
					return ' -- ';
				}
			},

			dateFormattedMinimal: function () {
				if (this.localDate) {
					if (this.showYear) {
						return DateTime.fromISO(this.localDate).toLocaleString({
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						});
					} else {
						return DateTime.fromISO(this.localDate).toLocaleString({ month: 'short', day: 'numeric' });
					}
				} else {
					return null;
				}
			},
		},

		watch: {
			date: function (newVal) {
				this.localDate = newVal;
			},
		},
	};
</script>

<style lang="scss">
	.datepicker .v-date-picker-header {
		display: flex !important;
		flex-direction: row;
	}

	.v-input.h-outline ::v-deep {
		input {
			font-size: 14px;
		}
	}
	//.select-append {
	//	border-left: 1px solid var(--v-gray_50-base);
	//	margin-top: 1px;
	//	margin-bottom: 8px;
	//	width: 45px;
	//	height: 25px;
	//}
</style>
