<template>
	<div class="text-input">
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
			<label v-html="htmlText" class="question"></label>
			<v-textarea
				type="text"
				outlined
				dense
				hide-details
				persistent-placeholder
				:placeholder="placeholder"
				:rules="required ? inputRequired : []"
				@input="$emit('input', $event)"
				:rows="parseInt(rows,10)"
				auto-grow
				:disabled="previewMode"
			/>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'TextInput',
		props: ['htmlHeader', 'options', 'htmlText', 'fieldType', 'previewMode','required'],

		mounted() {},

		data() {
			return {
				isValid: false,
				inputRequired: [(v) => !!v || 'Required'],
				rows: (this.options && this.options.length) ? this.options[0] : "1"
			};
		},

		methods: {
			validate: function () {
				return this.$refs.form.validate();
			},
		},

		computed: {
			placeholder: function () {
				if (this.previewMode) {
					return this.$t('discovery.client-answer');
				} else {
					return this.$t('discovery.your-answer');
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
