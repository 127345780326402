<template>
	<div v-if="isReady" id="meeting-detail" :style="scheduledMeeting.formData.answers.length ? 'min-width: 60vw; max-width: 60vw;' : 'width:500px'">
		<div id="meeting-inner-wrapper" class="pa-5 d-flex">
			<div class="row-format mb-2 align-center">
				<div class="brand-medium font-18 ml-1" v-if="view === 'MAIN'">{{scheduledMeeting.meetingName}}</div>
				<v-icon v-else @click="view = 'MAIN'">$arrowLeft</v-icon>
				<v-icon size="20" class="ml-auto pointer" @click="$emit('result')">$close</v-icon>
			</div>
			<div v-if="view === 'MAIN'">
				<div>
					<v-container class="ml-0 pl-1">
						<v-row>
							<v-col :md="scheduledMeeting.formData.answers.length ? 6 : 12" cols="12" class="ml-0" align="left">
								<v-container class="pa-2 ma-0 font-14">
									<v-row dense v-if="isCancelled">
										<v-col cols="3" class="brand-medium">{{ $t('meetings.detail.status') }}</v-col>
										<v-col cols="8" class="brand-medium" style="color: var(--v-error-base)">{{ $t('meetings.detail.cancelled') }}</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="3" class="brand-medium">{{ $t('meetings.detail.name') }}</v-col>
										<v-col cols="8">{{scheduledMeeting.formData.firstName}} {{scheduledMeeting.formData.lastName}}</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="3" class="brand-medium">{{ $t('meetings.detail.email') }}</v-col>
										<v-col cols="8">{{scheduledMeeting.formData.email}}</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="3" class="brand-medium">{{ $t('meetings.detail.phone') }}</v-col>
										<v-col cols="8">{{scheduledMeeting.formData.phone}}</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="3" class="brand-medium">{{ $t('meetings.detail.time') }}</v-col>
										<v-col cols="8" :class="isCancelled ? 'strike' : ''">{{ DateTime.fromISO(scheduledMeeting.confirmedTime.start).toFormat('cccc, LLL dd yyyy \'@\'  t') }}</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="3" class="brand-medium">{{ $t('meetings.detail.duration') }}</v-col>
										<v-col cols="8" :class="isCancelled ? 'strike' : ''">{{ DateTime.fromISO(scheduledMeeting.confirmedTime.end).diff(DateTime.fromISO(scheduledMeeting.confirmedTime.start),"minutes").minutes }} {{ $t('meetings.detail.minutes') }}</v-col>
									</v-row>
									<div v-if="!isCancelled">
										<div v-if="scheduledMeeting.location.type === 'Zoom'">
											<v-row dense>
												<v-col cols="3">{{ $t('meetings.detail.join') }}</v-col>
												<v-col cols="8"><a :href="scheduledMeeting.zoomMeeting.join_url" target="_blank">{{scheduledMeeting.zoomMeeting.join_url}}</a></v-col>
											</v-row>
											<v-row dense>
												<v-col cols="3">{{ $t('meetings.detail.id') }}</v-col>
												<v-col cols="8">{{scheduledMeeting.zoomMeeting.id}}</v-col>
											</v-row>
											<v-row dense v-if="scheduledMeeting.zoomMeeting.pstnPassword">
												<v-col cols="3">{{ $t('meetings.detail.passcode') }}</v-col>
												<v-col cols="8">{{scheduledMeeting.zoomMeeting.pstnPassword}}</v-col>
											</v-row>
											<v-row dense v-if="zoomDialIn">
												<v-col cols="3">{{ $t('meetings.detail.dial-in') }}</v-col>
												<v-col cols="8">{{zoomDialIn.number.replaceAll(' ','')}} ({{zoomDialIn.city}}, {{zoomDialIn.country_name}})</v-col>
											</v-row>
										</div>
										<div v-else-if="scheduledMeeting.location.type === 'Google'">
											<v-row dense>
												<v-col cols="3">{{ $t('meetings.detail.join') }}</v-col>
												<v-col cols="8"><a :href="scheduledMeeting.googleMeeting.hangoutLink" target="_blank">{{scheduledMeeting.googleMeeting.hangoutLink}}</a></v-col>
											</v-row>
											<v-row dense v-if="googleDialIn">
												<v-col cols="3">{{ $t('meetings.detail.dial-in') }}</v-col>
												<v-col cols="8">{{googleDialIn.label}} ({{googleDialIn.regionCode}})</v-col>
											</v-row>
											<v-row dense v-if="googleDialIn">
												<v-col cols="3">{{ $t('meetings.detail.pin') }}</v-col>
												<v-col cols="8">{{googleDialIn.pin}}</v-col>
											</v-row>
										</div>
										<div v-else-if="scheduledMeeting.location.type === 'PhoneIn'">
											<v-row dense>
												<v-col cols="12">{{ $t('meetings.detail.invitee-call') }} {{scheduledMeeting.location.phone}}</v-col>
											</v-row>
										</div>
										<div v-else-if="scheduledMeeting.location.type === 'PhoneOut'">
											<v-row dense>
												<v-col cols="12">{{ $t('meetings.detail.call-invitee') }} {{scheduledMeeting.formData.phone}}</v-col>
											</v-row>
										</div>
										<div v-else-if="scheduledMeeting.location.type === 'InPerson'">
											<v-row dense>
												<v-col cols="12">{{ $t('meetings.detail.meet') }} {{scheduledMeeting.location.address}}</v-col>
											</v-row>
										</div>
										<div v-else>
											<v-row dense>
												<v-col cols="12">{{scheduledMeeting.location.custom}}</v-col>
											</v-row>
										</div>
									</div>
									<v-row v-if="!isCancelled" dense>
										<v-col cols="12" class="mt-2">
												<v-btn class="secondary-action mb-1 mr-2" @click="view='CANCEL'"><span style="color: var(--v-error-base)">{{ $t('meetings.detail.cancel') }}</span></v-btn>
												<v-btn class="primary-action mb-1" @click="view='RESCHEDULE'">{{ $t('meetings.detail.reschedule') }}</v-btn>
										</v-col>
									</v-row>
								</v-container>
							</v-col>
							<v-col md="6" cols="12" align="left"  v-if="scheduledMeeting.formData.answers.length">
								<v-container class="pa-2 ma-0 font-14" style="background-color: var(--v-gray_20-base); border-radius: 4px">
									<v-row dense>
										<v-col class="pa-4">
											<div v-for="(answer,index) in scheduledMeeting.formData.answers" :key="index" class="text-left font-14 pb-3">
												<div class="brand-medium">{{answer.question}}</div>
												<div>{{answer.answer}}</div>
											</div>
										</v-col>
									</v-row>
								</v-container>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</div>
		</div>
		<div v-if="view === 'CANCEL'">
			<i-frame-wrapper :frame-url="cancelUrl" @frame-event="frameEvent"></i-frame-wrapper>
		</div>
		<div v-if="view === 'RESCHEDULE'">
			<i-frame-wrapper :frame-url="rescheduleUrl" @frame-event="frameEvent"></i-frame-wrapper>
		</div>
	</div>
</template>

<script>
	import MeetingService from "@/modules/meetings/MeetingService";
	import {DateTime} from 'luxon';
  import IFrameWrapper from "@/modules/meetings/iFrameWrapper";

	export default {
		name: 'MeetingDetail',

		props: ['scheduledMeeting'],

		components: {IFrameWrapper},

		data: function () {
			return {
				view: 'MAIN',
				uniqueAccountUrl: null,
				meetingService: new MeetingService(),
				DateTime:DateTime,
				baseUrl: process.env.VUE_APP_MEET_LINK,
        meeting: null,
        isReady: false,
			};
		},

		mounted() {
      this.getMeeting();
		},

		beforeDestroy() {},

		methods: {
      frameEvent: function(event){
        if(event.data === 'MEETING_CANCELLED' || event.data === 'MEETING_RESCHEDULED'){
          this.$emit('result',true);
        }
      },

      getMeeting: function () {
        this.meetingService.getMeeting(this.scheduledMeeting.meetingId).then((res) => {
          this.meeting = res.data;
          this.isReady = true;
        })
      },
		},

		computed: {
			fullMeetingLink: function () {
				return this.meeting.meetingLink;
			},

			cancelUrl: function(){
				return `${this.fullMeetingLink}/?id=${this.scheduledMeeting.id}&action=cancel&inFrame=true`;
			},

			rescheduleUrl: function(){
				return `${this.fullMeetingLink}/?id=${this.scheduledMeeting.id}&action=change&inFrame=true`;
			},

			isCancelled: function(){
				if(this.scheduledMeeting && this.scheduledMeeting.meetingStatus === 'Cancelled'){
					return true;
				}else{
					return false;
				}
			},

      zoomDialIn: function(){
        if(this.scheduledMeeting.zoomMeeting &&
            this.scheduledMeeting.zoomMeeting.settings &&
            this.scheduledMeeting.zoomMeeting.settings.global_dial_in_numbers &&
            this.scheduledMeeting.zoomMeeting.settings.global_dial_in_numbers.length){
          return this.scheduledMeeting.zoomMeeting.settings.global_dial_in_numbers[0];
        }else{
          return null;
        }
      },

			googleDialIn: function(){
				if(this.scheduledMeeting.googleMeeting){
					return this.scheduledMeeting.googleMeeting.entryPoints.find(e => e.entryPointType === 'phone');
				}else{
					return null;
				}
			}
		},
	};
</script>

<style scoped lang="scss">
#meeting-detail {
	#meeting-inner-wrapper {
		max-height: calc(100vh - 180px);
		overflow-y: auto;
		display: flex;
		flex-direction: column;
	}

	.modal-footer {
		position: sticky;
		position: -webkit-sticky;
		margin-top: 8px;
	}

	.strike {
		text-decoration: line-through;
	}
}
</style>
