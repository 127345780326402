<template>
	<div :class="signatureContainerCss">
		<v-container fluid fill-height class="pa-0 ma-0">
			<v-row v-if="isSigned">
				<v-col cols="12">
					<div class="signatureWrapper" v-if="shouldRenderKeyboard" :style="signatureFont">
						<div class="signature">{{ signatureObj.signature }}</div>
					</div>

					<div v-if="shouldRenderPad" class="svgSignature">
						<div v-html="svgSignature"></div>
					</div>
				</v-col>
			</v-row>

			<div v-if="!isSigned" style="width: 100%;">
				<div v-if="isApp" style="width: 100%;">
					<v-row v-if="setSigner">
						<v-col cols="12" style="display: flex; flex-direction: row;">
							<div style="display: flex; flex-direction: column; width: 90%;" class="ml-2">
								<v-text-field
									:label="$t('proposal.name')"
									v-model="signerName"
									:hide-details="true"
									style="font-size: 0.9em;"
									@keypress.native.enter="saveSignerInfo()"
								></v-text-field>
								<v-text-field
									:label="$t('proposal.title')"
									v-model="signerTitle"
									:hide-details="true"
									style="font-size: 0.9em;"
									@keypress.native.enter="saveSignerInfo()"
								></v-text-field>
							</div>
						</v-col>
					</v-row>

					<v-row v-if="!setSigner">
						<v-col cols="12" class="pa-0 ma-0" align="center">
							<v-btn v-if="isThem && !editMode" :disabled="true" text>
								{{ $t('proposals.pending') }}
							</v-btn>
							<v-btn v-if="isThem && editMode" text @click="setSignerInfo()" :disabled="templateMode">
								{{ this.signatureObj.signerLocked ? 'Change signatory' : 'Set signatory (optional)' }}
							</v-btn>
							<v-btn v-if="isUs" text @click="doSign = true" :disabled="templateMode">
								{{ editMode ? 'Sign before sending' : 'Sign' }}
							</v-btn>
						</v-col>
					</v-row>
				</div>

				<v-row v-if="isPortal">
					<v-col cols="12" class="pa-0 ma-0">
						<v-btn v-if="isThem" text @click="requestThemSign()" :disabled="!deliverableSelected">
							{{ deliverableSelected ? 'Sign' : 'Select Package' }}
						</v-btn>
						<v-btn v-if="isUs" text disabled>
							{{ $t('proposals.pending') }}
						</v-btn>
					</v-col>
				</v-row>
			</div>

			<basic-modal v-if="!isSigned" :dialog="doSign" persistent @close="doSign = false">
				<signature-dialog
					:signature-object="signatureObj"
					:mode="mode"
					:client-mode="clientMode"
					@cancel="doSign = false"
					@signed="finalizeSign($event)"
				></signature-dialog>
			</basic-modal>
		</v-container>
	</div>
</template>

<script>
	import SignatureDialog from './SignatureDialog';
	import BasicModal from '@/components/BasicModal';

	export default {
		name: 'Signature',

		props: ['signature', 'mode', 'clientMode', 'editMode', 'templateMode', 'deliverableSelected'],

		components: {
			SignatureDialog,
			BasicModal,
		},

		data: function() {
			return {
				signatureObj: null,
				doSign: false,
				setSigner: false,
				signerName: null,
				signerTitle: null,
				signatureType: 'Keyboard',
			};
		},

		created() {
			this.signatureObj = this.signature;
			this.signerName = this.signatureObj.signerName;
			this.signerTitle = this.signatureObj.signerTitle;
		},

		methods: {
			setSignerInfo() {
				if (this.templateMode) return;
				this.setSigner = true;
				this.$emit('set-signer');
			},

      requestThemSign: function(){
        this.$emit('request-them-sign');
      },

      allowThemSign: function(){
        this.doSign = true;
      },

			cancelSignerInfo() {
				this.signerName = this.signatureObj.signerName;
				this.signerTitle = this.signatureObj.signerTitle;
				this.setSigner = false;
			},

			saveSignerInfo() {
				this.signatureObj.signerName = this.signerName;
				this.signatureObj.signerTitle = this.signerTitle;

				if (this.$validations.isEmpty(this.signerName) && this.$validations.isEmpty(this.signerTitle)) {
					this.signatureObj.signerLocked = false;
				} else {
					this.signatureObj.signerLocked = true;
				}

				this.setSigner = false;
				this.$emit('signer-info', this.signatureObj);
			},

			finalizeSign(signatureObj) {
				this.signatureObj = signatureObj;
				this.$emit('signed', this.signatureObj);
				this.doSign = false;
			},
		},

		computed: {
			isUs: function() {
				return this.mode === 'us';
			},

			isThem: function() {
				return this.mode === 'them';
			},

			isApp: function() {
				if (this.clientMode === false) {
					return true;
				} else {
					return false;
				}
			},

			isPortal: function() {
				if (this.clientMode === true) {
					return true;
				} else {
					return false;
				}
			},

			isSigned: function() {
				return this.shouldRenderKeyboard || this.shouldRenderPad;
			},

			signatureFont: function() {
				return `font-family: ${this.signatureObj.font}, cursive;`;
			},

			shouldRenderKeyboard: function() {
				if (this.signatureObj.type === 'Keyboard' && !this.$validations.isEmpty(this.signatureObj.signature)) {
					return true;
				} else {
					return false;
				}
			},

			shouldRenderPad: function() {
				if (this.signatureObj.type === 'Pad' && !this.$validations.isEmpty(this.signatureObj.signature)) {
					return true;
				} else {
					return false;
				}
			},

			svgSignature: function() {
				if (this.signatureObj.type === 'Pad') {
					return atob(this.signatureObj.signature);
				} else {
					return null;
				}
			},

			signatureContainerCss: function() {
				if (!this.isSigned && !this.setSigner) {
					return 'signatureContainer signatureContainerBorder' + (this.templateMode ? ' templateMode' : '');
				} else {
					return 'signatureContainer' + (this.templateMode ? ' templateMode' : '');
				}
			},
		},

		watch: {
			editMode: function(newVal) {
				if (newVal === false) {
					this.setSigner = false;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.signatureContainer {
		height: 120px;
		width: 100%;
	}

	.templateMode {
		opacity: 0.7;
	}

	.signatureContainerBorder {
		border: 2px solid var(--v-primary-base);
		border-radius: 8px;
		&.templateMode {
			border: 2px solid var(--v-gray_50-base);
		}
	}

	.signatureWrapper {
		margin-left: 20px;
		font-size: 35px;
		height: 100px;
		position: relative;
	}

	.signature {
		position: absolute;
		bottom: 20px;
		left: 0px;
	}

	.svgSignature {
		height: 120px;
	}

	.signButtons {
		margin-left: 10px;
	}

	.v-text-field {
		font-size: 30px;
	}
</style>
