<template>
	<div id="proposal-add-component">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">{{ $t('proposal.content.add') }}</div>
			<!--			<div class="modal-subtitle">{{ $t('proposal.content.subtitle') }}</div>-->

			<div class="section">
				<div class="content-heading">{{ $t('proposal.content.content') }}</div>

				<div class="tiles">
					<div class="tile" @click="addSection('TextArea')">
						<div class="icon-box">
							<v-icon size="32">$text</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.text-block')"></div>
					</div>

					<div class="tile" @click="addSection('Files')">
						<div class="icon-box">
							<v-icon size="32">$attachment</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.files')"></div>
					</div>

					<div class="tile" @click="addSection('ImageTextBlock')">
						<div class="icon-box">
							<v-icon size="32">$imageAndText</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.image-caption')"></div>
					</div>

					<div class="tile" @click="addSection('ImageBlock')">
						<div class="icon-box">
							<v-icon size="32">$image</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.image')"></div>
					</div>
				</div>
			</div>

			<div class="section" v-if="termsCount == 0 || (paymentCount == 0 && integratedPaymentsEnabled)">
				<div class="content-heading">{{ $t('proposal.content.legal') }}</div>
				<div class="tiles">
					<div class="tile" @click="addSection('Terms')" v-if="termsCount === 0">
						<div class="icon-box">
							<v-icon size="32">$handshake</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.contract')"></div>
					</div>
					<div class="tile" @click="addSection('PaymentCaptureBlock')" v-if="paymentCount === 0 && integratedPaymentsEnabled">
						<div class="icon-box">
							<v-icon size="32">$money</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.payment-instructions')"></div>
					</div>
				</div>
			</div>

			<div class="section" v-if="!signatureCount && !acceptCount">
				<div class="content-heading">{{ $t('proposal.content.finalize') }}</div>
				<div class="tiles">
					<div class="tile" @click="addSection('SignatureBlock')">
						<div class="icon-box">
							<v-icon size="32">$signature</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.signatures')"></div>
					</div>

					<div class="tile" @click="addSection('AcceptBlock')">
						<div class="icon-box">
							<v-icon size="32">$success</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.accept')"></div>
					</div>
				</div>
			</div>

			<div v-if="isPaid && packageList.length && !gettingPackages" class="section">
				<div class="content-heading">{{ $t('proposal.content.fee-structure') }}</div>
				<div class="tiles packages">
					<div class="tile" @click="addSection('Deliverables')">
						<div class="icon-box">
							<v-icon size="24">$plus</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.from-scratch')"></div>
					</div>
					<div class="content-heading copy-fees">
						{{ $t('proposal.content.copy-deliverable-packages') }}
					</div>
					<!-- Package list -->
					<div
						v-for="(pkg, i) in packageList"
						:key="pkg.id"
						:class="packageClass(i)"
						@click="addSectionFromPackage(pkg.id)"
					>
						<div class="icon-box">
							<v-icon size="24">$box</v-icon>
						</div>
						<div class="instructions">{{ pkg.name }}</div>
					</div>
					<!-- Show more -->
					<div class="tile" v-if="packageList.length > 3" @click="showAllPackages = !showAllPackages">
						<div class="icon-box">
							<v-icon size="24">$more-hor</v-icon>
						</div>
						<div class="instructions" v-if="showAllPackages">{{ $t('proposal.content.see-less') }}</div>
						<div class="instructions" v-else>{{ $t('proposal.content.see-more') }}</div>
					</div>
					<!-- No packages exist -->
					<!--					<div class="tile no-packages" v-if="!gettingPackages && !packageList.length">-->
					<!--						<div class="icon-box">-->
					<!--							<v-icon size="24">$box</v-icon>-->
					<!--						</div>-->
					<!--						<div class="instructions" v-html="$t('proposal.content.no-packages')"></div>-->
					<!--					</div>-->
				</div>
			</div>

			<div v-else class="section">
				<div class="content-heading">{{ $t('proposal.content.fee-structure') }}</div>
				<div class="tiles">
					<div class="tile" @click="addSection('Deliverables')">
						<div class="icon-box">
							<v-icon size="24">$box</v-icon>
						</div>
						<div class="instructions" v-html="$t('proposal.content.from-scratch')"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>


	export default {
		name: 'AddComponent',

		props: ['signatureCount', 'termsCount','acceptCount','paymentCount'],

		data: function() {
			return {
				templateService: { },
				// deliverablePackageId: null,
				packageList: [],
				showAllPackages: false,
				gettingPackages: false,
			};
		},

		mounted() {
			this.getPackageList();
		},

		methods: {
			addSection: function(componentType) {
				this.$emit('add-section', { type: componentType });
				this.$emit('close');
			},

			addSectionFromPackage: function(id) {
				this.templateService
					.createProposalDeliverables(id)
					.then((res) => {
						console.log(res);
						let args = { type: 'Deliverables', deliverableBlock: res.data };
						this.$emit('add-section', args);
						this.$emit('close');
					})
					.catch((e) => {
						console.log(e);
					});
			},

			getPackageList: function() {
				this.gettingPackages = true;
				this.templateService.getDeliverablePackages().then((res) => {
					this.packageList = [];
					this.packageList.push(...res.data);
					this.gettingPackages = false;
				});
			},

			packageClass(i) {
				let css = ['tile'];
				if (this.showAllPackages) return css.join(' ');
				if (i > this.packagesToShow - 1) {
					css.push('no-show');
				}
				return css.join(' ');
			},
		},

		computed: {
			integratedPaymentsEnabled: function() {
				if (
						this.$store.state.podAccount.stripeIntegration &&
						this.$store.state.podAccount.stripeIntegration.chargesEnabled
				) {
					return true;
				} else {
					return false;
				}
			},

			packagesToShow() {
				if (this.packageList.length === 3) {
					return 3;
				}
				if (this.packageList.length === 0) {
					return 0;
				}
				return 2;
			},
			isPaid: function() {
				// return false;
				return this.$store.getters.isPaidAccount;
			},

			componentTypes: function() {
				let types = [];

				for (let i = 0; i < this.baseComponentTypes.length; i++) {
					if (this.baseComponentTypes[i].type === 'SignatureBlock' && this.signatureCount > 0) {
						continue;
					} else if (this.baseComponentTypes[i].type === 'Terms' && this.termsCount > 0) {
						continue;
					}

					types.push(this.baseComponentTypes[i]);
				}
				return types;
			},
		},
	};
</script>

<style lang="scss">
	#proposal-add-component {
		background-color: var(--v-white-base);
		width: 420px;

		.modal-title,
		.modal-subtitle {
			//text-align: center;
		}
		.section {
			&:not(:first-child) {
				margin-top: 16px;
			}
			.content-heading {
				margin-bottom: 8px;
				font-size: 14px;
				font-weight: 500;
				color: var(--v-gray_80-base);
				text-align: left;
				&.copy-fees {
					margin-top: 8px;
					margin-bottom: 0;
					font-size: 12px;
				}
			}

			.tiles {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 8px;
				.tile {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 8px;
					cursor: pointer;
					background: var(--v-gray_20-base);
					border-radius: 4px;
					height: 100px;
					overflow: hidden;
					transition: height 300ms, margin-top 300ms;
					&:not(.first-child) {
						margin-top: 8px;
					}
					&.no-show {
						height: 0 !important;
						transition: height 300ms, margin-top 300ms;
						margin-top: 0;
					}
					&:hover {
						background: var(--v-gray_10-base);
						svg {
							fill: var(--v-primary-base);
							--secondary-opacity: 1;
							--secondary-color: var(--v-accent-base);
						}
					}
					//&.no-packages {
					//	background: var(--v-white-base);
					//	border: 1px dotted var(--v-gray_50-base);
					//}
					.instructions {
						font-size: 14px;
						line-height: 18px;
						font-weight: 400;
					}
				}

				&.packages {
					grid-template-columns: 100%;
					.tile {
						flex-direction: row;
						justify-content: flex-start;
						height: 48px;
						padding: 0 12px;
						gap: 12px;
					}
				}
			}
		}
	}
</style>
