<template>
  <div id="invoice-details">
    <div id="header-area" class="" v-if="invoice">
      <div class="header-left pl-md-6 pl-4">
        <div class="mr-6 brand-medium">
          <h-icon2 name="invoices" :active="true" size="24" class="mr-4"></h-icon2>
          {{ $t('invoice.invoice-number-appended', { invoiceNumber: invoice.invoiceNumberFormatted }) }}
        </div>
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-menu :close-on-click="true" :nudge-bottom="30" :disabled="!invoice.payments.length">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="secondary-action" :disabled="!invoice.payments.length">
                <v-icon size="14" class="mr-1">$time</v-icon>
                <span class="body-12">{{ $t('invoices.details.payment-history') }}</span>
                <span
                    v-if="invoice.payments.length"
                    class="body-10 ml-2 px-1"
                    style="background-color: var(--v-gray_10-base)"
                >{{ invoice.payments.length }}</span
                >
              </v-btn>
            </template>
            <payment-history :invoice="invoice" @openPayment="openPaymentDetail($event)"></payment-history>
          </v-menu>
        </div>
      </div>
      <div class="header-right pr-md-6 pr-4">
        <div>
          <v-btn icon class="mr-3" @click="downloadInvoice">
            <v-icon size="25" color="gray_60">$download</v-icon>
          </v-btn>
          <v-btn icon class="primary-action" @click="handleClose()">
            <v-icon :size="20">$close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="show-scrollbar">
      <component
          style="max-height: calc(100vh - 55px); height: calc(100vh - 55px)"
          :is="rendererType"
          v-if="invoice && account"
          :invoice="invoice"
          :scheduled-payments="invoice.scheduledPayments"
          :account="account"
          :edit-mode="false"
          :key="refreshKey"
          :attachment-service="invoiceService"
          :invoice-service="invoiceService"
      ></component>
    </div>
  </div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
  import InvoiceRenderer from '@/modules/invoices/InvoiceRenderer/InvoiceRenderer';
  import PaymentHistory from '@/modules/invoices/InvoiceRenderer/PaymentHistory';
  import PaymentDetailsModal from '@/modules/invoices/InvoiceRenderer/PaymentDetailsModal';
  import InvoiceRendererV2 from '@/modules/invoices/invoiceRendererV2/InvoiceRendererV2';

  export default {
		name: 'InvoiceDetails',

		props: ['invoiceId'],

		components: {InvoiceRenderer,InvoiceRendererV2,PaymentHistory},

		data: function() {
			return {
        refreshKey:0,
        invoice:null,
        account:null,
        invoiceService: new InvoiceService(),
      };
		},

		mounted() {
      this.invoiceService.getInvoice(this.invoiceId).then((res) => {
        this.invoice = res.data.invoice;
        this.account = res.data.account;
        this.invoiceService.setInvoice(this.invoice);
      })
    },

		beforeDestroy() {},

		methods: {
      handleClose: function(){
        this.$emit('result');
      },

      openPaymentDetail(payment){
        let binding = {
          invoice:this.invoice,
          payment:payment,
          editMode:false
        }
        this.$store.state.globalModalController.openModal(PaymentDetailsModal,binding,true,false);
      },

      downloadInvoice: function(){
        this.$store.commit('startLoading')
        this.invoiceService
            .downloadInvoice()
            .then((res) => {
              let fileURL = window.URL.createObjectURL(new Blob([res.data]));

              let fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'Invoice-' + this.invoice.invoiceNumberFormatted + '.pdf');
              document.body.appendChild(fileLink);
              fileLink.click();
            }).finally(() => this.$store.commit('stopLoading'))
      }
    },

		computed: {
      rendererType: function(){
        return (this.invoice && this.invoice.invoiceTemplate) ? 'InvoiceRendererV2' : 'InvoiceRenderer';
      },
    },
	};
</script>

<style scoped lang="scss">
	#invoice-details {
		background-color: var(--v-white-base);
		#header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
