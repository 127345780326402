<template>
	<div class="mx-2">
		<pie-chart :chart-data="chartData" :options="options" style="height:450px"></pie-chart>
	</div>
</template>

<script>
	import PieChart from '@/modules/charts/PieChart';

	export default {
		name: 'TimeByProject',

		props: ['timerEvents'],

		components: { PieChart },

		data: function() {
			return {
				options: {
					showTooltips: true,
					responsive: true,
					maintainAspectRatio: false,

					legend: {
						position: 'bottom',
					},

					elements: {},

					tooltips: {
						callbacks: {
							title: function(tooltipItems) {
								return tooltipItems[0].xLabel;
							},
							label: function(tti, data) {
								let seconds = data.datasets[tti.datasetIndex].data[tti.index];

                let hours = Math.floor(seconds / 3600);
                let minutes = Math.floor((seconds - hours * 3600) / 60);
                seconds = Math.floor(seconds - hours * 3600 - minutes * 60);

                if (hours < 10) {
                  hours = '0' + hours;
                }
                if (minutes < 10) {
                  minutes = '0' + minutes;
                }
                if (seconds < 10) {
                  seconds = '0' + seconds;
                }

                let dataValue = hours + ':' + minutes + ':' + seconds;

								return ` ${data.labels[tti.index]}: ${dataValue}`;
							},
						},
					},
				},
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			incrementOrSet(map, projectId, projectName, amount) {
				if (!map.has(projectId)) {
					map.set(projectId, {
						projectId: projectId,
						projectName: projectName,
						amount: 0,
					});
				}

				map.get(projectId).amount += amount;
			},
		},

		computed: {
			chartData: function() {
				return {
					labels: this.labels,
					datasets: [
						{
							label: this.$t('timetrack.reports.time-by-project'),
							data: this.timeWorked,
							backgroundColor: this.$store.state.chartColors,
						},
					],
				};
			},

			labels: function() {
				let labels = [];

				this.timeByProject
					.filter((i) => i.amount > 0)
					.forEach((i) => {
						labels.push(i.projectName ? i.projectName : this.$t('timetrack.no-projects'));
					});

				return labels;
			},

			timeWorked: function() {
				let values = [];

				this.timeByProject
					.filter((i) => i.amount > 0)
					.forEach((i) => {
						values.push(i.amount);
					});

				return values;
			},

			timeByProject: function() {
				if (!this.timerEvents.length) {
					return [];
				}

				let projectMap = new Map();

				for (let i of this.timerEvents) {
					this.incrementOrSet(projectMap, i.projectId, i.projectName, i.durationSeconds);
				}

				let result = [];
				projectMap.forEach((value) => result.push(value));

				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
