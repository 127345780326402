<template>
	<v-row justify="center" align="center">
			<v-progress-circular :indeterminate="true" size="200" color="light-blue"></v-progress-circular>
	</v-row>
</template>

<script>
	import AuthService from "@/modules/auth/AuthService";

	export default {
		name: 'MagicToken',

		data() {
			return {

			};
		},

		mounted() {
			AuthService.logout().finally(() => {
        self.location.href = document.location.protocol + '//' + document.location.host + '/login';
      })
		},

	};
</script>

<style scoped></style>
