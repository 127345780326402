<template>
	<div class="new-ach-dialog-div">
		<v-container fluid class="mt-0 mb-4" v-if="stripeAccountId">
			<v-row dense>
				<v-col cols="1"></v-col>
				<v-col cols="10"><h2>{{ $t("invoices.manual-ach.account-info") }}</h2></v-col>
				<v-col cols="1"><v-icon @click="handleClose">$close</v-icon></v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<v-form ref="accountForm" @submit.prevent v-model="isValid">
						<v-text-field
							v-model="accountHolderName"
							:label="$t('invoices.manual-ach.name')"
							hide-details
							:rules="noBlank"
							persistent-placeholder
							class="h-outline"
						></v-text-field>
						<v-select
							v-model="accountHolderType"
							:items="holderTypes"
							:label="$t('invoices.manual-ach.type')"
							hide-details
							persistent-placeholder
							class="h-outline"
						></v-select>
						<v-text-field
							v-model="routingNumber"
							:label="$t('invoices.manual-ach.routing-number')"
							hide-details
              maxlength="9"
							:rules="requiredRules"
							persistent-placeholder
							class="h-outline"
						></v-text-field>
						<v-text-field
							v-model="accountNumber"
							:label="$t('invoices.manual-ach.account-number')"
							hide-details
							:rules="requiredRules"
							persistent-placeholder
							class="h-outline"
						></v-text-field>
						<v-select
							v-model="accountType"
							:items="accountTypes"
							:label="$t('invoices.manual-ach.account-type')"
							hide-details
							persistent-placeholder
							class="h-outline"
						></v-select>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
		<div style="border-top: 1px solid var(--v-gray_50-base)" class="pt-2 pb-4">
			<v-btn class="secondary-action mr-1" @click="handleClose">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="primary-action ml-2" @click="linkNewAccount" :disabled="!isValid">{{ $t('invoices.payment.new-linked') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import PaymentsService from '@/modules/invoices/PaymentsService';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'ManualAchAccount',

		props: [''],

		components: {},

		data: function() {
			return {
				accountHolderName: null,
				accountNumber: null,
				routingNumber: null,
				accountHolderType: 'individual',
				accountType: 'checking',
				paymentsService: new PaymentsService(),
				stripeAccountId: null,
				isValid: false,

				accountTypes: [
					{ text: this.$t('invoices.manual-ach.checking'), value: 'checking' },
					{ text: this.$t('invoices.manual-ach.savings'), value: 'savings' },
				],

				holderTypes: [
					{ text: this.$t('invoices.manual-ach.individual'), value: 'individual' },
					{ text: this.$t('invoices.manual-ach.company'), value: 'company' },
				],

        noBlank: [
          (v) => !!v || 'Required'
        ],

				requiredRules: [
          (v) => !!v || 'Required',
          (v) => v && v.length >= 5 || 'Required',
        ],
			};
		},

		mounted() {
			this.getStripeAccountId();
		},

		beforeDestroy() {},

		methods: {
			getStripeAccountId: function() {
				this.paymentsService.getStripeAccountId().then((res) => {
					this.stripeAccountId = res.data;
				});
			},

			linkNewAccount: function() {
        if(!this.$refs.accountForm.validate()){
          return;
        }

				let stripe = window.Stripe(this.$store.state.stripeKey, {
					stripeAccount: this.stripeAccountId,
				});

        this.$store.commit('startLoading');

				stripe
					.createToken('bank_account', {
						country: 'US',
						currency: 'usd',
						routing_number: this.routingNumber.replace(/\D/g, ''),
						account_number: this.accountNumber.replace(/\D/g, ''),
						account_holder_name: this.accountHolderName,
						account_holder_type: this.accountHolderType,
					})
					.then((res) => {
            if(res.token){
              this.paymentsService.addBankAccount(res.token.id).then((res) => {
                this.$store.commit('stopLoading');
                this.$emit('result',res.data);
              }).catch((err) => {
                this.handleError(err.response.data.message)
              })
            }else if(res.error){
              this.handleError(res.error.message);
            }else{
              this.handleError('There was an unknown error.');
            }
					})
					.catch((err) => {
						this.handleError(err.error.message);
					});
			},

			handleError: function(message) {
        this.$store.commit('stopLoading');

				let binding = {
					headingText: this.$t('error.connecting-account'),
					bodyText: message,
					hideNo: true,
					yesText: this.$t('global.okay'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, true, false);
			},

			handleClose() {
				this.$emit('result', false);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.new-ach-dialog-div {
		background-color: var(--v-white-base);
		position: relative;
		border-radius: 4px !important;
		width: 400px;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
