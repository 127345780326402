<template>
	<div :class="`${active ? 'form-item-active' : 'form-item-inactive'} ${inContainer ? '' : 'px-2'}`">
		<component
			ref="component"
			:key="item.type"
			:is="item.type"
			:item="item"
			:styles="styles"
			:font="font"
			:button-props="buttonProps"
			:active="active"
			:disabled="disabled"
			:account-logo="accountLogo"
			:index="index"
			:current-page="currentPage"
			:page-count="pageCount"
			:results="results"
      :known-data="knownData"
      :builder="builder"
			:container="container"
			@next-page="$emit('next-page')"
			@previous-page="$emit('previous-page')"
			@input="$emit('input', $event)"
			@click="$emit('click', $event)"
			@delete="$emit('delete',$event)"
			@change="$emit('change', $event)"
			@container-item-click="$emit('container-item-click', $event)"
			@container-item-update="$emit('container-item-update', $event)"
			@add-container-element="$emit('add-container-element', $event)"
			:class="item.type !== 'Container' ? 'pb-3' : ''"
		></component>
	</div>
</template>

<script>
	import TextBlock from '@/modules/forms/v2/schema/TextBlock';
	import TextArea from '@/modules/forms/v2/schema/TextArea';
	import TextInput from '@/modules/forms/v2/schema/TextInput';
	import Spacer from '@/modules/forms/v2/schema/Spacer';
	import Select from '@/modules/forms/v2/schema/Select';
	import Radio from '@/modules/forms/v2/schema/Radio';
	import NewPage from '@/modules/forms/v2/schema/NewPage';
	import FileInput from '@/modules/forms/v2/schema/FileInput';
	import ImageBlock from '@/modules/forms/v2/schema/ImageBlock';
	import Divider from '@/modules/forms/v2/schema/Divider';
	import DateInput from '@/modules/forms/v2/schema/DateInput';
	import Container from '@/modules/forms/v2/schema/Container';
	import Checkbox from '@/modules/forms/v2/schema/Checkbox';
	import Logo from '@/modules/forms/v2/schema/Logo';
	import Button from "@/modules/forms/v2/schema/Button";

	export default {
		name: 'ItemRenderer',

		props: ['item', 'styles','font', 'buttonProps', 'disabled', 'active', 'inContainer', 'accountLogo','index','currentPage','pageCount','results', 'builder','container','knownData'],

		components: {
			Checkbox,
			Container,
			DateInput,
			Divider,
			FileInput,
			ImageBlock,
			NewPage,
			Radio,
			Select,
			Spacer,
			TextBlock,
			TextArea,
			TextInput,
			Logo,
			Button
		},

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			validate: function () {
				return this.$refs.component.validate();
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	.form-item-active {
		border-radius: 4px;
		box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
	}

	.form-item-inactive {
		border-radius: 4px;
	}

	.form-content {
		font-size: var(--form-font-size) !important;
		p:last-of-type {
			margin-bottom: 0px !important;
		}
	}

	.form-question {
		font-size: var(--form-font-size) !important;
		white-space: pre-wrap;
	}

	.form-input {
		input {
			font-size: var(--form-font-size) !important;
		}
		label {
			font-size: var(--form-font-size) !important;
		}
		textarea {
			font-size: var(--form-font-size) !important;
		}
	}
</style>
