<template>
	<div @click="$emit('click', $event)">
		<div class="row-format gap-2 pt-2" :style="`justify-content: ${justification}`">
			<v-btn
				class="elevation-0"
				:color="styles.buttonColor"
				:x-small="buttonProps.xSmall"
				:small="buttonProps.small"
				:large="buttonProps.large"
				:x-large="buttonProps.xLarge"
				:style="`font-size: var(--form-font-size) !important; ${buttonProps.block ? 'width: 49%!important;' : ''}`"
				@click="$emit('previous-page')"
				:disabled="currentPage === 1"
			>
				<v-icon class="mr-1" :color="styles.buttonTextColor">{{ styles.prevPageIcon }}</v-icon>
				<span :style="`font-family: ${font}; ; text-transform: none; color: ${styles.buttonTextColor}`">{{ styles.prevPageText }}</span>
			</v-btn>
			<v-btn
				class="elevation-0"
				:x-small="buttonProps.xSmall"
				:small="buttonProps.small"
				:large="buttonProps.large"
				:x-large="buttonProps.xLarge"
				:color="styles.buttonColor"
				:style="`font-size: var(--form-font-size) !important; ${buttonProps.block ? 'width: 49%!important;' : ''}`"
				@click="$emit('next-page')"
			>
				<span :style="`font-family: ${font}; ; text-transform: none; color: ${styles.buttonTextColor}`">{{ styles.nextPageText }}</span>
				<v-icon class="ml-1" :color="styles.buttonTextColor">{{ styles.nextPageIcon }}</v-icon>
			</v-btn>
		</div>
		<!--div class="row-format align-center pt-3 pb-2" v-if="disabled">
			<hr class="page-divider" />
			<div class="font-gray_70 px-2 font-14" style="white-space: nowrap">New page</div>
			<hr class="page-divider" />
		</div-->
	</div>
</template>

<script>
	import RendererMixin from '@/modules/forms/v2/schema/RendererMixin';

	export default {
		name: 'NewPage',

		props: ['item', 'styles','font', 'buttonProps', 'disabled', 'currentPage', 'pageCount'],

		mixins: [RendererMixin],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			justification: function() {
				if (this.styles.submitAlignment === 'JUSTIFIED') {
					return 'space-between';
				} else if (this.styles.submitAlignment === 'LEFT') {
					return 'flex-start';
				} else if (this.styles.submitAlignment === 'RIGHT') {
					return 'flex-end';
				} else {
					return 'center';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.page-divider {
		border-radius: 4px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 0px;
		border-top: 3px dashed var(--v-gray_50-base);
		width: 100%;
	}
</style>
