<template>
  <div class="new-ach-dialog-div">
    <v-container fluid class="mt-0 mb-4">
      <v-row dense>
        <v-col cols="1"></v-col>
        <v-col cols="10"><h1>{{ $t('invoices.new-ach-account.connect-account') }}</h1></v-col>
        <v-col cols="1"><v-icon  @click="handleClose">$close</v-icon></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" style="border-right: 1px solid var(--v-gray_50-base)">
          <h2>{{ $t('invoices.new-ach-account.verification') }}</h2>
          <v-icon size="65" color="black"  class="mt-3">$plaidVertical</v-icon>
        </v-col>
        <v-col cols="6">
          <h2>{{ $t('invoices.new-ach-account.enter-info') }}</h2>
          <v-icon size="52" color="black" class="mt-3">{{ $t('global.paid') }}</v-icon>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" class="pa-3" style="border-right: 1px solid var(--v-gray_50-base)">
          <div class="font-14">{{ $t('invoices.new-ach-account.use-plaid') }}</div>
          <div class="brand-bold mt-3"><v-icon color="success">$success</v-icon>{{ $t('invoices.new-ach-account.recommended') }}</div>
        </v-col>
        <v-col cols="6" class="pa-3">
          <div class="font-14">{{ $t('invoices.new-ach-account.stripe-verification') }}</div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" style="border-right: 1px solid var(--v-gray_50-base)">
          <v-btn class="secondary-action" @click="connectPlaid">{{ $t('invoices.new-ach-account.connect-plaid') }}</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn class="secondary-action" @click="connectManually">{{ $t('invoices.new-ach-account.connect-manually') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'NewAchAccount',

  props: [],

  components: {},

  data: function() {
    return {};
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    connectPlaid: function(){
      this.$emit('result','PLAID');
    },

    connectManually: function(){
      this.$emit('result','MANUAL');
    },

    handleClose() {
      this.$emit('result', false);
    },
  },

  computed: {},

};
</script>

<style scoped lang="scss">
  .new-ach-dialog-div {
    background-color: var(--v-white-base);
    position: relative;
    border-radius: 4px !important;
    width: 600px;

    button.v-btn.close-dialog {
      right: 4px !important;
      top: 6px !important;
    }
  }
</style>