<template>
	<div id="project-selector" style="position: relative">
		<v-btn icon class="close-dialog" @click="handleClose">
			<v-icon>$close</v-icon>
		</v-btn>

		<div class="modal-padding">
			<h2 class="modal-title">{{ $t('projects.add-deliverable') }}</h2>
			<div class="modal-subtitle">{{ $t('projects.add-deliverable-sub') }}</div>
			<div>
				<v-data-table
					:items-per-page="100"
					:hide-default-footer="projects.length <= 100"
					:hide-default-header="true"
					:headers="headers"
					:items="projects"
					style="cursor:pointer;"
					@click:row="selectProject($event)"
					:mobile-breakpoint="0"
				>
					<template v-slot:item.name="{ item }">
						<div class="project-wrapper">
							<div class="project-name">{{ item.name }}</div>
						</div>
					</template>
				</v-data-table>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ProjectSelector',

		props: ['projects'],

		components: {},

		data: function() {
			return {
				headers: [{ text: this.$t('projects.project'), name: 'project', value: 'name', sortable: true }],
				sortCol: null,
				sortAscending: true,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			selectProject: function(row) {
				this.$emit('result', { type: 'project-selected', project: row });
			},

			handleClose() {
				this.$emit('result');
			},
		},

		computed: {},

		watch: {},
	};
</script>

<style lang="scss">
	.project-selector {
		width: 600px;
		position: relative !important;
		background-color: var(--v-white-base);
		border-radius: 4px !important;

		button.v-btn.close-dialog {
			position: absolute;
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
