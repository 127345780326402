<template>
  <div class="mx-2">
    <line-chart :chart-data="chartData" :options="options" style="height:350px;"></line-chart>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import LineChart from '@/modules/charts/LineChart';

export default {
  name: 'TimeByMonth',

  props: ['timerEvents'],

  components: {LineChart},

  data: function() {
    return {
      options: {
        showTooltips: true,
        responsive: true,
        maintainAspectRatio: false,

        elements: {
          point: {
            radius: 5,
          },
          line: {
            tension: 0.2
          }
        },
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                autoSkip: true,
                //maxTicksLimit: 2,
                maxRotation: 0,
                minRotation: 0,
                callback: function(value) {
                  return value;
                },
              },
            },
          ],
          yAxes: [
            {
              display: true,
              position: 'right',
              ticks: {
                autoSkip: true,
                callback: function(value) {
                  return value;
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItems) {
              return tooltipItems[0].xLabel;
            },
            label: function(tti, data) {
              return ` ${data.datasets[tti.datasetIndex].label}: ${tti.value}`;
            },
          },
        },
      },
    };
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    sortByDate: function(a, b) {
      return a.timerStart.localeCompare(b.timerStart);
    },
  },

  computed: {
    chartData: function() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: this.$t('timetrack.reports.monthly-hours'),
            backgroundColor: 'rgb(150, 152, 146,0.4)',
            data: this.hours,
          },
        ],
      };
    },

    labels: function(){
      let labels = [];
      this.timeByMonth.forEach(i => labels.push(i.label))
      return labels;
    },

    hours: function(){
      let values = [];
      this.timeByMonth.forEach(i => values.push(i.totalHours));
      return values;
    },

    timeByMonth: function() {
      if(!this.timerEvents.length){
        return []
      }

      let timerEvents = [...this.timerEvents];
      timerEvents.sort(this.sortByDate);

      let result = [];

      let earliest = DateTime.fromISO(timerEvents[0].startDate).startOf('month');
      let latest = DateTime.fromISO(timerEvents[timerEvents.length - 1].startDate).startOf('month');

      let start = earliest;

      while (start <= latest) {
        result.push({
          key: start.toISODate(),
          date: start,
          label: start.toFormat('MMM yy'),
          totalSeconds: 0,
          totalHours: 0,
        });
        start = start.plus({months:1});
      }

      for (let i of timerEvents) {
        let key = i.startDate.startOf('month').toISODate();
        let month = result.find(r => r.key === key);
        month.totalSeconds = month.totalSeconds + i.durationSeconds;
      }

      result.forEach(r => {
        r.totalHours = Math.round(r.totalSeconds / 60 / 60);
      })

      return result;
    },
  },

};
</script>

<style scoped lang="scss">

</style>