var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.state.isLoggedIn)?_c('div',{staticClass:"column-format fill-height"},[_c('div',{class:`row-format align-center mx-2 mt-2 mb-2 nav-link${_vm.$store.state.leftNavExpanded ? '-expanded' : ''}`},[_c('div',{staticClass:"row-format align-center px-3 pb-3 pt-2",staticStyle:{"width":"100%","min-height":"56px","min-width":"56px"}},[_c('div',{staticClass:"mt-2 pr-2",staticStyle:{"cursor":"pointer","text-align":"left","width":"100%"},on:{"click":function($event){return _vm.$router.push('/welcome')}}},[(_vm.$store.state.leftNavExpanded)?_c('img',{staticStyle:{"max-width":"120px","max-height":"50px"},attrs:{"src":_vm.logo}}):_c('img',{staticStyle:{"max-width":"120px","max-height":"35px"},attrs:{"src":_vm.logo}})])])]),_c('div',{staticStyle:{"max-height":"calc(var(--vh) - 114px)","overflow-y":"auto"}},_vm._l((_vm.navLinks),function(link,index){return _c('nav-link',{key:`navlink-${index}`,attrs:{"label":link.label,"icon":link.icon,"route":link.route,"hyper-link":link.hyperLink}})}),1),_c('v-menu',{attrs:{"offset-x":"","nudge-left":"10","content-class":"workspace-menu"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"workspace-settings row-format align-center pa-3"},on),[(!_vm.$store.state.leftNavExpanded)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
							placement: 'right',
							arrow: true,
							arrowType: 'round',
							animation: 'fade',
							delay: 50,
							boundary: 'window',
						}),expression:"{\n\t\t\t\t\t\t\tplacement: 'right',\n\t\t\t\t\t\t\tarrow: true,\n\t\t\t\t\t\t\tarrowType: 'round',\n\t\t\t\t\t\t\tanimation: 'fade',\n\t\t\t\t\t\t\tdelay: 50,\n\t\t\t\t\t\t\tboundary: 'window',\n\t\t\t\t\t\t}"}],staticClass:"row-format centered",staticStyle:{"margin-left":"6px"},attrs:{"content":`Settings`}},[_c('v-avatar',{attrs:{"size":"20","color":"accent"}},[_c('span',{staticClass:"white--text brand-bold font-12"},[_vm._v(_vm._s(_vm.clientInitials))])])],1):_c('div',{staticClass:"row-format centered",staticStyle:{"margin-left":"6px"}},[_c('v-avatar',{attrs:{"size":"20","color":"accent"}},[_c('span',{staticClass:"white--text brand-bold font-12"},[_vm._v(_vm._s(_vm.clientInitials))])])],1),(_vm.$store.state.leftNavExpanded)?_c('div',{staticClass:"nav-label ml-2"},[_vm._v(_vm._s(_vm.$t('settings.label')))]):_vm._e(),(_vm.$store.state.leftNavExpanded)?_c('v-icon',{staticClass:"ml-auto",attrs:{"small":""}},[_vm._v("$chevronRight")]):_vm._e()],1)]}}],null,false,965967893)},[_c('settings-nav')],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }