<template>
	<div class="row-format" style="height: 100%; width: 100%; max-height: 100%;">
		<div v-if="editable" style="height: 100%; max-height: 100%; overflow-x: hidden; overflow-y: auto; max-width: 400px; width: 400px; background-color: var(--v-white-base)" class="show-scrollbar">
			<div class="row-format py-2 px-2" style="border-bottom: 1px solid var(--v-gray_30-base)" v-if="recurring || paymentPlan">
				<div
						:class="`row-format align-center page-link ${tab === 'Settings' ? 'page-link-active' : ''}`"
						@click="selectTab('Settings')"
				>
					<v-icon
							class="material-symbols-outlined"
							size="22"
							:color="tab === 'Elements' ? 'primary' : 'gray_50'"
					>settings</v-icon
					>
					<div class="ml-1">Settings</div>
				</div>
				<div
						:class="`row-format align-center page-link ${tab === 'Additional' ? 'page-link-active' : ''}`"
						@click="selectTab('Additional')" v-if="recurring"
				>
					<v-icon
							class="material-symbols-outlined"
							size="22"
							:color="tab === 'Additional' ? 'primary' : 'gray_50'"
					>replay_circle_filled</v-icon
					>
					<div class="ml-1">Recurring</div>
				</div>

				<div
						:class="`row-format align-center page-link ${tab === 'Additional' ? 'page-link-active' : ''}`"
						@click="selectTab('Additional')" v-if="paymentPlan"
				>
					<v-icon
							class="material-symbols-outlined"
							size="22"
							:color="tab === 'Additional' ? 'primary' : 'gray_50'"
					>timeline</v-icon
					>
					<div class="ml-1">Payment plan</div>
				</div>
			</div>
			<invoice-settings v-show="tab === 'Settings'" :invoice="invoice" :additional-tax-rates="additionalTaxRates" :recurring="recurring" :payment-plan="paymentPlan" @force-save="$emit('force-save')" :hide-auto-pay="recurring || paymentPlan"></invoice-settings>
			<div class="px-3" v-show="tab === 'Additional'">
				<slot></slot>
			</div>
		</div>
		<div
				:style="`width:100%; height: 100%; max-height: 100%; overflow-y: auto; background-color: ${styles.backgroundColor}`"
				class="pa-8 column-format align-center show-scrollbar main-form" @click="handleClick(null)"
		>
			<div
					:style="
						`width:100%; min-width: 300px; max-width: ${styles.maxWidth}px; background-color: ${styles.pageColor}`
					"
			>
				<div v-for="item in schema" :key="item.id">
					<item-renderer
							:item="item"
							:styles="styles"
							:font="template.font"
							:invoice="invoice"
							:edit-mode="editable"
							:in-builder="false"
							:account-logo="accountLogo"
							:tokens="tokens"
							:token-map="tokenMap"
							:deposit-account="depositAccount"
							:has-expenses="hasExpenses"
							:recurring="recurring"
							:payment-plan="paymentPlan"
							:invoice-service="invoiceService"
							:attachment-service="attachmentService"
							:scheduled-payments="scheduledPayments"
							@click.stop="handleClick(item)"
							@change="elementUpdated($event)"
							@container-item-update="containerItemUpdated($event)"
							@container-item-click="containerItemClicked($event)"
							:active="item.id === editItemId || item.id === editContainerId"
					></item-renderer>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ItemRenderer from "@/modules/templates/invoices/schema/ItemRenderer";
	import InvoiceTemplateMixin from "@/modules/templates/invoices/InvoiceTemplateMixin";
	import WebFontLoader from "webfontloader";
	import InvoiceSettings from "@/modules/invoices/invoiceRendererV2/InvoiceSettings";

	export default {
		name: 'InvoiceRendererV2',

		props: {
			forceRenderIndex: { type: Number, required: false, default: 0 },
			invoice: { type: Object, required: true },
			account: { type: Object, required: true },
			depositAccount: { type: Object, required: false },
			editMode: { type: Boolean, required: false, default: false },
			hasExpenses: { type: Boolean, required: false, default: false },
			additionalTaxRates: { type: Array, required: false, default: () => [] },
			saving: { type: Boolean, required: false, default: false },
			attachmentService: { type: Object, required: true },
			invoiceService: { type: Object, required: true },
			itemFocus: { type: Number, required: false, default: -1 },
			recurring: { type: Boolean, required: false, default: false },
			paymentPlan: { type: Boolean, required: false, default: false },
			scheduledPayments: { type: Array, required: false, default: () => [] },
		},

		components: {InvoiceSettings, ItemRenderer},

		mixins: [InvoiceTemplateMixin],

		data: function() {
			return {
				editItem: null,
				tab: 'Settings',
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('invoice',this.handleEvent);
			this.loadFonts();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('invoice',this.handleEvent);
		},

		methods: {
			selectTab: function(tab){
				this.tab = tab;
			},

			loadFonts: function(){
				WebFontLoader.load({
					google: {
						families: [`${this.styles.font}:200,300,400,500,600,700,800`],
					}
				});
			},

			handleEvent(eventName){
				this.$emit(eventName);
			},

			handleClick: function(item) {
				if(this.editable) {
					this.editItem = item;
				}else{
					this.editItem = null;
				}
			},

			containerItemUpdated: function(event) {
				let container = event.container;
				let item = event.item;
				let index = event.index;

				item.containerTarget = {
					containerId: container.id,
					index: index,
				};

				this.elementUpdated(item);
			},

			elementUpdated: function(element) {
				if (element.containerTarget) {
					let containerIx = this.schema.findIndex((i) => i.id === element.containerTarget.containerId);
					let column = element.containerTarget.index;
					if (containerIx > -1) {
						let container = this.schema[containerIx];
						let ix = container.columns[column].items.findIndex((i) => i.id === element.id);
						if (ix > -1) {
							container.columns[column].items.splice(ix, 1, element);
							this.schema.splice(containerIx, 1, container);
						}
					}
				} else {
					let ix = this.schema.findIndex((i) => i.id === element.id);
					if (ix > -1) {
						this.schema.splice(ix, 1, element);
					}
				}
			},

			containerItemClicked: function(event) {
				let container = event.container;
				let item = event.item;
				let index = event.index;

				item.containerTarget = {
					containerId: container.id,
					index: index,
				};

				this.handleClick(item);
			},
		},

		computed: {
			template: function(){
				return this.invoice.invoiceTemplate;
			},

			schema: function(){
				return this.invoice.invoiceTemplate.schema
			},

			styles: function(){
				return this.invoice.invoiceTemplate.invoiceStyle
			},

			editable() {
				return this.editMode && ['INIT', 'DRAFT'].includes(this.invoice.status);
			},

			tokenMap: function(){
				return this.getTokensFromInvoice(this.invoice,this.recurring,this.paymentPlan);
			},

			accountLogo: function() {
				return this.account.accountLogo;
			},

			editContainerId: function() {
				if (this.editItem && this.editItem.containerTarget) {
					return this.editItem.containerTarget.containerId;
				} else {
					return null;
				}
			},

			editItemId: function() {
				if (this.editItem) {
					return this.editItem.id;
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
