<template>
	<div>
		<v-combobox
			ref="EmailInput"
			v-model="to"
			:items="contactMap"
			hide-details
			dense
			outlined
      persistent-placeholder
			:label="`${label}`"
			:multiple="true"
			small-chips
			@change="$emit('update',to)"
		>
		</v-combobox>
	</div>
</template>

<script>
	export default {
		name: 'ContactSelector',

		props: ['toList','label','contacts'],

		components: {},

		data: function () {
			return {
				to: [... this.toList]
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			isMatch: function (val1, val2) {
				if(val1 && val2){
					return val1.trim().toLowerCase().includes(val2.trim().toLowerCase());
				}else{
					return false
				}
			},

			validateEmail: function(email){
				let re = /\S+@\S+\.\S+/;
				return re.test(email);
			},
		},

		computed: {
			contactMap: function () {
				let contacts = this.contacts.filter(
					(c) => c.email
				);

				return contacts.map(c => c.firstName + ' ' + c.lastName + " <" + c.email + ">")
			},
		},
	};
</script>

<style scoped lang="scss">
</style>
