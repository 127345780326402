import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class MeetingService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getSchedulerList(){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/schedulers`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getMeeting(id){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/schedulers/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getScheduledMeetings(){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/meetings`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
