<template>
	<div class="iconContainer">
		<v-icon class="dragGrouping handle">
			$vuetify.icons.drag
		</v-icon>
		<v-icon class="dragGrouping" @click.native="confirmRemoveSection()">
			$vuetify.icons.delete
		</v-icon>
		<div><slot></slot></div>
	</div>
</template>

<script>
	export default {
		name: 'ComponentIcons',

		props: ['blockType'],

		methods: {
			confirmRemoveSection: function () {
				this.$emit('confirm-remove-section');
			},
		},
	};
</script>

<style scoped lang="scss">
	.iconContainer {
		display: flex;
		flex-direction: row;
	}

	.dragGrouping {
		color: var(--v-grey-base);
		cursor: pointer;
		margin-right: 10px;
		margin-top: 3px;

		&:hover {
			color: var(--v-primary-base)
		}
	}

</style>
