<template>
	<div class="text-left font-14 column-format">
		<div class="signature-line row-format centered">
			<div v-if="!isSigned && isCurrentUser" class="sign column-format centered pointer" @click.stop="doSign">
				<div>Sign</div>
				<v-icon color="black" size="18">arrow_downward</v-icon>
			</div>
			<div v-else-if="!isSigned && !isCurrentUser" class="no-sign column-format centered">
				<v-icon color="#bec4ca" size="18">block</v-icon>
			</div>
			<div v-else-if="isSigned" class="column-format align-center">
				<div v-if="signer.signatureType === 'Image'" style="height:100%" class="">
					<img :src="signer.signature" alt="signature" style="height: 70px; max-height:70px; padding: 8px">
				</div>
				<div v-else :style="`font-family: ${signer.font}; font-size:40px`">
					{{signer.signature}}
				</div>
				<div class="font-12">{{DateTime.fromISO(signer.timestamp).toLocaleString(DateTime.DATETIME_SHORT)}}</div>
			</div>
		</div>
		<div style="font-weight: 500">
			{{signer.firstName}} {{signer.lastName}}
		</div>
		<div class="font-12">{{signingFor}}</div>
		<div class="font-12">{{signer.title ? signer.title : '&nbsp;'}}</div>
		<div v-if="agreement.account.enableGeoLocationOnSignature && signer.ip && signer.ip.ip" class="font-12 row-format align-center gap-1">
			<img :src="signer.ip.countryFlag" width="15px" />
			<div>{{ signer.ip.ip }} - {{ signer.ip.city }}, {{ signer.ip.region }}, {{ signer.ip.country }}</div>
		</div>

		<basic-modal :dialog="signDialog">
			<sign-dialog @cancel="signDialog=false" :signer="signer" @signed="handleSigned"></sign-dialog>
		</basic-modal>
	</div>
</template>

<script>
import BasicModal from "@/components/BasicModal";
import SignDialog from "@/modules/agreements/schema/SignDialog";
import {DateTime} from 'luxon';

export default {
	name: "Signer",

	props: ['agreement','item','signer','isCurrentUser'],

	components: {SignDialog, BasicModal},

	data: function () {
		return {
			signDialog: false,
			DateTime: DateTime
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		doSign: function(){
			if(this.hasCreatedSignature){
				this.handleSigned(this.signer);
			}else {
				this.signDialog = true;
			}
		},

		handleSigned: function(event){
			this.signDialog = false;
			this.$emit('signed',event);
		}
	},

	computed: {
		signingFor: function(){
			return this.signer.signerType === 'USER' ? this.agreement.account.accountName : this.agreement.client.name;
		},

		hasCreatedSignature: function(){
			return !!this.signer.signature;
		},

		isSigned: function(){
			return this.item.signedBy.indexOf(this.signer.id) >= 0;
		}
	},

}
</script>

<style scoped lang="scss">
	.signature-line {
		border-bottom: 1px solid #bec4ca;
		width: 100%;
		height: 90px;
		min-height: 90px;
		max-height: 90px;
	}

	.sign {
		background-color: gold;
		color: black;
		min-width: 80px;
		max-width: 80px;
		min-height: 45px;
		margin: 6px;
		border-radius: 4px;
		filter: drop-shadow(0px 2px 4px #bec4ca);
	}

	.no-sign {
		border: 1px solid #bec4ca;
		background-color: #eeeeee;
		color: black;
		min-width: 80px;
		max-width: 80px;
		min-height: 45px;
		margin: 6px;
		border-radius: 4px;
	}
</style>