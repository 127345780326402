import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class SubscriptionService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	checkSubscriptionsEnabled() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/subscriptions/enabled`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSubscriptions() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/subscriptions`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getSubscriptionRequests() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/subscriptions/requests`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getSubscriptionPayments(subscriptionId) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/subscriptions/payments`,{
				params: {
					subscriptionId:subscriptionId
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getSubscriptionPortalLink(redirectUri) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/subscriptions/portalSession`,{
				params: {
					redirectUri: redirectUri
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
