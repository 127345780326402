<template>
	<v-container class="ma-0" fluid>
    <v-row>
      <v-col cols="12">
        <div class="row-format mx-8" style="flex-wrap: wrap">
        <div class="kpi-box mr-3 mt-3">
          <div class="fit text-left" style="white-space: nowrap">{{ this.$t('timetrack.reports.total-time')}}</div>
          <div class="font-24 brand-medium fit mt-1">{{ $formatters.formatSeconds(metrics.totalTime) }}</div>
        </div>
        <div class="kpi-box mr-3 mt-3">
          <div class="fit text-left" style="white-space: nowrap">{{ this.$t('timetrack.reports.avg-month' )}}</div>
          <div class="font-24 brand-medium fit mt-1">{{ $formatters.formatSeconds(metrics.averageMonthTime) }}</div>
        </div>
        <div class="kpi-box mr-3 mt-3">
          <div class="fit text-left" style="white-space: nowrap">{{ this.$t('timetrack.reports.avg-week') }}</div>
          <div class="font-24 brand-medium fit mt-1">{{ $formatters.formatSeconds(metrics.averageWeekTime) }}</div>
        </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><time-by-month :timer-events="timerEvents"></time-by-month></v-col>
      <v-col cols="12">
        <div class="brand-medium font-12 mb-3 font-gray_70 mt-1">{{ $t('timetrack.reports.time-by-project') }}</div>
        <time-by-project :timer-events="timerEvents"></time-by-project>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import TimeByProject from '@/modules/timetracking/charts/TimeByProject';
  import TimeByMonth from '@/modules/timetracking/charts/TimeByMonth';

  export default {
		name: 'TimerEventCharts',

		props: ['timerEvents','earliest','latest'],

		components: { TimeByProject, TimeByMonth},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
      sortByDate: function(a,b){
        return a.timerStart.localeCompare(b.timerStart);
      }
		},

		computed: {
      metrics: function() {
        let result = {
          totalTime: 0,
          averageMonthTime: 0,
          averageWeekTime: 0,
        };

        if(this.timerEvents.length === 0){
          return result;
        }

        let events = [...this.timerEvents];
        events.sort(this.sortByDate);

        let weeks = new Map();
        let months = new Map();
        let earliestWeek, earliestMonth, latestWeek, latestMonth;

        if(this.earliest){
          earliestWeek = this.earliest.startOf('week');
          earliestMonth = this.earliest.startOf('month');
        }else{
          earliestWeek = DateTime.fromISO(events[0].timerStart).startOf('week');
          earliestMonth = DateTime.fromISO(events[0].timerStart).startOf('month');
        }

        if(this.latest){
          latestWeek = this.latest.startOf('week');
          latestMonth = this.latest.startOf('month');
        }else{
          latestWeek = DateTime.fromISO(events[events.length-1].timerStart).startOf('week')
          latestMonth = DateTime.fromISO(events[events.length-1].timerStart).startOf('month')
        }

        while(earliestWeek <= latestWeek){
          weeks.set(earliestWeek.toISODate(),0);
          earliestWeek = earliestWeek.plus({week:1})
        }

        while(earliestMonth <= latestMonth){
          months.set(earliestMonth.toISODate(),0);
          earliestMonth = earliestMonth.plus({month:1})
        }

        events.forEach(item => {
          result.totalTime = result.totalTime + item.durationSeconds;

          let monthKey = item.startDate.startOf('month').toISODate();
          let weekKey = item.startDate.startOf('week').toISODate();

          months.set(monthKey,months.get(monthKey) + item.durationSeconds);
          weeks.set(weekKey,weeks.get(weekKey) + item.durationSeconds);
        })

        let monthCount = 0;
        let monthSum = 0;

        let weekCount = 0;
        let weekSum = 0;

        months.forEach((v) => {
          monthCount++;
          monthSum += v;
        })

        weeks.forEach((v) => {
          weekCount++;
          weekSum += v;
        })

        result.averageWeekTime = weekSum/weekCount;
        result.averageMonthTime = monthSum/monthCount;

        return result;
      },
		},
	};
</script>

<style scoped lang="scss"></style>
