import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class ClientService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getContact() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/contact`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getContactList() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/contact/list`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDefaultCurrency() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/defaultCurrency`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
