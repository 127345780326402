<template>
  <div>
      <h-header>
      </h-header>
      <div class="pa-4" style="height: var(--vh)">
        <div class="mt-4 row-format" style="flex-wrap: wrap">
          <div v-for="scheduler in schedulers" :key="scheduler.id" class="scheduler">
            <div class="brand-medium mb-2">{{ scheduler.name }}</div>
            <div class="font-14 mb-2" style="text-transform: capitalize">{{ $t('meetings.duration') }}
              {{ scheduler.meetingLength.duration }} {{ scheduler.meetingLength.timeUnit.toLowerCase() }}
            </div>
            <v-btn class="primary-action mt-auto" large @click="bookNow(scheduler)">{{
                $t('meetings.schedule.book-now')
              }}
            </v-btn>
          </div>
          <div v-if="schedulers.length === 0" class="scheduler column-format centered">
            <div>{{ $t('meetings.no-schedulers') }}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import MeetingService from '@/modules/meetings/MeetingService';
import MeetingEmbed from '@/modules/meetings/MeetingEmbed';
import HHeader from '@/components/HHeader';

export default {
  name: 'SchedulerList',

  props: [],

  components: {HHeader},

  data: function () {
    return {
      schedulers: [],
      meetingService: new MeetingService(),

      pages: [
        {label: this.$t('meetings.schedule.heading'), value: 'schedulers'},
        {label: this.$t('meetings.booked.heading'), value: 'booked'},
      ],
    };
  },

  mounted() {
    this.getSchedulerList();
  },

  beforeDestroy() {
  },

  methods: {
    setView: function (view) {
      this.$router.push('/meetings/' + view);
    },

    getSchedulerList: function () {
      this.meetingService.getSchedulerList().then((res) => {
        this.schedulers.splice(0, this.schedulers.length);
        this.schedulers.push(...res.data);
        this.schedulers.sort((a,b) => a.name.localeCompare(b.name));
      });
    },

    bookNow: function (scheduler) {
      let binding = {
        frameUrl: scheduler.meetingLink
      }

      this.$store.state.globalModalController.openModal(MeetingEmbed, binding).then((res) => {
        console.log(res);
      })
    }
  },

  computed: {},
};
</script>

<style scoped lang="scss">
.scheduler {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 350px;
  min-height: 170px;
  flex: 1 1 0px;
  text-align: left;
  border: 1px solid var(--v-gray_50-base);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px var(--v-gray_50-base);
  padding: 8px;

  &:hover {
    background-color: var(--v-gray_10-base);
  }

  margin: 0px 8px 8px 0px;
}

</style>
