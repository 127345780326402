<template>
	<div class="">
		<div v-for="file in files" :key="file.fileName" class="row-format align-center file-row" @click="downloadFile(file)">
			<div class="row-format align-center truncate">
				<img :src="file.fileIconUrl" width="20">
				<div class="ml-2 font-12 brand-medium truncate">{{file.fileName}}</div>
			</div>
			<div class="row-format align-center ml-auto">
				<v-icon small class="pointer file-delete" v-if="isImageType(file)" @click.stop="previewImage(file)">$visibility</v-icon>
				<v-icon small
								class="pointer file-delete ml-2"
								v-if="editMode"
								@click.stop="confirmDeleteFile(file)"
				>$delete</v-icon
				>
			</div>
		</div>
		<div class="" style="width: fit-content" v-if="editMode">
			<vue-dropzone
					style="background-color: var(--v-white-base)"
					ref="fileDropzone"
					id="dropzone"
					v-on:vdropzone-success="uploadSuccess"
					v-on:vdropzone-error="uploadFailure"
					v-on:vdropzone-sending="addFileUploadAuthHeader"
					:use-custom-slot="true"
					:options="dropzoneOptions"
			>
				<span class="font-14 font-gray_70"><v-icon small color="gray_70">attachment</v-icon> Attach file</span>
			</vue-dropzone>
		</div>

		<modal v-if="imageModal" :dialog="imageModal" :persistent="false" @close="imageModal = false">
			<v-container>
				<v-row>
					<v-col cols="12">
						<img style="max-width:500px" :src="imagePreview">
					</v-col>
				</v-row>
			</v-container>
		</modal>

		<modal :dialog="deleteFileDialog" :persistent="true" :max-width="290" @close="cancelDeleteFile()">
			<v-container class="mt-4 pa-4">
				<v-row>
					<v-col cols="12">
						{{ $t('projects.delete-attachment-confirm') }}
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="pt-4">
						<v-btn class="super-action mr-2" @click="cancelDeleteFile()">
							{{ $t('global.no') }}
						</v-btn>
						<v-btn class="primary-action ml-2" elevation="0" @click="deleteFile()">
							{{ $t('global.yes') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</modal>
	</div>
</template>

<script>
	import axios from 'axios';
	import VueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import Modal from '@bit/hecticapp.common.modal';

	/**
	 * To use this generic component, a fileService class must be provided that supports the following interface:
	 *
	 * fileService.deleteFile(fileName);
	 * fileService.getSignedUrl(fileName);
	 * fileService.getPreviewUrl(fileName);
	 * fileService.getUploadUrl();
	 *
	 * the files[] array will be directly written to upon upload/delete
	 */

	export default {
		name: 'FileAttachment',

		props: {
			files: { type: Array, required: true },
			fileService: { type: Object, required: true },
			previewsSupported: { type: Boolean, required: false, default: true },
			editMode: { type: Boolean, required: false, default: true },
		},

		components: {
			VueDropzone,
			Modal,
		},

		data: function() {
			return {
				fileToDelete: null,
				deleteFileDialog: false,
				imageModal: false,
				imagePreview: null
			};
		},

		mounted(){
		},

		methods: {
			confirmDeleteFile: function(file) {
				this.fileToDelete = file;
				this.deleteFileDialog = true;
			},

			cancelDeleteFile: function() {
				this.fileToDelete = null;
				this.deleteFileDialog = false;
			},

			deleteFile: function() {
				this.fileService
					.deleteFile(this.fileToDelete.fileName)
					.then(() => {
						this.removeFileByName(this.fileToDelete.fileName);
						this.$emit('deleted', this.fileToDelete);
						this.cancelDeleteFile();
					})
					.catch((err) => {
						this.$store.commit('error', 'Error deleting file: ' + err.response.data.message);
						this.cancelDeleteFile();
					});
			},

			removeFileByName: function(fileName) {
				for (let i = 0; i < this.files.length; i++) {
					if (this.files[i].fileName === fileName) {
						this.files.splice(i, 1);
						break;
					}
				}
			},

			previewImage: function(s3File){
				this.fileService.getSignedUrl(s3File.fileName).then((res) => {
					window.open(res.data,'_blank');
					// this.imageModal = true;
					// this.imagePreview = res.data;
				});
			},

			downloadFile: function(s3File) {
				this.fileService.getSignedUrl(s3File.fileName).then((res) => {
					let signedUrl = res.data;

					axios({
						url: signedUrl, //your url
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', s3File.fileName); //or any other extension
						document.body.appendChild(link);
						link.click();
					});
				});
			},

			isImageType: function(file){
				if(file.fileType === 'JPG' || file.fileType === 'PNG' || file.fileType === 'GIF'){
					return true;
				}else{
					return false;
				}
			},

			getFilePreview: function(file) {
				let result;
				if (this.previewsSupported && this.isImageType(file)) {
					result = this.fileService.getPreviewUrl(file);
				} else {
					result = file.fileIconUrl;
				}
				return result;
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			uploadSuccess: function(file, response) {
				this.$refs.fileDropzone.removeFile(file);
				this.files.push(response);
				this.$emit('uploaded', response);
			},

			uploadFailure: function(file, response) {
				this.$store.commit('error', 'Error uploading file: ' + response.message);
				this.$refs.fileDropzone.removeFile(file);
			},
		},

		computed: {
			headers: function() {
				let result = [
					{ text: 'Preview', value: 'id' },
						{ text: 'Name', value: 'fileName' },
					{ text: null, value: 'action', align: 'right', sortable: false },
				];

				return result;
			},

			dropzoneOptions: function() {
				return {
					url: `${this.fileService.getUploadUrl()}`,
					thumbnailWidth: 50,
					maxFiles: 10,
					maxFilesize: 100,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					includeStyling: false,
					parallelUploads: 1,
				};
			},
		},
	};
</script>

<style scoped lang="scss">
	#dropzone {
		min-height: 16px !important;
		margin: 0px;
		padding: 0px;
	}

	.drop {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.drop ::v-deep .dz-message {
		margin: 0;
		padding: 0px 0px 0px 0px;
		//border: 1px solid red;
	}

	.fileName {
		font-size: 12px;
		text-align: left;
		color: var(--v-gray_70-base);
		word-wrap: break-word;
	}

	.file-row {
		margin-top: 4px;
		margin-bottom: 4px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-right: 4px;

		cursor: pointer;
		border: 1px solid transparent;

		.file-delete {
			visibility: hidden;
		}

		&:hover {
			border: 1px solid var(--v-gray_30-base);
			border-radius: 4px;
			.file-delete {
				visibility: visible;
			}
		}
	}

	.buttonOverlay {
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 400;
		width: 100%;
		height: 100%;
		background-color: var(--v-gray_50-base);
		opacity: 0;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			opacity: 0.9;
		}
	}
</style>
