import Payment from '@/modules/invoices/viewer/Payment';
import InvoiceViewer from '@/modules/invoices/viewer/InvoiceViewer';
import InvoiceMain from '@/modules/invoices/InvoiceMain';
import PlaidOAuthHandler from '@/modules/integrations/plaid/PlaidOAuthHandler';
import PlaidLinkHandler from '@/modules/integrations/plaid/PlaidLinkHandler';

export default [
	{
		path: '/invoices',
		name: 'InvoiceMain',
		component: InvoiceMain,
		props: (route) => ({
			v: route.query.v,
		}),
	},
	{
		path: '/invoice',
		name: 'Invoice',
		component: InvoiceViewer,
		meta: {
			insecure: true,
		},
	},
	{
		path: '/invoice/:id',
		name: 'Invoice',
		component: InvoiceViewer,
		props: true,
		meta: {
			insecure: true,
		},
	},
	{
		path: '/integrations/plaid/oauth',
		name: 'PlaidOAuth',
		component: PlaidOAuthHandler,
		meta: {
			insecure: true,
		},
	},
	{
		path: '/integrations/plaid/link',
		name: 'PlaidLink',
		component: PlaidLinkHandler,
		props: (route) => ({
			sessionToken: route.query.sessionToken,
			pod: route.query.pod
		}),
		meta: {
			insecure: true,
		},
	},
	{
		path: '/payment',
		name: 'PaymentRoute',
		component: Payment,
		meta: {
			insecure: true,
		},
	},
]