<template>
	<div
		v-if="domain"
		id="login-panel"
		class="fluid fill-height column-format"
		:style="`${alignItems}; justify-content: center; --background:url('${domain.customBranding.loginSplash}')`"
	>
		<div :style="`max-width: 400px; background-color: var(--v-white-base); ${borderRadius}`" class="pa-5">
			<v-container style="max-width: 350px" v-if="isAccountPaid">
				<v-form v-model="isValid" @submit.prevent>
					<v-row dense>
						<v-col cols="12" align="center">
							<img :src="logo" style="max-height: 100px; max-width: 100%" />
						</v-col>
					</v-row>
					<v-row dense v-if="view === 'init'">
						<v-col cols="12" align="left">
							<div v-html="domain.customBranding.loginGreeting" style="white-space: pre-wrap" class="my-3"></div>
						</v-col>

						<v-col cols="12" align="left">
							<v-btn
								v-if="entryScreen === 'start'"
								elevation="0"
								block
								style="height: 40px !important;"
								outlined
								color="gray_60"
								@click="magicLinkMode()"
							>
								<div class="row-format centered" style="width: 100%">
									<v-icon :size="24" color="primary" class="material-symbols-outlined">magic_button</v-icon>
									<span class="ml-2 font-gray_90 font-14">{{ $t('login.use-magic-link') }}</span>
								</div>
							</v-btn>

							<div class="row-format align-center font-14 py-6" v-if="entryScreen === 'start'">
								<div style="background-color: var(--v-gray_50-base); height: 1px; flex-grow: 1"></div>
								<div class="font-secondary px-2 brand-bold">{{ $t('login.or-password') }}</div>
								<div style="background-color: var(--v-gray_50-base); height: 1px; flex-grow: 1"></div>
							</div>

							<v-text-field
								dense
								outlined
								persistent-placeholder
								hide-details
								v-model="email"
								:label="$t('login.email')"
								:rules="emailRules"
							></v-text-field>
							<v-text-field
								v-if="entryScreen === 'start'"
                type="password"
								class="mt-2"
								dense
								outlined
								persistent-placeholder
								hide-details
								v-model="password"
								:label="$t('login.password')"
								:rules="passwordRules"
							></v-text-field>
						</v-col>
						<v-col cols="12" align="left" v-if="errorMsg" class="mt-2">
							<span style="color: var(--v-warning-base)">{{ errorMsg }}</span>
						</v-col>
						<v-col cols="12" align="left" class="mt-0">
							<v-btn
								elevation="0"
								color="primary"
								style="height: 40px!important;"
								block
								:disabled="!isValid"
								@click="handleFormSubmit"
								>{{ $t('login.sign-in-button') }}</v-btn
							>
							<v-checkbox
								v-model="rememberEmail"
								:label="$t('login.remember-email')"
								hide-details
								dense
								:value="true"
							></v-checkbox>

							<div
								v-if="entryScreen !== 'start'"
								class="row-format align-center mt-5 font-gray_70 pointer font-14"
								@click="entryScreen = 'start'"
							>
								<v-icon color="gray_70" size="20">arrow_back</v-icon>
								<div>{{ $t('login.all-options') }}</div>
							</div>
						</v-col>
					</v-row>
					<v-row dense v-if="view === 'email-sent'">
						<v-col cols="12" class="mt-8">
							<v-icon large color="primary" class="mb-2">$success</v-icon>
							<div>{{ $t('login.magic-link') }} {{ email }}. {{ $t('login.click-link') }}</div>
						</v-col>
					</v-row>
				</v-form>
			</v-container>
			<v-container style="max-width: 350px" v-else>
				<v-row>
					<v-col>
						<h1>{{ $t('login.feature-not-enabled') }}</h1>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';

	export default {
		name: 'Login',

		props: [],

		components: {},

		data: function() {
			return {
				isValid: true,
				rememberEmail: false,

				errorMsg: null,
				view: 'init',
				disabled: false,
				entryScreen: 'start',

				email: null,
				password: null,
				emailRules: [(v) => !!v || this.$t('valid.email-required'), (v) => /.+@.+\..+/.test(v) || this.$t('valid.email')],
				passwordRules: [
					(v) => !!v || '',
					(v) => (v && v.length >= 8) || '',
				],

				showBackground: this.$vuetify.breakpoint.mdAndUp ? 'show-background' : '',

				//loginBoxPosition
			};
		},

		mounted() {
			if (this.$store.state.isLoggedIn) {
				this.$router.push('/welcome');
			}

			try {
				let savedEmail = localStorage.getItem('login-email');

				if (!this.$validations.isEmpty(savedEmail)) {
					this.email = savedEmail;
					this.rememberEmail = true;
				}
			} catch (err) {
				console.log('Error reading preferences from local storage.');
			}
		},

		beforeDestroy() {},

		methods: {
			magicLinkMode: function() {
				this.entryScreen = 'magic';
			},

			handleFormSubmit() {
				if (this.rememberEmail) {
					try {
						console.log('writing email to local storage');
						localStorage.setItem('login-email', this.email.trim());
					} catch (err) {
						console.log('Error putting preferences into local storage.');
					}
				} else {
					localStorage.removeItem('login-email');
				}

				if (this.entryScreen === 'magic') {
					this.requestMagicLink();
				} else {
					this.authWithCredentials();
				}
			},

			authWithCredentials() {
				this.$store.commit('startLoading');
				AuthService.authWithCredentials(this.$store.getters.getAccountId, this.email.trim(), this.password)
					.then(() => {
						self.location.href = document.location.protocol + '//' + document.location.host;
					})
					.catch((err) => {
						this.errorMsg = this.$t('error.bad-credentials') + ' [' + (err.response ? err.response.status : 'Unknown') + ']';
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			requestMagicLink() {
				this.$store.commit('startLoading');
				this.errorMsg = null;
				this.disabled = true;

				let url = document.location.protocol + '//' + document.location.host;
				url = url + '/magicToken';

				if (this.$store.state.origRequestedUri) {
					url = url + '?origUri=' + this.$store.state.origRequestedUri;
				} else {
					url = url + '?origUri=/welcome';
				}

				AuthService.requestMagicLink(this.$store.getters.getAccountId, this.email.trim(), url)
					.then(() => {
						this.view = 'email-sent';
					})
					.catch((err) => {
            this.errorMsg = this.$t('error.unable-to-locate') + ' [' + (err.response ? err.response.status : 'Unknown') + ']';
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},
		},

		computed: {
			logo: function() {
				if (this.$vuetify.theme.dark && this.$store.state.domain.logoDark) {
					return this.$store.state.domain.logoDark;
				} else {
					return this.$store.state.domain.logo;
				}
			},

			isAccountPaid: function() {
				if (this.$store.state.domain) {
					return this.$store.state.domain.isAccountPaid;
				} else {
					return false;
				}
			},

			domain: function() {
				return this.$store.state.domain;
			},

			alignItems: function() {
				if (this.domain.customBranding.loginBoxPosition === 'Left') {
					return 'align-items: start';
				} else if (this.domain.customBranding.loginBoxPosition === 'Right') {
					return 'align-items: end';
				} else {
					return 'align-items: center';
				}
			},

			borderRadius: function() {
				if (this.domain.customBranding.loginBoxPosition === 'Left') {
					return 'border-radius: 0px 4px 4px 0px;';
				} else if (this.domain.customBranding.loginBoxPosition === 'Right') {
					return 'border-radius: 4px 0px 0px 4px;';
				} else {
					return 'border-radius: 4px';
				}
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
	.v-btn--outlined {
		border: thin solid var(--v-gray_50-base);
	}
</style>

<style scoped lang="scss">
	#login-panel {
		background-image: var(--background);
		background-size: cover;
		min-height: 100vh;
		background-position: center;
	}

	.logo {
		max-width: 230px;
	}

	.login-box {
		max-width: 450px;
		background-color: var(--v-white-base);
		border-radius: 4px;
	}

	.primary-link {
		color: var(--v-primary-base);
		cursor: pointer;
	}

	.informational {
		color: var(--v-white-base);
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 4px;
		padding: 8px 16px;
		width: 300px;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.left-footer {
		position: absolute;
		left: 16px;
		bottom: 16px;
	}

	.right-footer {
		position: absolute;
		right: 16px;
		bottom: 24px;
	}

	#code-input ::v-deep input {
		font-family: Inter;
	}
</style>
