<template>
	<div @click="$emit('click', $event)" :style="`width: 100%; text-align: ${item.alignment};`">
		<img alt="logo" :src="accountLogo" :style="imageStyle">
	</div>
</template>

<script>
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'ImageBlock',

		props: ['accountLogo','item','isMobile','pageWidth'],

		mixins: [ResponsiveMixin],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			imageStyle: function(){
				let style = `height: auto; min-width: 50px; width: ${this.item.width}px; max-width: min(${this.item.width}px,100%); padding: ${this.item.padding}px; margin-top: ${this.item.pt}px; margin-bottom: ${this.item.pb}px; margin-left: ${this.padding(this.item.pl)}px; margin-right: ${this.padding(this.item.pr)}px; `
				if(this.item.backgroundColor){
					style = style + `background-color: ${this.item.backgroundColor}; `
				}
				if(this.item.borderColor){
					style = style + `border-color: ${this.item.borderColor}; `
				}
				if(this.item.borderWidth){
					style = style + `border-style: solid; border-width: ${this.item.borderWidth}px; `
				}
				if(this.item.borderRadius){
					style = style + `border-radius: ${this.item.borderRadius}px; `
				}
				return style;
			}
		},
	};
</script>

<style scoped lang="scss"></style>
