<template>
	<div>
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
			<label v-html="htmlText" class="question"></label>
			<v-radio-group v-model="selectedValue" :rules="required ? inputRequired : []" @change="handleChange()">
				<v-radio
					class="box"
					v-for="option in options"
					:key="option"
					:value="option"
					:label="option"
					:disabled="previewMode"
				></v-radio>
				<v-radio class="box" v-if="hasOther" value="$$other" :disabled="previewMode">
					<template slot="label">
						<div style="width: 320px">
							<v-text-field
								v-model="otherValue"
								:placeholder="$t('discovery.other')"
								@input="handleChange()"
								dense
								hide-details
								:disabled="this.selectedValue !== '$$other'"
							></v-text-field>
						</div>
					</template>
				</v-radio>
			</v-radio-group>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'RadioInput',
		props: ['options', 'htmlText', 'hasOther', 'previewMode','required'],

		data: function () {
			return {
				selectedValue: null,
				otherValue: null,
				isValid: false,
				inputRequired: [(v) => !!v || 'Required'],
			};
		},

		methods: {
			validate: function () {
				return this.$refs.form.validate();
			},

			handleChange: function () {
				if (this.selectedValue === '$$other') {
					this.$emit('input', this.otherValue);
				} else {
					this.$emit('input', this.selectedValue);
				}
			},
		},
	};
</script>

<style lang="scss"></style>
