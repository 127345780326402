import { DateTime } from 'luxon';
import i18n from '@/i18n';

export default class FilterHelpers {
	constructor() {
		//if (phase) this.phase = phase.toLowerCase();
	}


	static allDateOptions(){
		return [
			{ text: i18n.t('meetings.filter.all-time'), value: 'all-time'},
			{ text: i18n.t('meetings.filter.today'), value: 'today' },
			{ text: i18n.t('meetings.filter.yesterday'), value: 'yesterday' },
			{ text: i18n.t('meetings.filter.this-week'), value: 'this-week' },
			{ text: i18n.t('meetings.filter.last-week'), value: 'last-week' },
			{ text: DateTime.now().toLocaleString({month:'long', year:'numeric'}), value: 'this-month' },
			{
				text: DateTime.now().minus({ month: 1 }).toLocaleString({month:'long', year:'numeric'}),
				value: 'last-month',
			},
			{
				text: DateTime.now().minus({ month: 2 }).toLocaleString({month:'long', year:'numeric'}),
				value: 'last-last-month',
			},
			{ text: i18n.t('meetings.filter.next-30-days'), value: 'next-30-days' },
			{ text: i18n.t('meetings.filter.next-60-days'), value: 'next-60-days' },
			{ text: i18n.t('meetings.filter.next-90-days'), value: 'next-90-days' },
			{ text: i18n.t('meetings.filter.past-30-days'), value: 'past-30-days' },
			{ text: i18n.t('meetings.filter.past-60-days'), value: 'past-60-days' },
			{ text: i18n.t('meetings.filter.past-90-days'), value: 'past-90-days' },
			{ text: i18n.t('meetings.filter.past-6-months'), value: 'past-6-months' },
			{ text: i18n.t('meetings.filter.past-12-months'), value: 'past-12-months' },
			{
				text: DateTime.local().toFormat('yyyy'),
				value: 'this-year',
			},
			{
				text: DateTime.local().minus({ year: 1 }).toFormat('yyyy'),
				value: 'last-year',
			},
			{ text: i18n.t('meetings.filter.custom'), value: 'between' },
		]
	}

	static getDateOptions(options) {
		let dateOptions = [];
		let allOptions = this.allDateOptions();
		for (let i = 0; i < allOptions.length; i++) {
			let option = allOptions[i];
			if (options.includes(option['value'])) dateOptions.push(option);
		}
		return dateOptions;
	}

	static getEarliestAndLatest(dateSpecifier, isoFormat = true) {
		let now = DateTime.local().toObject();
		const today = DateTime.fromObject({ year: now.year, month: now.month, day: now.day });
		const firstOfWeek = DateTime.local().startOf('week');
		const firstOfMonth = DateTime.local().startOf('month');
		// if (!selectedFilters) selectedFilters = this.selectedFiltersDefault;
		let earliest, latest;
		switch (dateSpecifier) {
			case 'today':
				earliest = today;
				latest = today.plus({ days: 1 });
				break;
			case 'yesterday':
				earliest = today.minus({ days: 1 });
				latest = today;
				break;
			case 'this-week':
				earliest = firstOfWeek;
				latest = today.plus({ days: 1 });
				break;
			case 'last-week':
				earliest = firstOfWeek.minus({ week: 1 });
				latest = firstOfWeek;
				break;
			case 'this-month':
				earliest = firstOfMonth;
				latest = firstOfMonth.plus({ month: 1 }).minus({seconds:1});
				break;
			case 'last-month':
				earliest = firstOfMonth.minus({ month: 1 });
				latest = firstOfMonth.minus({seconds:1});
				break;
			case 'last-last-month':
				earliest = firstOfMonth.minus({ month: 2 });
				latest = earliest.plus({month:1});
				break;
			case 'next-30-days':
				earliest = today;
				latest = today.plus({ days: 30 });
				break;
			case 'next-60-days':
				earliest = today;
				latest = today.plus({ days: 60 });
				break;
			case 'next-90-days':
				earliest = today;
				latest = today.plus({ days: 90 });
				break;
			case 'past-30-days':
				earliest = today.minus({ days: 30 });
				latest = today.plus({ days: 1 });
				break;
			case 'past-60-days':
				earliest = today.minus({ days: 60 });
				latest = today.plus({ days: 1 });
				break;
			case 'past-90-days':
				earliest = today.minus({ days: 90 });
				latest = today.plus({ days: 1 });
				break;

			case 'past-6-months':
				earliest = today.minus({ month: 6 });
				latest = today.plus({ days: 1 });
				break;
			case 'past-12-months':
				earliest = today.minus({ month: 12 });
				latest = today.plus({ days: 1 });
				break;
			case 'this-year':
				earliest = today.startOf('year');
				latest = today.startOf('year').plus({ year: 1 });
				break;
			case 'last-year':
				earliest = today.startOf('year').minus({ year: 1 });
				latest = today.startOf('year');
				break;
			case 'all-time':
				earliest = today.startOf('year').minus({ year: 1000 });
				latest = today.startOf('year').plus({ year: 1000 });
				break;
			// case 'before':
			//     earliest = today.minus({ year: 10 });
			//     latest = DateTime.fromISO(this.selectedFilters.latest).plus({ day: 1 });
			//     break;
			// case 'after':
			//     earliest = DateTime.fromISO(this.selectedFilters.earliest);
			//     latest = today.plus({ day: 1 });
			//     break;
			// case 'between':
			// 	earliest = DateTime.fromISO(selectedFilters.earliest);
			// 	latest = DateTime.fromISO(selectedFilters.latest).plus({ day: 1 });
			// 	break;
			default:
				return;
			// earliest = firstOfWeek;
			// latest = today.plus({ days: 1 });
			// break;
		}

		if (isoFormat) {
			return { earliest: earliest.toISO(), latest: latest.toISO() };
		} else {
			return { earliest: earliest, latest: latest };
		}
	}
}
