<template>
	<div @click="$emit('click', $event)" :style="`width: 100%; text-align: left`">
		<file-attachment
			v-if="!inBuilder && attachmentService"
			:files="invoice.attachments"
			:file-service="attachmentService"
			:edit-mode="editMode"
			:previews-supported="false"
		>
		</file-attachment>
		<span v-else class="font-14 font-gray_70"><v-icon small color="gray_70">attachment</v-icon> Attach file</span>
	</div>
</template>

<script>
	import RendererMixin from '@/modules/forms/v2/schema/RendererMixin';
	import FileAttachment from '@/components/FileAttachments';

	export default {
		name: 'FileAttachments',

		props: ['item', 'active', 'tokens', 'tokenMap', 'inBuilder', 'editMode', 'attachmentService', 'recurring', 'invoice'],

		mixins: [RendererMixin],

		components: { FileAttachment },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
