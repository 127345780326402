<template>
	<div :class="deliverable.project.portalAccess === 'Full access' ? 'cardWrapper' : ''">
		<div v-if="deliverable.emptyCard" class="blankCard mx-0 my-2 pl-4 pr-0 pt-2" @click.stop="$emit('add-new-empty')">
			<v-icon size="34">$duplicate</v-icon>
			<span class="font-grey">{{ $t('projects.add-first-deliverable') }}</span>
		</div>
		<div v-else class="card mb-2">
			<div :class="`card-wrapper ${deliverable.project.portalAccess === 'Full access' ? 'full-access' : ''}`">
				<div class="row-format mb-2 ">
          <div class="project-name ellipsis">{{ deliverable.project.name }}</div>
					<v-icon small class="ml-auto" color="success" v-if="deliverable.invoiceId" v-tippy="{content:`Invoice: ${deliverable.invoiceNumber}`}">$money</v-icon>
				</div>
				<div class="deliverable-name mb-2 row-format">
					<div>{{ deliverable.name }}</div>
					<div class="ml-auto row-format align-center" v-if="showStatus">
						<div v-if="showStatus">{{status.label}}</div>
						<div class="ml-2" :style="`width:20px; height: 20px; border-radius: 4px; background-color: ${status.hexColor}`"></div>
					</div>
				</div>
				<div class="row-format align-center">
          <div class="row-format align-center">
            <assigned-user
                v-for="(assignedTo, index) in deliverable.assignedToList"
                :key="assignedTo"
                :show-name="false"
                :small="true"
                :assigned-to="assignedTo"
                :assignable-users="assignableUsers"
                :style="
									`${
										index > 0 ? 'margin-left: -6px' : ''
									}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
								"
            ></assigned-user>
          </div>

					<div style="margin-left: auto" class="row-format align-center">
						<div class="font-12 font-grey" v-if="deliverable.dueDate">
							<v-icon size="12" class="mb-1">$clock</v-icon>
							{{ DateTime.fromISO(deliverable.dueDate).toLocaleString({month:'short', day:'numeric'})}}
						</div>
						<div
							class="font-12 font-grey ml-1 pointer task-box"
							v-if="deliverable.tasks.length > 0"
							@click.stop="showTasks = !showTasks"
						>
							<v-icon size="8" class="mb-1">$check</v-icon>
							{{ getTaskCountLabel(deliverable.tasks) }}
							<v-icon x-small class="mb-1">{{ showTasks ? '$arrowUp' : '$arrowDown' }}</v-icon>
						</div>
						<div
							class="font-12 font-grey ml-1 pointer task-box"
							v-if="deliverable.tasks.length == 0"
							@click.stop="readOnly ? '' : addFirstTask()"
						>
							<v-icon size="8" class="mb-1">$check</v-icon>
							<v-icon size="12" class="mb-1" style="margin-left: 2px" color="gray_80">$plus</v-icon>
						</div>
					</div>
				</div>
			</div>
			<tasks
				ref="tasks"
				v-show="showTasks && deliverable.tasks.length > 0"
				:deliverable="deliverable"
				@empty="showTasks = false"
        :disabled="readOnly"
				@tasks-updated="updateTasks()"
			></tasks>
		</div>
	</div>
</template>

<script>
	import { DateTime as HDateTime } from 'luxon';
	import AssignedUser from '@/components/AssignedUser';
  import Tasks from '@/modules/projects/deliverable/Tasks';
  import ProjectService from '@/modules/projects/ProjectService';

	export default {
		name: 'KanbanCard',

		props: ['deliverable','showStatus','statusList','assignableUsers'],

		components: {
			AssignedUser,
			Tasks,
		},

		data: function() {
			return {
				DateTime: HDateTime,
				showTasks: false,
				deliverableService: new ProjectService(),
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('kanban-card-show-tasks', this.setShowTasks);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('kanban-card-show-tasks', this.setShowTasks);
		},

		methods: {
			expandTasks: function() {
				this.showTasks = true;
			},

			collapseTasks: function() {
				this.showTasks = false;
			},

			getTaskCountLabel: function(tasks) {
				if (tasks.length === 0) {
					return '';
				} else {
					let taskCount = tasks.length;
					let completedCount = 0;
					for (let i = 0; i < tasks.length; i++) {
						if (tasks[i].complete) {
							completedCount++;
						}
					}

					return `${completedCount}/${taskCount}`;
				}
			},

			setShowTasks: function(showTasks) {
				this.showTasks = showTasks;
			},

			addFirstTask: function() {
				this.$refs.tasks.addNewTask(0);
				this.showTasks = true;
			},

			updateTasks() {
				const patch = [{ op: 'replace', path: '/tasks', value: this.deliverable.tasks }];
				this.deliverableService
						.patchDeliverable(this.deliverable.id, patch)
						.catch((err) => {
							console.log(err);
						});
			},
		},

		computed: {
			status: function(){
        return this.statusList.find(s => s.id === this.deliverable.statusId);
			},

      readOnly: function(){
        return this.deliverable.project.portalAccess !== 'Full access';
      },

			highlightColor: function(){
        return '';
			}
		},
	};
</script>

<style scoped lang="scss">
	.card-wrapper {
		padding: 8px 8px 8px 8px;
	}

  .full-access {
    &:active {
      cursor: grabbing;
    }
    &:hover {
      background-color: var(--v-sidebar-base);
    }
  }

	.card {
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_50-base);
		box-sizing: border-box;
		box-shadow: 0px 1px 0px var(--v-gray_50-base);
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		transition: all ease 250ms;
	}

	.blankCard {
		background-color: var(--v-white-base);
		height: 144px;
		border: 1px solid var(--v-gray_50-base);
		box-sizing: border-box;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.project-name {
		font-size: 12px;
		border: 1px solid var(--v-gray_30-base);
		color: var(--v-gray_80-base);
		padding: 0px 6px;
		border-radius: 4px;
		width: fit-content;
		//width: fit-content;
		//white-space: nowrap;
		//text-overflow: ellipsis;
	}

	.deliverable-name {
		font-size: 14px;
		color: var(--v-gray_90-base);
	}

	.task-box {
		padding: 2px 6px;
		border-radius: 2px;
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.no-select {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	}
</style>
