<template>
	<div id="payment-details-modal">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h2 class="modal-title">{{ $t('invoice.details.payment-details-modal.heading') }}</h2>
			<p class="modal-subtitle">
				{{ $t('invoice.mark-paid-modal.subheading', { invoiceNumber: invoice.invoiceNumberFormatted }) }}
			</p>

			<v-container fluid class="px-0 pb-0">
				<v-row dense>
					<v-col cols="5" class="label">{{ $t('invoice.details.payment-details-modal.date-paid') }}</v-col>
					<v-col cols="7" class="value">
						{{ this.formattedDate() }}
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="5" class="label">{{ $t('invoice.status') }}</v-col>
					<v-col cols="7" class="value"  v-if="payment.pending">
						{{ $t('invoice.pending') }}
						<v-icon small color="warning" class="mb-1">$alert</v-icon>
					</v-col>
					<v-col v-else cols="7" class="value">
						{{ $t('invoice.cleared') }} <v-icon small color="success" class="mb-1">$success</v-icon>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="5" class="label">{{ $t('invoice.details.payment-details-modal.amount') }}</v-col>
					<v-col cols="7" class="value">
						<span>{{ $formatters.dollars(payment.amount,true,true,payment.currency) }}</span>
						<span v-if="payment.localAmount" class="font-12 font-gray_70"> / {{ $formatters.dollars(payment.localAmount,true,true,$store.state.defaultCurrency) }}</span>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="5" class="label">{{ $t('invoice.details.payment-details-modal.payment-type') }}</v-col>
					<v-col cols="7" class="value">
						{{ this.$t('invoice.payment-providers.' + payment.paymentProvider) }}
					</v-col>
				</v-row>
				<v-row dense v-if="payment.memo">
					<v-col cols="5" class="label">{{ $t('invoice.details.payment-details-modal.memo') }}</v-col>
					<v-col cols="7" class="value">
						{{ payment.memo }}
					</v-col>
				</v-row>

				<v-row dense class="mt-4">
					<v-col cols="12" class="body-12 text-left gray_70--text">
						<span class="mr-1">{{ $t('invoice.details.payment-details-modal.paid-by') }}</span>
						<span>{{ payment.paidBy }}</span>
						<br />

						<div v-if="payment.referenceNumber" style="word-wrap:break-word" class="my-2">
							<span class="mr-1">{{ $t('invoice.details.payment-details-modal.reference-number') }}</span>
							<br />
							<span>{{ payment.referenceNumber }}</span>
							<br />
						</div>

						<span class="mr-1">{{ $t('invoice.details.payment-details-modal.timestamp') }}</span>
						<span>{{
							$DateTime.fromISO(payment.timestamp).toLocaleString($DateTime.DATETIME_SHORT_WITH_SECONDS)
						}}</span>
					</v-col>
				</v-row>
				<v-row dense v-if="payment.integrationKeys.quickbooksId">
					<v-col cols="12" align="left">
						<v-icon size="20">$quickbooks</v-icon>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" icon @click="confirmDelete" v-if="editMode && !payment.integratedPayment"
				><v-icon size="20">$delete</v-icon></v-btn
			>
			<v-btn class="primary-action ml-1" style="width: 140px;" @click="handleClose()">{{ $t('global.close') }}</v-btn>
		</div>

		<confirm-dialog
			:dialog="confirmDeleteDialog"
			@close="confirmDeleteDialog = false"
			@confirm="deletePayment()"
			:heading-text="$t('global.confirm')"
			:yes-text="$t('global.yes')"
			:no-text="$t('global.no')"
			:body-text="$t('invoice.delete-payment')"
		>
		</confirm-dialog>
	</div>
</template>

<script>
	import ConfirmDialog from './ConfirmDialog';
	import InvoiceService from '@/modules/invoices/InvoiceService';
	// import ConfirmDialog from '@/components/ConfirmDialog';

	export default {
		name: 'PaymentDetailsModal',

		components: {
			ConfirmDialog,
		},

		props: {
			invoice: { type: Object, required: true },
			payment: { type: Object, required: true },
			editMode: { type: Boolean, required: false, default: false },
			// client: { type: Object, required: true },
		},

		data: function() {
			return {
				confirmDeleteDialog: false,
				invoiceService: new InvoiceService(),
				paymentProviders: [
					{ value: 'CHECK', text: this.$t('invoice.payment-providers.CHECK') },
					{ value: 'CASH', text: this.$t('invoice.payment-providers.CASH') },
					{ value: 'STRIPE', text: this.$t('invoice.payment-providers.STRIPE') },
				],
			};
		},

		mounted() {
			console.log('payment', this.payment);
		},

		beforeDestroy() {},

		methods: {
			formattedDate() {
        if(this.payment.integratedPayment){
          return this.$DateTime.humanDateTime(this.payment.timestamp);
        }else if (this.payment.datePaid){
          return this.payment.datePaid;
        } else {
          return this.$DateTime.humanDateTime(this.payment.timestamp);
        }
			},

			handleClose: function(){
				this.$emit('close');
				this.$emit('result');
			},

			confirmDelete() {
				this.confirmDeleteDialog = true;
			},

			deletePayment() {
				this.confirmDeleteDialog = false;
				this.invoiceService.deletePayment(this.invoice.clientId, this.invoice.id, this.payment.id).then((res) => {
					this.$emit('updated', res.data);
					this.$emit('close');
				});
			},
		},
		watch: {},
	};
</script>

<style lang="scss">
	#payment-details-modal {
		min-width: 300px;

		.label {
			font-size: 14px;
			text-align: left;
			color: var(--v-gray_70-base);
		}
		.value {
			font-size: 14px;
			text-align: left;
		}
	}
</style>
