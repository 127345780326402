import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class DashboardService{
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getActionItems() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/actionItems`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
