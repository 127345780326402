<template>
	<div>
		<h-header>
			<template v-slot:filter>
				<div class="row-format" style="width:200px!important;" v-if="view === 'overview'">
					<v-select
						:attach="true"
						dense
						hide-details
						v-model="filter"
						:items="filterOptions"
						placeholder="Filter"
						class="ml-3"
					></v-select>
				</div>
			</template>
		</h-header>
		<div :style="`height: calc(100vh - ${headerHeight}px)`" class="mx-0 pt-4 show-scrollbar" id="project-container">
			<div v-if="view === 'overview'" class="mx-3">
				<project
					v-for="project in filteredProjects"
					:key="project.id"
					:project="project"
					:status-list="statusList"
					class="project-wrapper"
				></project>
			</div>
			<div v-if="view === 'kanban' && fullReadProjects.length > 0">
				<project-kanban
					:assignable-users="assignableUsers"
					:has-full-access-projects="fullAccessProjects.length > 0"
					:deliverables="deliverables"
					:is-collaborator="true"
					:columns="statusList"
					:header-height="headerHeight"
					@edit-deliverable="editDeliverable($event)"
					@update-kanban-props="updateKanbanProperties($event)"
					@add-deliverable="addDeliverable($event)"
				></project-kanban>
			</div>
			<div v-if="view === 'timeline' && fullReadProjects.length > 0" class="show-scrollbar mr-3" style="height: 100%">
				<gantt-view
					:assignable-users="assignableUsers"
					:deliverables="deliverables"
					:status-list="statusList"
					:header-height="headerHeight"
					@edit-deliverable="editDeliverable($event)"
					@dates-updated="datesUpdated($event)"
				></gantt-view>
			</div>

			<div v-if="view === 'calendar' && fullReadProjects.length > 0" class="show-scrollbar mr-3" style="height: 100%">
				<project-calendar
					:projects="filteredProjects"
					:deliverables="deliverables"
					:status-list="statusList"
					@edit-deliverable="editDeliverable($event)"
          @update-due-date="datesUpdated($event)"
				>
				</project-calendar>
			</div>
		</div>
	</div>
</template>

<script>
	import Project from '@/modules/projects/Project';
	import ProjectService from '@/modules/projects/ProjectService';
	import HHeader from '@/components/HHeader';
	import ProjectKanban from '@/modules/projects/management/ProjectKanban';
	import GanttView from '@/modules/projects/management/GanttView';
	import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';
	import ProjectSelector from '@/modules/projects/ProjectSelector';
	import ProjectCalendar from '@/modules/projects/management/ProjectCalendar';

	export default {
		name: 'Projects',

		props: ['v'],

		components: { ProjectCalendar, GanttView, ProjectKanban, HHeader, Project },

		data: function() {
			return {
				projectService: new ProjectService(),
				view: 'overview',
				projects: [],
				deliverables: [],
				statusList: [],
				assignableUsers: [],
				filter: this.$t('projects.filter.active'),
				headerHeight: 70,
				filterOptions: [
					this.$t('projects.filter.active'),
					this.$t('projects.filter.complete'),
					this.$t('projects.filter.all'),
				],
			};
		},

		mounted() {
			if (this.v) {
				this.view = this.v;
			}

			this.getProjectList();
			this.getDeliverableStatusList();
			this.getAssignableUsers();
			this.$store.state.eventBus.$on(this.getChannelName(), this.eventHandler);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(this.getChannelName(), this.eventHandler);
		},

		methods: {
			setView: function(view) {
				this.view = view;
				window.history.pushState({}, document.title, this.$router.currentRoute.path + `?v=${view}`);
			},

			getAssignableUsers: function() {
				this.projectService.getAllAssignableUsers().then((res) => {
					this.assignableUsers.splice(0, this.assignableUsers.length);
					this.assignableUsers.push(...res.data);
				});
			},

			getProjectList: function() {
				let projectId = this.$route.query.projectId;

				this.projectService.getProjects().then((res) => {
					this.projects.splice(0, this.projects.length);
					this.projects.push(...res.data);
					this.projects = this.projects.sort(this.sortByActive);
					this.projects = this.projects.sort(this.sortByName);

					if (projectId) {
						this.projects.forEach((p) => {
							if (p.id === projectId) {
								p.initExpanded = true;
							}
						});
					}

					if (this.projects.length && !projectId) {
						this.projects[0].initExpanded = true;
					}

					this.getDeliverables();
					this.fixupTabs();
				});
			},

			fixupTabs: function() {
				if (!this.hasProjectManagement) {
					this.$store.state.tabs.splice(1, this.$store.state.tabs.length);
				}
			},

			getDeliverables: function() {
				this.projectService.getDeliverables().then((res) => {
					this.deliverables.splice(0, this.deliverables.length);
					this.deliverables.push(...res.data);
					this.deliverables.forEach((d) => {
						d.project = this.projects.find((p) => p.id === d.projectId);
					});
				});
			},

			addDeliverable: function(column) {
				if (this.fullAccessProjects.length > 1) {
					this.$store.state.globalModalController
						.openModal(ProjectSelector, { projects: this.fullAccessProjects })
						.then((res) => {
							if (res) {
								this.createDeliverable(res.project, column);
							}
						});
				} else {
					this.createDeliverable(this.fullAccessProjects[0], column);
				}
			},

			createDeliverable: function(project, column) {
				let deliverable = {
					id: null,
					name: null,
					description: null,
					assignedTo: null,
					dueDate: null,
					statusId: column.id,
					tasks: [],
					comments: [],
					events: [],
					files: [],
					archived: false,
				};
				this.$store.commit('startLoading');
				this.projectService
					.createDeliverable(project.id, deliverable)
					.then((res) => {
						this.editDeliverable(res.data);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			updateKanbanProperties: function(updates) {
				this.projectService.updateKanbanProperties(updates);
			},

			getChannelName() {
				return this.$store.getters.getMessageChannelPrefix + '.pr';
			},

			eventHandler: function(event) {
				if (event.userMetadata === 'ProjectDeliverableMini') {
					this.processDeliverableDataEvent(event.message);
				} else if (event.userMetadata === 'KanbanUpdate') {
					this.processKanbanUpdate(event.message);
				}
			},

			processKanbanUpdate: function(updates) {
				for (let i = 0; i < updates.length; i++) {
					let deliverableId = updates[i].deliverableId;
					let kanbanSort = updates[i].kanbanSort;
					let statusId = updates[i].statusId;

					let ix = this.deliverables.findIndex((d) => d.id === deliverableId);

					if (ix > -1) {
						let deliverable = this.deliverables[ix];
						deliverable.kanbanSort = kanbanSort;
						deliverable.statusId = statusId;
						this.deliverables.splice(ix, 1, deliverable);
					}
				}
			},

			processDeliverableDataEvent: function(deliverable) {
				let project = this.projects.find((p) => p.id === deliverable.projectId);

				if (!project) {
					return;
				}

				deliverable.project = project;

				let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);
				if (ix > -1) {
					if (deliverable.statusId === '__deleted__') {
						this.deliverables.splice(ix, 1);
					} else {
						this.deliverables.splice(ix, 1, deliverable);
					}
				} else if (deliverable.statusId !== '__deleted__') {
					this.deliverables.push(deliverable);
				}
			},

			editDeliverable: function(deliverable) {
				let project = this.projects.find((p) => p.id === deliverable.projectId);
				this.projectService.getDeliverableDetail(deliverable.id).then((res) => {
					let binding = {
						origDeliverable: res.data,
						project: project,
						statusList: this.statusList,
					};
					this.$store.state.globalModalController.openModal(DeliverableDetail, binding).then((res) => {
						if (res) {
							res.project = project;
							let ix = this.deliverables.findIndex((d) => d.id === deliverable.id);
							if (ix > -1) {
								this.deliverables.splice(ix, 1, res);
							} else {
								this.deliverables.push(res);
							}
						}
					});
				});
			},

			datesUpdated: function(dates) {
				let patch = [];
				let ix = this.deliverables.findIndex((d) => d.id === dates.id);
				let deliverable = this.deliverables[ix];

				if (deliverable.project.portalAccess === 'Full access') {
					deliverable.startDate = dates.startDate;
					deliverable.dueDate = dates.dueDate;
					this.deliverables.splice(ix, 1, deliverable);

					patch.push({
						op: 'replace',
						path: '/dueDate',
						value: dates.dueDate,
					});

					patch.push({
						op: 'replace',
						path: '/startDate',
						value: dates.startDate,
					});

					this.projectService.patchDeliverable(deliverable.id, patch);
				} else {
					this.deliverables.splice(ix, 1, deliverable);
				}
			},

			getDeliverableStatusList: function() {
				this.projectService.getDeliverableStatus().then((res) => {
					this.statusList.push(...res.data.statusList);
				});
			},

			sortByActive: function(a, b) {
				if (a.active && b.active) {
					return 0;
				} else if (a.active) {
					return -1;
				} else if (b.active) {
					return 1;
				} else {
					return 0;
				}
			},

			sortByName: function(a, b) {
				return a.name.localeCompare(b.name);
			},
		},

		computed: {
			filteredProjects: function() {
				let result = [...this.projects];
				return result
					.filter((p) => {
						if (this.filter === 'Active') {
							return p.active === true;
						} else if (this.filter === 'Complete') {
							return p.active === false;
						} else {
							return true;
						}
					})
					.sort(this.sortByActive)
					.sort(this.sortByName);
			},

			fullAccessProjects: function() {
				return this.projects.filter((p) => p.active && p.portalAccess === 'Full access');
			},

			fullReadProjects: function() {
				return this.projects.filter(
					(p) => p.active && (p.portalAccess === 'Full access' || p.portalAccess === 'Read only')
				);
			},

			hasProjectManagement: function() {
				return this.projects.filter((p) => p.portalAccess !== 'Overview').length > 0;
			},
		},

		watch: {
			v: function(val) {
				this.setView(val);
			},
		},
	};
</script>

<style scoped lang="scss">
	.project-wrapper {
		min-height: 42px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		margin-bottom: 16px;
	}
</style>
