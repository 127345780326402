<template>
	<div v-if="proposals.length || agreements.length">
		<div class="brand-medium pointer" @click="$router.push('/agreements')">
			{{ $t('welcome.currently-have') }} <span style="color: var(--v-primary-base)">{{ proposals.length + agreements.length }}</span>
			{{ $tc('welcome.proposals-ready', proposals.length + agreements.length) }}.
		</div>
		<div class="column-format" style="gap: 8px; margin-top: 12px">
			<div @click.stop="openProposal(proposal)" v-for="proposal in proposals" :key="proposal.id" class="action-button">
				<span v-html="proposal.coverPage.heading"></span> - {{ $t('global.submitted') }}
				{{ DateTime.fromISO(proposal.dateSent).toFormat('MMM dd') }}
			</div>
      <div @click.stop="openAgreement(agreement)" v-for="agreement in agreements" :key="agreement.id" class="action-button">
        <span>{{agreement.name}}</span> - {{ $t('global.submitted') }}
        {{ DateTime.fromISO(agreement.dateCreated).toFormat('MMM dd') }}
      </div>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
	import ProposalDetails from '@/modules/proposal/ProposalDetails';
	export default {
		name: 'Proposals',

		props: ['proposals','agreements'],

		components: {},

		data: function() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openProposal: function(proposal) {
				let binding = {
					proposalId: proposal.id,
				};
				this.$store.state.globalModalController
					.openModal(ProposalDetails, binding, false, true)
					.then(() => this.$emit('refresh'));
			},
      openAgreement: function(agreement){
        let signer = agreement.signers.find(s => s.email === this.$store.state.loggedInUser.email);
        window.open(`/document/${agreement.id}${signer ? '.' + signer.id : ''}`,'_blank');
      }
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
