<template>
	<div
		class="text-left"
		@click="handleClick($event)"
		:style="
			`padding-left: ${padding(item.pl)}px; padding-right: ${padding(item.pr)}px; padding-bottom: ${item.pb}px; padding-top: ${item.pt}px; `
		"
	>
		<editor
			v-if="isReady && activeElementId === item.id && editMode"
			ref="textEditor"
			:api-key="$store.getters.getTinyMceKey"
			:initial-value="text"
			:inline="true"
			:init="mceConfigText"
			@input="handleTextInput"
			:spellcheck="true"
		></editor>

		<div v-else v-html="tokenizedText"></div>

		<div v-if="active" class="row-format mb-n1 mt-1">
			<v-icon size="22" class="material-symbols-outlined" style="cursor: grab">drag_indicator</v-icon>
		</div>
	</div>
</template>

<script>
	import marked from "marked";
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'TextBlock',

		props: ['item', 'active', 'mceConfig','tokens','editMode','templateMode','isMobile','pageWidth'],

		mixins: [ResponsiveMixin],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function() {
			return {
				mceConfigText: null,
				text: this.item.text,
				isEditorActive: false,
				isReady: false,

				initialAiContext: [
					'You are helping to write content for a proposal.',
					'Feel free to ask clarifying questions.',
					'Once you have gathered enough information from the user, please output a nicely formatted version and prompt the user to click the "Create" button if they are happy with result.',
				],

				initialAiPrompt: 'Please tell me a bit about what I can help you write today.',
				finalizationPrompt: `Please return the final copy nicely formatted without any explanations or additional text.  No additional prompts to the user should be included.`
			};
		},

		mounted() {
			this.mceConfigText = JSON.parse(JSON.stringify(this.mceConfig));
			this.mceConfigText.contextmenu =  'paste | link | table | assistant';
			this.mceConfigText.setup = (editor) => {
				let items = [];

				this.tokens.forEach((value,key) => {
					items.push({
						type: 'menuitem',
						text: key,
						onAction: function () {
							editor.insertContent('{{' + key + '}}');
						}
					});
				})

				editor.ui.registry.addMenuButton('tokenbutton', {
					text: 'Tokens',
					fetch: function (callback) {
						callback(items);
					}
				});

				editor.ui.registry.addButton('ai', {
					text: '✨Assistant',
					onAction: () => {
						this.aiHelper();
					},
				});

				editor.ui.registry.addNestedMenuItem('assistant', {
					text: '✨Assistant',
					getSubmenuItems: () => {
						return [
							{
								type: 'menuitem',
								text: 'Elaborate',
								onAction: () => {
									this.rewrite('elaborate');
								},
							},
							{
								type: 'menuitem',
								text: 'Simplify',
								onAction: () => {
									this.rewrite('simplify');
								},
							},
							{
								type: 'menuitem',
								text: 'Make more formal',
								onAction: () => {
									this.rewrite('formalize');
								},
							},
							{
								type: 'menuitem',
								text: 'Make friendlier',
								onAction: () => {
									this.rewrite('friendlier');
								},
							},
							{
								type: 'menuitem',
								text: 'Summarize',
								onAction: () => {
									this.rewrite('summarize');
								},
							},
							{
								type: 'menuitem',
								text: 'Paraphrase',
								onAction: () => {
									this.rewrite('paraphrase');
								},
							},
						];
					},
				});

				editor.on('init', () => {
					this.editorActive(true);
				});

				editor.on('focusin', () => {
					this.editorActive(true);
				});

				editor.on('focusout', () => {
					this.broadcastChange();
				});
			};
			this.isReady = true;
		},

		beforeDestroy() {},

		methods: {
			async rewrite(modifier) {
				const { default: AiAssistantService } = await import('@/modules/ai/AiAssistantService');
				let aiAssistantService = new AiAssistantService();
				let editor = this.$refs.textEditor.editor;
				let selection = editor.selection.getContent({ format: 'html' });

				if (selection) {
					this.$store.commit('startLoading');
					aiAssistantService
							.rewrite(selection, modifier)
							.then((response) => {
								editor.selection.setContent(response);
								this.text = editor.getContent();
								let element = {... this.item};
								element.text = this.text;
								this.$emit('change', element);
							})
							.catch((err) => this.$store.commit('error', err.response?.data?.message))
							.finally(() => this.$store.commit('stopLoading'));
				}
			},

			broadcastChange: function(){
				this.editorActive(false);
				let element = {... this.item};
				element.text = this.text;
				this.$emit('change', element);
			},

			async aiHelper(){
				const { default: AiChatWidget } = await import('@/modules/ai/AiChatWidget');
				let binding = {
					maxHeight: 'calc(100vh - 350px)',
					context: this.initialAiContext,
					initialPrompt: this.initialAiPrompt,
					finalizationPrompt: this.finalizationPrompt,
					useCase: 'GeneralChat',
					model: "gpt-4o",
					resultFormat: "text"
				}
				this.$store.state.globalModalController.openModal(AiChatWidget,binding).then((res) => {
					if(res){
						let m = marked(res, { breaks: true });
						m = m.replaceAll('<a href', '<a target="_blank" href');

						const editor = this.$refs.textEditor.editor;
						const currentContent = editor.getContent();
						editor.setContent(currentContent + m);
						this.text = currentContent + m;
						this.broadcastChange();
					}
				})
			},


			handleClick: function(event) {
				if ((!this.isEditorActive || !this.active) && !this.isFocused) {
					this.$emit('click', event);
				} else {
					event.stopPropagation();
				}
			},

			editorActive: function(value) {
				this.isEditorActive = value;
				this.$store.state.eventBus.$emit('drag-enabled', !value);
				this.$store.state.eventBus.$emit('undo-enabled', !value);
			},

			handleTextInput: function() {
				this.text = this.$refs.textEditor.editor.getContent();
			},
		},

		computed: {
			activeElementId: function(){
				return this.$store.state.agreementBuilderState.activeElementId;
			},

			tokenizedText: function() {
				if (!this.item.text) {
					return null;
				}

				let text = this.item.text;

				if(!this.templateMode) {
					this.tokens.forEach((value, key) => {
						text = text.replaceAll(`{{${key}}}`, value);
					});
				}

				return text;
			},
		},

		watch: {
			itemText: function(val){
				this.text = val;
			},

			active: function(val) {
				if (val) {
					setTimeout(() => {if(this.$refs.textEditor && this.$refs.textEditor.editor)this.$refs.textEditor.editor.focus()}, 200);
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
