import SchedulerList from '@/modules/meetings/SchedulerList';
import MeetingList from '@/modules/meetings/MeetingList';

export default [
	{
		path: '/meetings/schedulers',
		name: 'Schedulers',
		component: SchedulerList,
	},
	{
		path: '/meetings/booked',
		name: 'Booked',
		component: MeetingList,
	}
]