<template>
	<div class="mx-2">
		<pie-chart :chart-data="chartData" :options="options" style="height:450px"></pie-chart>
	</div>
</template>

<script>
	import PieChart from '@/modules/charts/PieChart';

	export default {
		name: 'SpendByProject',

		props: ['invoices', 'projects'],

		components: { PieChart },

		data: function() {
			return {
				options: {
					showTooltips: true,
					responsive: true,
					maintainAspectRatio: false,

          legend: {
            position: 'bottom'
          },

					elements: {},

					tooltips: {
						callbacks: {
							title: function(tooltipItems) {
								return tooltipItems[0].xLabel;
							},
							label: (tti, data) => {
               let dataValue = data.datasets[tti.datasetIndex].data[tti.index];
								let amount = this.$formatters.dollars(dataValue,true,true,this.$store.state.defaultCurrency);
								return ` ${data.labels[tti.index]}: ${amount}`;
							},
						},
					},
				},
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			chartData: function() {
				return {
					labels: this.labels,
					datasets: [
						{
							label:this.$t('invoices.project'),
							data: this.invoiced,
							backgroundColor: this.$store.state.chartColors,
						},
					],
				};
			},

			labels: function() {
				let labels = [];

        this.invoiceByProject.filter(i => i.amount > 0).forEach(i => {
          labels.push(i.name);
        })

				return labels;
			},

			invoiced: function() {
				let values = [];

        this.invoiceByProject.filter(i => i.amount > 0).forEach(i => {
          values.push(i.amount);
        })

				return values;
			},

			invoiceByProject: function() {
				if (!this.invoices.length || !this.projects.length) {
					return [];
				}

				let projectMap = new Map();
				this.projects.forEach((p) =>
					projectMap.set(p.id, {
						id: p.id,
						name: p.name,
						amount: 0,
					})
				);

				for (let i of this.invoices) {
					for (let item of i.items) {
						if (item.projectId && projectMap.has(item.projectId)) {
							let p = projectMap.get(item.projectId);
							p.amount = p.amount + item.subtotal;
						}
					}
				}

        let result = [];
        projectMap.forEach(value => result.push(value));
        return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
