import HttpClient from '@/services/HttpClient';

export default class EmbeddedServices {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    getDeliverablePackages() {
        return this.httpClient
            .get('/templates/deliverablePackages')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}