import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class ProposalService {
	httpClient;
	proposal;

	constructor() {
		this.httpClient = new HttpClient();
	}

	setProposal(proposal){
		this.proposal = proposal;
	}

	getProposalList(){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/proposals`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProposal(id) {
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/proposals/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	executeProposal(result) {
		let clientId = store.getters.getClientId;
		let id = this.proposal.id;

		return this.httpClient
			.post(`/${clientId}/proposals/${id}/execute`, result)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendComment(clientId,proposalId,comment){
		return this.httpClient
			.post(`/${clientId}/proposals/${proposalId}/comment`, comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateComment(clientId,proposalId,comment){
		return this.httpClient
			.put(`/${clientId}/proposals/${proposalId}/comment`,comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteComment(clientId,proposalId,commentId){
		return this.httpClient
			.delete(`/${clientId}/proposals/${proposalId}/comment?commentId=${commentId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setLastViewed(){
		let clientId = store.getters.getClientId;
		let id = this.proposal.id;

		return this.httpClient
			.post(`/${clientId}/proposals/${id}/viewed`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(clientId, proposalId, fileName) {
		return this.httpClient
			.get(`/${clientId}/proposals/${proposalId}/files`, {
				params: {
					fileName: fileName,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
