<template>
	<div v-if="subscriptionRequests.length">
		<div class="brand-medium pointer" @click="$router.push('/invoices?v=subscriptions')">
			{{ $t('welcome.currently-have') }}
			<span style="color: var(--v-primary-base)">{{ subscriptionRequests.length }}</span>
			{{ $tc('welcome.subscription-payments',subscriptionRequests.length) }}.
		</div>
		<div class="column-format" style="gap: 8px; margin-top: 12px">
			<div
				v-for="request in subscriptionRequests"
				:key="request.id"
				class="action-button"
				@click.stop="openRequest(request)"
			>
        <div v-for="(product,index) in request.productSelections" :key="index">
          {{ product.quantity }} x {{ product.subscriptionProduct.name }} @
          {{
            $formatters.dollars(
                product.subscriptionProduct.prices[0].unitAmount / 100,
                true,
                true,
                product.subscriptionProduct.prices[0].currency
            )
          }}
        </div>
			</div>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';

	export default {
		name: 'SubscriptionRequests',

		props: ['subscriptionRequests'],

		components: {},

		data: function() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openRequest: function(request) {
				window.location.href = request.checkoutUrl;
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
