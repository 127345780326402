<template>
	<div
			class="text-left"
			@click="$emit('click', $event);"
			:style="
			`padding-left: ${padding(item.pl)}px; padding-right: ${padding(item.pr)}px; padding-bottom: ${item.pb}px; padding-top: ${item.pt}px; font-size: ${item.fontSize}px `
		"
	>
		<div v-html="tokenizedText"></div>
	</div>
</template>

<script>
import ResponsiveMixin from "./ResponsiveMixin";

export default {
	name: "Terms",

	props: ['item', 'active','tokens','templateMode','isMobile','pageWidth'],

	mixins: [ResponsiveMixin],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {
		tokenizedText: function() {
			if (!this.item.evaluatedTerms) {
				return null;
			}

			let text = this.item.evaluatedTerms;
			if(!this.templateMode) {
				this.tokens.forEach((value, key) => {
					text = text.replaceAll(`{{${key}}}`, value);
				});
			}
			return text;
		},
	},

}
</script>

<style scoped lang="scss">

</style>