<template>
	<div>
		<h-header>
			<meeting-list-filter slot="filter" v-model="filter" @clear-filter="clearFilter()"></meeting-list-filter>
		</h-header>

		<div class="column-format py-3 pl-3 show-scrollbar" style="flex: 1; height: var(--vh)" v-if="isReady">
			<v-data-table
				:key="tableKey"
				:headers="headers"
				:items="filteredMeetingList"
				@click:row="openScheduledMeeting($event)"
				style="cursor: pointer"
				:items-per-page="10"
				sort-by="confirmedTime.start"
				sort-asc
				:hide-default-footer="scheduledMeetings.length <= 10"
			>
				<template v-slot:item.confirmedTime.start="{ item }">
					<span :class="`${item.meetingStatus === 'Cancelled' ? 'cancelled' : ''}`">{{
						DateTime.fromISO(item.confirmedTime.start).toFormat("ccc, LLL dd '@'  t")
					}}</span>
				</template>

				<template v-slot:item.meetingName="{ item }">
					<span :class="`${item.meetingStatus === 'Cancelled' ? 'cancelled' : ''}`">{{ item.meetingName }}</span>
				</template>

				<template v-slot:item.contact="{ item }">
					<span :class="`${item.meetingStatus === 'Cancelled' ? 'cancelled' : ''}`"
						>{{ item.formData.firstName }} {{ item.formData.lastName }}</span
					>
				</template>

				<template v-slot:no-data>
					<div v-if="!scheduledMeetings.length">
						{{ $t('meetings.booked.no-meetings-booked') }}
					</div>
					<div v-else>
						{{ $t('meetings.booked.no-matching-criteria') }}
					</div>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
	import FilterHelpers from '@/utils/FilterHelpers';
	import MeetingService from '@/modules/meetings/MeetingService';
	import MeetingDetail from '@/modules/meetings/MeetingDetail';
	import HHeader from '@/components/HHeader';
	import MeetingListFilter from '@/modules/meetings/MeetingListFilter';

	export default {
		name: 'MeetingList',

		components: { HHeader, MeetingListFilter },

		data: function() {
			return {
				meetingService: new MeetingService(),
				tableKey: 0,
				scheduledMeetings: [],
				isReady: false,
				DateTime: DateTime,
				filter: this.emptyFilter(),

				pages: [
					{ label: this.$t('meetings.schedule.heading'), value: 'schedulers' },
					{ label: this.$t('meetings.booked.heading'), value: 'booked' },
				],
			};
		},

		mounted() {
			this.getScheduledMeetings();
			this.loadSavedFilter();
		},

		beforeDestroy() {},

		methods: {
			setView: function(view) {
				this.$router.push('/meetings/' + view);
			},

			emptyFilter: function() {
				return {
					search: null,
					dateSpecifier: null,
					earliest: null,
					latest: null,
					meetingStatus: [],
				};
			},

			saveCurrentFilter() {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			clearFilter() {
				this.filter = this.emptyFilter();
			},

			loadSavedFilter() {
				try {
					let filterString = localStorage.getItem(this.filterStateKey);
					if (filterString) {
						this.filter = JSON.parse(filterString);
					} else {
						this.filter = this.emptyFilter();
						this.filter.dateSpecifier = 'next-30-days';
						this.filter.meetingStatus.push('Scheduled');
					}
				} catch (err) {
					console.log('Error reading filter preferences from local storage.', err);
				}
			},

			getScheduledMeetings: function() {
				this.meetingService.getScheduledMeetings().then((res) => {
					this.scheduledMeetings.splice(0, this.scheduledMeetings.length);
					this.scheduledMeetings.push(...res.data);
					this.isReady = true;
				});
			},

			openScheduledMeeting: function(meeting) {
				let binding = {
					scheduledMeeting: meeting,
				};
				this.$store.state.globalModalController.openModal(MeetingDetail, binding, false, false).then((res) => {
					if (res) {
						this.getScheduledMeetings();
					}
				});
			},
		},

		watch: {
			filter: {
				deep: true,
				handler() {
					this.saveCurrentFilter();
				},
			},
		},

		computed: {
			filteredMeetingList: function() {
				let result = [...this.scheduledMeetings];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;

				return result
					.filter((m) => {
						if (
							!search ||
							m.formData.email.toLowerCase().startsWith(search) ||
							m.formData.firstName.toLowerCase().startsWith(search) ||
							m.formData.lastName.toLowerCase().startsWith(search)
						) {
							return true;
						} else if (search) {
							return false;
						}
					})
					.filter((m) => {
						if (this.filter.meetingStatus.length) {
							if (this.filter.meetingStatus.includes(m.meetingStatus)) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((m) => {
						if (this.earliest && this.latest) {
							let compareDate = DateTime.fromISO(m.confirmedTime.start);
							if (compareDate >= this.earliest && compareDate <= this.latest) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					});
			},

			earliest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.earliest);
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).earliest;
					}
				} else {
					return DateTime.now()
						.minus({ years: 100 })
						.startOf('month');
				}
			},

			latest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.latest);
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).latest;
					}
				} else {
					return DateTime.now().plus({years:100});
				}
			},

			filterStateKey: function() {
				return 'MEETING_LIST_FILTERS_' + this.$store.getters.getClientId;
			},

			headers: function() {
				return [
					{ text: this.$t('meetings.booked.meeting-name'), value: 'meetingName' },
					{ text: this.$t('meetings.booked.start-time'), value: 'confirmedTime.start' },
					{ text: this.$t('meetings.booked.booked-by'), value: 'contact' },
				];
			},
		},
	};
</script>

<style scoped lang="scss">
	.cancelled {
		text-decoration: line-through;
	}
</style>
