<template>
	<div
		id="invoice-line-items"
		@click="$emit('click', $event)"
		:style="
			`width:100%; padding-top: ${item.pt}px; padding-bottom: ${item.pb}px; padding-left: ${item.pl}px; padding-right: ${item.pr}px; overflow-x: auto; --menuY:${menuY}px; --menuX:${menuX}px;`
		"
	>
		<table :class="`line-item-table ${item.size.toLowerCase()}`" style="height: 1px">
			<thead>
				<tr class="header">
					<th>{{ item.labels.description }}</th>
					<th style="width:10%" v-if="item.enabledColumns.quantity">{{ item.labels.quantity }}</th>
					<th style="width:15%" v-if="item.enabledColumns.rate">{{ item.labels.rate }}</th>
					<th style="width:10%" v-if="item.enabledColumns.tax">{{ item.labels.tax }}</th>
					<th style="width:15%">{{ item.labels.total }}</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="(lineItem, index) in items">
					<tr v-if="lineItem.visible"
						:class="
							`handle ${item.showTimeDetails && lineItem.timerEventIds && lineItem.timerEventIds.length ? '' : 'line-item'} ${
								editMode && !lineItem.isTransientItem ? 'pointer' : ''
							}`
						"
						:style="lineItem.isTransientItem ? 'opacity: 0.4' : ''"
						@click="editLineItem(lineItem, $event)"
						:id="'line-' + lineItem.id"
						:key="'A' + lineItem.id + index"
						:content="lineItem.isTransientItem ? 'This item is automatically added based on your settings in the Recurring invoice panel.  If you wish to change the rates or inclusion, please update your project or recurring settings accordingly.' : null"
						v-tippy
					>
						<td class="line-item-description">
							<div class="description row-format align-center">
								<div class="column-format re-order" v-if="editMode && !lineItem.isTransientItem">
									<v-icon color="gray_70" class="mb-n1" @click.stop="moveUp(index)">expand_less</v-icon>
									<v-icon color="gray_70" class="mt-n1" @click.stop="moveDown(index)">expand_more</v-icon>
								</div>
								<div style="line-height: 1.4em"><span v-html="lineItem.description"></span><br>
									<span v-if="lineItem.projectId && !lineItem.description.includes(lineItem.projectName)"
										>{{ lineItem.projectName }}</span>
									<span v-if="lineItem.retainerPeriod"
										><br />{{
											DateTime.fromISO(lineItem.retainerPeriod.start).toLocaleString(DateTime.DATE_MED)
										}}
										-
										{{
											DateTime.fromISO(lineItem.retainerPeriod.end).toLocaleString(DateTime.DATE_MED)
										}}</span
									>
									<span v-if="lineItem.type === 'DEPOSIT'">
										<br>
										<v-icon color="gray_70"  size="16">account_balance</v-icon>
									</span>

								</div>
							</div>
						</td>

						<td style="width:10%" v-if="item.enabledColumns.quantity">
							<span v-if="item.roundQty">{{ formatQuantity(lineItem) }}</span>
							<span v-else v-tippy="{content:lineItem.qty}">{{ formatQuantity(lineItem) }}</span>
						</td>
						<td style="width:15%" v-if="item.enabledColumns.rate">
							{{ $formatters.dollars(lineItem.price, true, true, currency, 4) }}
						</td>
						<td style="width:10%" v-if="item.enabledColumns.tax">
							<v-icon
								v-if="lineItem.taxable"
								class="material-symbols-rounded pr-1"
								size="22"
								:style="`color:${styles.fontColor}!important`"
								>check</v-icon
							>
						</td>

						<td style="width:15%">{{ $formatters.dollars(lineItem.subtotal, true, true, currency) }}</td>
					</tr>
					<tr
						v-if="lineItem.visible && item.showTimeDetails && lineItem.timerEventIds && lineItem.timerEventIds.length && lineItem.id"
						:key="'B' + lineItem.id + index"
						class="line-item"
					>
						<td colspan="5" style="padding-top: 0!important;padding-bottom: 4px">
							<time-entries
								:invoice="invoice"
								:invoice-item-id="lineItem.id"
								:invoice-service="invoiceService"
							></time-entries>
						</td>
					</tr>
				</template>
				<tr style="height: 100%">
					<td :colspan="colspanSpacer" class="pr-3" :style="`height: 100%; text-align: ${buttonAlignment}`">
						<div style="min-height: 100%; height: 100%;" class="column-format">
							<div class="my-4" v-if="editMode && invoice.invoiceType === 'STANDARD'">
								<v-menu
									v-model="showProductsMenu"
									max-height="90vh"
									max-width="900"
									:close-on-content-click="false"
									:close-on-click="true"
									content-class="filter-menu-class"
									style="overflow-y: auto"
									origin="top center"
									transition="scale-transition"
								>
									<template v-slot:activator="{ on }">
										<div class="add-a-new-line" v-on="on">
											<v-icon size="20" color="primary">add_circle</v-icon>
											<span class="brand-regular">
												{{ $t('invoice.details.add-a-new-line-item') }}
											</span>
										</div>
									</template>
									<products
										v-if="editMode"
										@close="showProductsMenu = false"
										@select="handleSelectProduct($event)"
										:currency="invoice.currency"
										:client-id="invoice.clientId"
									/>
								</v-menu>

								<div class="add-a-new-line" v-if="!recurring && !paymentPlan" @click="handleAddNewTrackedItemModal()">
									<v-icon size="20" color="primary">folder_special</v-icon>
									<span class="brand-regular"> {{ $t('invoice.details.add-line-item-from-billable') }} </span>
								</div>

								<v-menu
										v-if="paymentPlan"
										v-model="showProjectMenu"
										max-height="90vh"
										max-width="900"
										:close-on-content-click="false"
										:close-on-click="true"
										content-class="filter-menu-class"
										style="overflow-y: auto"
										origin="top center"
										transition="scale-transition"
								>
									<template v-slot:activator="{ on }">
										<div class="add-a-new-line" v-on="on">
											<v-icon size="20" color="primary">folder_special</v-icon>
											<span class="brand-regular"> {{ $t('invoice.details.add-fixed-price-project') }} </span>
										</div>
									</template>
									<fixed-price-project-list :invoice="invoice" :invoice-service="invoiceService" @selected="handleSelectProject($event)"></fixed-price-project-list>
								</v-menu>

								<!-- Add a new expense -->
								<div class="add-a-new-line" v-if="hasExpenses && !recurring && !paymentPlan" @click="handleAddExpenses()">
									<v-icon size="20" color="primary">paid</v-icon>
									<span class="brand-regular">{{ $t('invoice.details.add-expenses') }}</span>
								</div>

								<!-- Apply a deposit -->
								<div
									class="add-a-new-line"
									v-if="(balanceExists || invoice.creditAmount) && !recurring && !paymentPlan"
									@click="handleApplyDeposit()"
								>
									<v-icon size="20" color="primary">account_balance</v-icon
									><span class="brand-regular">{{ $t('invoice.details.apply-deposit-credit') }}</span>
								</div>
							</div>

							<div class="mt-auto">
								<v-btn
									class="elevation-0"
									:color="item.buttonColor"
									:x-small="payNowButton.xSmall"
									:small="payNowButton.small"
									:large="payNowButton.large"
									:x-large="payNowButton.xLarge"
									:outlined="payNowButton.outlined"
									:block="payNowButton.block"
									:text="payNowButton.text"
									v-if="invoice.amountDue > 0 && invoice.payOnlineUrl && invoice.allowOnlinePayment"
									@click="handlePayNow()"
								>
									<v-icon
										v-if="item.payNowIcon"
										:style="`color: ${item.buttonTextColor}!important`"
										class="material-symbols-rounded mr-1"
										>{{ item.payNowIcon }}</v-icon
									>
									<span :style="`color: ${item.buttonTextColor}; font-family: ${styles.font}`">{{
										item.payNowText
									}}</span>
								</v-btn>
							</div>
						</div>
					</td>
					<td :colspan="colspanSubTotal" style="padding-right: 0!important">
						<div class="row-format">
							<div class="ml-auto mt-2">
								<table style="border-collapse: collapse;">
									<tr class="summary sub-total">
										<td class="text-right summary-label" colspan="2">{{ item.labels.subTotal }}</td>
										<td>{{ $formatters.dollars(invoice.itemSubtotal, true, true, currency) }}</td>
									</tr>
									<tr class="summary discount" v-if="invoice.discountAmount || inBuilder">
										<td class="text-right summary-label">{{ item.labels.discount }}</td>
										<td class="text-right summary-label">{{ invoice.discountPercentage }}%</td>
										<td>{{ $formatters.dollars(invoice.discountAmount * -1, true, true, currency) }}</td>
									</tr>
									<tr class="summary credit" v-if="invoice.creditAmount || inBuilder">
										<td class="text-right summary-label" colspan="2">{{ item.labels.creditApplied }}</td>
										<td>{{ $formatters.dollars(invoice.creditAmount * -1, true, true, currency) }}</td>
									</tr>
									<tr class="summary credit" v-if="invoice.convenienceFee || inBuilder">
										<td class="text-right summary-label" colspan="2">{{ item.labels.processingFee }}</td>
										<td>{{ $formatters.dollars(invoice.convenienceFee, true, true, currency) }}</td>
									</tr>
									<tr class="summary late-fee" v-if="lateFee || inBuilder">
										<td class="text-right summary-label" colspan="2">{{ item.labels.lateFee }}</td>
										<td>
											{{ $formatters.dollars(lateFee, true, true, currency) }}
										</td>
									</tr>
									<tr class="summary tax" v-if="showTax">
										<td class="text-right summary-label">{{ item.labels.tax }}</td>
										<td class="text-right summary-label">{{ invoice.taxPercentage }}%</td>
										<td>{{ $formatters.dollars(invoice.taxAmount, true, true, currency) }}</td>
									</tr>
									<tr class="summary grand-total">
										<td class="text-right summary-label" colspan="2">{{ item.labels.total }}</td>
										<td>{{ $formatters.dollars(invoice.total, true, true, currency) }}</td>
									</tr>
									<tr class="summary status" v-for="payment in invoice.payments" :key="payment.id">
										<td class="text-right summary-label" colspan="2">{{ $DateTime.shortDate(payment.datePaid)}}</td>
										<td>{{ $formatters.dollars(payment.amount, true, true, currency) }}</td>
									</tr>
									<tr class="summary status" v-if="invoice.paymentTotal > 0">
										<td class="text-right summary-label" colspan="2">{{ item.labels.paymentTotal }}</td>
										<td>{{ $formatters.dollars(invoice.paymentTotal, true, true, currency) }}</td>
									</tr>
									<tr class="summary status" v-if="invoice.paymentTotal > 0">
										<td class="text-right summary-label" colspan="2">{{ item.labels.amountDue }}</td>
										<td>{{ $formatters.dollars(invoice.amountDue, true, true, currency) }}</td>
									</tr>
								</table>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<scheduled-payments v-if="scheduledPayments && scheduledPayments.length" :invoice="invoice" :scheduled-payments="scheduledPayments" :line-items="item" class="mt-5"></scheduled-payments>
		<v-menu
			attach="#invoice-line-items"
			content-class="override-menu"
			v-model="dialog"
			v-if="dialog"
			:close-on-content-click="false"
			:close-on-click="false"
		>
			<line-item-editor
				:line-item="lineItem"
				:invoice="invoice"
				:menu-width="menuWidth"
				@cancel="dialog = false"
				@save="updateLineItem($event)"
				@delete="deleteLineItem($event)"
			></line-item-editor>
		</v-menu>
	</div>
</template>

<script>
	import RendererMixin from '@/modules/forms/v2/schema/RendererMixin';
	import Products from '@/modules/invoices/InvoiceRenderer/Products';
	import { v4 as uuid4 } from 'uuid';
	import LineItemEditor from '@/modules/templates/invoices/schema/LineItemEditor';
	import { DateTime } from 'luxon';
	import TimeEntries from '@/modules/invoices/invoiceRendererV2/TimeEntries';
	import FixedPriceProjectList from "@/modules/invoices/invoiceRendererV2/FixedPriceProjectList";
	import ScheduledPayments from "@/modules/templates/invoices/schema/ScheduledPayments";

	export default {
		name: 'LineItems',

		props: [
			'item',
			'invoice',
			'styles',
			'editMode',
			'inBuilder',
			'depositAccount',
			'hasExpenses',
			'recurring',
			'paymentPlan',
			'invoiceService',
			'scheduledPayments'
		],

		mixins: [RendererMixin],

		components: {ScheduledPayments, TimeEntries, LineItemEditor, Products, FixedPriceProjectList },

		data: function() {
			return {
				menuX: 0,
				menuY: 0,
				menuWidth: 0,
				lineItem: null,
				dialog: false,
				showProductsMenu: false,
				showProjectMenu: false,
				DateTime: DateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			dragEnd(item) {
				console.log(item);
			},

			moveUp: function(index){
				if(index === 0){
					return;
				}
				let item = this.invoice.items[index];
				this.invoice.items.splice(index,1);
				this.invoice.items.splice(index-1,0,item);
				this.forceSave();
			},

			moveDown: function(index){
				if(index === this.invoice.items.length - 1){
					return;
				}

				let item = this.invoice.items[index];
				this.invoice.items.splice(index,1);
				this.invoice.items.splice(index+1,0,item);
				this.forceSave();
			},

			updateLineItem: function(lineItem) {
				let ix = this.invoice.items.findIndex((i) => i.id === lineItem.id);
				if (ix > -1) {
					this.invoice.items.splice(ix, 1, lineItem);
				} else {
					this.invoice.items.push(lineItem);
				}
				this.closeEditModal();
				this.forceSave();
			},

			deleteLineItem: function(lineItem) {
				let ix = this.invoice.items.findIndex((i) => i.id === lineItem.id);
				if (ix > -1) {
					this.invoice.items.splice(ix, 1);
				}
				this.closeEditModal();
				this.forceSave();
			},

			closeEditModal: function() {
				this.dialog = false;
				this.lineItem = null;
			},

			editLineItem: function(lineItem) {
				if (!this.editMode || lineItem.isTransientItem) {
					return;
				}
				this.dialog = true;
				this.lineItem = lineItem;
				let div = document.getElementById('invoice-line-items').getBoundingClientRect();
				let rect = document.getElementById('line-' + lineItem.id).getBoundingClientRect();
				this.menuX = rect.x;
				this.menuY = rect.y + 2;
				this.menuWidth = div.width - this.item.pl - this.item.pr;
			},

			handleSelectProject(project){
				let item = {};
				item.id = uuid4();
				item.type = 'PROJECT';
				item.description = project.name;
				item.price = project.feeSchedule.amount - project.paymentAmount;
				item.qty = 1;
				item.taxable = project.feeSchedule.taxable;
				item.projectId = project.id;
				item.projectName = project.name;
				this.invoice.items.push(item);
				this.showProjectMenu = false;
				this.forceSave();
			},

			containsHTML: function(str) {
				const htmlTagPattern = /<\/?[a-z][\s\S]*>/i;
				return htmlTagPattern.test(str);
			},

			handleSelectProduct(event) {
				let item = {};
				item.id = uuid4();
				item.type = event.product.deposit ? 'DEPOSIT' : 'ADHOC';
				item.product = event.product;
				item.description = `<strong>${event.product.productName}</strong>`;

				if (event.product.description) {
					if(this.containsHTML(event.product.description)){
						item.description = `${item.description}<br>${event.product.description}`;
					}else{
						item.description = `${item.description}<br>${event.product.description.replace(/\n/g, '<br>')}`;
					}
				}

				let currencyRate = event.product.currencyRates.find((c) => c.currency === this.invoice.currency);

				if (currencyRate) {
					item.price = currencyRate.rate;
				} else {
					item.price = event.product.rate;
				}

				item.qty = 1;
				item.taxable = event.product.taxable;
				this.invoice.items.push(item);
				this.showProductsMenu = false;
				this.forceSave();
				this.$nextTick(() => this.editLineItem(item));
			},

			handleAddNewTrackedItemModal() {
				this.$store.state.eventBus.$emit('invoice', 'tracked-item-modal');
			},

			handleAddExpenses() {
				this.$store.state.eventBus.$emit('invoice', 'expenses-modal');
			},

			handleApplyDeposit() {
				this.$store.state.eventBus.$emit('invoice', 'apply-deposit-modal');
			},

			handlePayNow() {
				if (!this.editMode) {
					window.open(this.invoice.payOnlineUrl, '_blank');
				}
			},

			formatQuantity(lineItem){
				if(this.item.showTimeInHourMinute && lineItem.timerEventIds && lineItem.timerEventIds.length){
					return this.formatSeconds(lineItem.qty*60*60);
				}else {
					return Number.isInteger(lineItem.qty) ? Number(lineItem.qty).toFixed(0) : Number(lineItem.qty).toFixed(2);
				}
			},

			formatSeconds(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);

				if (minutes < 10) {
					minutes = '0' + minutes;
				}

				return hours + ':' + minutes;
			},

			forceSave() {
				this.$store.state.eventBus.$emit('invoice', 'force-save');
			},
		},

		computed: {
			showTax: function(){
				return this.invoice.taxAmount || this.invoice.taxPercentage || this.inBuilder || this.invoice.accountInfo.accountPreferences.showTaxWithZeroPercent;
			},

			lateFee: function() {
				return this.invoice.lateFeeApplied + this.invoice.lateFeeCalculated;
			},

			payNowButton: function() {
				return {
					xSmall: this.item.buttonSize === 'X-Small',
					small: this.item.buttonSize === 'Small',
					large: this.item.buttonSize === 'Large',
					xLarge: this.item.buttonSize === 'X-Large',
					outlined: this.item.buttonStyle === 'Outlined',
					block: this.item.buttonAlignment === 'block',
					text: this.item.buttonStyle === 'Text',
				};
			},

			buttonAlignment: function() {
				if (this.item.buttonAlignment === 'block') {
					return 'unset';
				} else {
					return this.item.buttonAlignment;
				}
			},

			currency: function() {
				return this.invoice.currency;
			},

			colspanSpacer: function() {
				let colspan = 1;
				if (this.item.enabledColumns.quantity) {
					colspan++;
				}
				if (this.item.enabledColumns.rate) {
					colspan++;
				}
				return colspan;
			},

			balanceExists() {
				if (!this.depositAccount) return false;
				return !!this.depositAccount.balance;
			},

			colspanSubTotal: function() {
				let colspan = 1;
				if (this.item.enabledColumns.tax) {
					colspan++;
				}
				return colspan;
			},

			items: function() {
				return this.invoice.items;
			},
		},
	};
</script>

<style lang="scss">
	.override-menu {
		top: var(--menuY) !important;
		left: var(--menuX) !important;
		position: absolute !important;
	}

	#invoice-line-items {
		strong {
			font-weight: 600;
		}

		ol {
			line-height: 1.75em;
		}

		.line-item-description {
			padding-top: 4px;
			padding-bottom: 4px;
			p {
				line-height: 1.2em;
				margin-top: -4px!important;
				margin-bottom: -4px!important;
			}

			.activator-label {
				line-height: 1 !important;
				margin-bottom: 8px;
			}

			.activator-label.show-hide {
				transition: height 200ms;
				height: 0;
			}

			&:hover {
				.activator-label.show-hide {
					height: 20px;
					transition: height 200ms;
				}
			}
		}
	}
</style>

<style scoped lang="scss">
	.add-a-new-line {
		font-family: Inter, serif !important;
		line-height: 2em;
		text-align: left;
		font-size: 14px;
		font-weight: 500;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 4px;

		cursor: pointer;
		&:hover {
			opacity: 0.7;
		}
		.v-icon {
		}
		color: var(--v-primary-base);
	}

	.line-item-table {
		border-collapse: collapse;
		width: 100%;
		thead {
			th {
				font-weight: 400;
				white-space: nowrap;
				text-align: right;
				padding-right: 8px;
			}
			th:nth-child(1) {
				text-align: left;
				padding-left: 8px;
			}

			th:last-child {
				padding-right: 8px;
			}
		}

		tbody {
			tr {
				td {
					vertical-align: top;
					text-align: right;
				}

				td:nth-child(1) {
					vertical-align: middle;
					text-align: left;
					padding-left: 8px;
				}

				td:last-child {
					padding-right: 8px;
				}
			}
		}
	}

	.line-item-table.compact {
		font-size: 14px !important;
		line-height: 2.5em;
	}

	.line-item-table.standard {
		font-size: 16px !important;
		line-height: 3em;
	}

	.line-item-table.roomy {
		font-size: 16px !important;
		line-height: 3.5em;
	}

	.summary {
		border-bottom: 1px solid #cfd3d7;
	}

	.summary-label {
		font-weight: 600;
		font-size: 0.85em;
		padding-right: 12px;
		white-space: nowrap;
	}

	.description {
		padding: 4px 0;
		line-height: 1.25em !important;
		font-size: 0.9em;
		div::first-line {
			font-size: 1.1em;
		}

	}

	.late-fee {
		color: red !important;
	}

	.grand-total {
		font-weight: 600;
	}

	.discount {
	}

	.credit {
	}

	.header {
		border-bottom: 1px solid #cfd3d7;
		font-size: 0.85em;
	}

	.handle {
		.re-order {
			display: none;
		}

		&:hover {
			.re-order {
				display: flex;
			}
		}
	}

	.line-item {
		border-bottom: 1px solid #cfd3d7;
		td {
			padding-right: 6px;
		}
	}

	.status {
		background-color: #ebedef;
		border: 0 !important;
		line-height: 2em;
	}
</style>
