
export default {

    data: function(){
        return {
            isReady: false,
            updateCounter: 0
        }
    },

    methods:{
        isInStatusFilter: function (filter,statusId) {
            if (filter.status.length === 0) {
                return true;
            } else if (filter.status.indexOf(statusId) > -1) {
                return true;
            } else {
                return false;
            }
        },


        sortByName: function (a, b) {
            if(a.name && b.name){
                return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})
            }else if(a.name){
                return 1;
            }else if(b.name){
                return -1;
            }else{
                return 0;
            }
        },
    },

    computed: {}


}