<template>
	<div class="confirm-dialog-div" style="width:500px">
		<div class="ma-4">
			<v-text-field
				v-model="inputValue"
				type="text"
				:label="label"
				persistent-placeholder
				autofocus hide-details class="h-outline"
			></v-text-field>
		</div>
		<v-form @submit.prevent="handleSubmit()" ref="confirmDialog" id="confirmDialog" lazy-validation>
			<div class="modal-footer">
				<v-btn v-if="!hideNo" min-width="100" class="secondary-action mr-1" @click.prevent="handleClose()">{{
					no
				}}</v-btn>
				<v-btn
					type="submit"
					min-width="100"
					elevation="0"
					:disabled="submitDisabled"
					class="primary-action ml-1"
					@click.prevent="handleSubmit()"
				>
					{{ yes }}
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'SimpleTextInput',

		props: {
			label: String,
			hideNo: { type: Boolean, required: false, default: false },
			yesText: { type: String, required: false },
			noText: { type: String, required: false } ,
			input: { type: String, required: false },
			required: {type: Boolean, required: false, default: true}
		},

		data() {
			return {
				inputValue: this.input ? this.input : null,
        yes: this.yesText ? this.yesText : this.$t('global.ok'),
        no: this.noText ? this.noText : this.$t('global.cancel')
			};
		},

		mounted() {},

		methods: {
			handleSubmit() {
				this.$emit('result', this.inputValue);
			},
			handleClose() {
				this.$emit('result', null);
			},
		},

		computed: {
			submitDisabled() {
				if (this.inputValue || !this.required) return false;
				else return true;
			},
		},
	};
</script>

<style lang="scss">
	.confirm-dialog-div {
		background-color: var(--v-white-base);
		position: relative;
		border-radius: 4px !important;
		width: 500px;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
