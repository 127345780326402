<template>
	<div></div>
</template>

<script>
	export default {
		name: 'InvoiceSettings',

		props: [],

		components: {  },

		data: function() {
			return {
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
