<template>
	<div>
		<div v-if="filter">
			<v-menu
				v-model="menuOpen"
				max-height="90vh"
				max-width="250"
				:close-on-content-click="false"
				:close-on-click="true"
				content-class="filter-menu-class"
				style="overflow-y: scroll"
			>
				<template v-slot:activator="{ on }">
					<div
						:class="`filter-button pointer row-format align-center ${!isDefaultFilter ? 'filter-active' : ''}`"
						v-on="on"
					>
						<v-icon
							:size="20"
							style="margin-top: 2px; margin-right: 2px"
							:color="!isDefaultFilter ? 'blue_70' : 'primary'"
							>$filter</v-icon
						>
						<div class="font-14" v-html="isDefaultFilter ? $t('meetings.filter.heading') : filterDescription"></div>
						<div v-if="!isDefaultFilter" style="margin-left: auto">
							<v-icon style="margin-bottom: 2px" small @click.stop="clearFilter" color="blue_70">$clear</v-icon>
						</div>
					</div>
				</template>

				<div class="column-format open-menu">
					<v-text-field
						id="search-input"
						v-model="filter.search"
						autofocus
						solo
						flat
						hide-details
						dense
						:placeholder="$t('global.filter')"
						@change="updated"
						class="font-16"
						@keypress.enter.exact="menuOpen=false"
					></v-text-field>

					<filter-option
						v-show="showMeetingStatus"
						:label="$t('meetings.filter.meeting-status')"
						:option-list="meetingStatus"
						v-model="filter.meetingStatus"
						@change="updated"
					></filter-option>

					<div class="filter-option-box">
						<div class="row-format" @click="dateExpanded = !dateExpanded">
							<div class="font-14">{{ $t('meetings.filter.date-range') }}</div>
							<v-icon style="margin-left: auto; margin-top: 1px" small>{{
								dateExpanded ? '$arrowUp' : '$arrowDown'
							}}</v-icon>
						</div>

						<div v-if="dateExpanded">
							<v-radio-group v-model="filter.dateSpecifier" @change="updated()">
								<v-radio v-for="option in dateOptions" :key="option.value" :value="option.value">
									<template v-slot:label>
										<span style="font-size: 14px" class="font-14 brand-semilight">{{ option.text }}</span>
									</template>
								</v-radio>
							</v-radio-group>

							<div class="" v-if="showEarliest">
								<date-selector
									content-class="my-5"
									:date="filter.earliest"
									@change="filter.earliest = $event; updated()"
									:label="$t('meetings.filter.earliest')"
								></date-selector>
							</div>

							<div class="" v-if="showLatest">
								<date-selector
									content-class="my-5"
									:date="filter.latest"
									@change="filter.latest = $event; updated()"
									:label="$t('meetings.filter.latest')"
								></date-selector>
							</div>
						</div>
					</div>

					<div class="mx-3 mt-3 mb-2">
						<v-btn text block color="primary" elevation="0" class="filter-clear mt-2 mb-1" @click="clearFilter()"
						><v-icon small>$clear</v-icon> {{ $t('meetings.filter.clear-filter') }}</v-btn
						>
						<v-btn block class="filter-submit" @click="menuOpen = false" color="primary" elevation="0">{{
							$t('global.done')
						}}</v-btn>

					</div>
				</div>
			</v-menu>
		</div>
	</div>
</template>

<script>
	import FilterHelpers from '@/utils/FilterHelpers';
	import DateSelector from '@/components/DateSelector';
	import FilterOption from '@/components/FilterOption';
	import { DateTime } from 'luxon';

	export default {
		name: 'MeetingListFilter',

		props: ['value'],

		components: { FilterOption, DateSelector },

		data: function () {
			return {
				menuOpen: false,
				filter: JSON.parse(JSON.stringify(this.value)),

				dateOptions: FilterHelpers.getDateOptions([
						'today',
					'next-30-days',
					'next-60-days',
					'past-30-days',
					'past-3-months',
					'past-6-months',
					'past-12-months',
					'this-year',
					'last-year',
					'between',
				]),

				meetingStatus: [
					{ text: this.$t('meetings.filter.scheduled'), value: 'Scheduled' },
					{ text: this.$t('meetings.filter.cancelled'), value: 'Cancelled' },
				],

				dateExpanded: false,
				meetingStatusExpanded: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			clearFilter: function () {
				this.$emit('clear-filter');
			},
			updated: function(){
				this.$emit('input',this.filter);
			}
		},

		watch: {
			value: {
				deep: true,
				handler(val){
					this.filter = JSON.parse(JSON.stringify(val));
				}
			},
		},

		computed: {
			filterDescription() {
				let l = [];
				let f = this.filter;

				if (f.search && f.search.length > 0) {
					l.push('"' + f.search + '"');
				}

				if(f.dateSpecifier){
					if(f.dateSpecifier === 'between'){
						return DateTime.fromISO(f.earliest).toFormat('MMM dd') + ' - ' + DateTime.fromISO(f.latest).toFormat('MMM dd');
					}else{
						l.push(this.dateOptions.find(d => d.value === f.dateSpecifier).text);
					}
				}


				if(f.meetingStatus.length){
					l.push(f.meetingStatus.join());
				}

				if(l.length){
					return l.join(" &bull; ");
				}else{
					return null;
				}
			},


			showEarliest: function () {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}

				if (this.filter.dateSpecifier === 'between') {
					return true;
				} else {
					return false;
				}
			},

			showLatest: function () {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}

				if (this.filter.dateSpecifier === 'between') {
					return true;
				} else {
					return false;
				}
			},

			showDateRange: function () {
				return true;
			},

			showClientList: function(){
				return true;
			},

			showMeetingStatus: function () {
				return true;
			},

			isDefaultFilter() {
				if(this.filterDescription === null){
					return true;
				}else{
					return false;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
