<template>
	<v-row justify="center" align="center">
		<div class="text-center ma-12" v-if="!dialog">
			<v-progress-circular :indeterminate="true" size="200" color="light-blue"></v-progress-circular>
		</div>
		<v-dialog v-model="dialog" persistent max-width="290">
			<v-card>
				<v-card-text style="padding-top: 15px;">{{ $t('login.token-expired') }}</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red darken-1" text @click="freshLogin()">{{ $t('global.ok') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
	import qs from 'qs';
	import AuthService from "@/modules/auth/AuthService";

	export default {
		name: 'MagicToken',

		data() {
			return {
				magicToken: null,
				origUri: null,
				dialog: false,
			};
		},

		methods: {
			freshLogin() {
				self.location.href = document.location.protocol + '//' + document.location.host + '/login';
			},
		},

		mounted() {
      let queryString = qs.parse(self.location.search.slice(1));
      this.magicToken = queryString['magicToken'];
			this.origUri = queryString['origUri'];

			AuthService.magicToken(this.magicToken)
				.then(() => {
          this.origUri = this.origUri.replace('@','');
          self.location.href = document.location.protocol + '//' + document.location.host + this.origUri;
				})
				.catch((err) => {
					console.log('Failed to fetch refresh token:' + err);
					this.dialog = true;
				});
		},

	};
</script>

<style scoped></style>
