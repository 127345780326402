<template>
	<div>
		<v-data-table
			id="project-data-table"
			:items="projects"
			:headers="headers"
			:hide-default-footer="true"
			no-data-text="No fixed price projects found"
			class="pointer"
			sort-by="amountDue"
			:sort-desc="true"
			@click:row="handleProjectSelected"
		>
			<template v-slot:item.name="{ item }">
				{{ item.name }}
			</template>
			<template v-slot:item.feeSchedule.amount="{ item }">
				{{ $formatters.dollars(item.feeSchedule.amount, true, true, invoice.currency) }}
			</template>
			<template v-slot:item.amountDue="{ item }">
				{{ $formatters.dollars(item.amountDue, true, true, invoice.currency) }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
	export default {
		name: 'FixedPriceProjectList',

		props: ['invoice', 'invoiceService'],

		components: {},

		data: function() {
			return {
				projects: [],
			};
		},

		mounted() {
			this.getFixedPriceProjects();
		},

		beforeDestroy() {},

		methods: {
			getFixedPriceProjects: function() {
				this.$store.commit('startLoading');
				this.invoiceService.getBillable(this.invoice.clientId, null).then((res) => {
					this.projects.push(
						...res.data.projects.filter((p) => p.feeSchedule && p.feeSchedule.feeType === 'Fixed Price')
					);
					this.projects.forEach((p) => {
						p.paymentAmount = p.paymentHistory.reduce((sum, payment) => sum + payment.amount, 0);
						p.amountDue = p.feeSchedule.amount - p.paymentHistory.reduce((sum, payment) => sum + payment.amount, 0);
					});
				}).finally(() => this.$store.commit('stopLoading'));
			},

			handleProjectSelected: function(project){
				this.$emit('selected',project);
			}
		},

		computed: {
			headers: function() {
				return [
					{ text: 'Project name', value: 'name', sortable: true },
					{ text: 'Project fee', value: 'feeSchedule.amount', sortable: true },
					{ text: 'Amount due', value: 'amountDue', sortable: true },
				];
			},
		},
	};
</script>

<style lang="scss">
	#project-data-table {
		table tbody tr td {
			padding-top: 12px !important;
			padding-bottom: 4px !important;
		}
	}
</style>
