<template>
	<v-container fluid class="pa-0 ma-0 selector_text">
		<v-row>
			<v-col cols="12" :style="headerStyle">
				<span v-html="lHeader" v-if="!editMode"></span>
				<editor
					ref="headerEditor"
					class="grey-dots"
					v-if="editMode"
					:api-key="$store.getters.getTinyMceKey"
					:initial-value="lHeader"
					:inline="true"
					:init="mceConfigHeader"
					@input="handleHeaderInput"
				></editor>
			</v-col>
			<v-col cols="12" style="text-align: left;">
				<span :style="bodyStyle">
					<span v-html="lText" v-if="!editMode"></span>
					<editor
						v-if="editMode"
						ref="textEditor"
						:iniline="true"
						style="padding: 4px"
						:api-key="$store.getters.getTinyMceKey"
						:initial-value="lText"
						:init="mceConfigText"
						@input="handleTextInput"
					></editor>
				</span>
			</v-col>
			<v-col cols="12" v-show="!isLocked || cardPaymentFailed || (!pendingCharge && !clientMode)">
				<div
					class="pa-3 text-left column-format"
					style="border: 1px solid var(--v-gray_50-base); border-radius: 8px; gap: 16px"
				>
					<div
						class="brand-medium"
						style="width: fit-content"
						v-if="!clientMode && !forceAllDeliverables"
						v-tippy="{ content: 'Amount will be set based on client selections' }"
					>
						{{ $formatters.dollars(upFrontAmount, true, true, currency) }}
					</div>
					<div class="brand-medium" v-else>{{ $formatters.dollars(upFrontAmount, true, true, currency) }}</div>
					<div ref="card" style="border-bottom: 1px solid var(--v-gray_50-base)"></div>
					<div class="row-format gap-1 mt-1">
						<v-img src="/images/payment/american_express.svg" style="max-width: 30px; margin-left: -2px"></v-img>
						<v-img src="/images/payment/visa.svg" style="max-width: 30px"></v-img>
						<v-img src="/images/payment/mastercard.svg" style="max-width: 30px"></v-img>
						<v-img src="/images/payment/discover.svg" style="max-width: 30px"></v-img>
					</div>
				</div>
			</v-col>
			<v-col v-if="pendingCharge && pendingCharge.clientPaymentMethod" cols="12">
				<div
					class="pa-3 font-14 text-left column-format font-italic font-primary"
					style="border: 1px solid var(--v-gray_50-base); border-radius: 8px; gap: 16px"
				>
					<div>
						{{ pendingCharge.clientPaymentMethod.label }} {{ pendingCharge.clientPaymentMethod.last4 }} charged
						{{ $formatters.dollars(pendingCharge.amount, true, true, pendingCharge.currency) }}
						{{ pendingCharge.currency }} on
						{{ $DateTime.fromISO(pendingCharge.timestamp).toLocaleString($DateTime.DATETIME_MED) }}
					</div>
					<div class="row-format gap-1 mt-0">
						<v-img src="/images/payment/american_express.svg" style="max-width: 30px; margin-left: -2px"></v-img>
						<v-img src="/images/payment/visa.svg" style="max-width: 30px"></v-img>
						<v-img src="/images/payment/mastercard.svg" style="max-width: 30px"></v-img>
						<v-img src="/images/payment/discover.svg" style="max-width: 30px"></v-img>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		name: 'PaymentCaptureBlock',

		props: [
			'header',
			'text',
			'id',
			'editMode',
			'clientMode',
			'upFrontAmount',
			'forceAllDeliverables',
			'currency',
			'isLocked',
			'blockType',
			'mceConfigHeader',
			'mceConfigText',
			'headerStyle',
			'bodyStyle',
			'proposalType',
			'account',
			'pendingCharge',

			'cardPaymentFailed',
		],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function() {
			return {
				lText: this.text,
				lHeader: this.header,
				seedText: this.text,
				stripe: null,
			};
		},

		mounted: function() {
			if (this.account.stripeAccountId) {
				this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY, { stripeAccount: this.account.stripeAccountId });
				this.elements = this.stripe.elements();
				this.card = this.elements.create('card',{
          style: {
            base: {
              color: this.$vuetify.theme.dark ? 'white' : 'black'
            },
          }
        });
				this.card.mount(this.$refs.card);
			}
		},

		methods: {
			handleHeaderInput: function() {
				this.lHeader = this.$refs.headerEditor.editor.getContent();
				this.notifyChange();
			},

			handleTextInput: function() {
				this.lText = this.$refs.textEditor.editor.getContent();
				this.notifyChange();
			},

			exchangeCardForToken() {
				return new Promise((resolve, reject) => {
					this.stripe
						.createToken(this.card)
						.then((res) => {
							if (res.error) {
								reject(res.error.message);
							} else {
								resolve(res.token.id);
							}
						})
						.catch((err) => reject(err));
				});
			},

			notifyChange() {
				this.$emit('changed', {
					id: this.id,
					blockType: this.blockType,
					header: this.lHeader,
					text: this.lText,
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.ipBlock {
		width: fit-content;
		text-align: left;
		color: darkgray;
		font-size: 0.7em;
	}
</style>
