<template>
	<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			:style="styles"
			@mouseover="internalHover = true"
			@mouseleave="internalHover = false">
		<use :id="id" :xlink:href="href"></use>
	</svg>
</template>

<script>
export default {
	name: 'HIcon2',

	props: {
		size: String, // In pixels
		name: { type: String, required: true },
		id: String, // id of "use" block for direct styling
		active: {type: Boolean, required: false, default: false},
		hovering: {type: Boolean, required: false, default: false},
	},

	data() {
		return {
			internalHover: false,
		};
	},

	computed: {

		href() {
			return '/next-gen-icons.svg#' + this.name;
		},

		styles() {
			let str = 'outline: none !important; ';

			if (this.size) {
				str += 'height:' + this.size + 'px;width:' + this.size + 'px;';
			}

			if(this.active){
				str += '--icon-color: var(--v-primary-base)'
			}else if(this.hovering || this.internalHover){
				str += '--icon-color: var(--v-gray_90-base)'
			}else{
				str += '--icon-color: var(--v-gray_80-base)'
			}

			return str;
		},
	},

	mounted() {},
};
</script>

<style lang="scss">

</style>
