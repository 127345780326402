<template>
	<div>
		<v-textarea
			class="ma-0 pa-0"
			style="font-size:14px;"
			@blur="handleBlur()"
			@keydown.enter.exact.prevent
			@keydown.enter.shift.exact.prevent
			@keyup.enter.exact="handleEnter()"
			@keyup.enter.shift.exact="handleShiftEnter()"
			auto-grow
			rows="1"
			v-model="data"
			ref="markdownEditor"
			:id="'markdown_editor_' + localId"
			:placeholder="placeholder"
			v-if="(editing || inputOnlyMode) && !disabled"
			:solo="solo"
			:flat="flat"
		></v-textarea>
		<div class="marked" v-else v-html="compiledMarkdown" @click="setEditing(true, true)"></div>
	</div>
</template>

<script>
	import marked from 'marked';
	import { v4 as uuidv4 } from 'uuid';
	import { cloneDeep } from 'lodash';

	export default {
		name: 'Markdown',
		props: {
			value: { type: String, required: false, default: '' },
			id: { type: String, required: false },
			inputOnlyMode: { type: Boolean, required: false, default: false },
			focus: { type: Boolean, required: false, default: false },
			placeholder: { type: String, required: false, default: '' },
			flat: { type: Boolean, required: false, default: false },
      solo: { type: Boolean, required: false, default: false },
      disabled: { type: Boolean, required: false, default: false },
		},
		components: {},

		data() {
			return {
				editing: false,
				data: '',
				localId: null,
			};
		},
		mounted() {
			this.data = cloneDeep(this.value);
			if (this.id) this.localId = this.id;
			else this.localId = this.getId();
			this.setEditing(this.inputOnlyMode, this.focus);
		},
		methods: {
			handleShiftEnter() {
				const el = document.getElementById('markdown_editor_' + this.localId);
				const caret = this.getCaretPosition(el);
				this.data = this.data.slice(0, caret) + '\r\n' + this.data.slice(caret);
				this.$nextTick(() => this.setCaretPosition(el, caret + 1));
			},
			handleEnter() {
				this.data = this.data ? this.data.trim() : '';
				this.$emit('update', this.data);
				this.editing = false;
			},
			handleBlur() {
				return this.handleEnter();
			},
			getId() {
				return uuidv4().replace(/-/g, '');
			},
			setEditing(editing, focus) {
        if(this.disabled){
          return;
        }
				this.editing = editing;
				if (editing && focus) {
					this.$nextTick(() => {
						this.$refs.markdownEditor.focus();
					});
				}
			},
			getCaretPosition(el) {
				let caret = 0;
				if (el.selectionStart || el.selectionStart == '0') caret = el.selectionStart;
				return caret;
			},
			setCaretPosition(el, pos) {
				if (el.setSelectionRange) {
					el.focus();
					el.setSelectionRange(pos, pos);
				} else if (el.createTextRange) {
					var range = el.createTextRange();
					range.collapse(true);
					range.moveEnd('character', pos);
					range.moveStart('character', pos);
					range.select();
				}
			},
		},
		computed: {
			compiledMarkdown: function() {
				if (this.data == null || this.data.length === 0) {
					return `<span style="color:var(--v-gray_70-base)">${this.placeholder}</span>`;
				}
				let m = marked(this.data, { breaks: true });
				m = m.replaceAll('<a href', '<a target="_blank" href');
				return m;
			},
		},

		watch: {
			value: function(newVal) {
				this.data = cloneDeep(newVal);
			},
		},
	};
</script>

<style lang="scss">
	.marked {
		margin: 0;
		padding: 0;
    text-align: left;
    word-break: break-word;
		a {
			word-wrap: anywhere;
			word-break: break-all;
			overflow-wrap: break-word;
		}
	}
</style>
