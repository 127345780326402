<template>
	<div :id="item.id" @click="$emit('click', $event)" :style="`width:100%; padding-left: ${padding(item.pl)}px; padding-right: ${padding(item.pr)}px; padding-bottom: ${item.pb}px; padding-top: ${item.pt}px;`">
		<table class="text-left payment-plan-table" :style="`--v-table-border: ${item.borderColor}; --v-table-padding: ${item.padding}px; ${wrapperStyle}; --v-table-border-width: ${item.borderWidth}px`">
			<tbody>
				<tr
					v-for="(payment, index) in item.scheduledPayments"
					:key="payment.id"
					:style="`height: 1px;`"
				>
					<td class="text-left font-14" :colspan="(payment.triggerType === 'Date'  && payment.triggerDate) || payment.triggerType === 'RelativeDate' ? 1 : 2">{{ index + 1 }}) {{ payment.label }}</td>
					<td class="text-center font-14" v-if="payment.triggerType === 'Date' && payment.triggerDate">
						{{DateTime.fromISO(payment.triggerDate).toLocaleString(DateTime.DATE_MED)}}
					</td>
					<td class="text-center font-14" v-if="payment.triggerType === 'RelativeDate'">
						{{calculateRelativeDate(payment.relativeTriggerDate).toLocaleString(DateTime.DATE_MED)}}
					</td>
					<td class="text-right font-14">
						{{ $formatters.dollars(payment.calculatedAmount, true, true, agreement.currency) }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
	import AgreementMixin from "@/modules/agreements/schema/AgreementMixin";
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'PaymentPlan',

		props: ['agreement', 'item','isMobile','pageWidth'],

		mixins: [AgreementMixin, ResponsiveMixin],

		components: {},

		data: function() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			calculateRelativeDate: function(relativeDate){
				let date = this.agreement.dateCompleted ? DateTime.fromISO(this.agreement.dateCompleted) : DateTime.now();
				if(relativeDate.timeUnit === 'DAYS'){
					return date.plus({days:relativeDate.duration});
				}else if(relativeDate.timeUnit === 'WEEKS'){
					return date.plus({weeks:relativeDate.duration});
				}else if(relativeDate.timeUnit === 'MONTHS'){
					return date.plus({months:relativeDate.duration});
				}else{
					return date;
				}
			},

			calculateTotals: function(){
				let totalPercentage = 0;
				let total = this.totalAmount;

				for(let i=0; i < this.item.scheduledPayments.length; i++){
					let p = this.item.scheduledPayments[i];

					if(total === 0){
						p.calculatedPercentage = 0;
						p.calculatedAmount = 0;
						continue;
					}

					if(i === (this.item.scheduledPayments.length -1)){
						p.calculatedPercentage = this.round(100 - totalPercentage, 6);
						p.calculatedAmount = this.round(total * (p.calculatedPercentage / 100), 2);
					}else{
						if (p.amountType === 'Percentage') {
							p.calculatedPercentage = this.round(p.amount, 6);
							p.calculatedAmount = this.round(total * (p.amount / 100), 2);
						} else {
							p.calculatedAmount = this.round(p.calculatedAmount, 2);
							p.calculatedPercentage = this.round((p.amount / total) * 100, 6);
						}
					}

					p.calculatedAmount = this.round(total * (p.calculatedPercentage / 100), 2);
					totalPercentage += p.calculatedPercentage;
				}
			},

			round: function(d, scale) {
				const factor = Math.pow(10, scale);
				return Math.round(d * factor) / factor;
			}
		},

		watch: {
			totalAmount: {
				immediate: true,
				handler: function(){
					this.calculateTotals();
				}
			},
			item: {
				deep: true,
				handler: function(){
					this.calculateTotals();
				}
			}
		},

		computed: {
			totalAmount: function(){
				let totalUpFront = this.getTotalUpFront(this.agreement,false);
				return totalUpFront.totalTax + totalUpFront.totalPreTax;
			},

			wrapperStyle: function() {
				let style = ``;

				if (this.item.backgroundColor) {
					style = style + `background-color: ${this.item.backgroundColor}; `;
				}

				return style;
			},
		},
	};
</script>

<style scoped lang="scss">
	.payment-plan-table {
		width: 100%;
		text-align: left;
		//border-collapse: collapse;

			border-radius: 4px;
			border:  var(--v-table-border-width) solid var(--v-table-border);

		tbody {
			tr {
				td {
					vertical-align: top;
					text-align: right;
					border-bottom: var(--v-table-border-width) solid var(--v-table-border);
					padding: var(--v-table-padding);
				}

				td:nth-child(1) {
					vertical-align: middle;
					text-align: left;
				}
			}
			tr:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
</style>
