import {DateTime,Duration} from "luxon";
import store from '@/store';

export default class HDateTime extends DateTime{

    static now(){
        return new HDateTime(new DateTime({}));
    }

    static local(year, month, day, hour, minute, second, millisecond){
        return new HDateTime(super.local(year, month, day, hour, minute, second, millisecond));
    }

    static utc(year, month, day, hour, minute, second, millisecond) {
        return new HDateTime(super.utc(year, month, day, hour, minute, second, millisecond));
    }

    static fromJSDate(date, options = {}) {
        return new HDateTime(super.fromJSDate(date,options))
    }

    static fromISO(text, opts = {}) {
        return new HDateTime(super.fromISO(text,opts));
    }

    static fromMillis(milliseconds, options = {}) {
        return new HDateTime(super.fromMillis(milliseconds,options));
    }

    static fromObject(obj) {
        return new HDateTime(super.fromObject(obj));
    }

    constructor(config) {
        super(config);
    }

    plus(duration){
        return new HDateTime(super.plus(duration));
    }

    minus(duration){
        return new HDateTime(super.minus(duration));
    }

    startOf(unit) {
        let normalizedUnit = Duration.normalizeUnit(unit);
        if(normalizedUnit === 'weeks'  && store.state.weekStartsOn === 'Sunday'){
            return new HDateTime(super.weekdayShort === 'Sun' ? super.startOf('day') : super.startOf('week').minus({day: 1}));
        }else{
            return new HDateTime(super.startOf(unit));
        }
    }

    endOf(unit) {
        if(this.isValid){
            return new HDateTime(this.plus({ [unit]: 1 })).startOf(unit).minus(1);
        }else{
            return this;
        }
    }

}