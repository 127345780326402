<template>
	<div
		@click="$emit('click', $event)" class=""
		:style="schedulerStyle"
    :id="item.id"
	>

		<div v-if="meetingInfo" :class="`flex-wrap row-format gap-2 ${scheduledMeeting ? '' : 'centered'}`">
			<meeting-info v-if="meetingInfo && (item.showInfo || scheduledMeeting)" :meet="meetingInfo" :confirmed-time="confirmedTime" :token-map="tokenMap" style="flex: 1 1 0; min-width: 250px"></meeting-info>
			<meeting-schedule v-if="meetingInfo && !scheduledMeeting" :meet="meetingInfo" :scheduler-id="item.attachedSchedulerId"  :http-client="httpClient"  style="flex: 1 1 0;" @time-confirmed="timeConfirmed($event)"></meeting-schedule>
			<v-container v-if="scheduledMeeting" class="font-14 text-left mx-5" style="border: 1px solid var(--v-gray_30-base); border-radius: 4px">
				<v-row dense>
					<v-col cols="4" class="brand-medium">Meeting status</v-col>
					<v-col cols="8" style="font-weight: 600">{{
							scheduledMeeting.meetingStatus
						}}</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="4" class="brand-medium">Date/Time</v-col>
					<v-col cols="8">{{
							DateTime.fromISO(scheduledMeeting.confirmedTime.start).toFormat("cccc") + ' ' + DateTime.fromISO(scheduledMeeting.confirmedTime.start).toLocaleString(DateTime.DATETIME_MED) + ' (' + Settings.defaultZone.name + ')'
						}}</v-col>
				</v-row>
				<div v-if="scheduledMeeting.location.type === 'Zoom' && scheduledMeeting.zoomMeeting">
					<v-row dense>
						<v-col cols="4" class="brand-medium">Join URL</v-col>
						<v-col cols="8"
						><a :href="scheduledMeeting.zoomMeeting.join_url" target="_blank">{{
								scheduledMeeting.zoomMeeting.join_url
							}}</a></v-col
						>
					</v-row>
					<v-row dense>
						<v-col cols="4" class="brand-medium">Meeting Id</v-col>
						<v-col cols="8">{{ scheduledMeeting.zoomMeeting.id }}</v-col>
					</v-row>
					<v-row dense v-if="scheduledMeeting.zoomMeeting.pstnPassword">
						<v-col cols="4" class="brand-medium">Passcode</v-col>
						<v-col cols="8">{{ scheduledMeeting.zoomMeeting.pstnPassword }}</v-col>
					</v-row>
					<v-row dense v-if="zoomDialIn">
						<v-col cols="4" class="brand-medium">Dial in</v-col>
						<v-col cols="8"
						>{{ zoomDialIn.number.replaceAll(' ', '') }} ({{ zoomDialIn.city }},
							{{ zoomDialIn.country_name }})</v-col
						>
					</v-row>
				</div>
				<div v-else-if="scheduledMeeting.microsoftEvent && scheduledMeeting.microsoftEvent.meetingLink">
					<v-row dense>
						<v-col cols="4" class="brand-medium"></v-col>
						<v-col cols="8" class="my-2">
							<v-btn elevation="0" @click="openTeamsMeeting(scheduledMeeting.microsoftEvent.meetingLink)"><v-icon class="mr-1">$microsoft</v-icon> Join Teams meeting</v-btn>
						</v-col>
					</v-row>
				</div>
				<div v-else-if="scheduledMeeting.location.type === 'Google' && scheduledMeeting.googleMeeting">
					<v-row dense>
						<v-col cols="4" class="brand-medium">Join URL</v-col>
						<v-col cols="8"
						><a :href="scheduledMeeting.googleMeeting.hangoutLink" target="_blank">{{
								scheduledMeeting.googleMeeting.hangoutLink
							}}</a></v-col
						>
					</v-row>
					<v-row dense v-if="googleDialIn">
						<v-col cols="4" class="brand-medium">Dial in</v-col>
						<v-col cols="8">{{ googleDialIn.label }} ({{ googleDialIn.regionCode }})</v-col>
					</v-row>
					<v-row dense v-if="googleDialIn">
						<v-col cols="4" class="brand-medium">Pin</v-col>
						<v-col cols="8">{{ googleDialIn.pin }}</v-col>
					</v-row>
				</div>
				<div v-else-if="scheduledMeeting.location.type === 'PhoneIn'">
					<v-row dense>
						<v-col cols="4" class="brand-medium">Invitee will call</v-col>
						<v-col cols="8">{{ scheduledMeeting.location.phone }}</v-col>
					</v-row>
				</div>
				<div v-else-if="scheduledMeeting.location.type === 'PhoneOut'">
					<v-row dense>
						<v-col cols="4" class="brand-medium">Call invitee at</v-col>
						<v-col cols="8">{{ scheduledMeeting.formData.phone }}</v-col>
					</v-row>
				</div>
				<div v-else-if="scheduledMeeting.location.type === 'InPerson'">
					<v-row dense>
						<v-col cols="4" class="brand-medium">Meet at</v-col>
						<v-col cols="8">{{ scheduledMeeting.location.address }}</v-col>
					</v-row>
				</div>
				<div v-else-if="scheduledMeeting.meetingStatus !== 'Cancelled'">
					<v-row dense>
						<v-col cols="12">{{ scheduledMeeting.location.custom }}</v-col>
					</v-row>
				</div>
				<v-row dense v-if="scheduledMeeting.meetingStatus === 'Scheduled'">
					<v-col cols="12" class="mt-4 d-flex align-center justify-space-between gap-3">
						<v-btn class="secondary-action" style="flex:0 1 50%;" @click="openLink(scheduledMeeting.cancelUrl)"
						><span style="color: var(--v-error-base)">Cancel meeting</span></v-btn
						>
						<v-btn class="primary-action" style="flex:0 1 50%" @click="openLink(scheduledMeeting.rescheduleUrl)"
						>Reschedule meeting</v-btn
						>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div v-else class="row-format centered" style="height:200px; border: 1px solid var(--v-gray_20-base)">
			<div class="font-gray_60 row-format align-center">
				<v-icon class="material-symbols-rounded" color="gray_60">event</v-icon>
				<div>Please select an embedded meeting scheduler</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import {DateTime, Settings} from 'luxon';
	import MeetingSchedule from "@/modules/agreements/schema/MeetingSchedule";
	import MeetingInfo from "@/modules/agreements/schema/MeetingInfo";
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'SchedulerEmbed',

		props: ['agreement', 'item','isMobile','pageWidth'],

		mixins: [ResponsiveMixin],

		components: {MeetingInfo, MeetingSchedule},

		data: function() {
			return {
				DateTime: DateTime,
        Settings: Settings,
				httpClient: null,
				meetingInfo: null,
				start: DateTime.now(),
				end: DateTime.now().endOf('month'),
				confirmedTime: null,
        scheduledTimezone: Settings.defaultZone.name,
        scheduledLocale: this.$store.state.locale,
				scheduledMeeting: null,
			};
		},

		mounted() {
			this.scheduledMeeting = this.item.scheduledMeeting;

			this.httpClient = axios.create({
				baseURL: this.$store.getters.getPodUrl,
				withCredentials: true
			});
			if (this.item.attachedSchedulerId) {
				this.getMeetingInfo();
			}
		},

		beforeDestroy() {},

		methods: {
      finalize: function(){
        return new Promise((resolve,reject) => {
          if(!this.confirmedTime){
            document.getElementById(this.item.id).scrollIntoView({ block: 'start', behavior: 'smooth' });
            document.getElementById(this.item.id).classList.add("red-border");
            reject();
            return false;
          }
          document.getElementById(this.item.id).classList.remove("red-border");
          this.item.confirmedTime = this.confirmedTime;
          this.item.scheduledTimezone = this.scheduledTimezone;
          this.item.scheduledLocale = this.scheduledLocale;
          resolve();
          return true;
        })
      },

			timeConfirmed: function(slot){
				this.confirmedTime = slot;
			},

			openTeamsMeeting: function(url){
				window.open(url,'_blank');
			},

			openLink: function(url){
				window.open(url,'_blank');
			},

			getMeetingInfo: function(){
				let params = {
					urlPath: this.agreement.account.uniqueUrlPath,
					meetingId: this.item.attachedSchedulerId,
					start: this.start.toISODate(),
					end: this.end.toISODate()
				};

				this.httpClient.get(`/api/meeting/info`,{ params: params }).then((res) => {
					this.meetingInfo = res.data;
				}).catch((err) => this.$store.commit('error',err.response.data.message));
			},

			checkNull: function(val){
				return val ? val : ''
			},

			timeUnitTitleCase: function(str) {
				return str.substring(0, 1) + str.substring(1).toLowerCase();
			},
		},

		watch: {
			attachedSchedulerId: function(val){
				if(val){
					this.getMeetingInfo();
				}
			}
		},

		computed: {
			locationDesc: function () {
				switch (this.meetingInfo.location.type) {
					case 'Google':
						return 'Google Meet';
					case 'Microsoft':
						return 'Microsoft Teams';
					case 'Zoom':
						return 'Zoom';
					case 'PhoneIn':
						return 'Phone';
					case 'PhoneOut':
						return 'Phone';
					case 'InPerson':
						return 'In person';
					case 'Custom':
						return this.meetingInfo.location.custom;
					default:
						return 'Meeting location';
				}
			},

			tokenMap: function() {
				let result = new Map();

				if(this.meetingInfo) {
					result.set('Meeting.Name', this.checkNull(this.meetingInfo.name));
					result.set('Meeting.Duration', this.checkNull(this.meetingInfo.length.duration));
					result.set('Meeting.Location', this.checkNull(this.meetingInfo.location.type));
					result.set('Meeting.TimeUnit', this.checkNull(this.timeUnitTitleCase(this.meetingInfo.length.timeUnit)));
					result.set('Meeting.ScheduledTime', this.checkNull(this.formatConfirmedTime));
					result.set('Meeting.AccountName', this.checkNull(this.meetingInfo.account.accountName));
					result.set('Meeting.OwnerName', this.checkNull(this.meetingInfo.ownerName));
					result.set('Meeting.LocationInfo', this.checkNull(this.locationDesc));
					result.set('Meeting.TimeZone', this.checkNull(Settings.defaultZone.name));
					result.set('Meeting.Description', this.checkNull(this.meetingInfo.description));
					result.set('Icons.Clock', `<span><i class="v-icon notranslate material-icons" aria-hidden="true">schedule</i></span>`);
					result.set('Icons.Mic', `<span><i class="v-icon notranslate material-icons" aria-hidden="true">mic</i></span>`);
					result.set('Icons.Event', this.confirmedTime ? `<span><i class="v-icon notranslate material-icons" aria-hidden="true">event</i></span>` : "");
					result.set('Icons.Public', `<span><i class="v-icon notranslate material-icons" aria-hidden="true">public</i></span>`);
					result.set('Icons.Info', `<span><i class="v-icon notranslate material-icons" aria-hidden="true">info</i></span>`);
				}

				return result;
			},

			formatConfirmedTime: function () {
				if (this.confirmedTime) {
					let s = DateTime.fromISO(this.confirmedTime.start);
					let e = DateTime.fromISO(this.confirmedTime.end);

					return s.toLocaleString(DateTime.TIME_SIMPLE) + ' - ' + e.toLocaleString(DateTime.TIME_SIMPLE) + ', ' + s.toLocaleString({weekday:'short',month:'short',day:'numeric',year:'numeric'});

				} else {
					return null;
				}
			},

			attachedSchedulerId: function(){
				return this.item.attachedSchedulerId;
			},

			zoomDialIn: function() {
				if (
						this.scheduledMeeting &&
						this.scheduledMeeting.zoomMeeting &&
						this.scheduledMeeting.zoomMeeting.settings &&
						this.scheduledMeeting.zoomMeeting.settings.global_dial_in_numbers &&
						this.scheduledMeeting.zoomMeeting.settings.global_dial_in_numbers.length
				) {
					return this.scheduledMeeting.zoomMeeting.settings.global_dial_in_numbers[0];
				} else {
					return null;
				}
			},

			googleDialIn: function() {
				if (this.scheduledMeeting && this.scheduledMeeting.googleMeeting) {
					return this.scheduledMeeting.googleMeeting.entryPoints.find((e) => e.entryPointType === 'phone');
				} else {
					return null;
				}
			},

			cancelUrl: function() {
				if(this.scheduledMeeting) {
					return `${this.fullMeetingLink}?id=${this.id}&action=cancel&inFrame=true`;
				}else{
					return null;
				}
			},

			rescheduleUrl: function() {
				if(this.scheduledMeeting) {
					return `${this.fullMeetingLink}?id=${this.id}&action=change&inFrame=true`;
				}else{
					return null;
				}
			},

			schedulerStyle: function(){
				let style = `padding: ${this.item.padding}px; margin-top: ${this.item.pt}px; margin-bottom: ${this.item.pb}px; margin-left: ${this.padding(this.item.pl)}px; margin-right: ${this.padding(this.item.pr)}px; `

				if(this.item.borderColor){
					style = style + `border-color: ${this.item.borderColor}; `
				}
				if(this.item.borderWidth){
					style = style + `border-style: solid; border-width: ${this.item.borderWidth}px; `
				}
				if(this.item.borderRadius){
					style = style + `border-radius: ${this.item.borderRadius}px; `
				}
				return style;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
