<template>
	<div id="products-widget">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding pb-4" v-if="isReady">
			<div class="modal-title" style="height:24px;line-height:24px">{{ $t('product.title') }}</div>
			<v-data-table
				id="products-data-table"
				:items-per-page="25"
				:hide-default-footer="products.length <= 25"
				:hide-default-header="false"
				:headers="headers"
				:items="products"
				item-key="id"
				@click:row="selectProduct($event)"
				:mobile-breakpoint="0"
				:item-class="itemClass"
			>
				<template v-slot:header.manage="{}">
					<div class="row-format ml-auto mr-1" style="justify-content: flex-end">
						<v-icon size="20" class="pointer"  @click="createNewProduct">$plus</v-icon>
					</div>
				</template>

				<template v-slot:item.productName="{ item }">
					<div class="product-name">{{ item.productName }}</div>
				</template>
				<template v-slot:item.rate="{ item }">
					<div>{{ $formatters.decimal(item.rate) }}</div>
				</template>
				<template v-slot:item.unit="{ item }">
					<div>{{ item.unit }}</div>
				</template>
				<template v-slot:item.hourly="{ item }">
					<div class="text-center">
						<v-icon v-if="item.hourly" small>{{ $t('product.check') }}</v-icon>
					</div>
				</template>
				<template v-slot:item.taxable="{ item }">
					<div class="text-center">
						<v-icon v-if="item.taxable" small>{{ $t('product.check') }}</v-icon>
					</div>
				</template>
				<template v-slot:item.manage="{ item }">
					<div class="text-right mt-n1">
						<v-btn icon class="edit-icon" @click.stop="editProduct(item)">
							<v-icon color="gray_50" size="20">$edit</v-icon>
						</v-btn>
						<v-btn icon class="delete-icon" @click.stop="deleteProduct(item)">
							<v-icon color="var(--v-gray_70-base)" size="20">$delete</v-icon>
						</v-btn>
					</div>
				</template>
			</v-data-table>

			<div class="text-left" style="height: 32px;line-height:32px;">
				<v-btn class="primary-action ml-n2" text style="width: 140px" @click="createNewProduct()">
					<v-icon size="14">$plus</v-icon>
					<span class="ml-1">{{ $t('global.create-new') }}</span>
				</v-btn>
			</div>
		</div>
		<div class="modal-footer text-center">
			<v-btn class="primary-action" style="width: 140px" @click="cancel()">
				{{ $t('global.close') }}
			</v-btn>
		</div>
		<basic-modal :dialog="productModal" @close="productModal = false" :persistent="true">
			<div id="edit-product" class="">
				<v-btn icon class="close-dialog" @click="productModal = false">
					<v-icon>$close</v-icon>
				</v-btn>
				<div class="modal-padding">
					<div class="modal-title">{{ $t('product.create-product-title') }}</div>
					<v-text-field
						autofocus
						hide-details
						class="h-outline"
						persistent-placeholder
						:placeholder="$t('product.product-name-placeholder')"
						:label="$t('product.product-name-label')"
						v-model="editingProduct.productName"
						:rules="[(value) => !!value || 'Required.']"
					/>

					<div class="d-flex justify-space-between half">
						<v-text-field
							hide-details
							class="h-outline"
							persistent-placeholder
							:placeholder="$t('product.unit-placeholder')"
							:label="$t('product.unit-label')"
							v-model="editingProduct.unit"
							:rules="[(value) => !!value || 'Required.']"
							:disabled="editingProduct.hourly"
							v-if="!editingProduct.hourly"
						/>
						<div class="h-outline px-2 py-1 mb-2">
							<div class="d-flex justify-start align-start">
								<v-simple-checkbox v-model="editingProduct.hourly" @click="handleHourlyClick()" />
								<div class="text-left">
									<label class="font-14 font-gray_80 ml-n1">{{ $t('product.hourly-label') }}</label>
									<!--									<div class="font-12 font-gray_80">{{ $t('product.hourly-description') }}</div>-->
								</div>
							</div>
						</div>
					</div>
					<div class="d-flex justify-space-between half">
						<v-text-field
							hide-details
							class="h-outline"
							persistent-placeholder
							:placeholder="$t('product.rate-placeholder')"
							:label="$t('product.rate-label')"
							v-model="editingProduct.rate"
							:rules="[(value) => !!value || 'Required.']"
						/>
						<div class="h-outline px-2 py-1">
							<div class="d-flex justify-start align-start">
								<v-simple-checkbox v-model="editingProduct.taxable" />
								<label class="font-14 font-gray_80 ml-n1">{{ $t('product.taxable-label') }}</label>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<v-btn class="secondary-action mr-1" style="width: 140px" @click="productModal = false">
						{{ $t('global.cancel') }}
					</v-btn>
					<v-btn
						class="primary-action ml-1"
						style="width: 140px"
						:disabled="!editingProduct.productName"
						@click="save()"
					>
						{{ $t('global.save') }}
					</v-btn>
				</div>
			</div>
		</basic-modal>
	</div>
</template>

<script>
  import ProductService from '@/modules/products/ProductService';
  import { v4 as uuidv4 } from 'uuid';
	import BasicModal from '@/components/BasicModal';
	import ConfirmModal from '@/components/ConfirmModal';
  import {DateTime} from 'luxon';

	export default {
		name: 'Products',
		components: { BasicModal },
		props: ['filter','clientId'],

		data() {
			return {
				productService: new ProductService(),
				DateTime: DateTime,

				products: [],
				isReady: false,
				productModal: false,
				editingProduct: {},
				headers: [
					{ text: this.$t('product.headers.product-name'), name: 'productName', value: 'productName', sortable: true },
					{ text: this.$t('product.headers.rate'), name: 'rate', value: 'rate', sortable: true, align: 'right'},
					{ text: this.$t('product.headers.unit'), name: 'unit', value: 'unit', sortable: true },
					{ text: this.$t('product.headers.hourly'), name: 'hourly', value: 'hourly', sortable: true, align: 'center' },
					{
						text: this.$t('product.headers.taxable'),
						name: 'taxable',
						value: 'taxable',
						sortable: true,
						align: 'center',
					},
					{ text: '', name: 'manage', value: 'manage', sortable: false },
				],

				search: null,
				// productActions: [
				// 	{ action: 'edit', label: 'Edit' },
				// 	{ action: 'select', label: 'Select' },
				// 	{ action: 'assignProject', label: 'Select and assign to project...' },
				// ],
				product: null,
				// projectId: '',


				// DateTime: DateTime,
			};
		},

		mounted() {
			this.getProducts();
		},

		methods: {
			getProducts: function() {
				this.productService.getProducts().then((res) => {
					this.products.splice(0, this.products.length);
					this.products.push(...res.data);
					this.$emit('set-products', this.products);
					this.isReady = true;
				});
			},


			cancel: function() {
				this.$emit('close');
				setTimeout(()=>{
						this.view = 'PRODUCTS';
				},
				100);
			},
			save: function() {
				if (this.editingProduct.hourly) {
					this.editingProduct.unit = 'Hours';
				}
				if (this.editingProduct.isNew) {
					this.productService.postProduct(this.editingProduct).then((res) => {
						this.products.unshift(res.data);
						this.editingProduct = {};
						// console.log(this.products);
						this.productModal = false;
					});
				} else {
					this.productService.updateProduct(this.editingProduct.id, this.editingProduct).then((res) => {
						let index = this.products.findIndex((p) => p.id === this.editingProduct.id);
						if (index > -1) {
							this.products.splice(index, 1, res.data);
						}
						this.productModal = false;
						// console.log(this.products);
					});
				}
			},
			createNewProduct: function() {
				this.editingProduct = this.initNewProduct();
				this.productModal = true;
			},
			editProduct: function(product) {
				this.editingProduct = product;
				this.productModal = true;
			},
			deleteProduct: function(product) {
				let binding = {
					headingText: this.$t('global.confirm'),
					bodyText: this.$t('product.delete-confirm-body'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.productService.deleteProduct(product.id).then((res) => {
							let index = this.products.findIndex((p) => p.id === product.id);
							if (index > -1) {
								this.products.splice(index, 1);
							}
							console.log(res);
						});
					}
				});
			},
			handleHourlyClick: function() {
				// if (this.editingProduct.hourly) {
				// 	this.editingProduct.unit = 'Hours';
				// } else {
				// 	this.editingProduct.unit = null;
				// }
			},

			newId: function() {
				return uuidv4().replace(/-/g, '');
			},
			initNewProduct: function() {
				return {
					hourly: false,
					id: this.newId(),
					productName: '',
					rate: 0,
					taxable: this.$store.state.podAccount.accountPreferences.invoiceUseTax && this.$store.state.podAccount.accountPreferences.defaultTaxRate > 0,
					unit: '',
					isNew: true,
				};
			},
			selectProduct: function(product) {
				this.$emit('select', {product:product});
			},
			selectProductAndProject: function(product) {
				this.getProjects();
				this.product = product;
				this.view = 'PROJECTS';
			},
			selectProject: function(project) {
				this.$emit('select', { product: this.product, project: project });
				this.view = 'PROJECTS';
			},
			itemClass() {
				return 'pointer';
			},
		},

		computed: {},

		watch: {},
	};
</script>

<style lang="scss">
	#products-widget {
		min-width: 420px;
		background-color: var(--background);
		.v-data-table .v-data-table__wrapper {
			table tbody tr td {
				padding-top: 12px !important;
				padding-bottom: 4px !important;
			}
		}
		.edit-icon, .delete-icon {
			//--icon-color: red;
			//svg {
			//	color: var(--v-gray_80-base);
			//}
		}
	}
	#edit-product {
		width: 420px;
		div.h-outline {
			height: 52px;
			margin-top: 4px;
			margin-bottom: 8px;
			width: 100%;
			align-items: center;
			display: flex;
		}
		div.half {
			gap: 12px;
			> div {
				flex: 0 1 auto;
			}
		}
	}
	div.product-name {
		max-width: 260px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:nowrap;
	}
</style>
