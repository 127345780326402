<template>
	<div class="ma-4">
		<div class="mt-4">
			<v-data-table
				:headers="headers"
				:items="filteredResults"
				sort-by="dateSent"
				:sort-desc="true"
				style="cursor: pointer"
				:hide-default-footer="true"
				:disable-pagination="true"
        :mobile-breakpoint="200"
				@click:row="openInvoice($event)"
			>
				<template v-slot:item.dateSent="{ item }">
					<span v-if="item.dateSent">{{ DateTime.fromISO(item.dateSent).toFormat('DD') }}</span>
				</template>

				<template v-slot:item.dateDue="{ item }">
					<span v-if="item.dateDue">{{ DateTime.fromISO(item.dateDue).toFormat('DD') }}</span>
				</template>

				<template v-slot:item.invoiceNumberFormatted="{ item }">
					<span>{{ item.invoiceNumberFormatted }}</span>
				</template>

				<template v-slot:item.total="{ item }">
					<span v-if="item.total">{{ $formatters.dollars(item.total ? item.total : 0, true,true,item.currency) }}</span>
				</template>

				<template v-slot:item.status="{ item }">
					<div class="font-12" v-html="getStatusHtml(item)"></div>
				</template>

				<template v-slot:item.id="{ item }">
          <v-btn
						v-if="item.statusDetail.payable && item.payOnlineUrl && item.allowOnlinePayment"
						elevation="0"
						color="success"
						small
						@click.stop="payNow(item.payOnlineUrl)"
						><span class="font-12 brand-bold">{{ $t('invoices.pay-now') }}</span></v-btn
					>
				</template>

				<template v-slot:no-data>
					{{ $t('invoices.no-invoice-record') }}
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';

	export default {
		name: 'InvoiceTableAndChart',
		components: {},
		props: ['filter', 'invoices'],

		data() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {},

		methods: {
			openInvoice: function(invoice) {
				let binding = {
					invoiceId: invoice.id,
				};
				this.$store.state.globalModalController
					.openModal(InvoiceDetails, binding, true, true)
					.then(() => this.$emit('refresh'));
			},

			payNow: function(payOnlineUrl) {
				window.open(payOnlineUrl, '_blank');
			},

			formatDate(date) {
				return DateTime.fromISO(date).toFormat('MMM dd, YYYY');
			},

			getStatusHtml(invoice) {
				return `<div class="invoice-status" style="--color:var(${invoice.statusDetail.color});--background:var(${invoice.statusDetail.background})">${invoice.statusDetail.label}</div>`;
			},
		},

		computed: {
			headers: function() {
				let result = [];
				result.push({ text: this.$t('invoices.list.invoice-number'), value: 'invoiceNumberFormatted' });

        if(this.$vuetify.breakpoint.mdAndUp){
          result.push({ text: this.$t('invoices.list.issued'), value: 'dateSent' });
          result.push({ text: this.$t('global.due'), value: 'dateDue' });
        }

        if(this.$vuetify.breakpoint.smAndUp){
          result.push({ text: this.$t('invoices.list.total'), value: 'total' });
        }

				result.push({ text: this.$t('invoices.status.heading'), value: 'status' });
				result.push({ text: '', value: 'id' });

				return result;
			},

			filteredResults: function() {
				if (!this.filter) {
					return this.invoices;
				}

				let results = [...this.invoices];
				let search = this.filter.toLowerCase();

				return results.filter((i) => {
					if (
						i.invoiceNumberFormatted
							.toString()
							.toLowerCase()
							.includes(search)
					) {
						return true;
					} else if (i.statusDetail.label.toLowerCase().startsWith(search)) {
						return true;
					} else if (i.total.toString().startsWith(search)) {
						return true;
					} else if (
						DateTime.fromISO(i.dateSent)
							.toFormat('DDD')
							.toLowerCase()
							.startsWith(search)
					) {
						return true;
					} else if (
						DateTime.fromISO(i.dateDue)
							.toFormat('DDD')
							.toLowerCase()
							.startsWith(search)
					) {
						return true;
					}

					return false;
				});
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
	.invoice-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
	}
</style>

<style scoped lang="scss">
	.action-menu ::v-deep {
		.primary-action {
			border: 0px !important;
		}
		.menu-activator {
			border: 0px !important;
		}
	}
</style>
