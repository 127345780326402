<template>
	<div id="submission-details">
		<div id="header-area" class="">
			<div class="header-left pl-md-6 pl-4">
				<div class="mr-6 brand-medium">
					{{ request.name }}
				</div>
			</div>
			<div class="header-right pr-md-6 pr-4">
				<div>
					<v-btn icon class="primary-action" @click="handleClose()">
						<v-icon :size="20">$close</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
		<div class="show-scrollbar" style="width: 100%; height: 100%">
      <discovery-form :request-token="token" v-if="token" @result="$emit('result',$event)"></discovery-form>
		</div>
	</div>
</template>

<script>
  import DiscoveryForm from '@/modules/forms/DiscoveryForm';
  export default {
		name: 'FormRequestViewer',

		props: ['request'],

		components: { DiscoveryForm },

		data: function() {
			return {

			};
		},

		mounted() {

		},

		beforeDestroy() {
		},

		methods: {
			handleClose: function() {
				this.$emit('result');
			},
		},

		computed: {
      token: function(){
        const parsedUrl = new URL(this.request.url);
        const params = new URLSearchParams(parsedUrl.search);
        return params.get('token');
      }
		},
	};
</script>

<style scoped lang="scss">
  #submission-details {
		background-color: var(--v-white-base);
		#header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
