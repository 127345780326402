<template>
  <div>
    <v-menu offset-y content-class="locale-workspace-sub-menu" v-if="children.length">
      <template v-slot:activator="{ on }">
        <div class="settings-link row-format align-center pointer" v-on="on">
          <v-icon size="16" >{{icon}}</v-icon>
          <div class="ml-1">{{label}}</div>
        </div>
      </template>
      <div class="column-format">
        <locale-switcher v-for="child in children" :key="child.code" :label="child.name" :route="child.route" :select-value="child.code" @selected="$emit('selected',$event)"></locale-switcher>
      </div>
    </v-menu>
    <div class="settings-link row-format align-center pointer" v-else @click="handleClick">
      <v-icon size="16">{{icon}}</v-icon>
      <div class="ml-1">{{label}}</div>
    </div>
  </div>

</template>

<script>
export default {
  name: "LocaleSwitcher",

  props: {
    pro: {type: Boolean, required: false, default: false},
    icon: { type: String, required: false},
    label: { type: String, required: false },
    route: { type: String, required: false },
    selectValue: {type: String, required: false},
    children: { type: Array, required: false, default: () => []},
  },

  components: {},

  data: function () {
    return {}
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    handleClick: function(){
      if(this.route){
        this.$router.push(this.route);
      }
      if(this.selectValue){
        this.$emit('selected',this.selectValue);
      }
    },
  },

  computed: {},

}
</script>

<style lang="scss">
.locale-workspace-sub-menu {
  height: 280px;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
  box-shadow: none !important;
  border-radius: 8px !important;
  background-color: var(--v-white-base);
  padding: 4px 12px;
}
</style>

<style scoped lang="scss">
.settings-link {
  font-weight: 500;
  width: 100%;
  font-size: 14px;
  line-height: 14px;

  padding: 8px 0px;
  color: var(--v-primary-base);

  &:hover {
    color: var(--v-primary-base);
  }
}
</style>






<!--<template>-->
<!--  <v-select class="locale-switcher text-uppercase ml-2" style="width:160px" v-model="$i18n.locale" @change="switchLocale()"-->
<!--    :items="countries.getCountriesTranslate()" hide-details item-value="code" item-text="code">-->
<!--      <template v-slot:prepend-inner>-->
<!--        <div class="row-format centered" style="padding-right:8px">-->
<!--          <v-icon color="primary">language</v-icon>-->
<!--        </div>-->
<!--      </template>-->
<!--      <template v-slot:item="data">-->
<!--        {{ data.item.name }}-->
<!--      </template>-->
<!--  </v-select>-->
<!--</template>-->

<!--<script>-->
<!--import Countries from '@/modules/utils/Countries.js'-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      countries: new Countries(),-->
<!--    }-->
<!--  },-->

<!--  methods: {-->
<!--    switchLocale() {-->
<!--      localStorage.setItem('lang', this.$i18n.locale);-->
<!--      window.location.reload()-->
<!--    },-->
<!--  },-->
<!--};-->
<!--</script>-->

<!--<style lang="scss">-->
<!--.locale-switcher.v-text-field>.v-input__control>.v-input__slot:before {-->
<!--  border-style: none;-->
<!--}-->

<!--.locale-switcher.v-text-field>.v-input__control>.v-input__slot:after {-->
<!--  border-style: none;-->
<!--}-->

<!--.locale-switcher.v-text-field {-->
<!--  padding: 5px 10px 5px 8px;-->
<!--}-->

<!--.locale-switcher.v-select:hover {-->
<!--  background-color: var(&#45;&#45;v-blue_5-base);-->
<!--  border-radius: 4px;-->
<!--}-->
<!--</style>-->


