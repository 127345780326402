<template>
	<v-container fluid class="ma-0 pa-0">
		<v-row class="hello" v-if="!disabled">
			<v-col cols="12" align="left">
				<vue-dropzone
					class="pa-0 ma-0 drop row-format centered"
					style="border-radius: 4px"
					ref="fileDropzone"
					id="dropzone"
					v-on:vdropzone-success="uploadSuccess"
					v-on:vdropzone-error="uploadFailure"
					v-on:vdropzone-sending="addFileUploadAuthHeader"
					:use-custom-slot="true"
					:options="dropzoneOptions"
				>
					<div class="row-format centered">
						<v-icon size="35">$attachment</v-icon><br />
						<div class="font-12 text-left ml-2" v-html="$t('projects.deliverable-upload')"></div>
					</div>
				</vue-dropzone>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col>
				<div v-for="file in deliverable.files" :key="file.fileName">
					<div class="d-flex align-center attachment-wrapper" style="width: 100%; position: relative">
						<div class="buttonOverlay row-format px-6" style="justify-content: space-between">
							<v-icon class="pointer" color="gray_80" @click.native="downloadFile(file)"> $download </v-icon>
              <v-icon v-if="project.portalAccess === 'Full access'" class="pointer" color="gray_80" @click.native="deleteFile(file)">$delete</v-icon>
							<v-icon
								class="pointer"
								color="gray_80"
								@click.native="downloadFile(file, false)"
								v-if="isOpenableFile(file)"
								>open_in_new</v-icon
							>
						</div>
						<div class="fileContainer">
							<v-img contain :src="getFilePreview(file)" width="56" style="cursor: pointer"></v-img>
						</div>
						<div class="fileName ml-2">{{ file.fileName }}</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import axios from 'axios';
	import VueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'FileAttachments',

		props: ['project', 'deliverable', 'projectService','disabled'],

		components: {
			VueDropzone,
		},

		data: function() {
			return {
				fileToDelete: null,
				deleteFileDialog: false,
			};
		},

		methods: {
			confirmDeleteFile: function(file) {
				this.fileToDelete = file;
				this.deleteFileDialog = true;
			},

			cancelDeleteFile: function() {
				this.fileToDelete = null;
				this.deleteFileDialog = false;
			},


			downloadFile: function(s3File, download = true) {
				this.projectService.getSignedUrl(this.project.id, this.deliverable.id, s3File.fileName).then((res) => {
					let signedUrl = res.data.signedUrl;

					if (download == false) {
						window.open(signedUrl, '_blank');
					} else {
						axios({
							url: signedUrl, //your url
							method: 'GET',
							responseType: 'blob', // important
						}).then((response) => {
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', s3File.fileName); //or any other extension
							document.body.appendChild(link);
							link.click();
						});
					}
				});
			},

      deleteFile(s3File){
        let binding = {
          headingText:'Confirm',
          bodyText: `Are you sure you want to delete ${s3File.fileName}?  This cannot be undone.`
        }
        this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
          if(res) {
            this.projectService.deleteFile(this.project.id, this.deliverable.id, s3File.fileName).then(() => {
              let ix = this.deliverable.files.findIndex((f) => f.fileName === s3File.fileName);
              if (ix > -1) {
                this.deliverable.files.splice(ix, 1);
              }
            })
          }
        })
      },

			isOpenableFile(s3File) {
				if (
					s3File.fileType === 'JPG' ||
					s3File.fileType === 'PNG' ||
					s3File.fileType === 'GIF' ||
					s3File.fileType === 'PDF'
				) {
					return true;
				} else {
					return false;
				}
			},

			getFilePreview: function(file) {
				if (file.fileType === 'JPG' || file.fileType === 'PNG' || file.fileType === 'GIF') {
					return `${this.$store.getters.getPodUrl}api/insecure/${this.deliverable.accountId}/deliverables/${
						this.deliverable.id
					}/files/thumbnail?fileName=${encodeURIComponent(file.fileName)}&authToken=${encodeURIComponent(
						this.$store.state.accessToken
					)}`;
				}
				return file.fileIconUrl;
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			uploadSuccess: function(file, response) {
				this.$refs.fileDropzone.removeFile(file);
				this.deliverable.files.push(response);
			},

			uploadFailure: function(file, response) {
				this.$store.commit('error', this.$t('error.uploading-file') + response.message);
				this.$refs.fileDropzone.removeFile(file);
			},
		},

		computed: {
			dropzoneOptions: function() {
        let clientId = this.$store.getters.getClientId;
				return {
					url: `${this.$store.getters.getBaseUrl}/${clientId}/projects/${this.project.id}/deliverables/${this.deliverable.id}/files`,
					thumbnailWidth: 50,
          maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					includeStyling: false,
					parallelUploads: 1,
				};
			},
		},
	};
</script>

<style scoped lang="scss">
	#dropzone {
		min-height: 0px;
		min-width: 0px;
		height: 75px;
		border: 2px dotted var(--v-gray_50-base);
		background-color: var(--v-gray_10-base);
	}

	.iconHover {
		&:hover {
			color: var(--v-primary-base);
		}
	}

	.drop ::v-deep .dz-message {
		margin: 15px 5px 0px 0px;
		padding: 0px 0px 0px 0px;
	}

	.attachment-wrapper {
		margin-top: 8px;
	}
	.fileContainer {
		width: 56px;
		text-align: center;
		overflow-wrap: break-word;
	}

	.fileName {
		width: 132px;
		max-width: 132px;
		font-size: 12px;
		color: var(--v-gray_70-base);
		word-wrap: break-word;
		text-align: left;
	}

	.buttonOverlay {
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 400;
		width: 100%;
		height: 100%;
		background-color: var(--v-white-base);
		opacity: 0;
		transition: all ease 250ms;

		&:hover {
			opacity: 0.9;
		}
	}
</style>
