<template>
	<div id="submission-details">
		<div id="header-area" class="">
			<div class="header-left pl-md-6 pl-4">
				<div class="mr-6 brand-medium">
					{{ submission.formName }} -
					{{ DateTime.fromISO(submission.submittedAt).toLocaleString(DateTime.DATETIME_MED) }}
				</div>
			</div>
			<div class="header-right pr-md-6 pr-4">
				<div>
					<v-btn icon class="primary-action" @click="handleClose()">
						<v-icon :size="20">$close</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
		<div class="show-scrollbar" style="width: 100%; height: 100%">
      <iframe :src="fullUrl" :id="`frame-${frameId}`" class="responsive-iframe"></iframe>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
	import { v4 as uuid4 } from 'uuid';
	import iFrameResize from 'iframe-resizer/js/iframeResizer';

	export default {
		name: 'FormResultViewer',

		props: ['submission'],

		components: {},

		data: function() {
			return {
				baseUrl: process.env.VUE_APP_MEET_LINK,
				DateTime: DateTime,
				frameId: uuid4().replaceAll('-', ''),
				resizerOptions: {
					log: false,
					heightCalculationMethod: 'min',
					sizeWidth: true,
					sizeHeight: true,
				},
			};
		},

		mounted() {
			iFrameResize(this.resizerOptions, `#frame-${this.frameId}`);
			window.addEventListener('message', this.handleFrameMessage);
			console.log(this.$store.state.loggedInUser);
		},

		beforeDestroy() {
			window.removeEventListener('message', this.handleFrameMessage);
		},

		methods: {
			handleClose: function() {
				this.$emit('result');
			},

			handleFrameMessage(e) {
				if (e.data.startsWith('[iFrameSizer]')) {
					return;
				}
				console.log('Frame message received', e);
				this.$emit('frame-event', e);
			},
		},

		computed: {
			fullUrl: function() {
				return this.baseUrl + this.podId + '/completedForm?token=' + encodeURIComponent(this.submission.submissionToken);
			},

			podId: function() {
				if (this.$store.state.domain) {
					let domain = new URL(this.$store.state.domain.podUrl).hostname;
					return domain.split('.')[0].replace(/\D/g, '');
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.responsive-iframe {
		padding: 0px;
		margin: 0px;
		border: 0;
		max-width: 100%;
		min-width: 100%;
	}
	#submission-details {
		background-color: var(--v-white-base);
		#header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
