<template>
	<v-container fluid class="pa-0 ma-0 selector_terms">
		<v-row>
			<v-col cols="12" :style="headerStyle">
				<span v-html="terms.header" v-if="!editMode"></span>
				<editor
					ref="headerEditor"
					v-if="editMode"
					class="grey-dots"
					:api-key="$store.getters.getTinyMceKey"
					:initial-value="terms.header"
					:inline="true"
					:init="mceConfigHeader"
					@input="handleHeaderInput"
				></editor>
			</v-col>
			<v-col cols="12" style="text-align: left; position: relative" @click="openTermsEditor()">
				<span
					:style="bodyStyle"
					:class="this.editMode ? 'pointer' : ''"
					v-html="terms.evaluatedTerms"
				></span>
				<div class="brand-medium font-primary pointer" v-if="editMode" style="transform: rotate(-20deg); width: 100%; position: absolute; top: 20%; text-align: center; font-size: 70px; opacity: 0.4">Click to edit</div>
			</v-col>
		</v-row>

		<basic-modal v-if="termsDialog" :dialog="termsDialog" :persistent="true" @close="handelCancel">
			<terms-dialog
				:proposal-service="proposalService"
				:client-mode="clientMode"
				:terms="terms"
				@cancel="handelCancel()"
				@save="handleSave($event, false)"
				@text-conversion="handleSave($event, true)"
			></terms-dialog>
		</basic-modal>
	</v-container>
</template>

<script>
	// import Modal from '@bit/hecticapp.common.modal';
	import TermsDialog from './TermsDialog';
	import BasicModal from '@/components/BasicModal';

	export default {
		name: 'Terms',

		props: [
			'id',
			'clientId',
			'proposalId',
			'proposalService',
			'editMode',
			'clientMode',
			'mceConfigHeader',
			'headerStyle',
			'bodyStyle',
			'block',
			'templateMode',
			'templateService',
		],

		components: {
			TermsDialog,
			BasicModal,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function() {
			return {
				termsDialog: false,
				terms: this.block,
			};
		},

		mounted() {
			if (!this.clientMode && this.$validations.isEmpty(this.terms.evaluatedTerms)) {
				this.generateTerms().then(() => {
					this.notifyChange();
				});
			}
		},

		methods: {
			handleSave: function(terms, convertToText) {
				this.termsDialog = false;
				this.terms = terms;
				this.generateTerms().then(() => {
					if (convertToText) {
						this.notifyChangeWithConversion();
					} else {
						this.notifyChange();
					}
				});
			},

			generateTerms: function() {
				if (this.templateMode) {
					return this.templateService
						.createTerms(this.proposalId, this.terms)
						.then((res) => {
							this.terms.evaluatedTerms = res.data.terms;
							return Promise.resolve(res);
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
							return Promise.reject(err);
						});
				}
				return this.proposalService
					.createTerms(this.clientId, this.proposalId, this.terms)
					.then((res) => {
						this.terms.evaluatedTerms = res.data.terms;
						return Promise.resolve(res);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						return Promise.reject(err);
					});
			},

			handelCancel: function() {
				this.termsDialog = false;
			},

			openTermsEditor: function() {
				if (this.editMode && !this.clientMode) {
					this.termsDialog = true;
				}
			},

			handleHeaderInput: function() {
				this.terms.header = this.$refs.headerEditor.editor.getContent();
				this.notifyChange();
			},

			notifyChange: function() {
				this.$emit('changed', this.terms);
			},

			notifyChangeWithConversion: function() {
				let newBlock = {
					id: this.terms.id,
					blockType: 'TextArea',
					header: this.terms.header,
					text: this.terms.evaluatedTerms,
				};

				this.$emit('changed', newBlock);
			},
		},
	};
</script>

<style scoped></style>
