<template>
	<div class="columnDragHandle row-format align-center mb-2 mx-2" @click.stop>
		<div :style="`width: 8px; min-width:8px; height: 8px; border-radius: 2px; background-color: ${column.hexColor}`"></div>
		<div class="font-14 brand-medium ml-3">
			<input
				:disabled="true"
				type="text"
				@click.stop
				class="truncate"
				style="max-width: 160px; outline: none; color: var(--v-black-base)"
				v-model="column.label"
			/>
		</div>
		<div style="margin-left: auto" class="row-format align-center" v-if="allowAddDeliverable">
			<v-icon class="pointer ml-1" color="gray_70" size="20" @click.native.stop="addDeliverable(column)">$plus</v-icon>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'KanbanHeader',

		props: ['column','allowAddDeliverable'],

		components: {},

		data: function () {
			return {
			};
		},

		mounted() {},

		methods: {
			addDeliverable: function (column) {
				this.$emit('add-deliverable', column);
			},
		},

		computed: {
			maxHeaderWidth: function () {
        return 156;
			},
		},
	};
</script>

<style scoped lang="scss">
	.columnDragHandle {
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}

	.truncate {
		max-width: var(--max-header-width);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
