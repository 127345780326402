<template>
	<div class="mx-3" v-if="ticket">
		<v-container fluid>
			<v-row>
				<v-col cols="12" class="text-left">
					<div class="font-20">{{ticket.ticketNumber}}: {{ ticket.subject }}</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="8" class="text-left order-last order-md-first">
					<v-textarea
						rows="8"
						v-model="comment"
						:label="$t('requests.comment')"
						dense
						outlined
						persistent-placeholder
						hide-details
					></v-textarea>
          <div v-cloak @drop.prevent="addDropFile" @dragover.prevent>
            <v-file-input
              v-model="attachments"
              :placeholder="$t('requests.upload-attachments')"
              :label="$t('requests.attachments')"
              multiple
              hide-details
              prepend-icon="attachment"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary" style="color: var(--v-white-base)">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </div>
					<div class="row-format my-5" style="gap: 12px">
						<v-btn class="secondary-action" @click="$emit('cancel')">{{ $t('global.cancel') }}</v-btn>
						<v-btn :disabled="!comment" class="super-action" width="200" @click="addComment()">{{ $t('requests.add-comment') }}</v-btn>
					</div>

					<v-container class="pa-0 ma-0">
						<v-col
							cols="12"
							class="text-left"
							style="border-top: 1px solid var(--v-gray_30-base)"
							v-for="comment in comments"
							:key="comment.id"
						>
							<div class="font-12 font-gray_70">
								{{ getCommentBy(comment.commentBy) }} {{ $t('global.on') }}
								{{ DateTime.fromISO(comment.created).toLocaleString(DateTime.DATETIME_FULL) }}
							</div>
							<div class="font-12 font-gray_70" v-if="comment.updated">
								{{ $t('requests.existing.updated') }} {{ DateTime.fromISO(comment.updated).toLocaleString(DateTime.DATETIME_FULL) }}
							</div>
              <div style="white-space: pre-wrap" v-if="comment.commentFormat === 'PlainText'">{{ comment.comment }}</div>
              <div v-else v-html="comment.comment"></div>
							<div v-if="comment.attachments && comment.attachments.length" class="py-2">
								<v-chip
									v-for="attachment in comment.attachments"
									:key="attachment.fileName"
									class="pointer"
									@click="downloadFile(attachment)"
								>
									<v-icon small>attachment</v-icon> {{ attachment.fileName }}
								</v-chip>
							</div>
						</v-col>
					</v-container>
				</v-col>
				<v-col cols="12" md="4">
					<v-container class="ma-0 font-14" style="border: 1px solid var(--v-gray_50-base); border-radius: 4px;">
						<v-row dense>
							<v-col cols="4" align="left">{{ $t('requests.existing.requester') }}</v-col>
							<v-col cols="8" align="right"
								><span class="brand-medium" v-if="requester"
									>{{ requester.firstName }} {{ requester.lastName }}</span
								></v-col
							>
						</v-row>
						<v-row dense>
							<v-col cols="4" align="left">{{ $t('requests.existing.type') }}</v-col>
							<v-col cols="8" align="right"
								><span class="brand-medium">{{ ticketType.type }}</span></v-col
							>
						</v-row>
						<v-row dense>
							<v-col cols="4" align="left">{{ $t('requests.existing.status') }}</v-col>
							<v-col cols="8" align="right">
                <div v-if="ticket.open" class="status">{{ticket.status}}</div>
                <div v-else class="closed">{{$t('requests.closed')}}</div>
              </v-col>
						</v-row>
						<v-row dense>
							<v-col cols="4" align="left">{{ $t('requests.existing.created') }}</v-col>
							<v-col cols="8" align="right"
								><span class="brand-medium">{{
									DateTime.fromISO(ticket.created).toLocaleString(DateTime.DATETIME_MED)
								}}</span></v-col
							>
						</v-row>
						<v-row dense>
							<v-col cols="4" align="left">{{ $t('requests.existing.updated') }}</v-col>
							<v-col cols="8" align="right"
								><span class="brand-medium">{{
									DateTime.fromISO(ticket.updated).toLocaleString(DateTime.DATETIME_MED)
								}}</span></v-col
							>
						</v-row>
					</v-container>

          <div style="height: 16px" v-if="ticket.formData && ticket.formData.answers.length"></div>

					<v-container
						v-if="ticket.formData && ticket.formData.answers.length"
						class="ma-0 pa-0 font-14"
						style="border: 1px solid var(--v-gray_50-base); border-radius: 4px;"
					>
						<v-row dense>
							<v-col class="pa-4">
								<div
									v-for="(answer, index) in ticket.formData.answers"
									:key="index"
									class="text-left font-14 pb-3"
								>
									<div class="font-12 font-gray_70" v-html="answer.question"></div>
									<div class="text-pre-wrap">{{ answer.answer }}</div>
								</div>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import TicketService from '@/modules/tickets/TicketService';
	import { DateTime } from 'luxon';
	import ClientService from '@/modules/client/ClientService';
	import ProjectService from '@/modules/projects/ProjectService';
	import axios from 'axios';

	export default {
		name: 'ExistingTicket',

		props: ['id', 'ticketTypes'],

		components: {},

		data: function() {
			return {
				ticket: null,
				comments: [],
				contacts: [],
				comment: null,
				attachments: [],
				users: [],
				ticketService: new TicketService(),
				clientService: new ClientService(),
				projectService: new ProjectService(),
				DateTime: DateTime,
			};
		},

		mounted() {
			this.initialize();
      this.setupTicketListeners();
		},

		beforeDestroy() {
      this.stopTicketListeners();
    },

		methods: {
			initialize: function() {
				this.getTicketDetail();
				this.getContacts();
			},

      setupTicketListeners: function(){
        this.$store.state.eventBus.$on(this.$store.getters.getMessageChannelPrefix + '.ticketUpdated.' + this.id,this.ticketNotification);
        this.$store.state.eventBus.$on(this.$store.getters.getMessageChannelPrefix + '.ticketComment.' + this.id,this.ticketNotification);
        this.$store.state.eventBus.$on(this.$store.getters.getMessageChannelPrefix + '.ticketDeleted.' + this.id,this.ticketNotification);
      },

      stopTicketListeners: function(){
        this.$store.state.eventBus.$off(this.$store.getters.getMessageChannelPrefix + '.ticketUpdated.' + this.id,this.ticketNotification);
        this.$store.state.eventBus.$off(this.$store.getters.getMessageChannelPrefix + '.ticketComment.' + this.id,this.ticketNotification);
        this.$store.state.eventBus.$off(this.$store.getters.getMessageChannelPrefix + '.ticketDeleted.' + this.id,this.ticketNotification);
      },


      ticketNotification: function(event){
        if(event.channel.includes('ticketUpdated')){
          this.ticket = event.message;
        }else if(event.channel.includes('ticketComment')){
          this.ticket = event.message.ticket;
          this.commentAdded(event.message.comments[0]);
        }else if(event.channel.includes('ticketDeleted')){
          this.$emit('back');
        }
      },

      commentAdded: function(comment){
        let ix = this.comments.findIndex(c => c.id === comment.id);
        if(ix === -1){
          this.comments.unshift(comment);
        }
      },

			getTicketDetail: function() {
				this.ticketService.getTicketDetail(this.id).then((res) => {
					this.ticket = res.data.ticket;
					this.comments = res.data.comments;

          if(this.ticket.unreadClient){
            this.ticketService.markAsRead(this.id);
          }
				});
			},

			addComment: function() {
				this.$store.commit('startLoading');
				this.ticketService
					.createComment(this.id, this.comment, this.attachments)
					.then((res) => {
            this.commentAdded(res.data);
						this.comment = null;
						this.attachments.splice(0, this.attachments.length);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			getContacts: function() {
				this.projectService.getAllAssignableUsers().then((res) => {
					this.contacts.splice(0, this.contacts.length);
					this.contacts.push(...res.data);
				});
			},

			getCommentBy: function(id) {
				let contact = this.contacts.find((c) => c.userId === id);
				if (contact) {
					return contact.firstName + ' ' + contact.lastName;
				} else {
					return ' -- ';
				}
			},

      addDropFile: function(e){
        this.attachments.push(...Array.from(e.dataTransfer.files));
      },

			downloadFile: function(s3File, download = true) {
				this.ticketService.getSignedUrl(this.id, s3File.fileName).then((res) => {
					let signedUrl = res.data;

					if (download == false) {
						window.open(signedUrl, '_blank');
					} else {
						axios({
							url: signedUrl, //your url
							method: 'GET',
							responseType: 'blob', // important
						}).then((response) => {
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', s3File.fileName); //or any other extension
							document.body.appendChild(link);
							link.click();
						});
					}
				});
			},
		},

		computed: {
			requester: function() {
				return this.contacts.find((c) => c.userId === this.ticket.clientContactId);
			},

			ticketType: function() {
				if (this.ticketTypes) {
					let result = this.ticketTypes.find((t) => t.id === this.ticket.type);
					if (result) {
						return result;
					}
				}

				return {};
			},
		},
	};
</script>

<style scoped lang="scss">
.closed {
  background-color: var(--v-error-base);
  width: fit-content;
  border-radius: 4px;
  padding: 0px 4px;
  color: var(--v-white-base);
  font-size: 12px;
  font-weight: 500;
}

.status {
  background-color: var(--v-accent-base);
  width: fit-content;
  border-radius: 4px;
  padding: 0px 4px;
  color: var(--v-black-base);
  font-size: 12px;
  font-weight: 500;
}
</style>
