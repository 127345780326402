<template>
	<div :id="`comment-wrapper-${uuid}`" style="position: relative">
		<v-textarea
			:id="`comment-${uuid}`"
			dense
			hide-details
			rows="1"
			auto-grow
			outlined
			:placeholder="$t('projects.comment-placeholder')"
			v-model="newComment"
			style="font-size: 14px;"
		>
			<template v-slot:append>
				<v-icon
					v-if="!commentToEdit"
					:color="!newComment || newComment.length == 0 ? 'gray' : 'primary'"
					@click.native="sendComment()"
					class="pointer"
					:disabled="!newComment || newComment.length == 0"
					>$comment
				</v-icon>
			</template>
		</v-textarea>
		<div v-if="commentToEdit" class="row-format mt-2">
			<v-btn class="secondary-action mr-1" @click="cancelEdit()">
				{{ $t('global.cancel') }}
			</v-btn>
			<v-btn class="primary-action ml-1" style="min-width: 100px" @click="saveEdit()">
				{{ $t('global.save') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import { v4 as uuid4 } from 'uuid';

	export default {
		name: 'TagUserField',

		props: ['commentToEdit'],

		data: function() {
			return {
				newComment: '',
				uuid: uuid4(),
			};
		},

		mounted() {
			if (this.commentToEdit) {
				this.newComment = this.commentToEdit.comment;
			}
		},

		methods: {
			saveEdit: function() {
        let newCommentObj = Object.assign({}, this.commentToEdit);
        newCommentObj.comment = this.newComment;
				this.$emit('saveEdit', newCommentObj);
			},

			cancelEdit: function() {
				this.$emit('cancelEdit', this.newComment);
			},

			sendComment: function() {
				this.$emit('sendComment', this.newComment);
				this.newComment = '';
			},

			getPendingComment: function() {
				return this.newComment;
			},

      clearComment: function(){
        this.newComment = '';
      }
		},

		computed: {
		},
	};
</script>

<style lang="scss">
	#comment-wrapper {
		width: 100%;
		align-items: center;
	}

	.tribute-container {
		background-color: var(--v-white-base);
		color: var(--v-gray_80-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
		z-index: 100 !important;
		margin-top: 15px;
		padding: 0 !important;
		//padding: 10px;

		ul {
			list-style-type: none; /* Remove bullets */
			padding: 0 !important; /* Remove padding */
			margin: 0 !important; /* Remove margins */
			li {
				padding: 8px 12px;
				margin: 0;
				min-width: 180px;
				cursor: pointer;
				&.selected {
					background-color: var(--v-gray_20-base);
					color: var(--v-black-base);
				}
			}
		}
	}
</style>
