<template>
	<div v-if="invoices.length">
		<div class="brand-medium pointer" @click="$router.push('/invoices?v=list')">
			{{ $t('welcome.currently-have') }} <span style="color: var(--v-primary-base)">{{ invoices.length }}</span>
			{{ $tc('welcome.invoices-ready', invoices.length) }}.
		</div>
		<div class="column-format" style="gap: 8px; margin-top: 12px">
			<div v-for="invoice in invoices" :key="invoice.id" class="action-button" @click.stop="openInvoice(invoice)">
				{{ invoice.invoiceNumberFormatted }} - {{ $formatters.dollars(invoice.total) }} - {{ $t('global.due') }}
				{{ DateTime.fromISO(invoice.dateDue).toFormat('MMM dd') }}
			</div>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';

	export default {
		name: 'Invoices',

		props: ['invoices'],

		components: {},

		data: function() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openInvoice: function(invoice) {
				let binding = {
					invoiceId: invoice.id,
				};
				this.$store.state.globalModalController
					.openModal(InvoiceDetails, binding, true, true)
					.then(() => this.$emit('refresh'));
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>