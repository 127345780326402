<template>
	<div>
		<div v-if="assignedUser" v-tippy="{ content: firstName + ' ' + lastName }" class="row-format align-center">
			<v-avatar size="25" v-if="assignedUser.profilePicture">
				<img class="pic" :src="assignedUser.profilePicture" />
			</v-avatar>
			<v-avatar size="25" v-else color="primary">
				<span style="color: white;">{{
					firstName.substr(0, 1).toUpperCase() + lastName.substr(0, 1).toUpperCase()
				}}</span>
			</v-avatar>
			<div class="ml-2 assigned-user-name ellipsis" v-if="showName">{{ firstName }} {{ lastName }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AssignedUser',

		props: {
      assignableUsers: Array,
			assignedTo: Number,
			showName: {
				type: Boolean,
				default: true
			}
		},

		components: {},

		data: function () {
			return {
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
		},

		computed: {
			assignedUser: function() {
				return this.assignableUsers.find((u) => u.userId === this.assignedTo)
			},

			firstName: function(){
				if(this.assignedUser && this.assignedUser.firstName){
					return this.assignedUser.firstName;
				}else{
					return '';
				}
			},

			lastName: function(){
				if(this.assignedUser && this.assignedUser.lastName){
					return this.assignedUser.lastName;
				}else{
					return '';
				}
			}
		},
	};
</script>

<style scoped lang="scss"></style>
