<template>
	<div id="project-kanban" class="row-format centered" :style="`height:${headerHeight}`">
    <div class="my-0 py-0" id="kanban-container">
			<div id="kanban-v-row" class="ml-1 mr-3">
				<div class="row-format">
					<div
						v-if="cards['unknown'].length > 0"
						class="kanban-v-col"
						:style="'--kanban-width: ' + columns.length * 272 + 'px'"
					>
						<div class="kanban-column" id="id.unknown">
							<div class="row-format">
								<span class="kanban-label" style="white-space: nowrap; padding-bottom: 10px"
									>[ {{ $t('projects.no-status') }} ]</span
								>
							</div>
							<div id="unknown">
								<draggable
									:list="cards['unknown']"
									:group="{ name: 'card', pull: 'clone' }"
									tag="section"
									:emptyInsertThreshold="100"
									:animation="200"
									:handle="$store.state.isMobile ? 'disabled' : '.cardWrapper'"
									ghost-class="ghost"
									:move="cardDragHandler"
									@end="endDragHandler"
								>
									<div
										v-for="deliverable in cards['unknown']"
										:key="deliverable.id"
										@click.stop="editDeliverable(deliverable)"
									>
										<kanban-card
											:deliverable="deliverable"
                      :show-status="false"
										></kanban-card>
									</div>
								</draggable>
							</div>
						</div>
					</div>
					<div class="row-format">
						<div v-for="column in columns" :key="column.id">
							<div class="kanban-v-col">
								<div class="kanban-column pointer" :id="`id.${column.id}`" @click="addDeliverable(column)">
									<kanban-header
										:all-columns="columns"
										:column="column"
										:is-collaborator="isCollaborator"
                    :allow-add-deliverable="hasFullAccessProjects"
										@add-deliverable="addDeliverableNoWait($event)"
									></kanban-header>
									<draggable
										:list="cards['id' + column.id]"
										:group="{ name: 'card', pull: 'clone' }"
										tag="section"
										:style="`height: calc(100vh - ${headerHeight + 57}px); overflow-y: auto;`"
										:emptyInsertThreshold="100"
										:animation="200"
										:handle="$store.state.isMobile ? 'disabled' : '.cardWrapper'"
										ghost-class="ghost"
										:class="`draggable-container ${$store.state.isMac ? 'hidden-scroll' : ''}`"
										:move="cardDragHandler"
										@end="endDragHandler"
									>
										<div
											v-for="deliverable in cards['id' + column.id]"
											:key="deliverable.id"
											@click.stop="editDeliverable(deliverable)"
										>
											<kanban-card
												:deliverable="deliverable"
                        :assignable-users="assignableUsers"
                        :status-list="columns"
												@add-new="addDeliverable(column)"
												@add-new-empty="addDeliverableNoWait(column)"
											></kanban-card>
										</div>
									</draggable>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Draggable from 'vuedraggable';
	import KanbanCard from './KanbanCard';
	import KanbanHeader from './KanbanHeader';

	export default {
		name: 'ProjectKanban',

		props: ['deliverables', 'updateFlag', 'columns', 'isCollaborator', 'headerHeight','kanbanSort','hasFullAccessProjects','assignableUsers'],

		components: {
			KanbanHeader,
			KanbanCard,
			Draggable,
		},

		data: function() {
			return {
				projectSelectorDialog: false,
				newProjectStatus: null,
				selectedCard: null,
				targetSortIndex: null,
				fromStatusId: null,
				toStatusId: null,

				clickCount: 0,
				clickTimer: null,
			};
		},

		mounted() {

		},

		methods: {
			addDeliverable: function(column) {
				this.clickCount++;
				if (this.clickCount === 1) {
					this.clickTimer = setTimeout(() => {
						this.clickCount = 0;
					}, 500);
					return;
				}

				clearTimeout(this.clickTimer);
				this.clickCount = 0;

				this.$emit('add-deliverable', column);
			},

			addDeliverableNoWait: function(column) {
				this.$emit('add-deliverable', column);
			},

			editDeliverable: function(deliverable) {
				this.$emit('edit-deliverable', deliverable);
			},

			cardDragHandler: function(event) {
				if (event.to.parentNode.id === 'unknown') {
					this.selectedCard = null;
					this.targetSortIndex = null;
					return false;
				}

				this.selectedCard = event.draggedContext.element;
				this.targetSortIndex = event.draggedContext.futureIndex;
				return true;
			},

			endDragHandler: function(event) {
				if (this.selectedCard === null) {
					return;
				}

				let updates = [];

				this.fromStatusId = event.from.parentElement.id.split('.')[1];
				this.toStatusId = event.to.parentElement.id.split('.')[1];

				let sortArray = this.cards['id' + this.toStatusId];

				for (let i = 0; i < sortArray.length; i++) {
					if (sortArray[i].kanbanSort !== i || sortArray[i].id === this.selectedCard.id) {
						let update = {
							deliverableId: sortArray[i].id,
							projectId: sortArray[i].project.id,
							kanbanSort: i,
							statusId: this.toStatusId,
							statusLabel: null,
						};

						if (sortArray[i].statusId !== this.toStatusId) {
							update.statusLabel = this.columns.find(s => s.id === this.toStatusId).label;
						}

						updates.push(update);
					}
					sortArray[i].kanbanSort = i;
					sortArray[i].statusId = this.toStatusId;
				}

				this.$emit('update-kanban-props', updates);
        console.log(updates);

				this.updateCounter++;
				this.targetSortIndex = null;
				this.selectedCard = null;
				this.fromStatusId = null;
				this.toStatusId = null;
			},

			cardSort: function(a, b) {
				if (a.kanbanSort !== b.kanbanSort) {
					return a.kanbanSort - b.kanbanSort;
				} else if (a.dueDate && b.dueDate && a.dueDate !== b.dueDate) {
					return Date.parse(a.dueDate) - Date.parse(b.dueDate);
				} else if (a.dueDate && !b.dueDate) {
					return 1;
				} else if (b.dueDate && !a.dueDate) {
					return -1;
				} else {
					if (a.name && b.name) {
						if (a.name.toLowerCase() > b.name.toLowerCase()) {
							return 1;
						} else if (a.name.toLowerCase() < b.name.toLowerCase()) {
							return -1;
						} else {
							return 0;
						}
					} else {
						return 0;
					}
				}
			},

			cardSortDue: function(a, b) {
				if (a.dueDate && b.dueDate && a.dueDate !== b.dueDate) {
					return Date.parse(a.dueDate) - Date.parse(b.dueDate);
				} else if (a.dueDate && !b.dueDate) {
					return -1;
				} else if (b.dueDate && !a.dueDate) {
					return 1;
				} else {
					if (a.name && b.name) {
						if (a.name.toLowerCase() > b.name.toLowerCase()) {
							return 1;
						} else if (a.name.toLowerCase() < b.name.toLowerCase()) {
							return -1;
						} else {
							return 0;
						}
					} else {
						return 0;
					}
				}
			},
		},

		computed: {
			calculatedWidth: function() {
				let count = this.columns.length;
				try {
					return (count + (this.cards.unknown.length > 0 ? 2 : 1)) * 300;
				} catch (e) {
					return 0;
				}
			},

			cards: function() {
				if (this.updateFlag) {
					//do nothing - just reference for updating ui
				}

				let columns = [...this.columns];
				let cards = {
					unknown: [],
				};

				for (let i = 0; i < columns.length; i++) {
					cards['id' + columns[i].id] = [];
				}

				if (this.deliverables.length === 0) {
					let colId = 'id' + columns[0].id;
					cards[colId].push({
						emptyCard: true,
					});
				} else {
					for (let i = 0; i < this.deliverables.length; i++) {
						let colId = 'id' + this.deliverables[i].statusId;
						if (Object.prototype.hasOwnProperty.call(cards, colId)) {
							cards[colId].push(this.deliverables[i]);
						} else {
							cards.unknown.push(this.deliverables[i]);
						}
					}

					for (const key in cards) {
						let arr = cards[key];
						if(this.kanbanSort === 'due'){
							arr.sort(this.cardSortDue);
						}else{
							arr.sort(this.cardSort);
						}

					}
				}

				return cards;
			},
		},
	};
</script>

<style lang="scss">
	#project-kanban {
		//::-webkit-scrollbar {
		//	display: none; // Inherits width and height from brand.scss
		//}
		.add-column {
			width: 36px;
			height: 36px;
			min-width: 36px;
			min-height: 36px;
			cursor: pointer;
			background-color: var(--v-gray_20-base);
			border-radius: 4px;
		}
		#kanban-container {
			overflow-x: auto;
			overflow-y: hidden;
			//height: calc(100% - 50px);

			#kanban-v-row {
				.kanban-v-col {
					margin-left: 8px;
					margin-right: 8px;
					//height: calc(100% - 50px);
					min-width: 272px;
					max-width: 272px;
					.kanban-column {
						background: linear-gradient(var(--v-sidebar-base), var(--v-white-base));
						//background: var(--v-gray_20-base);
						padding: 8px 8px 8px 8px;
						border-radius: 4px;
						text-align: left;
						filter: none;

						.draggable-container {
							//&::-webkit-scrollbar {
							//	display: none!important; // Inherits width and height from brand.scss
							//}
						}

						.hidden-scroll {
							&::-webkit-scrollbar {
								display: none!important; // Inherits width and height from brand.scss
							}
						}
					}

					.new-column {
						height: 160px;
						background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f9f9f7 100%), #f0efea;
						padding: 8px;
						border-radius: 8px;
						text-align: left;
						filter: none;
					}
				}
			}
		}
	}
</style>
