<template>
	<div style="width: 100%; height: 100%" class="column-format centered">
		<div class="brand-bold font-24">{{ $t('integration.connecting') }}</div>
	</div>
</template>

<script>
	import PaymentsService from '@/modules/invoices/PaymentsService';
  import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'PlaidLinkHandler',

		props: ['sessionToken','pod'],

		components: {},

		data: function () {
			return {
				paymentsService: new PaymentsService(),
        achSession: null,
        token: null,
			};
		},

		mounted() {
			localStorage.setItem('PlaidSessionToken',this.sessionToken);
      localStorage.setItem('PlaidSessionPod',this.pod);
      this.getAchSession();
		},

		beforeDestroy() {},

		methods: {
      getAchSession: function(){
        this.paymentsService.getACHLinkSession(this.pod,this.sessionToken).then((res) => {
          console.log('ACH Session found',res.data);

          this.achSession = res.data;
          this.getPlaidLinkToken();
        })
      },

      getPlaidLinkToken: function(){
        this.paymentsService.getPlaidLinkToken(this.pod,this.sessionToken).then((res) => {
          console.log('Plaid link token received ' + res.data);

          this.token = res.data;
          localStorage.setItem('PlaidLinkToken',this.token);
          this.initializePlaidLink();
        })
      },

			initializePlaidLink: function(){
        let plaidHandler = window.Plaid.create({
					token: this.token,
          onLoad: () => {},
          onSuccess: (token, metadata) => {
						this.plaidSuccess(token, metadata);
					},
					onExit: (token, metadata) => {
            this.plaidExit(token,metadata);
          },
					onEvent: (token, metadata) => {
            this.plaidEvent(token,metadata);
          },
					receivedRedirectUri: null,
				});

				plaidHandler.open();
			},


			plaidSuccess: function (token, metadata) {
				console.log('Plaid Success');
				console.log(token);
				console.log(metadata);

        let plaidAccountId = metadata.accounts[0].id;
        this.paymentsService.exchangePlaidPublicToken(this.pod,this.sessionToken,token,plaidAccountId).then((res) => {
          console.log('Success',res.data);
          window.location.href = this.achSession.redirectUri;
        }).catch((err) => {
          console.log('Failure',err);
          this.handleError(err.response.data.message);
        })
			},

      plaidError: function (err, metadata) {
        console.log('Plaid Error');
        console.log(err);
        console.log(metadata);
        if(err){
          this.handleError(err.error_code + ' - ' + err.error_message);
        }else{
          this.handleError('Unknown Error');
        }
      },

			plaidExit: function (err, metadata) {
				console.log('Plaid Exit');
				console.log(err);
				console.log(metadata);
        if(err){
          this.handleError(err.error_code + ' - ' + err.error_message);
        }else{
          window.location.href = this.achSession.redirectUri;
        }
			},

			plaidEvent: function (eventName, metadata) {
				console.log('Plaid Event');
				console.log(eventName);
				console.log(metadata);
			},

      handleError: function(message){
        let binding = {
          headingText: this.$t('error.connecting-account'),
          bodyText: message,
          hideNo:true,
          yesText: this.$t('global.okay')
        }
        this.$store.state.globalModalController.openModal(ConfirmModal,binding,true,false).then(() => {
          window.location.href = this.achSession.redirectUri;
        })
      }
		},

		computed: {},
	}
</script>

<style scoped lang="scss"></style>
