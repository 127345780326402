<template>
	<div id="invoice-payment-history" class="pa-4">
		<div v-for="payment in invoice.payments" :key="payment.id" @click="openPaymentDetails(payment)" class="payment">
			<div class="d-flex justify-space-between">
				<div class="timestamp">
					<v-icon size="12">$clock</v-icon>
					{{ paymentDateFormat(payment) }}
				</div>
				<!--				<div class="paid-by">-->
				<!--					{{ payment.paidBy }}-->
				<!--				</div>-->
			</div>
			<div class="d-flex justify-space-between">
				<div class="payment-provider">
					{{ $formatters.titleCase(payment.paymentProvider) }}
				</div>
				<div class="amount">
					{{ $formatters.dollars(payment.amount, true, true, payment.currency) }}
				</div>
			</div>
			<!--			<div class="memo">-->
			<!--				{{ payment.memo }}-->
			<!--			</div>-->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PaymentHistory',

		props: ['invoice'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {
			console.log(this.invoice.payments);
		},

		beforeDestroy() {},

		methods: {
			openPaymentDetails: function(payment) {
				this.$emit('openPayment', payment);
			},

			paymentDateFormat(payment) {
				if (payment.datePaid) {
					return this.$DateTime.humanDate(payment.datePaid);
				}
				return this.$DateTime.humanDate(payment.timestamp);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	#invoice-payment-history {
		min-height: 200px;
		width: 212px;
		background-color: var(--v-white-base);
		font-size: 16px;

		.payment {
			color: var(--v-black-base);
			&:hover {
				color: var(--v-gray_70-base);
			}
			cursor: pointer;
			text-align: left;
			&:not(:first-child) {
				margin-top: 12px;
			}
			.timestamp {
				color: var(--v-gray_70-base);
				font-size: 12px;
				.v-icon {
					margin-top: -3px;
				}
			}
		}
	}
</style>
