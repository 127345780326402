<template>
	<div class="text-left" @click="$emit('click', $event)" v-if="isConditionMet">
		<div class="form-question">{{ item.question }} <span v-if="item.required" class="font-red brand-medium">*</span>  <v-icon v-if="showConditionalHelper" small color="gray_50">account_tree</v-icon></div>
		<v-menu :close-on-content-click="false" :close-on-click="false" v-model="menu" offset-y :disabled="disabled">
			<template v-slot:activator="{ on }">
				<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
					<v-text-field
						v-on="on"
						hide-details
						dense
						:placeholder="item.placeholder"
						:outlined="outlined"
						:solo="solo"
						:filled="filled"
						:readonly="true"
						:value="dateFormatted"
						:disabled="disabled"
						class="form-input"
						append-icon="event"
						:rules="item.required ? inputRequired : []"
					></v-text-field>
				</v-form>
			</template>

			<div style="background-color: var(--v-white-base)">
				<v-date-picker v-model="localDate" no-title scrollable @change="dateUpdated" :first-day-of-week="$store.state.firstDayOfWeek" >
					<template v-slot:default>
						<div style="width: 100%" class="row-format centered">
							<div
								class="input-wrapper font-14 font-primary py-1 px-2 pointer"
								@click="clearDate()">
								{{ $t('global.clear') }}
							</div>
						</div>
					</template>
				</v-date-picker>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import RendererMixin from '@/modules/forms/v2/schema/RendererMixin';
	import HDateTime from '@/modules/utils/HDateTime';

	export default {
		name: 'DateInput',

		props: ['disabled', 'item', 'styles','index','results','builder','container'],

		mixins: [RendererMixin],

		data: function() {
			return {
				menu: false,
				localDate: null,
			};
		},

		mounted() {
			let currentResult = this.getCurrentResult(this.item.id,this.results);
			if(currentResult){
				this.localDate = currentResult;
			}
		},

		beforeDestroy() {},

		methods: {
			dateUpdated: function() {
				this.menu = false;
				this.$emit('input', {item:this.item, value:this.localDate, index: this.index, container: this.container});
			},

			clearDate: function(){
				this.localDate = null;
				this.dateUpdated();
			}
		},

		computed: {
			dateFormatted: function() {
				if (this.localDate) {
					return HDateTime.fromISO(this.localDate).toLocaleString(HDateTime.DATE_SHORT);
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
