<template>
	<div id="proposal-details">
		<div id="header-area" class="" v-if="proposal">
			<div class="header-left pl-md-6 pl-4">
				<div class="mr-6 brand-medium">
					<h-icon2 name="proposals" :active="true" size="24" class="mr-4"></h-icon2>
					{{ proposal.coverPage.heading }}
				</div>
				<div>
					<comments-menu
						class="mr-1"
						v-if="proposal"
						:comments="proposal.comments"
						:proposal-id="proposal.id"
						:client-id="proposal.clientId"
						:proposal-service="proposalService"
						:client-mode="true"
						:viewed-timestamps="proposal.viewedTimestamps"
					></comments-menu>
				</div>
			</div>
			<div class="header-right pr-md-6 pr-4">
				<div>
					<v-btn icon class="primary-action" @click="handleClose()">
						<v-icon :size="20">$close</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
    <div class="show-scrollbar" style="max-height: calc(100vh - 50px)">
      <proposal-renderer
        v-if="isReady"
        :proposal="proposal"
        :account="account"
        :proposal-service="proposalService"
        :edit-mode="false"
        :pod-service="null"
        :client-service="null"
        :need-save="false"
        :saving="false"
        :client-mode="true"
        :is-locked="isLocked"
        :card-payment-failed="cardPaymentFailed"
        @deliverable-selected="deliverableSelected($event)"
        @client-signed="clientFinalized($event)"
        @client-accepted="clientFinalized($event)"
      ></proposal-renderer>
    </div>
	</div>
</template>

<script>
	import ProposalService from '@/modules/proposal/ProposalService';
	import ProposalRenderer from '@/modules/proposal/proposal-renderer/ProposalRenderer';
  import CommentsMenu from '@/modules/proposal/proposal-renderer/CommentsMenu';

	export default {
		name: 'ProposalDetails',

		props: ['proposalId'],

		components: { ProposalRenderer, CommentsMenu },

		data: function() {
			return {
				refreshKey: 0,
				isReady: false,
				proposal: null,
				account: null,
				selectedPackageIds: [],
				theirSignature: null,
				proposalService: new ProposalService(),
        cardPaymentFailed: false,
			};
		},

		mounted() {
			this.getProposal();
		},

		beforeDestroy() {},

		methods: {
			getProposal: function() {
				this.proposalService.getProposal(this.proposalId).then((res) => {
					this.proposal = res.data;
					this.account = this.proposal.accountMini;
					this.proposalService.setProposal(this.proposal);
					this.proposalService.setLastViewed();
					this.isReady = true;
				});
			},

			deliverableSelected: function(deliverable) {
				if (this.proposal.allowMultipleDeliverables) {
					if (deliverable.selectedPackage) {
						this.selectedPackageIds.push(deliverable.id);
					} else {
						let ix = this.selectedPackageIds.findIndex((p) => p === deliverable.id);
						if (ix > -1) {
							this.selectedPackageIds.splice(ix, 1);
						}
					}
				} else {
					this.selectedPackageIds.splice(0, this.selectedPackageIds.length);
					this.selectedPackageIds.push(deliverable.id);
				}
			},

			clientFinalized: function(payload) {
        let block = payload.block;

				if (this.requirePackageSelection && this.selectedPackageIds.length === 0) {
					this.$store.commit('error', 'Package not selected.');
				} else {
          let result = {
            selectedPackageIds: this.selectedPackageIds,
            cardTokenId: payload.cardTokenId,
            upFrontAmount: payload.upFrontAmount
          }

          if(block.blockType === 'SignatureBlock'){
            result.signature = block.them;
          }else{
            result.clientAccept = true;
          }

          this.$store.commit('startLoading');
					this.proposalService
						.executeProposal(result)
						.then((res) => {
              this.cardPaymentFailed = false;
							this.proposal = res.data;
						})
						.catch((err) => {
              if(result.cardTokenId){
                this.cardPaymentFailed = true;
              }
							this.$store.commit('error', err.response.data.message);
						}).finally(() => this.$store.commit('stopLoading'));
				}
			},

			handleClose: function() {
				this.$emit('result');
			},
		},

		computed: {
			signatureBlock: function() {
				for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
					if (this.proposal.contentBlocks[i].blockType === 'SignatureBlock') {
						return this.proposal.contentBlocks[i];
					}
				}

				return null;
			},

      acceptBlock: function () {
        for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
          if (this.proposal.contentBlocks[i].blockType === 'AcceptBlock') {
            return this.proposal.contentBlocks[i];
          }
        }

        return null;
      },

			requirePackageSelection: function() {
				if(this.proposal.forceAllDeliverables){
          return false;
        }

        let deliverableCount = 0;
				for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
					if (this.proposal.contentBlocks[i].blockType === 'Deliverables') {
						deliverableCount = deliverableCount + 1;
					}
				}

				return deliverableCount > 1;
			},

			isLocked: function() {
				let signature = this.signatureBlock;
				if (signature != null) {
          return !this.$validations.isEmpty(signature.them.signature);
        }else if(this.acceptBlock != null){
          return this.acceptBlock.accepted ? true : false;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	#proposal-details {
		background-color: var(--v-gray_10-base);
		#header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
