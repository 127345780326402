import HttpClient from "./HttpClient";
import store from '@/store';

export default class PodService {
    httpClient;
    standardHttpClient;

    constructor() {
        this.httpClient = new HttpClient(store.getters.getPodUrl + 'api');
        this.standardHttpClient = new HttpClient();
    }

    ping(){
        return this.standardHttpClient.get('/ping')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getIpInfo(){
        return this.httpClient.get('/insecure/ip')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    clearCookies() {
        return this.httpClient.get('/insecure/clearCookies')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

}
