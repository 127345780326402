export default  {

    data() {
        return {
            isValid: false,
            inputRequired: [(v) => !!v || 'Required']
        };
    },

    methods: {
        validate: function () {
            if(!this.isConditionMet) {
                return true
            }
            if(this.$refs.form) {
                return this.$refs.form.validate();
            }else{
                return true;
            }
        },

        getCurrentResult: function(id,results){
            if(results && id) {
                let result = results.find(r => r.item.id === id);
                if (result) {
                    return result.value;
                }
            }
            return null;
        },

        checkSingleCondition: function(cond) {
            return this.results.some(res => {
                if (res.item.fieldName === cond.fieldName) {
                    switch (cond.operation) {
                        case "is":
                            return res.value === cond.answer;
                        case "isn't":
                            return res.value !== cond.answer;
                        case "is answered":
                            return !!res.value;
                        case "isn't answered":
                            return !res.value;
                        case "contains":
                            return res.value && res.value.includes(cond.answer);
                        case "doesn't contain":
                            return !res.value || !res.value.includes(cond.answer);
                        default:
                            return false;
                    }
                }
                return false;
            });
        },

        colConditionMet: function(colItem) {
            if(this.builder) {
                return true
            }
            if(colItem.conditionsEnabled && colItem.conditions.length) {
                if (colItem.conditionalType === "and") {
                    return colItem.conditions.every(cond => this.checkSingleCondition(cond));
                }
                else {
                    return colItem.conditions.some(cond => this.checkSingleCondition(cond));
                }
            }else{
                return true;
            }
        }
    },

    computed: {
        isConditionMet: function(){
            return this.colConditionMet(this.item)
        },

        outlined: function(){
            return this.styles.inputStyle === 'outlined';
        },

        filled: function(){
            return this.styles.inputStyle === 'filled';
        },

        solo: function(){
            return this.styles.inputStyle === 'solo';
        },

        showConditionalHelper: function(){
          return this.builder &&  this.item.conditionsEnabled && this.item.conditions.length;
        }
    },
}