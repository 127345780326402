<template>
	<div :id="item.id" @click="$emit('click', $event)" :style="`width:100%; text-align: ${buttonAlignment}; padding-left: ${padding(item.pl)}px; padding-right: ${padding(item.pr)}px; padding-bottom: ${item.pb}px; padding-top: ${item.pt}px;`" class="mt-auto" >
		<v-btn
				class="elevation-3"
				:style="buttonStyle"
				:color="accepted ? item.buttonColor : '#FFFFFF'"
				:x-small="button.xSmall"
				:small="button.small"
				:large="button.large"
				:x-large="button.xLarge"
				:block="button.block"
        :disabled="buttonDisabled"
				@click="handleClick"
		>
			<div
					class="pa-1"
					:style="`color: ${buttonFontColor}`"
			>
				{{ accepted ? item.acceptedText : item.text }}
			</div>
		</v-btn>
	</div>
</template>

<script>
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'AcceptButton',

		props: ['agreement','item','signer','clientMode','isMobile','pageWidth'],

		components: {},

		mixins: [ResponsiveMixin],

		data: function() {
			return {

			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
      finalize: function(){
        return new Promise((resolve,reject) => {
          if(!this.accepted){
            document.getElementById(this.item.id).scrollIntoView({ block: 'start', behavior: 'smooth' });
            document.getElementById(this.item.id).classList.add("red-border");
            reject();
          }else {
            document.getElementById(this.item.id).classList.remove("red-border");
            resolve();
          }
        })
      },

			handleClick: function(){
        if(this.clientMode) {
          let ix = this.item.acceptedBy.indexOf(this.signer.id);
          if (ix > -1) {
            this.item.acceptedBy.splice(ix, 1);
          } else {
            document.getElementById(this.item.id).classList.remove("red-border");
            this.item.acceptedBy.push(this.signer.id);
          }
        }
			}
		},

		computed: {
      accepted: function(){
        return this.item.acceptedBy.length > 0
      },

      buttonDisabled: function(){
        return this.agreement.fullyExecuted || (this.signer && this.signer.locked) || (!this.clientMode && this.agreement.clientSigned);
      },

      buttonStyle: function(){
        if(this.buttonDisabled){
          if(this.item.acceptedBy.length) {
            return `border: 1px solid ${this.item.buttonColor}; background-color: ${this.item.buttonColor}!important`;
          }else{
            return '';
          }
        }else{
          return `border: 1px solid ${this.item.buttonColor}`;
        }
      },

			buttonAlignment: function() {
				if (this.item.buttonAlignment === 'block') {
					return 'unset';
				} else {
					return this.item.buttonAlignment;
				}
			},

			button: function() {
				return {
					xSmall: this.item.buttonSize === 'X-Small',
					small: this.item.buttonSize === 'Small',
					large: this.item.buttonSize === 'Large',
					xLarge: this.item.buttonSize === 'X-Large',
					block: this.item.buttonAlignment === 'block',
				};
			},

			buttonFontColor: function() {
				if (!this.accepted) {
					return this.item.buttonColor;
				} else {
					let bgColor = this.item.buttonColor;
					let color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
					let r = parseInt(color.substring(0, 2), 16); // hexToR
					let g = parseInt(color.substring(2, 4), 16); // hexToG
					let b = parseInt(color.substring(4, 6), 16); // hexToB
					return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
