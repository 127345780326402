<template>
	<div v-if="forms.length">
		<div class="brand-medium pointer" @click="$router.push('/forms')">
			{{ $t('welcome.currently-have') }} <span style="color: var(--v-primary-base)">{{ forms.length }}</span>
			{{ $tc('forms.forms-ready', forms.length) }}.
		</div>
		<div class="column-format" style="gap: 8px; margin-top: 12px">
			<div v-for="form in forms" :key="form.id" class="action-button" @click.stop="openRequest(form)">
				{{ form.name }} - {{DateTime.fromISO(form.sentTimestamp).toLocaleString(DateTime.DATETIME_SHORT)}}
			</div>
		</div>
	</div>
</template>

<script>
  import {DateTime} from 'luxon';
  import FormRequestViewer from '@/modules/forms/FormRequestViewer';

	export default {
		name: 'Forms',

		props: ['forms'],

		components: {},

		data: function() {
			return {
        DateTime: DateTime
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
      openRequest: function(request) {
        let binding = {
          request: request,
        };
        this.$store.state.globalModalController.openModal(FormRequestViewer, binding, true, true).then((res) => {
          if (res) {
            this.$emit('refresh')
          }
        });
      },
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>