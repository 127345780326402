<template>
	<v-dialog
		:value="dialog"
		width="unset"
		:persistent="true"
		:overlay-color="overlayColor"
		:overlay-opacity="overlayOpacity"
		@click:outside="!persistent ? handleClose() : ''"
		:fullscreen="fullscreen"
		content-class="basic-modal"
	>
		<slot></slot>
	</v-dialog>
</template>

<script>
	export default {
		name: 'BasicModal',
		components: {},

		props: {
			dialog: { type: Boolean, default: false },
			fullscreen: { type: Boolean, default: false },
			persistent: { type: Boolean, default: true },
			overlayColor: { type: String, default: 'modal_overlay' },
			overlayOpacity: { type: String, default: '0.8' },
			closeOnEscape: { type: Boolean, default: true },
		},

		data() {
			return {
				alert: {},
			};
		},

		mounted() {
			if (this.closeOnEscape) {
				document.addEventListener('keydown', this.handleKeyDown);
			}
		},

		beforeDestroy() {
			if (this.closeOnEscape) {
				document.removeEventListener('keydown', this.handleKeyDown);
			}
		},

		methods: {
			handleClose: function() {
				this.$emit('close');
			},

			handleKeyDown: function(e) {
				if (e.key === 'Escape') {
					this.handleClose();
				}
			},

			setAlert: function(alert) {
				this.alert = alert;
			},
		},

		watch: {
			dialog(newVal) {
				if (this.closeOnEscape) {
					if (newVal) {
						document.addEventListener('keydown', this.handleKeyDown);
					} else {
						document.removeEventListener('keydown', this.handleKeyDown);
					}
				}
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	.v-dialog.basic-modal {
		background-color: var(--v-white-base);
	}
</style>
