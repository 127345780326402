<script>
import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
	extends: Line,
	mixins: [reactiveProp],
	props: {
		chartData: { type: Object, required: true },
		options: { type: Object, required: true },
	},

	data() {
		return {};
	},

	mounted() {
		// console.log(mixins);
		this.renderChart(this.chartData, this.options);
	},

	beforeDestroy() {},

	methods: {},

	computed: {},
	watch: {},
};
</script>

<style lang="scss" scoped></style>