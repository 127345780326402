<template>
	<agreement-renderer
		ref="AgreementRenderer"
		:agreement="agreement"
		:signer="signer"
		v-if="agreement"
		@finalize="submitFinalized"
		:show-finish-warning="showWarning"
	></agreement-renderer>
</template>

<script>
	import AgreementRenderer from '@/modules/agreements/AgreementRenderer';
	import AgreementViewerService from '@/modules/agreements/viewer/AgreementViewerService';
	import AgreementMixin from '@/modules/agreements/schema/AgreementMixin';

	export default {
		name: 'AgreementViewer',

		props: ['id', 'secretKey'],
		mixins: [AgreementMixin],

		components: { AgreementRenderer },

		data: function () {
			return {
				agreement: null,
				signer: null,
				finalized: false,
				showWarning: false,
				viewerService: new AgreementViewerService(this.$store.state.domain.podUrl, this.id, this.secretKey),
			};
		},

		mounted() {
      this.$vuetify.theme.dark = false;
			setTimeout(() => (this.$vuetify.theme.dark = false), 50);
			this.getAgreement();

			window.addEventListener('beforeunload', (event) => this.checkFinalized(event));
		},

		beforeDestroy() {},

		methods: {
			checkFinalized: function (event) {
				if (!this.finalized) {
					event.preventDefault();
					event.returnValue = false;
					setTimeout(() => (this.showWarning = true), 1000);
				}
			},

			getAgreement: function () {
				this.viewerService
					.getAgreement()
					.then((res) => {
						this.agreement = res.data.agreement;
						this.signer = res.data.signer;
						this.$store.state.clientFields = res.data.clientFields;
						this.$store.state.contactFields = res.data.contactFields;
						this.$store.state.pipelineFields = res.data.pipelineFields;
						this.loadFonts(this.agreement);
					})
					.catch(() => this.$router.push('/NotFound'));
			},

			async submitFinalized() {
				this.$store.commit('startLoading');

				if (this.$store.state.agreementPaymentElement) {
					let result = await this.$store.state.agreementPaymentElement.processPayment();
					if (result && result.status !== 'succeeded' && result.status !== 'processing') {
            this.$store.commit('stopLoading');
						return;
					}
				}

				this.viewerService
					.finalizeAgreementFormData(this.agreement)
					.then((res) => {
						this.agreement = res.data.agreement;
						this.signer = res.data.signer;
						this.$refs.AgreementRenderer.handlePostSignature();
						this.finalized = true;
						this.showWarning = false;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						this.$refs.AgreementRenderer.reset();
					})
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
