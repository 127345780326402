<template>
  <div>
    <div
        class="pointer"
        style="width:100%;border-bottom: 1px solid var(--v-gray_50-base)"
        @click.stop="expandClientList = !expandClientList"
    >
      <v-container
          fluid
          class="ma-0 pa-0"
          :style="expandClientList && $store.getters.getClientAccounts.length > 1 ? 'border-bottom: 1px solid var(--v-gray_50-base)' : ''"
      >
        <v-row
            dense
            class="ma-0 pt-2 pb-2 pl-3"
            align="center"
            v-for="(client, index) in clientList"
            :key="client.clientId"
            :style="index === 0 ? 'background: var(--v-primary-base)' : 'background: var(--v-sidebar-base);'"
        >
          <v-col cols="10" align="left">
            <div
                class="row-format pointer align-center"
                style="align-items: center"
                @click.stop="
								expandClientList ? handleClientSelection(client) : (expandClientList = true)
							"
            >
              <div>
                <v-avatar size="25" color="accent">
                  <span class="white--text brand-bold">{{ getClientInitials(client.clientName) }}</span>
                </v-avatar>
              </div>
              <div :class="`pl-2 ${index === 0 ? 'black--text' : ''}`" style="margin-left: -2px">
                <span style="font-size: 14px; font-weight: 600">{{ client.clientName }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="2" align="right" class="pointer" v-if="index === 0">
            <v-icon color="black" style="margin-right:12px" size="25" v-if="$store.getters.getClientAccounts.length > 1">{{
                expandClientList ? 'unfold_less' : 'unfold_more'
              }}</v-icon>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkspaceSelector",

  props: [],

  components: {},

  data: function () {
    return {
      expandClientList: false,
    }
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    handleClientSelection(client) {

      if (this.$store.getters.getClient === client) {
        return;
      }

      // for (let i = 0; i <this.$store.getters.getClientAccounts.length; i++) {
      //   let ua = client.clientId[i];
      //   if (ua.client.clientId === client) {
      //     this.$store.dispatch('setSelectedClient', ua);
      //     this.expandClientList = false
      //     return;
      //   }
      // }

      this.$store.commit('setSelectedClient',client.clientId);
      localStorage.setItem('SELECTED_CLIENT',client.clientId);
      window.location.href = "/";
    },

    getClientInitials(clientName) {
      if(!clientName){
        return '?'
      }
      let ignoreMe = ['a', 'an', 'as', 'to', 'and', 'of', 'if', 'in', 'the', '-'];
      let words = clientName.toLowerCase().split(' ');
      let ret = [];
      for (let i = 0; i < words.length; i++) {
        if (ignoreMe.indexOf(words[i]) > -1) continue;
        ret[i] = words[i].substring(0, 1);
        if (ret.length == 3) break;
      }
      return ret.join('').toUpperCase();
    },

  },

  computed: {
    clientList() {
      let clientList = [];
      clientList.push(this.$store.getters.getClient);
      console.log(this.$store.getters.get);

      if (this.expandClientList) {
        // return this.$store.getters.getClientAccounts;

        for ( let i = 0; i < this.$store.getters.getClientAccounts.length; i++) {
          let ua = this.$store.getters.getClientAccounts[i];
          if (ua.clientId !== this.$store.getters.getClientId) {
            clientList.push(ua);
          }
        }
      }
      return clientList;
    },
  },
}
</script>

<style scoped lang="scss">

</style>