import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class ProjectService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getDeliverableStatus() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/deliverableStatus`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjects() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/projects`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeliverables(){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/deliverables`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeliverableDetail(deliverableId){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/deliverables/${deliverableId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getAssignableUsers(projectId){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/projects/${projectId}/assignableUsers`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getAllAssignableUsers(){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/assignableUsers`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateKanbanProperties(updates) {
		let clientId = store.getters.getClientId;
		return this.httpClient
			.put(`/${clientId}/deliverables/kanban`, updates)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createDeliverable(projectId, deliverable){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.post(`/${clientId}/projects/${projectId}/deliverables`, deliverable)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchDeliverable(deliverableId, patchData) {
		let clientId = store.getters.getClientId;
		return this.httpClient
			.patch(`/${clientId}/deliverables/${deliverableId}`, patchData)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteDeliverable(deliverableId) {
		let clientId = store.getters.getClientId;
		return this.httpClient
			.delete(`/${clientId}/deliverables/${deliverableId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjectDeliverables(projectId) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/projects/${projectId}/deliverables`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	approveDeliverable(projectId,deliverableId) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.post(`/${clientId}/projects/${projectId}/deliverables/${deliverableId}/approve`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	addComment(projectId,deliverableId,comment){
		let clientId = store.getters.getClientId;

		return this.httpClient
			.post(`/${clientId}/projects/${projectId}/deliverables/${deliverableId}/comment`,comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	editComment(projectId,deliverableId,comment){
		let clientId = store.getters.getClientId;

		return this.httpClient
			.put(`/${clientId}/projects/${projectId}/deliverables/${deliverableId}/comment`,comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteComment(projectId,deliverableId,commentId){
		let clientId = store.getters.getClientId;

		return this.httpClient
			.delete(`/${clientId}/projects/${projectId}/deliverables/${deliverableId}/comment`,{
				params:{
					commentId:commentId
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjectPayments(projectId) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/projects/${projectId}/payments`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTimeWorkedForProject(projectId) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/projects/${projectId}/timeWorked`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedProjectUrl(projectId,fileName){
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/projects/${projectId}/files`, {
				params: {
					fileName: fileName,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(projectId, deliverableId, fileName) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/projects/${projectId}/deliverables/${deliverableId}/files`, {
				params: {
					fileName: fileName,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteFile(projectId, deliverableId, fileName) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.delete(`/${clientId}/projects/${projectId}/deliverables/${deliverableId}/files`, {
				params: {
					fileName: fileName,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
