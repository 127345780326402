<template>
	<div class="column-format fill-height" style="" v-if="$store.state.isLoggedIn">
		<div :class="`row-format align-center mx-2 mt-2 mb-2 nav-link${$store.state.leftNavExpanded ? '-expanded' : ''}`">
			<div class="row-format align-center px-3 pb-3 pt-2" style="width: 100%; min-height: 56px; min-width: 56px">
				<div
					class="mt-2 pr-2"
					style="cursor: pointer; text-align: center; width: 100%; text-align: left"
					@click="$router.push('/welcome')"
				>
					<img v-if="$store.state.leftNavExpanded" :src="logo" style="max-width: 120px; max-height: 50px" />
					<img v-else :src="logo" style="max-width: 120px; max-height: 35px" />
				</div>
			</div>
		</div>
		<div style="max-height: calc(var(--vh) - 114px); overflow-y: auto">
			<nav-link
				v-for="(link, index) in navLinks"
				:key="`navlink-${index}`"
				:label="link.label"
				:icon="link.icon"
				:route="link.route"
				:hyper-link="link.hyperLink"
			></nav-link>
		</div>
		<v-menu offset-x nudge-left="10" content-class="workspace-menu">
			<template v-slot:activator="{ on }">
				<div class="workspace-settings row-format align-center pa-3" v-on="on">
					<div
						v-if="!$store.state.leftNavExpanded"
						style="margin-left: 6px"
						class="row-format centered"
						:content="`Settings`"
						v-tippy="{
							placement: 'right',
							arrow: true,
							arrowType: 'round',
							animation: 'fade',
							delay: 50,
							boundary: 'window',
						}"
					>
						<v-avatar size="20" color="accent">
							<span class="white--text brand-bold font-12">{{ clientInitials }}</span>
						</v-avatar>
					</div>

					<div v-else style="margin-left: 6px" class="row-format centered">
						<v-avatar size="20" color="accent">
							<span class="white--text brand-bold font-12">{{ clientInitials }}</span>
						</v-avatar>
					</div>

          <div class="nav-label ml-2" v-if="$store.state.leftNavExpanded">{{ $t('settings.label') }}</div>
          <v-icon class="ml-auto" small v-if="$store.state.leftNavExpanded">$chevronRight</v-icon>
				</div>
			</template>

			<settings-nav></settings-nav>
		</v-menu>
	</div>
</template>

<script>
	import NavLink from '@/views/nav/NavLink.vue';
	import SettingsNav from '@/views/nav/settings/SettingsNav.vue';
	import NavMixin from '@/views/nav/NavMixin';
	export default {
		name: 'LeftNav',

		props: [],

		components: { NavLink, SettingsNav },

		mixins: [NavMixin],

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style lang="scss">
	.workspace-menu {
		filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
		box-shadow: none !important;
		border-radius: 8px !important;
		position: absolute;
		bottom: 20px !important;
		top: unset !important;
		background-color: var(--v-white-base);
		z-index: 1000 !important;
	}
</style>

<style scoped lang="scss">
	.fill-height {
		height: var(--vh);
	}

	.nav-link {
		width: 44px;
		height: 44px;
		border-radius: 8px;
	}

	.nav-link-expanded {
		width: 180px;
		height: 44px;
		border-radius: 8px;
	}

	.nav-label {
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		//letter-spacing: -0.01em;
		color: var(--v-custom-base);
	}

	.workspace-settings {
		cursor: pointer;
		margin-top: auto;
		padding: 0px 8px;

		width: 100%;
		height: 52px;

		/* Blues/Royal Blue 05 */

		background: var(--v-sidebar-base);
		border-top: 1px solid rgba(0, 0, 0, 0.02);
		box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);
	}
</style>
