<template>
  <div class="confirm-dialog-div">
    <v-btn class="close-dialog" icon rounded @click.prevent="handleClose()">
      <v-icon size="20">$close</v-icon>
    </v-btn>
    <div class="modal-padding">
      <div class="modal-title text-center mb-2 mt-4">
      </div>

      <v-form @submit.prevent="handleSubmit()" v-model="isValid" ref="confirmDialog" id="confirmDialog" lazy-validation>
        <div class="column-format gap-2">
          <v-text-field v-model="password" type="password" hide-details dense outlined persistent-placeholder :label="$t('login.password')" :rules="passwordRules"></v-text-field>
          <v-text-field v-model="confirmPassword" type="password" hide-details dense outlined persistent-placeholder  :label="$t('login.confirm-password')" :rules="confirmPasswordRules"></v-text-field>
          <v-btn :disabled="!isValid" color="primary" style="height: 40px!important;" elevation="0" block @click="handleSubmit()">{{ $t('global.save') }}</v-btn>
          <v-btn text block  @click="disablePasswordLogin()"><v-icon class="material-symbols-outlined" color="gray_70">cancel</v-icon>{{$t('login.disable-password')}}</v-btn>
        </div>
      </v-form>
    </div>

  </div>
</template>

<script>
import PasswordService from '@/modules/auth/PasswordService';

export default {
  name: 'UpdatePassword',

  props: [],

  components: {},

  data: function() {
    return {
      passwordService: new PasswordService(),
      isValid: false,
      password: null,
      confirmPassword: null,
      passwordRules: [(v) => !!v || '', (v) => v && v.length >= 8 || ''],
      confirmPasswordRules: [(v) => !!v || '', (v) => v && v === this.password || '']
    };
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    handleSubmit: function(){
      this.passwordService.setPassword(this.password).then(() => {
        this.$store.commit('success','OK');
        this.$emit('result');
      }).catch((err) => {
        this.$store.commit('error',err.response.data.message);
      })
    },

    disablePasswordLogin: function(){
      console.log('disabling');
      this.passwordService.setPassword(null).then(() => {
        this.$store.commit('success','OK');
        this.$emit('result');
      }).catch((err) => {
        this.$store.commit('error',err.response.data.message);
      })
    },

    handleClose: function(){
      this.$emit('result');
    }
  },

  computed: {},

};
</script>

<style scoped lang="scss">
.confirm-dialog-div {
  background-color: var(--v-white-base);
  position: relative;
  border-radius: 4px !important;

  button.v-btn.close-dialog {
    right: 4px !important;
    top: 6px !important;
  }
}
</style>