<template>
	<div style="width: 700px">
		<div class="modal-padding">
			<v-data-table :headers="headers" :items="payments" disable-pagination hide-default-footer>
				<template v-slot:item.amount="{item}">
					{{$formatters.dollars(item.amount,true,true,item.currency)}}
				</template>
				<template v-slot:item.paid="{item}">
					{{$formatters.yesNoFromBoolean(item.paid)}}
				</template>
			</v-data-table>
		</div>
		<div class="modal-footer">
			<v-btn class="primary-action ml-1" width="150" @click="$emit('result')">{{ $t('global.done') }}</v-btn>
		</div>
	</div>
</template>

<script>

	import SubscriptionService from '@/modules/subscriptions/SubscriptionService';

  export default {
		name: 'SubscriptionPayments',

		props: ['subscription'],

		components: {},

		data: function () {
			return {
				payments: [],
				stripeSubscriptionService: new SubscriptionService(),
				headers: [
					{ text: this.$t('stripe.subscription.period-start'), value: 'periodStart' },
					{ text: this.$t('stripe.subscription.period-end'), value: 'periodEnd' },
					{ text: this.$t('stripe.subscription.invoice-amount'), value: 'amount' },
					{ text: this.$t('stripe.subscription.paid'), value: 'paid' },
				],
			};
		},

		mounted() {
			this.getPayments();
		},

		beforeDestroy() {},

		methods: {
			getPayments: function () {
				this.stripeSubscriptionService.getSubscriptionPayments(this.subscription.id).then((res) => {
					this.payments.splice(0, this.payments.length);
					this.payments.push(...res.data);
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
