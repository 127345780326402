export default {
	light: {
		primary: '#267AE1' /* Royal Blue */,
		secondary: '#002B52' /* Prussian Blue */,
		accent: '#FEBB37' /* Sunglow */,
		anchor: '#867F77' /* Gray 70 */,
		success: '#008556',
		error: '#DE1C22',
		warning: '#E86825',
		info: '#175AA8' /* Fun Blue */,

		custom: '#000000',
		custom_light: '#000000',
		custom_dark: '#000000',

		modal_overlay: '#002B52',
		//sidebar: '#F4F3EF',

		success_10: '#D6F3E2',
		success_50: '#008556',

		warning_10: '#FFE1BE',
		warning_50: '#E86825',

		alert_10: '#F9D3D4',
		alert_50: '#DE1C22',

		black: '#000000' /* Black */,
		white: '#FFFFFF' /* White */,

		gray_10: '#F9F8F6',
		gray_20: '#F4F3EF' /* "light" (mobile), carrara_20 */,
		gray_30: '#E9E8E0' /* carrara_50 */,
		gray_50: '#D6D1CB' /* swirl */,
		gray_60: '#AAA5A0' /* schooner_20 */,
		gray_70: '#867F77' /* grey, schooner_50 */,
		gray_80: '#565451',
		gray_90: '#10110F' /* dark (mobile), log cabin */,

		blue_90: '#002B52' /* Prussian Blue */,
		blue_70: '#174987',
		blue_50: '#267AE1',
		// blue_40: '#5A9DEE',
		blue_30: '#7DAFED',
		//blue_20: '#A8CAF3',
		blue_20: '#565451',
		blue_10: '#D4E4F9',
		blue_5: '#E9F1FB',
		// blue_0: '#F4F8FD',

		// gray: '#10110F',
		bali_hai: '#8EA3B8',
		sundown: '#FFA5B6',
		heliothrope: '#CE62E9',
		cornflower: '#7950F2',
		dodger: '#4F72FF',
		turquoise: '#3BDBBE',
		emerald: '#51CF66',
		sunglow: '#FEBB37',
		pumpkin: '#FD7E14',
		carnation: '#FA5252',

	},
	dark: {
		primary: '#bebebe' /* Royal Blue DK */,
		secondary: '#D4E4F9' /* Royal Blue 90 DK */,
		accent: '#F59F00' /* Sunglow */,
		anchor: '#D6D1CB' /* Gray 60 */,
		success: '#008556',
		error: '#DE1C22',
		warning: '#E86825',
		info: '#175AA8' /* Fun Blue */,

		custom: '#f5f5f5',
		custom_light: '#7DAFED',
		custom_dark: '#002B52',

		success_10: '#003522',
		success_50: '#66B69A',

		warning_10: '#461F0B',
		warning_50: '#ED8651',

		alert_10: '#43080A',
		alert_50: '#E86064',

		modal_overlay: '#2F2E2C',
		sidebar: '#454444',

		// Inverted from light theme
		black: '#FFFFFF' /* Black */,
		white: '#181818' /* White */,
		// grey: '#D6D1CB' /* Gray 60 */,

		// Inverted from light theme
		gray_10: '#10110F',
		gray_20: '#2F2E2C',
		gray_30: '#56524E',
		gray_50: '#56524E',
		gray_60: '#D6D1CB',
		gray_70: '#E9E8E0',
		gray_80: '#F4F3EF',
		gray_90: '#F9F8F6',

		// Inverted from light theme
		blue_90: '#E9F1FB',
		blue_70: '#D4E4F9',
		blue_50: '#7DAFED',
		blue_30: '#267AE1',
		blue_20: '#627B94',
		blue_10: '#174987',
		blue_5: '#002B52' /* Prussian Blue */,

		// gray: '#F9F8F6' /* gray 90 */,
		bali_hai: '#9FB2C5',
		sundown: '#F06595',
		heliothrope: '#CE62E9',
		cornflower: '#9775FA',
		dodger: '#748FFC',
		turquoise: '#099268',
		emerald: '#5C940D',
		sunglow: '#F59F00',
		pumpkin: '#E8590C',
		carnation: '#FF8787',

		// sundown_tint: '#ffdbe2',
		// heliothrope_tint: '#ebc0f6',
		// cornflower_tint: '#c9b9fa',
		// dodger_tint: '#b9c7ff',
		// turquoise_tint: '#b1f1e5',
		// emerald_tint: '#b9ecc2',
		// sunglow_tint: '#ffe4af',
		// pumpkin_tint: '#fed8b9',
		// carnation_tint: '#fecbcb',
	},
};
