import TimerEventList from '@/modules/timetracking/TimerEventList';

export default [
	{
		path: '/time',
		name: 'TimerEventList',
		component: TimerEventList,
		props: (route) => ({
			v: route.query.v,
		}),
	},
]