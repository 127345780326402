import HttpClient from '@/services/HttpClient';

export default class InvoiceService {

	constructor(token,podUrl) {
		this.token = token;
		this.podUrl = podUrl;
		this.httpClient = new HttpClient(podUrl, false);
	}

	getSignedUrl(fileName) {
		return this.httpClient
			.get(`/api/docs/invoice/files`, {
				params: {
					token: this.token,
					fileName: fileName
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	downloadInvoice(){
		return this.httpClient
			.get(`/api/docs/invoice/download`, {
				params: {
					token: this.token,
				},
				responseType: 'blob',
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getInvoiceItem(clientId, invoiceId, invoiceItemId) {
		return this.httpClient
			.get(`/api/docs/invoice/item/${invoiceItemId}`, {
				params: {
					token: this.token
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
