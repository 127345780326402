<template>
	<div @click="$emit('click', $event)" :style="`text-align: ${alignment}`">
		<v-btn
			class="elevation-0"
			:style="`font-size: var(--form-font-size) !important;`"
			:color="item.color"
			:x-small="buttonSettings.xSmall"
			:small="buttonSettings.small"
			:large="buttonSettings.large"
			:x-large="buttonSettings.xLarge"
			:outlined="buttonSettings.outlined"
			:block="buttonSettings.block"
			:text="buttonSettings.text"
			@click="handleClick"
		>
			<span :style="`color: ${item.textColor}`">{{ item.text }}</span>
		</v-btn>
	</div>
</template>

<script>
export default {
		name: 'Button',

		props: ['disabled','item'],

		mixins: [],

		data: function() {
			return {
				template: null,
			};
		},

		methods: {
			handleClick: function(){
				if(!this.disabled) {
					window.open(this.item.redirectUrl, '_blank');
				}
			},
		},

		computed: {
			alignment: function(){
				if(this.item.alignment === 'block'){
					return 'unset';
				}else{
					return this.item.alignment;
				}
			},

			buttonSettings: function() {
				return {
					xSmall: this.item.size === 'X-Small',
					small: this.item.size === 'Small',
					large: this.item.size === 'Large',
					xLarge: this.item.size === 'X-Large',
					outlined: this.item.style === 'Outlined',
					block: this.item.alignment === 'block',
					text: this.item.style === 'Text'
				};
			},
		},
	};
</script>

<style scoped></style>
