<template>
  <div class="fill-height column-format">
    <h-header>
      <template v-slot:filter>
        <div class="row-format" style="flex-wrap: wrap">
          <v-text-field
              dense
              hide-details
              prepend-icon="$filter"
              :placeholder="$t('global.filter')"
              style="font-size:14px; width:200px"
              v-model="filter"
              clearable
              @change="handleFilterChange"
          ></v-text-field>
          <v-select
              clearable
              dense
              hide-details
              v-model="dateFilter"
              :items="dateOptions"
              :placeholder="$t('global.date')"
              class="ml-3"
              style="width:200px"
              @change="handleDateFilterChange"
          ></v-select>
        </div>
      </template>
    </h-header>
    <div class="show-scrollbar">
      <v-data-table
          :headers="headers"
          :items="combinedList"
          :disable-pagination="true"
          :hide-default-footer="true"
          :items-per-page="-1"
          fixed-header
          style="cursor: pointer;  height: calc(var(--vh) - 150px)"
          @click:row="routeClick($event)"
      >
        <template v-slot:item.formData.firstName="{ item }">
					<span v-if="item.formData"
          >{{ item.formData.firstName }} {{ item.formData.lastName }} &lt;{{ item.formData.email }}&gt;</span
          >
          <span v-else>--</span>
        </template>

        <template v-slot:item.type="{ item }">
          <div class="font-12 status-submitted" v-if="item.type === 'SUBMITTED'">
            {{ $t('forms.submissions.complete') }}
          </div>
          <div class="font-12 status-requested" v-else>{{ $t('forms.submissions.incomplete') }}</div>
        </template>

        <template v-slot:item.submittedAt="{ item }">
					<span v-if="item.submittedAt">{{
              DateTime.fromISO(item.submittedAt).toLocaleString(DateTime.DATETIME_MED)
            }}</span>
          <span v-else>--</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
	import FormService from '@/modules/forms/FormService';
	import { DateTime } from 'luxon';
	import FormResultViewer from '@/modules/forms/FormResultViewer';
	import FormRequestViewer from '@/modules/forms/FormRequestViewer';
  import HHeader from '@/components/HHeader';
  import FilterHelpers from '@/utils/FilterHelpers';

	export default {
		name: 'FormSubmissionsList',

		props: [],

		components: {HHeader},

		data: function() {
			return {
				DateTime: DateTime,
				submissions: [],
				requests: [],
				formService: new FormService(),

        filter: null,
        dateFilter: null,
        earliest: null,
        latest: null,
        dateOptions: FilterHelpers.getDateOptions([
          'this-month',
          'last-month',
          'last-last-month',
          'past-60-days',
          'past-90-days',
          'past-6-months',
          'past-12-months',
          'this-year',
          'last-year',
        ]),
			};
		},

		mounted() {
      this.getDateFilter();
			this.initData();
		},

		beforeDestroy() {},

		methods: {
      handleFilterChange(event) {
        this.setFilter(event);
      },

      handleDateFilterChange(event) {
        this.setDateFilter(event);
      },

      getDateFilter: function(){
        let dateFilter = localStorage.getItem('FORM_DATE_FILTER');

        if(dateFilter){
          this.dateFilter = JSON.parse(dateFilter);
        }else{
          this.setDateFilter('past-60-days');
        }
      },

      setDateFilter: function(filter){
        this.dateFilter = filter;
        localStorage.setItem('FORM_DATE_FILTER',JSON.stringify(filter));
      },

      setEarliestAndLatest: function() {
        if (this.dateFilter) {
          let helper = FilterHelpers.getEarliestAndLatest(this.dateFilter, false);
          this.earliest = helper.earliest;
          this.latest = helper.latest;
        } else {
          this.earliest = null;
          this.latest = null;
        }
      },

			initData: function() {
				this.getFormSubmissions();
				this.getFormRequests();
			},

			routeClick: function(item) {
				if (item.type === 'SUBMITTED') {
					this.openSubmission(item);
				} else {
					this.openRequest(item);
				}
			},

			openSubmission: function(submission) {
				let binding = {
					submission: submission,
				};
				this.$store.state.globalModalController.openModal(FormResultViewer, binding, true, true);
			},

			openRequest: function(request) {
				let binding = {
					request: request,
				};
				this.$store.state.globalModalController.openModal(FormRequestViewer, binding, true, true).then((res) => {
					if (res) {
						this.initData();
					}
				});
			},

			getFormSubmissions: function() {
				this.formService.getFormSubmissionList().then((res) => {
					this.submissions.splice(0);
					this.submissions.push(...res.data);
				});
			},

			getFormRequests: function() {
				this.formService.getFormRequestList().then((res) => {
					this.requests.splice(0);
					this.requests.push(...res.data);
				});
			},
		},

    watch: {
      dateFilter: function(){
        this.setEarliestAndLatest();
      }
    },

		computed: {
			combinedList: function() {
				let result = [];

				this.submissions.forEach((s) => {
					s.type = 'SUBMITTED';
          s.contactName = s.formData?.firstName + ' ' + s.formData?.lastName + s.formData?.email;
					result.push(s);
				});

				this.requests.forEach((r) => {
					r.type = 'ACTION-REQUIRED';
					r.formName = r.name;
          r.contactName = '';
					result.push(r);
				});

				result.sort((a, b) => {
					if (!a.submittedAt) return -1;
					if (!b.submittedAt) return 1;

					// Otherwise, compare the dates (descending order)
					return b.submittedAt.localeCompare(a.submittedAt);
				});

        return result.filter(r => {
          if(this.filter){
            return ((r.formName && r.formName.toLowerCase().includes(this.filter.toLowerCase())) || r.contactName.toLowerCase().includes(this.filter.toLowerCase()));
          }else{
            return true;
          }
        }).filter(r => {
          if(!r.submittedAt || !this.earliest){
            return true;
          }else{
            let date = DateTime.fromISO(r.submittedAt);
            return (date >= this.earliest && date <= this.latest);
          }
        });
			},

			headers: function() {
				return [
					{ text: this.$t('forms.submissions.name'), value: 'formName' },
					{ text: this.$t('forms.submissions.contact'), value: 'formData.firstName' },
					{ text: this.$t('global.submitted'), value: 'submittedAt' },
					{ text: this.$t('invoices.status.heading'), value: 'type' },
				];
			},
		},
	};
</script>

<style lang="scss">
	.status-submitted {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--v-success_10-base);
		color: var(v-success-base);
		border-radius: 4px;
	}

	.status-requested {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--v-alert_10-base);
		color: var(--v-alert-base);
		border-radius: 4px;
	}
</style>

<style scoped lang="scss"></style>
