import axios from 'axios';
import qs from 'qs';
import store from '@/store';

const getClient = () => {
	const options = {
		baseURL: process.env.VUE_APP_BASE_AUTH_URL,
		withCredentials: true,
	};

	const client = axios.create(options);
	return client;
};

export default {
	authWithCredentials(accountId, email, password){
		const request = {
			accountId,
			email,
			password
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/portal/credentials', qs.stringify(request), config)
			.then((response) => {
				console.log('Refresh Token: ' + response.data.refreshToken);
				localStorage.setItem(store.getters.getAccountId + '-RefreshToken', response.data.refreshToken);
				return Promise.resolve(response.data);
			})
			.catch((error) => Promise.reject(error));
	},

	requestMagicLink(accountId, email, baseUrl) {
		const request = {
			accountId,
			email,
			baseUrl,
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/portal/requestMagicLink', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	magicToken(token) {
		const request = {
			token,
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/auth/portal/magicToken', qs.stringify(request), config)
			.then((response) => {
				console.log('Refresh Token: ' + response.data.refreshToken);
				localStorage.setItem(store.getters.getAccountId + '-RefreshToken', response.data.refreshToken);
				return Promise.resolve(response.data);
			})
			.catch((error) => Promise.reject(error));
	},

	logout() {
		let refreshToken = localStorage.getItem(store.getters.getAccountId + '-RefreshToken');

		let config = {
			headers: {
				Authorization: 'Refresh ' + refreshToken,
			},
		};

		return getClient()
			.post('/auth/portal/logout', null, config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error))
			.finally(() => {
				localStorage.removeItem(store.getters.getAccountId + '-RefreshToken');
				store.commit('logout');
			});
	},

	refreshToken(force = false) {
		let refreshToken = localStorage.getItem(store.getters.getAccountId + '-RefreshToken');

		let config = {
			headers: {
				Authorization: 'Refresh ' + refreshToken,
			},
		};

		return getClient()
			.post(`/auth/portal/refresh?force=${force}`, null, config)
			.then((response) => {
				localStorage.setItem(store.getters.getAccountId + '-RefreshToken', response.data.refreshToken);
				return Promise.resolve(response.data);
			})
			.catch((error) => Promise.reject(error));
	},

	getProfile() {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.get('/auth/portal/me', config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	getCustomPages() {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.get('/auth/portal/pages', config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	getCustomEmbed() {
		const config = {
			headers: {
				Authorization: 'Bearer ' + store.state.accessToken,
			},
		};

		return getClient()
			.get('/auth/portal/embed', config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},

	getDomainSettings(domain) {
		return getClient()
			.get('/domains', {
				params: {
					domain: domain,
				},
			})
			.then((response) => Promise.resolve(response.data))
			.catch((error) => Promise.reject(error));
	},
};
