<template>
  <div>
    <v-container class="pa-0 ma-0" style="max-width: 225px; overflow: hidden;">
      <v-row dense>
        <v-col cols="12" class="text-left pa-4">
          <settings-link :label="$t('nav.update-password')" select-value="password" @selected="updatePassword()" icon="lock"></settings-link>
          <settings-link :label="$t('nav.logout')" select-value="logout" @selected="confirmLogout()" icon="logout"></settings-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AuthService from '@/modules/auth/AuthService';
import PodService from '@/services/PodService';
import SettingsLink from "@/views/nav/settings/SettingsLink";
import ConfirmModal from "@/components/ConfirmModal";
import UpdatePassword from '@/modules/auth/UpdatePassword';

export default {
  name: 'UserMenu',

  components: { SettingsLink },

  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    updatePassword(){
      this.$store.state.globalModalController.openModal(UpdatePassword, { }).then((res) => console.log(res));
    },

    confirmLogout() {
      let binding = {
        icon: 'logout',
        severity: 'info',
        headingText: this.$t('nav.logout-confirm'),
      }
      this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
        if(res){
          this.doLogout();
        }
      })
    },

    doLogout() {
      this.dialog = false;
      try {
        window.google.accounts.id.disableAutoSelect();
      }catch(err){
        console.log(err);
      }

      AuthService.logout()
          .then(() => {
            console.log('Logout Success');
            this.podLogout();
          })
          .catch((err) => {
            console.log(err);
            this.podLogout();
          });
    },

    podLogout() {
      let podService = new PodService();
      podService
          .clearCookies()
          .then(() => {
            this.finalizeLogout();
          })
          .catch(() => {
            this.finalizeLogout();
          });
    },

    finalizeLogout() {
      this.$store.commit('logOut');
      location.reload();
    },
  },

  watch: {

  },

  computed: {


  },
};
</script>

<style lang="scss">

</style>
