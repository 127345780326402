<script>
	import { Pie, mixins } from 'vue-chartjs';
	const { reactiveProp } = mixins;

	export default {
		extends: Pie,
		mixins: [reactiveProp],
		props: {
			chartData: { type: Object, required: true },
			options: { type: Object, required: true },
		},

		data() {
			return {};
		},

		mounted() {
			this.renderChart(this.chartData, this.options);
		},

		beforeDestroy() {},

		methods: {},

		computed: {},
		watch: {},
	};
</script>

<style lang="scss" scoped></style>
