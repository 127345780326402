<template>
	<div class="text-left" @click="$emit('click', $event)" v-if="isConditionMet">
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
			<div class="form-question">{{ item.question }} <span v-if="item.required" class="font-red brand-medium">*</span>  <v-icon v-if="showConditionalHelper" small color="gray_50">account_tree</v-icon></div>
			<div
				:class="`${item.formatVertically ? 'column-format' : 'row-format'} gap-2 mb-3 mt-n1`"
				style="flex-wrap: wrap"
				:key="refreshKey"
			>
				<v-checkbox
					:disabled="disabled"
					class="form-checkbox form-input mb-n3"
					v-for="(option, index) in item.options"
					:key="index"
					:value="option"
					v-model="selection"
					:label="option"
					dense
					hide-details
					:rules="item.required ? checkboxInputRequired : []"
					@change="handleChange()"
				></v-checkbox>
			</div>
		</v-form>
	</div>
</template>

<script>
	import RendererMixin from '@/modules/forms/v2/schema/RendererMixin';

	export default {
		name: 'Checkbox',

		props: ['disabled', 'item', 'styles', 'index','results', 'builder','container'],

		mixins: [RendererMixin],

		data: function () {
			return {
				refreshKey: 1,
				selection: [],
			};
		},

		mounted() {
			let currentResult = this.getCurrentResult(this.item.id,this.results);
			if(currentResult){
				this.selection = currentResult;
			}
		},

		beforeDestroy() {},

		methods: {
			handleChange: function () {
				if(this.selection.length) {
					this.$emit('input', {item: this.item, value: this.selection, index: this.index,  container: this.container});
				}else{
					this.$emit('input', {item: this.item, value: null, index: this.index, container: this.container});
				}
			},
		},

		computed: {
			checkboxInputRequired() {
				return [this.selection.length > 0 || 'Required'];
			},
		},

		watch: {
			'item.options': {
				deep: true,
				handler: function () {
					this.refreshKey++;
				},
			},
		},
	};
</script>

<style lang="scss">
	.form-checkbox {
		.v-label {
			color: var(--font-color)!important;
		}
		.v-input--selection-controls__input {
			margin-right: 0px !important;
		}
	}
</style>
