import { render, staticRenderFns } from "./TextInput.vue?vue&type=template&id=1f007220&scoped=true"
import script from "./TextInput.vue?vue&type=script&lang=js"
export * from "./TextInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f007220",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VForm,VTextarea})
