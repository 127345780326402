<template>
	<v-container fluid class="pa-0 ma-0 selector_image">
		<v-row dense>
			<v-col cols="12" v-if="editMode" align="center">
				<v-slider
					v-if="signedUrl !== null"
					min="0"
					max="100"
					thumb-label
					style="width: 400px;"
					v-model="lScale"
					@end="sliderChanged"
				></v-slider>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12">
				<div class="baseContainer">
					<div class="imageContainer" :style="calculatedImageWidth">
						<div v-if="templateMode || (editMode && signedUrl !== null)" class="deleteButton">
							<v-btn small icon class="deleteIcon" @click="confirmDeleteFile()"
							><v-icon size="20">$delete</v-icon></v-btn
							>
						</div>

						<img v-if="signedUrl !== null" :src="signedUrl" class="image" />

						<vue-dropzone
							v-if="editMode && lFile === null"
							ref="fileDropzone"
							id="dropzone"
							v-on:vdropzone-success="uploadSuccess"
							v-on:vdropzone-error="uploadFailure"
							v-on:vdropzone-sending="addFileUploadAuthHeader"
							:use-custom-slot="true"
							:options="dropzoneOptions"
						>
							<div>
								<v-icon>$vuetify.icons.upload</v-icon>
								<span class="ml-2" style="font-size: 0.8em;">{{ $t('proposal.image.upload') }}</span>
							</div>
						</vue-dropzone>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-dialog v-model="confirmDelete" persistent max-width="290">
			<div class="brand-regular" style="background-color: var(--v-white-base);">
				<v-btn class="close-dialog" icon rounded @click.prevent="cancelDeleteFile()">
					<v-icon size="20">$close</v-icon>
				</v-btn>
				<div class="modal-padding">
					<div class="modal-title text-center">
						<span class="pr-1">
							<v-icon color="warning" class="mt-n1" size="24">$alert</v-icon>
						</span>
						{{ $t('global.confirm') }}
					</div>
					<div class="modal-subtitle text-center mx-3">{{ $t('proposal.image.delete') }}</div>
				</div>
				<div class="modal-footer">
					<v-btn class="mr-1 super-action" width="120" @click="cancelDeleteFile()">{{ $t('global.cancel') }}</v-btn>
					<v-btn class="ml-1 primary-action" width="120" @click="deleteFile()">{{ $t('global.delete') }}</v-btn>
				</div>
			</div>
		</v-dialog>
	</v-container>
</template>

<script>
	import vueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'ImageBlock',

		props: [
			'file',
			'scale',
			'id',
			'editMode',
			'clientId',
			'proposalId',
			'blockType',
			'proposalService',
			'templateService',
			'templateMode',
		],

		components: {
			vueDropzone,
		},

		mounted() {
			if (this.file) {
				this.setSignedUrl(this.file);
			}
		},

		data: function() {
			return {
				lFile: this.file,
				lScale: this.scale,
				signedUrl: null,
				confirmDelete: false,
			};
		},

		methods: {
			sliderChanged: function() {
				this.notifyChange();
			},

			notifyChange: function() {
				this.$emit('changed', {
					id: this.id,
					blockType: this.blockType,
					file: this.lFile,
					scale: this.lScale,
				});
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			uploadSuccess: function(file, response) {
				this.$refs.fileDropzone.removeFile(file);
				this.setSignedUrl(response);
				this.lFile = response;
				this.notifyChange();
			},

			uploadFailure: function(file, message) {
				this.$store.commit('error', this.$t('error.error-uploading') + message);
				this.$refs.fileDropzone.removeFile(file);
			},

			setSignedUrl: function(s3File) {
				if (this.templateMode) {
					this.templateService.getSignedUrl(this.proposalId, s3File.fileName).then((res) => {
						this.signedUrl = res.data.signedUrl;
					});
				} else {
					this.proposalService.getSignedUrl(this.clientId, this.proposalId, s3File.fileName).then((res) => {
						this.signedUrl = res.data.signedUrl;
					});
				}
			},

			confirmDeleteFile: function() {
				this.confirmDelete = true;
			},

			cancelDeleteFile: function() {
				this.confirmDelete = false;
			},

			deleteFile: function () {
				this.deleteFileInternal()
						.then(() => {
							this.lFile = null;
							this.signedUrl = null;
							this.notifyChange();
							this.cancelDeleteFile();
						})
						.catch((err) => {
							this.$store.commit('error', this.$t('error.error-deleting') + err.response.data.message);
							this.cancelDeleteFile();
						});
			},

			deleteFileInternal: function () {
				if (this.templateMode) {
					return this.templateService.deleteFileAttachment(this.proposalId, this.lFile.fileName);
				} else {
					return this.proposalService.deleteFileAttachment(this.clientId, this.proposalId, this.lFile.fileName);
				}
			},
		},

		computed: {
			calculatedImageWidth: function() {
				return `width: ${this.lScale}%`;
			},

			dropzoneOptions: function() {
				let theUrl;

				if (this.templateMode){
					theUrl = `${this.$store.getters.getBaseUrl}/templates/proposals/${this.proposalId}/files`;
				}else{
					theUrl = `${this.$store.getters.getBaseUrl}/clients/${this.clientId}/proposals/${this.proposalId}/files`;
				}

				return {
					url: theUrl,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},
		},
	};
</script>

<style scoped lang="scss">
	.baseContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0px;
		padding: 0px;
	}

	.imageContainer {
		margin-left: 5px;
		margin-right: 5px;
		overflow: hidden;
		position: relative;
		.deleteButton {
			position: absolute;
			top: 8px;
			right: 8px;
			opacity: 0.5;
			&:hover {
				opacity: 1;
			}
			button {
				background-color: var(--v-white-base);
				z-index: 100;
			}
		}
	}

	.image {
		width: 100%;
	}
</style>
