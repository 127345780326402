import { render, staticRenderFns } from "./PackageEditor.vue?vue&type=template&id=77819d04&scoped=true"
import script from "./PackageEditor.vue?vue&type=script&lang=js"
export * from "./PackageEditor.vue?vue&type=script&lang=js"
import style0 from "./PackageEditor.vue?vue&type=style&index=0&id=77819d04&prod&lang=scss"
import style1 from "./PackageEditor.vue?vue&type=style&index=1&id=77819d04&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77819d04",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCheckbox,VIcon,VSelect,VTextField})
