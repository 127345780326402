<template>
	<div id="signature-dialog">
		<v-btn icon class="close-dialog" @click="cancelSign()">
			<v-icon>$close</v-icon>
		</v-btn>

		<div class="pa-4">
			<div class="modal-title">{{ $t('proposal.signature.signature') }}</div>

			<v-tabs @change="tabChanged" slider-size="4" slider-color="blue_50">
				<v-tab>{{ $t('proposal.signature.type-signature') }}</v-tab>
				<v-tab>{{ $t('proposal.signature.draw-signature') }}</v-tab>
				<v-tab-item>
					<div class="signature-block mt-4 mb-3" :style="signatureFont">
						<div class="signature">
							<input placeholder="Your Name Here" v-model="textSignature" type="text" class="keyboardInput" />
						</div>
					</div>

					<v-select
						class="h-outline"
						hide-details
						persistent-placeholder
						:label="$t('proposal.signature.signature-font')"
						:items="fonts"
						v-model="signatureObj.font"
					>
						<template slot="item" slot-scope="data">
							<span :style="`font-family: ${data.item}, cursive; font-size:30px`">{{ $t('proposal.signature.signature') }}</span>
						</template>
						<template slot="selection" slot-scope="data">
							<span :style="`font-family: ${data.item}, cursive; font-size:30px`">{{ $t('proposal.signature.signature') }}</span>
						</template>
					</v-select>

					<v-text-field
						class="h-outline"
						hide-details
						persistent-placeholder
						:disabled="disableInput"
						v-model="signatureObj.signerTitle"
						:label="$t('proposal.signature.title')"
					></v-text-field>
				</v-tab-item>

				<v-tab-item>
					<div class="signature-block mt-4 mb-3">
						<div class="instructions">{{ $t('proposal.signature.draw-in-box') }}</div>
						<div class="signature" :style="'background-color: var(--v-gray_10-base);' + signatureFont">
							<vue-signature-pad width="476px" height="150px" ref="signaturePad"></vue-signature-pad>
						</div>
					</div>

					<v-text-field
						class="h-outline"
						hide-details
						persistent-placeholder
						:disabled="disableInput"
						v-model="signatureObj.signerName"
						label="Full Name"
					></v-text-field>
					<v-text-field
						class="h-outline"
						hide-details
						persistent-placeholder
						:disabled="disableInput"
						v-model="signatureObj.signerTitle"
						label="Title"
					></v-text-field>
				</v-tab-item>
			</v-tabs>
		</div>

		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" @click="cancelSign()">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="super-action ml-1" @click="confirmSign()">{{ $t('proposal.signature.sign-accept') }}</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SignatureDialog',

		props: ['proposalService', 'signatureObject', 'mode', 'clientMode'],

		components: {},

		data: function() {
			return {
				signatureObj: this.signatureObject,
				signatureType: 'Keyboard',
				fontMenu: false,
				textSignature: null,

				fonts: ['Arizonia', 'Charmonman', 'Herr Von Muellerhoff', 'Reenie Beanie'],

				tinyMceConfig: {
					menubar: false,
					inline: true,
					plugins: [],
					toolbar: [],
					forced_root_block: '',
					setup: function(ed) {
						ed.on('keyDown', function(event) {
							if (event.keyCode == 13) {
								event.preventDefault();
								event.stopPropagation();
								return false;
							}
						});
					},
				},
			};
		},

		methods: {
			tabChanged: function(tabNumber) {
				if (tabNumber === 0) {
					this.signatureType = 'Keyboard';
				} else {
					this.signatureType = 'Pad';
				}
			},

			cancelSign: function() {
				if (this.$refs.signatureEditor) {
					this.$refs.signatureEditor.editor.setContent('');
				}

				if (this.$refs.signaturePad) {
					this.$refs.signaturePad.clearSignature();
				}

				this.$emit('cancel');
			},

			confirmSign: function() {
				if (this.signatureType === 'Keyboard') {
					let keyboardSignature = this.textSignature;

					if (this.$validations.isEmpty(keyboardSignature)) {
						this.$store.commit('error', this.$t('error.signature'));
						return;
					}

					if (!this.signatureObj.signerLocked) {
						this.signatureObj.signerName = keyboardSignature;
					}

					this.signatureObj.type = 'Keyboard';
					this.signatureObj.signature = keyboardSignature;
				} else {
					let svgSignature = this.$refs.signaturePad.saveSignature('image/svg+xml');

					if (svgSignature.isEmpty) {
						this.$store.commit('error', this.$t('error.signature'));
						return;
					}

					let tmpSignature = atob(svgSignature.data.split(',')[1]);
					tmpSignature = tmpSignature.replace('height', 'invalidate-height');
					tmpSignature = tmpSignature.replace('width', 'invalidate-width');

					this.signatureObj.type = 'Pad';
					this.signatureObj.signature = btoa(tmpSignature);
				}

				this.signatureObj.dts = this.$DateTime.utc().toISO();
				this.finalizeSign();
			},

			finalizeSign() {
				this.$emit('signed', this.signatureObj);
			},
		},

		computed: {
			signatureFont: function() {
				return `font-family: ${this.signatureObj.font}, cursive;`;
			},

			disableInput: function() {
				if (this.mode === 'them' && this.clientMode === true && this.signatureObj.signerLocked) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style lang="scss">
	#signature-dialog {
		background-color: var(--v-white-base);
    max-width: 580px;
		.v-slide-group__content.v-tabs-bar__content {
			border-bottom: 1px solid var(--v-gray_50-base) !important;
		}
		.signature-block {
			background-color: var(--v-gray_20-base);
			border-radius: 4px;
			border: 1px solid var(--v-gray_50-base);
			height: 224px;
			display: flex;
			align-items: center;
			justify-content: center;
			> div.signature {
				font-size: 40px;
				border-bottom: 1px solid var(--v-gray_50-base);
			}
			position: relative;
			.instructions {
				position: absolute;
				top: 4px;
				left: 8px;
        font-weight: 500;
        font-size: 12px;
			}
		}
		.keyboardInput {
			width: 337px;
			outline: none;
			text-align: center;
		}

		.v-tab {
			text-transform: none;
			background-color: transparent;
			&:hover {
				background-color: transparent;
			}
		}
		.v-tab--active {
			background-color: var(--v-white-base) !important;
			border: none;
		}
	}
</style>
