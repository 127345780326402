export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $validations: {
                get() {
                    return {
                        isEmpty: function(val) {
                            if (val === undefined || val === null || val.length === 0) {
                                return (true);
                            } else {
                                return (false);
                            }
                        }
                    };
                },
            },
        });

    }
}