<template>
  <div>
    <v-menu offset-x content-class="workspace-sub-menu" v-if="children.length">
      <template v-slot:activator="{ on }">
        <div class="settings-link row-format align-center pointer" v-on="on">
          <v-icon size="16" color="custom">{{icon}}</v-icon>
          <div class="ml-1">{{label}}</div>
          <v-icon small class="ml-auto" v-if="children.length">$chevronRight</v-icon>
        </div>
      </template>
      <div class="column-format">
        <settings-link v-for="child in children" :key="child.route" :label="child.label" :route="child.route" :icon="child.icon" :select-value="child.selectValue" @selected="$emit('selected',$event)"></settings-link>
      </div>
    </v-menu>

    <div class="settings-link row-format align-center pointer" v-else @click="handleClick">
      <v-icon size="16" color="custom">{{icon}}</v-icon>
      <div class="ml-1">{{label}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsLink",

  props: {
    pro: {type: Boolean, required: false, default: false},
    icon: { type: String, required: false},
    label: { type: String, required: true },
    route: { type: String, required: false },
    selectValue: {type: String, required: false},
    children: { type: Array, required: false, default: () => []},
  },

  components: {},

  data: function () {
    return {}
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    handleClick: function(){
      if(this.route){
        this.$router.push(this.route);
      }
      if(this.selectValue){
        this.$emit('selected',this.selectValue);
      }
    }
  },

  computed: {},

}
</script>

<style lang="scss">
.workspace-sub-menu {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
  box-shadow: none !important;
  border-radius: 8px !important;
  background-color: var(--v-white-base);
  padding: 4px 12px;
}
</style>

<style scoped lang="scss">
.settings-link {
  font-weight: 500;
  width: 100%;
  font-size: 14px;
  line-height: 14px;

  padding: 8px 0px;
  color: var(--v-custom-base);

  &:hover {
    color: var(--v-primary-base);
  }
}
</style>