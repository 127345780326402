import TicketList from '@/modules/tickets/TicketList';
import TicketDetail from '@/modules/tickets/TicketDetail';


export default [
	{
		path: '/requests',
		name: 'Requests',
		component: TicketList,
	},
	{
		path: '/requests/:id',
		name: 'Request detail',
		component: TicketDetail,
		props: (route) => ({
			id: route.params.id
		}),
	}

]