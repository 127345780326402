<template>
	<div>
		<div v-html="htmlHeader" class="pb-1 question"></div>
		<div v-html="htmlText" class="words"></div>
	</div>
</template>

<script>
	export default {
		name: 'TextBlock',
		props: ['htmlText', 'htmlHeader'],

		methods: {
			validate: function(){
				return true;
			}
		}
	};
</script>

<style scoped lang="scss">

</style>