import axios from 'axios';
import store from '@/store';
//import router from '@/router';
import AuthService from '../modules/auth/AuthService';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const refreshLogic = (failedRequest) => {
	return AuthService.refreshToken()
		.then((res) => {
			store.commit('setAccessToken', res);
			failedRequest.response.config.headers['Authorization'] = 'Bearer ' + res.authToken;
			return Promise.resolve();
		})
		.catch((err) => {
			console.log("Error processing refresh token",err);
			// AuthService.logout().finally(() => {
			// 	router.push('/login');
			// })
			return Promise.reject(err);
		});
};


const getClient = (baseUrl = null, useAccessToken = true) => {
	const options = {
		baseURL: baseUrl == null ? store.getters.getBaseUrl : baseUrl,
		withCredentials: true,
	};

	const client = axios.create(options);

	if(useAccessToken) {
		createAuthRefreshInterceptor(client, refreshLogic);
	}

	if (useAccessToken && store.state.accessToken) {
		client.interceptors.request.use(request => {
			request.headers['Authorization'] = `Bearer ${store.state.accessToken}`;
			return request;
		});
	}

	return client;
};



/**
 * Base HTTP Client
 */
export default class HttpClient{

	baseUrl;
	useAccessToken

	constructor(baseUrl = null, useAccessToken = true) {
		this.baseUrl = baseUrl;
		this.useAccessToken = useAccessToken;
	}

	// Provide request methods with the default base_url
	get(url, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken)
			.get(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	delete(url, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken)
			.delete(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	head(url, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken)
			.head(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	options(url, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken)
			.options(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	post(url, data = {}, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken)
			.post(url, data, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	put(url, data = {}, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken)
			.put(url, data, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	patch(url, data = {}, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken)
			.patch(url, data, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}
}
