import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class AgreementService {
	httpClient;
	proposal;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getAgreementList(){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/agreements`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
