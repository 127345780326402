<template>
	<div class="column-format" @click="$emit('click', $event)">
		<draggable
			:disabled="!active || !editMode || item.packages.length < 2"
			v-model="item.packages"
			:class="`${item.stacked ? 'column-format' : 'row-format'} gap-3`"
			:id="item.id"
			handle=".dragHandle"
			@start="onDragStart" @end="onDragEnd"
			:style="
				`padding-left: ${padding(item.pl)}px; padding-right: ${padding(item.pr)}px; padding-bottom: ${item.pb}px; padding-top: ${
					item.pt
				}px; ${agreement.styles.pageSize === 'RESPONSIVE' ? 'flex-wrap: wrap;' : 'flex-wrap:wrap'}`
			"
		>
			<div
				v-for="servicePackage in item.packages"
				:key="servicePackage.id"
				:style="`flex: 1 1 0; ${agreement.styles.pageSize === 'RESPONSIVE' ? 'min-width:300px' : 'min-width:300px'}`"
			>
				<service-package
					ref="servicePackage"
					:agreement="agreement"
					:services="item"
					:service-package="servicePackage"
					:mce-config="mceConfig"
					:active="active"
					:client-mode="clientMode"
					:edit-mode="editMode"
					:tokens="tokens"
					:signer="signer"
					:template-mode="templateMode"
					@click="$emit('click', $event)"
					@change="handleChange(servicePackage, $event)"
					@delete="deletePackage(servicePackage)"
				></service-package>
			</div>
		</draggable>

		<div class="row-format centered py-4" v-if="active && editMode" >
			<div class="row-format add-button align-center" style="min-width: 400px">
				<div class="new row-format centered" @click="addNewPackage()">
					<v-icon class="material-symbols-rounded" color="primary" size="20">add</v-icon>
					<div>Add package</div>
				</div>
				<v-menu close-on-content-click close-on-click nudge-top="100">
					<template v-slot:activator="{ on }">
						<div class="from-package row-format centered" v-on="on">
							<div>From template</div>
							<v-icon class="material-symbols-rounded" color="primary">arrow_drop_down</v-icon>
						</div>
					</template>
					<div class="more-menu show-scrollbar" style="max-height: 500px">
						<div
								v-for="template in packageTemplates"
								:key="template.id"
								class="more-menu-item font-14"
								@click="importTemplate(template)"
						>
							{{ template.name }}
						</div>
					</div>
				</v-menu>
			</div>
		</div>
	</div>
</template>

<script>
	import ServicePackage from '@/modules/agreements/schema/ServicePackage';
	import Draggable from 'vuedraggable';
	import {v4 as uuid4} from "uuid";
	import EmbeddedServices from "@/modules/agreements/schema/EmbeddedServices";
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'Services',

		props: ['agreement', 'item', 'mceConfig', 'editMode', 'clientMode', 'tokens', 'signer', 'templateMode','isMobile','pageWidth'],

		components: { ServicePackage, Draggable },

		mixins: [ResponsiveMixin],

		data: function() {
			return {
				embeddedServices: new EmbeddedServices(),
				packageTemplates: [],
			};
		},

		mounted() {
			if(this.editMode){
				this.getPackageTemplates();
			}
		},

		beforeDestroy() {},

		methods: {
			getPackageTemplates: function() {
				this.embeddedServices.getDeliverablePackages().then((res) => {
					this.packageTemplates.splice(0);
					this.packageTemplates.push(...res.data);
					this.packageTemplates.sort((a,b) => {
						if(a.name && b.name){
							return a.name.localeCompare(b.name);
						}else if(a.name){
							return -1;
						}else{
							return 1;
						}
					})
				});
			},

			importTemplate: function(template) {
				let newPackage = {
					id: uuid4(),
					packageName: template.name,
					description:
							'<h2>{{Package.Name}}</h2><br>Description that shows the price of the package<br>{{Package.Total}} {{Package.FeeLabel}}<br>',
					fees: template.feeSchedule,
					buttonText: 'Select',
					selected: false,
					defaultSelection: false,
					items: [],
				};

				template.deliverables.forEach((d) => {
					newPackage.items.push({
						id: uuid4(),
						description: d.name + '\n' + d.description,
						product: d.product,
						quantity: d.quantity,
						rate: d.product ? d.product.rate : 0,
						taxable: d.product ? d.product.taxable : false,
						relativeDueDate: true,
						dueDateRelative: d.dueDate,
					});
				});

				this.item.packages.push(newPackage);
				this.$emit('change', this.item);
			},


			addNewPackage: function() {
				let newPackage = {
					id: uuid4(),
					packageName: 'Sample package',
					description:
							'<h2>{{Package.Name}}</h2><br>Description that shows the price of the package<br>{{Package.Total}} {{Package.FeeLabel}}<br>',
					defaultSelection: false,
					selected: false,
					buttonText: 'Select',
					fees: {
						feeType: 'Fixed Price',
						amount: 500,
						retainerSchedule: 'Monthly',
						retainerTiming: 'Advanced',
						estimateMin: 0,
						estimateMax: 0,
						retainerOverage: 0,
					},
					items: [],
				};

				this.item.packages.push(newPackage);
				this.$emit('change', this.item);
			},

			onDragStart: function(){
				if(this.$refs.servicePackage.length) {
					for (let i = 0; i < this.$refs.servicePackage.length; i++) {
						this.$refs.servicePackage[i].setReadOnly(true);
					}
				}
			},

			onDragEnd: function(){
				this.$nextTick(() => {
					if(this.$refs.servicePackage.length) {
						for (let i = 0; i < this.$refs.servicePackage.length; i++) {
							this.$refs.servicePackage[i].setReadOnly(false);
						}
					}
				});
			},

			handleChange: function(servicePackage, update) {
				console.log(update);
				let ix = this.item.packages.findIndex((p) => p.id === servicePackage.id);
				let p = this.item.packages[ix];
				p.description = update.description;
				this.item.packages.splice(ix, 1, p);
				this.$emit('change', this.item);
			},

			deletePackage: function(servicePackage) {
				let ix = this.item.packages.findIndex((p) => p.id === servicePackage.id);
				this.item.packages.splice(ix, 1);
				this.$emit('change', this.item);
			},

			finalize: function() {
				return new Promise((resolve, reject) => {
					if (this.verifySelection) {
						if (!this.item.packages.find((p) => p.selected)) {
							document.getElementById(this.item.id).scrollIntoView({ block: 'start', behavior: 'smooth' });
							document.getElementById(this.item.id).classList.add('red-border');
							reject();
							return;
						}
					} else if (this.item.selectionRequired) {
						this.item.packages.forEach((p) => (p.selected = true));
					}
					document.getElementById(this.item.id).classList.remove('red-border');
					resolve();
				});
			},
		},

		computed: {
			verifySelection: function() {
				if (this.item.packages.length > 1 && this.item.selectionRequired) {
					return true;
				} else {
					return false;
				}
			},

			active: function() {
				return this.item.id === this.$store.state.agreementBuilderState.activeElementId;
			},
		},
	};
</script>

<style scoped lang="scss">
	.add-button {
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		cursor: pointer;
		min-height: 32px;
		height: 32px;
		font-size: 14px;
		font-weight: 500;
		color: var(--v-primary-base);
		background-color: var(--v-white-base);
	}

	.new {
		padding-left: 8px;
		padding-right: 8px;
		height: 100%;
		border-radius: 4px 0 0 4px;
		flex-grow: 1;

		&:hover {
			background-color: var(--v-gray_20-base);
		}
	}

	.from-package {
		border-left: 1px solid var(--v-gray_30-base);
		padding-right: 8px;
		padding-left: 8px;
		height: 100%;
		border-radius: 0 4px 4px 0;
		flex-grow: 1;
		&:hover {
			background-color: var(--v-gray_20-base);
		}
	}
</style>
