<template>
  <div class="fill-height column-format">
    <h-header></h-header>
    <v-data-table
        v-if="filteredProposalList.length"
        :items="filteredProposalList"
        :headers="headers"
        class="pointer"
        :hide-default-footer="true"
        height="calc(100vh - 150px)"
        fixed-header
        @click:row="openAgreement"
    >
      <template v-slot:item.date="{ item }">
        {{ DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_MED) }}
      </template>
      <template v-slot:item.dateCompleted="{ item }">
        <span v-if="item.dateCompleted">{{ DateTime.fromISO(item.dateCompleted).toLocaleString(DateTime.DATE_MED) }}</span>
        <span v-else>--</span>
      </template>
      <template v-slot:item.status="{ item }">
        <div v-html="formatStatus(item.status)"></div>
      </template>
      <template v-slot:item.lastEvent="{ item }">
        <div v-if="item.lastEvent">
          {{ item.lastEvent.event }} -
          {{ DateTime.fromISO(item.lastEvent.timestamp).toLocaleString(DateTime.DATETIME_MED) }}
        </div>
        <div v-else>--</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ProposalService from '@/modules/proposal/ProposalService';
import { DateTime } from 'luxon';
import ProposalDetails from '@/modules/proposal/ProposalDetails';
import HHeader from '@/components/HHeader.vue';
import AgreementService from '@/modules/agreements/AgreementService';

export default {
  name: 'ProposalList',

  components: {HHeader},

  mixins: [],

  data: function () {
    return {
      proposalService: new ProposalService(),
      agreementService: new AgreementService(),
      isReady: false,
      proposalList: [],
      agreementList: [],
      DateTime: DateTime,
    };
  },

  mounted() {
    this.getProposals();
    this.getAgreements();
  },

  beforeDestroy() {
  },

  methods: {
    getAgreements: function(){
      this.agreementService.getAgreementList().then((res) => {
        this.agreementList.splice(0, this.agreementList.length);
        this.agreementList.push(...res.data);
      })
    },

    getProposals: function () {
      this.proposalService.getProposalList().then((res) => {
        this.proposalList.splice(0, this.proposalList.length);
        this.proposalList.push(...res.data);
        this.isReady = true;
      });
    },

    openAgreement: function(agreement){
      if(agreement.version === 1){
        this.openProposal(agreement.id);
      }else{
        window.open(`/document/${agreement.id}${agreement.signer ? '.' + agreement.signer.id : ''}`,'_blank');
      }
    },

    openProposal: function (id) {
      let binding = {
        proposalId: id,
      }
      this.$store.state.globalModalController
          .openModal(ProposalDetails, binding, false, true)
          .then(() => this.getProposals())
    },

    sortByDate: function (a, b) {
      return b.date.localeCompare(a.date);
    },

    formatStatus(status) {
      let background, color;

      if (status === 'Signed' || status === 'Fully Executed') {
        background = '--v-success_10-base';
        color = '--v-success-base';
      } else if (status === 'Declined') {
        background = '--v-alert_10-base';
        color = '--v-alert-base';
      } else if (status === 'Sent') {
        background = '--v-warning_10-base';
        color = '--v-warning-base';
      } else {
        background = '--v-gray_20-base';
        color = '--v-gray_80-base';
      }

      if (status === 'Sent') {
        status = this.$t('proposals.status.ready-for-signature')
      } else if (status === 'Signed') {
        status = this.$t('proposals.status.signed')
      } else if (status === 'Fully Executed') {
        status = this.$t('proposals.status.fully-executed')
      } else if (status === 'declined') {
        status = this.$t('proposals.status.declined')
      }


      return `<div class="proposal-status" style="--color:var(${color});--background:var(${background})">${status}</div>`;
    },
  },

  watch: {},

  computed: {
    headers: function() {
      return [
        { text: 'Name', value: 'name' },
        { text: 'Date created', value: 'date' },
        { text: 'Date signed', value: 'dateCompleted' },
        { text: 'Status', value: 'status' }
      ];
    },

    normalizedProposals: function() {
      let result = [];

      this.proposalList.forEach((p) => {
        result.push({
          id: p.id,
          name: p.coverPage?.heading,
          client: p.client,
          date: p.dateCreated,
          dateCompleted: p.dateSigned,
          status: p.proposalStatus,
          type: p.proposalType,
          lastEvent: p.lastEvent,
          version: 1,
        });
      });

      return result;
    },

    normalizedAgreements: function() {
      let result = [];

      this.agreementList.forEach((a) => {
        result.push({
          id: a.id,
          name: a.name,
          client: a.client,
          date: a.dateCreated,
          dateCompleted: a.dateCompleted,
          status: a.fullyExecuted ? 'Fully Executed' : a.status,
          type: 'Agreement',
          lastEvent: a.lastEvent,
          version: 2,
          signer: a.signers.find(s => s.email === this.$store.state.loggedInUser.email)
        });
      });

      return result;
    },

    filteredProposalList: function() {
      let result = [];
      result.push(...this.normalizedProposals);
      result.push(...this.normalizedAgreements);
      result.sort(this.sortByDate);
      return result;
    }
  },
};
</script>

<style lang="scss">
.proposal-status {
  width: fit-content;
  padding: 4px 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background-color: var(--background);
  color: var(--color);
  border-radius: 4px;
  font-size: 12px;
}

.proposal-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.proposal-card {
  width: 260px;
  height: 310px;
  border: 1px solid var(--v-gray_50-base);
  border-radius: 4px;
  box-shadow: 0px 1px 0px var(--v-gray_50-base);
  cursor: pointer;

  &:hover {
    background-color: var(--v-sidebar-base);
    transition: all 500ms ease;
  }
}

.image-preview {
  width: 259px;
  height: 140px;
  background-size: cover;
  border-radius: 4px 4px 0px 0px;
}


</style>
