<template>
	<div class="text-left" @click="$emit('click',$event)" v-if="isConditionMet">
		<div class="form-question">{{ item.question }} <span v-if="item.required" class="font-red brand-medium">*</span>  <v-icon v-if="showConditionalHelper" small color="gray_50">account_tree</v-icon></div>
		<div v-if="fileResult" class="row-format gap-2">
			<v-chip small label color="primary" v-for="file in fileResult" :key="file.fileName" class="pointer" @click="downloadFile(file)">
				{{ file.fileName }}
			</v-chip>
		</div>
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form" v-else>
			<v-file-input
				class="file-upload"
				:disabled="disabled"
				hide-details
				dense
				:multiple="item.multi"
				:placeholder="item.placeholder"
				:outlined="outlined"
				:solo="solo"
				:filled="filled"
				append-icon="attach_file"
				:accept="accept"
				v-model="files"
				:rules="item.required ? fileInputRequired : []"
				@change="handleChange"
			>
				<template v-slot:selection="{ text }">
					<v-chip small label color="primary">
						{{ text }}
					</v-chip>
				</template>
			</v-file-input>
		</v-form>
	</div>
</template>

<script>
	import RendererMixin from "@/modules/forms/v2/schema/RendererMixin";
	import axios from "axios";

	export default {
		name: 'FileInput',

		props: ['disabled', 'item', 'styles','index','results','builder','container'],

		components: {},

		mixins: [RendererMixin],

		data: function() {
			return {
				files: this.item.multi ? [] : null,
				fileResult: null
			};
		},

		mounted() {
			let currentResult = this.getCurrentResult(this.item.id,this.results);
			if(currentResult){
				this.fileResult = currentResult;
			}
		},

		beforeDestroy() {},

		methods: {
			handleChange: function() {
				if(this.item.multi && this.files.length){
					this.$emit('input', {item: this.item, value: this.files, index: this.index, container: this.container});
					return;
				}else if(!this.item.multi && this.files){
					this.$emit('input', {item: this.item, value: [this.files], index: this.index, container: this.container});
					return;
				}

				this.$emit('input', {item: this.item, value: null, index: this.index, container: this.container});
			},

			downloadFile: function (file) {
				if(this.isOpenableFile(file)){
					window.open(file.signedUrl, '_blank');
				}else {
					axios({
						url: file.signedUrl, //your url
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.fileName); //or any other extension
						document.body.appendChild(link);
						link.click();
					});
				}
			},

			isOpenableFile(s3File) {
				if (
						s3File.fileType === 'JPG' ||
						s3File.fileType === 'PNG' ||
						s3File.fileType === 'GIF' ||
						s3File.fileType === 'PDF'
				) {
					return true;
				} else {
					return false;
				}
			},
		},

		computed: {
			fileInputRequired() {
				if(this.item.multi){
					return [this.files.length > 0 || 'Required'];
				}else{
					return [this.files != null || 'Required'];
				}
			},

			accept: function(){
				if(this.item.fileTypes && this.item.fileTypes.length){
					return this.item.fileTypes.join(", ");
				}else {
					return "*/*";
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.file-upload .v-input__prepend-outer {
		display: none !important;
	}
</style>
