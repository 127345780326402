<template>
	<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
		<div class="column-format gap-2">
			<div class="row-format gap-2" style="flex-wrap: wrap" v-if="showBusiness || showWebsite">
				<v-text-field
					hide-details
					dense
					outlined
					class=""
					style="min-width: 250px"
					label="Business / Organization name"
					v-if="showBusiness"
					v-model="businessName"
					:disabled="previewMode"
					:rules="required ? inputRequired : []"
					@input="handleChange"
				></v-text-field>
				<v-text-field
					hide-details
					dense
					outlined
					class=""
					style="min-width: 250px"
					:label="$t('contact.website')"
					v-model="website"
					v-if="showWebsite"
					:disabled="previewMode"
					:rules="required ? inputRequired : []"
					@input="handleChange"
				></v-text-field>
			</div>

			<div class="row-format gap-2" style="flex-wrap: wrap" v-if="showContact">
				<v-text-field
					label="First name"
					hide-details
					dense
					outlined
					class=""
					style="min-width: 250px"
					v-model="firstName"
					:rules="required ? inputRequired : []"
					:disabled="previewMode"
					@input="handleChange"
				></v-text-field>
				<v-text-field
					label="Last name"
					hide-details
					dense
					outlined
					class=""
					style="min-width: 250px"
					v-model="lastName"
					:disabled="previewMode"
					:rules="required ? inputRequired : []"
					@input="handleChange"
				></v-text-field>
			</div>
			<div class="row-format gap-2" style="flex-wrap: wrap" v-if="showEmail || showPhone">
				<v-text-field
					label="Email"
					hide-details
					dense
					outlined
					class=""
					style="min-width: 250px"
					v-model="email"
					v-if="showEmail"
					:disabled="previewMode"
					:rules="required ? emailRequired : []"
					@input="handleChange"
				></v-text-field>
				<v-text-field
					hide-details
					dense
					outlined
					class=""
					style="min-width: 250px"
					label="Phone"
					v-model="phone"
					v-if="showPhone"
					:disabled="previewMode"
					:rules="required ? inputRequired : []"
					@input="handleChange"
				></v-text-field>
			</div>
			<div v-if="showAddress" class="column-format gap-2">
				<div class="row-format gap-2" style="flex-wrap: wrap">
					<v-text-field
						hide-details
						dense
						outlined
						class=""
						style="min-width: 250px"
						v-model="address1"
						:label="$t('contact.address1')"
						:disabled="previewMode"
						:rules="required ? inputRequired : []"
						@input="handleChange"
					></v-text-field>
					<v-text-field
						hide-details
						dense
						outlined
						class=""
						style="min-width: 250px"
						v-model="address2"
						:label="$t('contact.address2')"
						:disabled="previewMode"
						@input="handleChange"
					></v-text-field>
				</div>

				<div class="row-format gap-2" style="flex-wrap: wrap">
					<v-text-field
						hide-details
						dense
						outlined
						class=""
						style="min-width: 250px"
						v-model="city"
						:label="$t('contact.city')"
						:disabled="previewMode"
						:rules="required ? inputRequired : []"
						@input="handleChange"
					></v-text-field>
					<v-text-field
						v-model="locality"
						hide-details
						dense
						outlined
						class=""
						style="min-width: 250px"
						:label="$t('contact.locality')"
						:disabled="previewMode"
						:rules="required ? inputRequired : []"
						@input="handleChange"
					/>
				</div>

				<div class="row-format gap-2" style="flex-wrap: wrap">
					<v-text-field
						v-model="postal"
						hide-details
						dense
						outlined
						class=""
						style="min-width: 250px"
						:label="$t('contact.postal')"
						:disabled="previewMode"
						:rules="required ? inputRequired : []"
						@input="handleChange"
					></v-text-field>

					<div :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width:49%'" class="">
						<v-autocomplete
							hide-details
							dense
							outlined
							:disabled="previewMode"
							v-model="country"
							:label="$t('contact.country')"
							:items="countries.getCountriesSimple()"
							item-value="code"
							item-text="name"
							:rules="required ? inputRequired : []"
							@input="handleChange"
						/>
					</div>
				</div>
			</div>
		</div>
	</v-form>
</template>

<script>
	import Countries from '@/modules/utils/Countries.js';
	export default {
		name: 'LeadContact',
		props: ['previewMode', 'options', 'required'],

		components: {},

		mounted() {},

		data() {
			return {
				businessName: null,
				firstName: null,
				lastName: null,
				email: null,
				phone: null,
				website: null,
				address1: null,
				address2: null,
				city: null,
				locality: null,
				postal: null,
				country: null,
				countries: new Countries(),

				isValid: false,
				inputRequired: [(v) => !!v || 'Required'],

				emailRequired: [
					(v) => !!v || 'Email is required',
					(v) =>
							/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
									v
							) || 'Please enter a valid email address',
				],
			};
		},

		methods: {
			handleChange: function () {
				this.$emit('input', {
					businessName: this.businessName,
					firstName: this.firstName,
					lastName: this.lastName,
					email: this.email,
					phone: this.phone,
					website: this.website,
					address1: this.address1,
					address2: this.address2,
					city: this.city,
					locality: this.locality,
					postal: this.postal,
					country: this.country,
				});
			},

			validate: function(){
				return this.$refs.form.validate();
			},

			getSelected: function (key) {
				return this.options.includes(key);
			},
		},

		computed: {
			showBusiness: function () {
				return this.getSelected('BusinessName');
			},
			showContact: function () {
				return this.getSelected('ContactName');
			},
			showPhone: function () {
				return this.getSelected('Phone');
			},
			showEmail: function () {
				return this.getSelected('Email');
			},
			showWebsite: function () {
				return this.getSelected('Website');
			},
			showAddress: function () {
				return this.getSelected('Address');
			},
		},
	};
</script>

<style scoped lang="scss"></style>
