<template>
	<div class="text-input">
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
			<label v-html="htmlText" class="question"></label>
			<date-selector :disabled="previewMode" :standard="true" @change="setDate($event)" :class="wasValidated && !ourDate ? 'error-state' : ''"></date-selector>
		</v-form>
	</div>
</template>

<script>
	import DateSelector from "@/components/DateSelector";
	export default {
		name: 'DateInput',
		components: {DateSelector},
		props: ['htmlHeader', 'options', 'htmlText', 'fieldType', 'previewMode','required'],

		mounted() {},

		data() {
			return {
				ourDate: null,
				isValid: false,
				wasValidated: false,
			};
		},

		methods: {
			validate: function () {
				this.wasValidated = true;
				if(this.required && !this.ourDate){
					return false;
				}else{
					return true;
				}
			},

			setDate: function(date){
				this.ourDate = date;
				this.$emit('input',date);
			}
		},

		computed: {
			placeholder: function () {
				if (this.previewMode) {
					return this.$t('discovery.client-answer');
				} else {
					return this.$t('discovery.your-answer');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.error-state {
		border: 1px solid var(--v-error-base);
		border-radius: 4px
	}
</style>
