<template>
	<v-container fluid class="pa-0 ma-0 selector_text">
		<v-row>
			<v-col cols="12" :style="headerStyle">
				<span v-html="lHeader" v-if="!editMode"></span>
				<editor
					ref="headerEditor"
					class="grey-dots"
					v-if="editMode"
					:api-key="$store.getters.getTinyMceKey"
					:initial-value="lHeader"
					:inline="true"
					:init="mceConfigHeader"
					@input="handleHeaderInput"
				></editor>
			</v-col>
			<v-col cols="12" align="center">
				<v-btn style="min-width: 200px" large elevation="0" color="primary" outlined v-if="!editMode" :disabled="!clientMode || isLocked" @click="confirmAccept()">
					<div>{{lText}}</div>
					<v-icon color="primary" class="ml-2" >$success</v-icon>
				</v-btn>
				<div v-else class="row-format centered font-16 font-primary brand-medium" style="border: 1px solid var(--v-primary-base); border-radius: 4px; width: fit-content; min-width: 200px; height: 50px">
					<div contenteditable style="width: fit-content" class="px-2" @input="handleTextInput($event)">{{seedText}}</div>
					<v-icon color="primary" size="20" class="ml-2" >$success</v-icon>
				</div>
				<div class="ipBlock mt-4" v-if="accepted">
					{{ $t('proposals.country') }}: <img :src="ip.countryFlag" width="15px" /><br />
					{{ $t('proposals.ip') }}: {{ ip.ip }}<br />
					{{ $t('proposals.location') }}: {{ ip.city }}, {{ ip.region }}, {{ ip.country }}<br />
					{{ $t('proposals.date') }}: {{ this.$DateTime.dateTime(acceptedAt) }}
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import ConfirmModal from '@/components/ConfirmModal';

  export default {
		name: 'AcceptBlock',

		props: ['header', 'text', 'id', 'editMode', 'clientMode', 'isLocked', 'blockType', 'ip', 'acceptedAt', 'accepted', 'mceConfigHeader', 'mceConfigText', 'headerStyle','bodyStyle','proposalType'],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function () {
			return {
				lText: this.text,
				lHeader: this.header,
				seedText: this.text,
			};
		},

		methods: {
			handleHeaderInput: function () {
				this.lHeader = this.$refs.headerEditor.editor.getContent();
				this.notifyChange();
			},

			handleTextInput: function(event){
				this.lText = event.target.innerText;
				this.notifyChange();
			},

      confirmAccept: function(){
        this.$emit('request-them-sign');
      },

      allowThemSign: function(){
        let binding = {
          headingText: this.$t('global.confirm'),
          bodyText: `${this.$t('proposals.accept')} ${this.proposalType}?`
        }
        this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
          if(res){
            this.notifyChange();
          }
        });
      },

			notifyChange() {
				this.$emit('changed', {
					id: this.id,
					blockType: this.blockType,
					header: this.lHeader,
					text: this.lText,
				});
			},
		},
	};
</script>

<style scoped lang="scss">
  .ipBlock {
    width:fit-content;
    text-align: left;
    color: darkgray;
    font-size: 0.7em;
  }
</style>
