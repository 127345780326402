<template>
	<div v-html="initialContent"></div>
</template>

<script>
	export default {
		name: 'ContentBlock',

		props: ['index', 'content'],

		components: {},

		data: function() {
			return {
				initialContent: this.content.length >= this.index ? this.content[this.index] : '',
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},

		watch: {},
	};
</script>

<style scoped lang="scss"></style>
