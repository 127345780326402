<template>
	<div @click="$emit('click', $event)" :style="paymentStyle" :id="item.id">
		<div v-if="showPaymentElement">
			<div id="payment-element"></div>
			<div class="text-left mt-3 row-format gap-1 align-center">
				<div class="ml-auto font-14 font-gray_80">Pay securely with</div>
				<img src="/images/invoices/stripe-logo.svg" alt="Stripe Logo" width="50" />
			</div>
		</div>
		<div v-else class="row-format text-left align-center font-14">
			<div style="text-transform: capitalize;">
				{{ item.stripePaymentIntent.status }} -
				{{
					this.$formatters.dollars(item.stripePaymentIntent.amount / 100, true, true, item.stripePaymentIntent.currency)
				}}
				- {{ item.stripePaymentIntent.clientPaymentMethod.type }} -
				{{ item.stripePaymentIntent.clientPaymentMethod.label }} - {{ item.stripePaymentIntent.clientPaymentMethod.last4
				}}<br />
				{{ DateTime.fromISO(item.stripePaymentIntent.timestamp).toLocaleString(DateTime.DATETIME_FULL) }}<br>
        <a :href="`/invoice/${agreement.invoiceId}`" target="_blank">{{agreement.invoiceNumberFormatted}}</a>
			</div>
			<div class="ml-auto row-format gap-1 align-center">
				<div class="ml-auto font-14 font-gray_80">Pay securely with</div>
				<img src="/images/invoices/stripe-logo.svg" alt="Stripe Logo" width="50" />
			</div>
		</div>
	</div>
</template>

<script>
	import chroma from 'chroma-js';
	import axios from 'axios';
	import qs from 'qs';
	import { DateTime } from 'luxon';
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'PaymentV2',

		props: ['agreement', 'item', 'tokens','isMobile','pageWidth'],

		components: {},

		mixins: [ResponsiveMixin],

		data: function() {
			return {
				DateTime: DateTime,
				showPaymentElement: true,
				stripe: null,
				elements: null,
				paymentElement: null,
				httpClient: null,
			};
		},

		mounted() {
			this.setupStripePayment();

			this.httpClient = axios.create({
				baseURL: this.$store.getters.getPodUrl,
				withCredentials: true,
			});

			this.$store.state.agreementPaymentElement = this;
		},

		beforeDestroy() {},

		methods: {
			async setupStripePayment() {
				if (this.agreement.account.stripeAccountId) {
					this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {
						stripeAccount: this.agreement.account.stripeAccountId,
					});

					if (this.item.stripePaymentIntent) {
						const { paymentIntent } = await this.stripe.retrievePaymentIntent(
							this.item.stripePaymentIntent.clientSecret
						);
						this.item.stripePaymentIntent.status = paymentIntent.status;
						this.item.stripePaymentIntent.amount = paymentIntent.amount;

						if (paymentIntent.status !== 'requires_payment_method') {
							this.showPaymentElement = false;
							return;
						}
					}

					const options = {
						paymentMethodCreation: 'manual',
						fonts: [
							{
								cssSrc: `https://fonts.googleapis.com/css2?family=${this.agreement.styles.body.fontFamily}:wght@400;700&display=swap`,
							},
						],
						appearance: {
							theme: this.stripeTheme,
							variables: {
								fontFamily: this.agreement.styles.body.fontFamily,
								fontSizeBase: this.agreement.styles.body.fontSize + 'px',
								colorBackground: this.item.backgroundColor,
								colorText: this.stripeContrast,
							},
						},
					};

					if (this.item.stripePaymentIntent) {
						options.clientSecret = this.item.stripePaymentIntent.clientSecret;
					} else {
						options.mode = 'payment';
						options.amount = 100;
						options.currency = this.agreement.currency ? this.agreement.currency.toLowerCase() : 'usd';
					}

					this.elements = this.stripe.elements(options);
					this.paymentElement = this.elements.create('payment');
					this.paymentElement.mount('#payment-element');
				}
			},

			finalize() {},

			reset: function() {},

			calculateAdditionalFee: function(feeDecimal, netAmount) {
				let totalInvoice = netAmount / (1 - feeDecimal);
				return totalInvoice - netAmount;
			},

			async processPayment() {
				try {
					console.log('Processing payment', this.item.stripePaymentIntent);

					if (this.item.stripePaymentIntent.status === 'requires_payment_method') {
						let submitError = await this.elements.submit();

						if (submitError.message) {
							this.$store.commit('error', submitError.message);
							this.errorState();
							return this.item.stripePaymentIntent;
						}

						const paymentMethodResult = await this.stripe.createPaymentMethod({ elements: this.elements });

						if (paymentMethodResult.error) {
							this.$store.commit('error', paymentMethodResult.message);
							this.errorState();
							return this.item.stripePaymentIntent;
						}

						let amount = Number(this.tokens.get('UpFront.UnformattedTotal'));

						let cardTypes = ['apple_pay', 'google_pay', 'microsoft_pay', 'card'];

						if (cardTypes.includes(paymentMethodResult.paymentMethod.type)) {
							if (this.agreement.client.whoPaysCardFees === 'Client') {
                amount = amount + this.calculateAdditionalFee(this.agreement.account.baseCardFeeRate,amount);
							} else if (this.agreement.client.whoPaysCardFees === 'Split') {
                amount = amount + this.calculateAdditionalFee(this.agreement.account.splitCardFeeRate,amount);
							}
						}

						let data = qs.stringify({
							id: this.agreement.id,
							amount: amount,
						});

						let result = await this.httpClient.post(`/api/docs/agreement/paymentIntent`, data, {
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
							},
						});

						this.item.stripePaymentIntent = result.data;

						const { error, paymentIntent } = await this.stripe.confirmPayment({
							elements: this.elements,
							redirect: 'if_required',
						});

						if (error) {
							this.$store.commit('error', error.message);
							this.errorState();
						} else {
							console.log(paymentIntent);
							this.item.stripePaymentIntent.status = paymentIntent.status;
							this.item.stripePaymentIntent.amount = paymentIntent.amount;
							this.goodState();
						}
					}
				} catch (err) {
					console.log(err);
					this.$store.commit('error', err.response.data.message);
					this.errorState();
				}

				return this.item.stripePaymentIntent;
			},

			errorState: function() {
				document.getElementById(this.item.id).scrollIntoView({ block: 'start', behavior: 'smooth' });
				document.getElementById(this.item.id).classList.add('red-border');
			},

			goodState: function() {
				document.getElementById(this.item.id).classList.remove('red-border');
			},
		},

		computed: {
			stripeContrast: function() {
				let black = chroma.contrast(this.item.backgroundColor, '#000000');
				let white = chroma.contrast(this.item.backgroundColor, '#FFFFFF');
				if (black > white) {
					return '#3C3F44';
				} else {
					return '#FFFFFF';
				}
			},

			paymentStyle: function() {
				let style = `width: ${this.item.scale}%; padding: ${this.item.padding}px; margin-top: ${this.item.pt}px; margin-bottom: ${this.item.pb}px; margin-left: ${this.padding(this.item.pl)}px; margin-right: ${this.padding(this.item.pr)}px; `;
				if (this.item.backgroundColor) {
					style = style + `background-color: ${this.item.backgroundColor}; `;
				}
				if (this.item.borderColor) {
					style = style + `border-color: ${this.item.borderColor}; `;
				}
				if (this.item.borderWidth) {
					style = style + `border-style: solid; border-width: ${this.item.borderWidth}px; `;
				}
				if (this.item.borderRadius) {
					style = style + `border-radius: ${this.item.borderRadius}px; `;
				}
				return style;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
