<template>
	<v-container>
		<v-row>
			<v-col cols="12" align="left" class="solid-hr">
				<div style="line-height: 1.8;"  v-html="tokenizedText"></div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { Settings } from 'luxon';
import timezones from '@/assets/data/timezones.json';

export default {
	name: 'MeetingInfo',

	props: ['meet', 'confirmedTime','action', 'tokenMap'],

	components: {},

	data: function () {
		return {
			Settings:Settings,
			timezones: timezones,
		};
	},

	mounted() {},

	beforeDestroy() {},

	methods: {

	},

	computed: {
		tokenizedText: function() {
			let result = this.meet.scheduleMeeting.schedulerInfo;
			this.tokenMap.forEach((value, key) => (result = result.replaceAll('{{' + key + '}}', value)));
			return result;
		},
	},
};
</script>

<style scoped lang="scss">
	.solid-hr {
		hr {
			border-top: 1px solid var(--v-black-base) !important;
		}
	}
</style>
