<template>
	<div @click="$emit('click',$event)">
		HEADER
	</div>
</template>

<script>
export default {
	name: "Header",

	props: [],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>