<template>
	<div class="show-scrollbar" style="background-color:var(--v-gray_20-base); max-height: 100vh">
		<div class="row-format centered">
			<div style="max-width: 155px" v-if="rendererType === 'InvoiceRenderer'"><language-switcher></language-switcher></div>
			<v-btn elevation="0" text color="gray_70" @click="downloadPdf"
				><v-icon class="mr-2" color="primary">$download</v-icon> Download PDF</v-btn
			>
		</div>

		<component
			style="max-height: calc(100vh - 32px); height: calc(100vh - 32px)"
			:is="rendererType"
			v-if="invoice && account"
			:invoice="invoice"
      :scheduled-payments="invoice.scheduledPayments"
			:account="account"
			:edit-mode="false"
			:key="refreshKey"
			:attachment-service="invoiceService"
			:invoice-service="invoiceService"
		></component>
	</div>
</template>

<script>
	import qs from 'qs';
	import HttpClient from '@/services/HttpClient';
	import InvoiceRenderer from '@/modules/invoices/InvoiceRenderer/InvoiceRenderer';
	import InvoiceService from '@/modules/invoices/viewer/InvoiceService';
	import LanguageSwitcher from '@/components/LanguageSwitcher';
	import InvoiceRendererV2 from '@/modules/invoices/invoiceRendererV2/InvoiceRendererV2';

	export default {
		name: 'InvoiceViewer',

		props: ['id'],

		components: { InvoiceRenderer, InvoiceRendererV2, LanguageSwitcher },

		data: function() {
			return {
				refreshKey: 1,
				invoice: null,
				account: null,
				invoiceService: null,
				token: null,
				httpClient: null,
			};
		},

		mounted() {
			this.$vuetify.theme.dark = false;
			setTimeout(() => (this.$vuetify.theme.dark = false), 50);
			let podUrl;

			if (this.id) {
        podUrl = this.$store.getters.getPodUrl;
        this.httpClient = new HttpClient(this.$store.state.domain.podUrl, false);
        this.token = this.id;
			} else {
				let queryString = qs.parse(self.location.search.slice(1));
				let request = JSON.parse(atob(queryString.token.split('.')[0]));

				podUrl = request.podUrl;

				this.httpClient = new HttpClient(podUrl, false);
				this.token = queryString.token;
			}

			this.invoiceService = new InvoiceService(this.token, podUrl);

			this.httpClient
				.get('/api/docs/invoice', {
					params: {
						token: this.token,
					},
				})
				.then((res) => {
					this.invoice = res.data.invoice;
					this.account = res.data.account;
				})
				.catch((err) => {
          console.log(err);
					this.$router.push('/404');
				});
		},

		beforeDestroy() {},

		methods: {
			downloadPdf: function() {
				this.$store.commit('startLoading');
				this.invoiceService
					.downloadInvoice()
					.then((res) => {
						let fileURL = window.URL.createObjectURL(new Blob([res.data]));

						let fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute('download', 'Invoice-' + this.invoice.invoiceNumberFormatted + '.pdf');
						document.body.appendChild(fileLink);
						fileLink.click();
					})
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {
			rendererType: function() {
				return this.invoice && this.invoice.invoiceTemplate ? 'InvoiceRendererV2' : 'InvoiceRenderer';
			},
		},
	};
</script>

<style scoped lang="scss"></style>
