import DateTime from "@/modules/utils/HDateTime";
import libPhoneNumber from "google-libphonenumber";

export default {

    data: function () {
        return {
            PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
            PNF: libPhoneNumber.PhoneNumberFormat,
        }
    },

    methods: {
        checkNull: function(val){
            return val ? val : ''
        },

        getTokensFromInvoice: function(invoice, recurring = false, paymentPlan = false){
            let result = new Map();
            result.set('Account.Name', this.checkNull(invoice.accountInfo.accountName));
            result.set('Account.TaxId', this.checkNull(invoice.accountInfo.taxId));
            result.set('Account.Address1',this.checkNull(invoice.accountInfo.address1));
            result.set('Account.Address2',this.checkNull(invoice.accountInfo.address2));
            result.set('Account.City',this.checkNull(invoice.accountInfo.city));
            result.set('Account.Locality',this.checkNull(invoice.accountInfo.locality));
            result.set('Account.Postal',this.checkNull(invoice.accountInfo.postal));
            result.set('Account.Country',this.checkNull(invoice.accountInfo.country));
            result.set('Account.Phone',this.checkNull(this.formatPhone(invoice.accountInfo.phone)));
            result.set('Account.Website',this.checkNull(invoice.accountInfo.website));
            result.set('Invoice.Total', this.$formatters.dollars(invoice.total,true,true,invoice.currency));
            result.set('Invoice.AmountDue', this.$formatters.dollars(invoice.amountDue,true,true,invoice.currency));
            result.set('Invoice.Number', this.checkNull(invoice.invoiceNumberFormatted));
            result.set('Invoice.Status', invoice.status);

            if(recurring || paymentPlan) {
                result.set('Invoice.DateCreated', '[Set when created]');
                result.set('Invoice.DateIssued', '[Set when created]');
                if(!invoice.paymentTerms || invoice.paymentTerms.paymentDays === 0){
                    result.set('Invoice.DateDue', '[On receipt]');
                }else{
                    result.set('Invoice.DateDue', '[+' + invoice.paymentTerms.paymentDays + ' days]');
                }
                result.set('Client.DepositBalance','[Set when created]');
            }else{
                result.set('Invoice.DateCreated', this.$DateTime.longDate(invoice.dateCreated, '--'));
                result.set('Invoice.DateIssued', this.$DateTime.longDate(invoice.dateSent, '--'));
                result.set('Invoice.DateDue', this.$DateTime.longDate(invoice.dateDue, '--'));

                if(invoice.clientInfo.depositBalance || invoice.clientInfo.depositBalance === 0){
                    result.set('Client.DepositBalance',this.$formatters.dollars(invoice.clientInfo.depositBalance,true,true, invoice.currency));
                }else{
                    result.set('Client.DepositBalance','');
                }
            }

            result.set('Invoice.OnlineUrl', this.checkNull(invoice.viewOnlineUrl));
            result.set('Invoice.PayUrl', this.checkNull(invoice.payOnlineUrl));
            result.set('Invoice.Currency', this.checkNull(invoice.currency));
            result.set('Invoice.PurchaseOrder',this.checkNull(invoice.purchaseOrder));
            result.set('Client.Name', this.checkNull(invoice.clientInfo.name));
            result.set('Client.TaxId', this.checkNull(invoice.clientInfo.taxId));
            result.set('Client.Address1',this.checkNull(invoice.clientInfo.address1));
            result.set('Client.Address2',this.checkNull(invoice.clientInfo.address2));
            result.set('Client.City',this.checkNull(invoice.clientInfo.city));
            result.set('Client.Locality',this.checkNull(invoice.clientInfo.locality));
            result.set('Client.Postal',this.checkNull(invoice.clientInfo.postal));
            result.set('Client.Country',this.checkNull(invoice.clientInfo.country));
            result.set('Client.Phone',this.checkNull(this.formatPhone(invoice.clientInfo.phone)));
            result.set('Client.Website',this.checkNull(invoice.clientInfo.website));

            if(invoice.accountInfo && invoice.accountInfo.accountPreferences) {
                result.set('Invoice.ComplianceHeader', invoice.status === 'PAID' ? invoice.accountInfo.accountPreferences.paidInvoiceDocumentHeader : invoice.accountInfo.accountPreferences.invoiceDocumentHeader);
            }else{
                result.set('Invoice.ComplianceHeader','');
            }

            if(invoice.paymentTerms){
                result.set('Invoice.LateFee',invoice.paymentTerms.latePaymentFee);
            }else{
                result.set('Invoice.LateFee','');
            }

            if(invoice.payInstructions){
                result.set('Invoice.PayInstructions',`${this.replaceLineBreaksWithBR(invoice.payInstructions)}</span>`);
            }else{
                result.set('Invoice.PayInstructions','');
            }

            if(invoice.clientInfo.contact) {
                result.set('Contact.First', this.checkNull(invoice.clientInfo.contact.firstName));
                result.set('Contact.Last', this.checkNull(invoice.clientInfo.contact.lastName));
                result.set('Contact.FullName', this.checkNull(invoice.clientInfo.contact.firstName) + ' ' + this.checkNull(invoice.clientInfo.contact.lastName));
                result.set('Contact.Email', this.checkNull(invoice.clientInfo.contact.email));
                result.set('Contact.Phone', this.checkNull(this.formatPhone(invoice.clientInfo.contact.phone)));

                this.contactFields.forEach((f) => {
                    let value = invoice.clientInfo.contact.customValues.find((v) => v.mappingKey === f.mappingKey);
                    if(f.type === 'Date' && value && value.value) {
                        result.set('Contact.Custom.' + f.mappingKey, DateTime.fromISO(value.value).toLocaleString(DateTime.DATE_FULL));
                    }else if(f.type === 'Phone' && value && value.value){
                        result.set('Contact.Custom.' + f.mappingKey, this.formatPhone(value.value));
                    }else {
                        result.set('Contact.Custom.' + f.mappingKey, value ? value.value : '');
                    }
                });
            }else{
                result.set('Contact.First', '');
                result.set('Contact.Last', '');
                result.set('Contact.FullName', '');
                result.set('Contact.Email', '');
                result.set('Contact.Phone', '');

                this.contactFields.forEach(f => {
                    result.set('Contact.Custom.' + f.mappingKey,'');
                });
            }

            this.clientFields.forEach((f) => {
                let value = invoice.clientInfo.customValues.find((v) => v.mappingKey === f.mappingKey);
                if(f.type === 'Date' && value && value.value) {
                    result.set('Client.Custom.' + f.mappingKey, DateTime.fromISO(value.value).toLocaleString(DateTime.DATE_FULL));
                }else if(f.type === 'Phone' && value && value.value){
                    result.set('Client.Custom.' + f.mappingKey, this.formatPhone(value.value));
                }else {
                    result.set('Client.Custom.' + f.mappingKey, value ? value.value : '');
                }
            });


            return result;
        },

        replaceLineBreaksWithBR: function(text) {
            return text.replace(/\n/g, '<br>');
        },

        formatPhone: function(phoneNumber) {
            try {
                let phone = this.PhoneUtil.parse(phoneNumber,'US');
                return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
            } catch (err) {
                return phoneNumber;
            }
        },
    },

    computed: {
        clientFields: function(){
            if(this.$store.state.clientFields && this.$store.state.clientFields.fields){
                return this.$store.state.clientFields.fields;
            }else{
                return [];
            }
        },

        contactFields: function(){
            if(this.$store.state.contactFields && this.$store.state.contactFields.fields){
                return this.$store.state.contactFields.fields;
            }else{
                return [];
            }
        },

        tokens: function(){
            let invoice = [
                'Account.Name',
                'Account.TaxId',
                'Account.Address1',
                'Account.Address2',
                'Account.City',
                'Account.Locality',
                'Account.Postal',
                'Account.Country',
                'Account.Phone',
                'Account.Website',
                'Invoice.Total',
                'Invoice.AmountDue',
                'Invoice.Number',
                'Invoice.DateIssued',
                'Invoice.DateDue',
                'Invoice.OnlineUrl',
                'Invoice.PayUrl',
                'Invoice.Currency',
                'Invoice.PurchaseOrder',
                'Invoice.PayInstructions',
                'Invoice.LateFee',
                'Invoice.Status',
                'Invoice.ComplianceHeader'
            ]

            let client = [
                'Client.Name',
                'Client.TaxId',
                'Client.Address1',
                'Client.Address2',
                'Client.City',
                'Client.Locality',
                'Client.Postal',
                'Client.Country',
                'Client.Phone',
                'Client.Website',
                'Client.DepositBalance'
            ];

            let contact = [
                'Contact.First',
                'Contact.Last',
                'Contact.FullName',
                'Contact.Email',
                'Contact.Phone',
            ];

            this.clientFields.forEach(f => {
                client.push('Client.Custom.' + f.mappingKey);
            })

            this.contactFields.forEach(f => {
                contact.push('Contact.Custom.' + f.mappingKey);
            });

            return [].concat(invoice,client,contact);
        }
    }
}