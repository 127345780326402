<template>
	<div class="text-left" @click="$emit('click',$event)" v-if="isConditionMet">
		<div class="form-question">{{ item.question }} <span v-if="item.required" class="font-red brand-medium">*</span>  <v-icon v-if="showConditionalHelper" small color="gray_50">account_tree</v-icon></div>
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
			<v-select
					hide-details
					dense
					v-model="selection"
					:items="item.options"
					:placeholder="item.placeholder"
					:outlined="outlined"
					:solo="solo"
					:filled="filled"
					:disabled="disabled"
					class="form-input"
					:rules="item.required ? inputRequired : []"
					@change="handleChange()"
			></v-select>
		</v-form>
	</div>
</template>

<script>
import RendererMixin from "@/modules/forms/v2/schema/RendererMixin";

export default {
	name: "Select",

	props: ['disabled', 'item', 'styles', 'index','results','builder','container'],

	mixins: [RendererMixin],

	components: {},

	data: function () {
		return {
			selection: null,
		}
	},

	mounted() {
		let currentResult = this.getCurrentResult(this.item.id,this.results);
		if(currentResult){
			this.selection = currentResult;
		}
	},

	beforeDestroy() {
	},

	methods: {
		handleChange: function(){
			this.$emit('input', {item: this.item, value: this.selection, index: this.index, container: this.container});
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>