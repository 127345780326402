<template>
	<div class="mx-3 mt-3">
		<v-container fluid>
			<v-row dense>
				<v-col>
					<div class="column-format" style="gap: 12px">
						<div class="column-format px-2 gap-4">
							<v-select
								v-model="ticketType"
								:items="ticketTypes"
								item-text="type"
								item-value="id"
								:label="$t('requests.new.type')"
								outlined
								persistent-placeholder
								hide-details
								dense
							></v-select>
							<v-text-field
								v-model="subject"
								:label="$t('requests.new.subject')"
								dense
								outlined
								persistent-placeholder
								hide-details
							></v-text-field>
							<contact-selector
								:to-list="ccList"
								:contacts="contacts"
								:label="$t('requests.new.cc')"
								@update="updateCcList"
							></contact-selector>
						</div>

						<div class="" v-if="selectedType.discoveryTemplate">
							<ticket-form-v1
								ref="form"
								v-if="selectedType.discoveryTemplate.schemaVersion == 1"
								:template="selectedType.discoveryTemplate"
							></ticket-form-v1>
							<ticket-form-v2
								ref="form"
								v-if="selectedType.discoveryTemplate.schemaVersion == 2"
								:template="selectedType.discoveryTemplate"
							></ticket-form-v2>
						</div>

						<v-textarea
							v-if="!selectedType.discoveryTemplate"
							rows="12"
							v-model="comment"
							:label="$t('requests.new.details')"
							dense
							outlined
							persistent-placeholder
							hide-details
						></v-textarea>

						<div v-cloak @drop.prevent="addDropFile" @dragover.prevent>
							<v-file-input
								v-model="attachments"
								:placeholder="$t('requests.new.upload-attachments')"
								:label="$t('requests.new.attachments')"
								multiple
								prepend-icon="attach_file"
							>
								<template v-slot:selection="{ text }">
									<v-chip small label color="primary" style="color: var(--v-white-base)">
										{{ text }}
									</v-chip>
								</template>
							</v-file-input>
						</div>
						<div class="row-format" style="gap: 12px">
							<v-btn class="secondary-action" @click="confirmCancel()">{{ $t('global.cancel') }}</v-btn>
							<v-btn class="super-action" width="200" @click="createTicket()">{{
								$t('requests.new.submit')
							}}</v-btn>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import ClientService from '@/modules/client/ClientService';
	import ConfirmModal from '@/components/ConfirmModal';
	import TicketService from '@/modules/tickets/TicketService';
	import ContactSelector from '@/modules/tickets/ContactSelector';
	import TicketFormV1 from '@/modules/tickets/TicketFormV1';
	import TicketFormV2 from '@/modules/tickets/TicketFormV2';
	export default {
		name: 'NewTicket',

		props: ['ticketTypes'],

		components: { TicketFormV1, TicketFormV2, ContactSelector },

		data: function() {
			return {
				clientService: new ClientService(),
				ticketService: new TicketService(),
				subject: null,
				ticketType: this.ticketTypes[0].id,
				comment: null,
				attachments: [],
				contacts: [],
				ccList: [],
				formResult: null,
			};
		},

		mounted() {
			this.clientService.getContactList().then((res) => {
				this.contacts.splice(0, this.contacts.length);
				this.contacts.push(
					...res.data.filter(
						(c) => c.email && c.email.toLowerCase() !== this.$store.state.loggedInUser.email.toLowerCase()
					)
				);
			});
		},

		beforeDestroy() {},

		methods: {
			validate: function() {
				if (!this.subject) {
					this.$store.commit('error', this.$t('error.subject-required'));
					return false;
				}

				if (this.$refs.form) {
					if (!this.$refs.form.validate()) {
						this.$store.commit('error', this.$t('error.correct-errors'));
						return false;
					}
				} else if (!this.comment) {
					this.$store.commit('error', this.$t('error.request-details'));
					return false;
				}

				return true;
			},

			createTicket: function() {
				try {
					if (!this.validate()) {
						this.$store.commit('error', 'Please correct the errors in your form.');
						return;
					}

					let newTicket = {
						subject: this.subject,
						comment: this.comment,
						ticketType: this.ticketType,
						ccList: this.ccList,
					};

					if (this.selectedType.discoveryTemplate) {
						let formData = {};
						this.$refs.form.setFormResult(formData);

						let files = formData.files;
						delete formData.files;
						this.attachments.push(...files);

						newTicket.formData = formData;
					}

					this.$store.commit('startLoading');
					this.ticketService
						.createTicket(newTicket, this.attachments)
						.then((res) => {
							this.$emit('new-ticket', res.data.ticket.id);
						})
						.catch((err) => this.$store.commit('error', err.response.data.message))
						.finally(() => this.$store.commit('stopLoading'));
				} catch (err) {
          console.log(err);
          this.$store.commit('error',JSON.stringify(err));
        }
			},

			updateCcList: function(list) {
				this.ccList.splice(0, this.ccList.length);
				this.ccList.push(...list);
			},

			handleResult(result) {
				this.formResult = result;
			},

			addDropFile: function(e) {
				this.attachments.push(...Array.from(e.dataTransfer.files));
			},

			confirmCancel: function() {
				if (this.comment || this.subject || this.attachments.length) {
					let binding = {
						bodyText: this.$t('requests.confirm-cancel'),
					};
					this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
						if (res) {
							setTimeout(() => this.$emit('cancel'), 200);
						}
					});
				} else {
					this.$emit('cancel');
				}
			},
		},

		computed: {
			selectedType: function() {
				return this.ticketTypes.find((t) => t.id === this.ticketType);
			},
		},
	};
</script>

<style scoped lang="scss"></style>
