<template>
	<div id="proposal-deliverable-fees" v-if="isReady">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">{{ $t('proposal.deliverables.project-fees') }}</div>

			<v-select
				class="h-outline"
				hide-details
				persistent-placeholder
				:items="feeTypes"
				v-model="lFeeSchedule.feeType"
				item-text="label"
				item-value="value"
				:label="$t('proposal.deliverables.fee-type')"
			>
				<template v-slot:item="{ item }">
					<span style="font-size: 16px">{{ item.label }}</span>
				</template>
			</v-select>

			<div
				v-if="lFeeSchedule.feeType === 'Per Item'"
				class="row-format text-left font-14 font-gray_80"
				style="align-items: flex-end"
			>
				{{ $t('proposal.deliverables.is-null.per-item-text') }}
			</div>

			<div v-if="lFeeSchedule.feeType !== 'Per Item'" class="row-format" style="align-items: flex-end">
				<v-text-field
					class="h-outline"
					hide-details
					persistent-placeholder
					v-model="lFeeSchedule.amount"
					:prefix="currencySymbol"
					type="number"
					:label="$t('proposal.deliverables.amount')"
				></v-text-field>
				<v-checkbox v-if="taxEnabled" class="ml-3 mb-2" dense hide-details v-model="lFeeSchedule.taxable"
					><template v-slot:label
						><span class="font-14 ml-n2">{{ $t('proposal.deliverables.taxable') }}</span></template
					></v-checkbox
				>
			</div>

			<v-select
				v-if="lFeeSchedule.feeType === 'Retainer'"
				class="h-outline"
				hide-details
				persistent-placeholder
				:items="schedule"
				v-model="lFeeSchedule.retainerSchedule"
				:label="$t('proposal.deliverables.frequency')"
			>
				<template v-slot:item="{ item }">
					<span>{{ item }}</span>
				</template>
			</v-select>

			<div v-if="lFeeSchedule.feeType === 'Hourly' || lFeeSchedule.feeType === 'Retainer'">
				<div class="modal-subtitle mt-3 mb-0">
					{{ $t('proposal.deliverables.hour-estimate') }}
					<span style="font-style: italic; font-weight: 300; font-size: 12px" class="ml-1">{{
						$t('proposal.deliverables.hour-estimate-optional')
					}}</span>
				</div>
				<div class="d-flex justify-space-between">
					<div style="flex: 0 0 50%" class="pr-1">
						<v-text-field
							class="h-outline"
							hide-details
							persistent-placeholder
							v-model="lFeeSchedule.estimateMin"
							:label="$t('proposal.deliverables.hour-estimate-min-label')"
							type="number"
						></v-text-field>
					</div>
					<div style="flex: 0 0 50%" class="pl-1">
						<v-text-field
							class="h-outline"
							hide-details
							persistent-placeholder
							v-model="lFeeSchedule.estimateMax"
							:label="$t('proposal.deliverables.hour-estimate-max-label')"
							type="number"
						></v-text-field>
					</div>
				</div>
				<v-text-field
					v-if="lFeeSchedule.feeType === 'Retainer' && lFeeSchedule.estimateMax"
					class="h-outline"
					hide-details
					persistent-placeholder
					v-model="lFeeSchedule.retainerOverageRate"
					:prefix="currencySymbol"
					type="number"
					:label="$t('proposal.deliverables.retainer-overage')"
				></v-text-field>
			</div>
			<div class="column-format gap-3 mt-2" style="border-top: 1px solid var(--v-gray_30-base)">
				<div class="row-format align-center gap-2 mt-3">
					<v-switch
						class="mt-n1"
						v-model="lAutomations.autoCreateProject"
						inset
						flat
						dense
						hide-details
						label="Enable automations & payment"
					></v-switch>
					<v-icon class="ml-auto pointer" color="accent" v-tippy="{content: 'When this is enabled, Moxie will automatically create a corresponding project and first invoice based on the settings on this package.'}">help</v-icon>
				</div>
				<div class="row-format align-center gap-2" v-if="lAutomations.autoCreateProject">
					<v-text-field
						v-if="lAutomations.autoCreateProject"
						v-model="lAutomations.projectName"
						hide-details
						style="margin-bottom: -4px"
						:persistent-placeholder="true"
						label="New project name (optional)"
						class="h-outline"
					></v-text-field>
					<v-icon class="ml-auto pointer" color="accent" v-tippy="{content:'This value will be the name for the new project that is created.'}">help</v-icon>
				</div>
				<div class="row-format align-center gap-2" v-if="lAutomations.autoCreateProject">
					<v-select
						v-model="lAutomations.overrideDeliverablePackageId"
						:items="packageList"
						item-text="name"
						item-value="id"
						hide-details
						style="margin-top: 0;"
						:persistent-placeholder="true"
						class="h-outline"
						clearable
						label="Use tasks from project template"
					>
					</v-select>
					<v-icon class="ml-auto pointer" color="accent" v-tippy="{content:'Selecting this value will replace the deliverables specified in the agreement with tasks from the specified template when the project is automatically created.'}">help</v-icon>
				</div>
				<div v-if="lAutomations.autoCreateProject">
					<div v-if="lFeeSchedule.feeType !== 'Retainer'" class="row-format gap-2">
						<v-text-field
							type="number"
							style="min-width:200px; margin-top: 0px;"
							v-model="lAutomations.invoiceAmount"
							hide-details
							:persistent-placeholder="true"
							label="Initial invoice amount"
							class="h-outline"
							:prefix="lAutomations.invoicePercentage ? '' : currencySymbol"
							:suffix="lAutomations.invoicePercentage ? '%' : ''"
							:min="lAutomations.invoicePercentage ? '0' : null"
							:max="lAutomations.invoicePercentage ? '100' : null"
							@change="scrubInput"
						></v-text-field>
						<v-select
							v-model="lAutomations.invoicePercentage"
							hide-details
							style="margin-top: 0px;"
							:persistent-placeholder="true"
							label="Type"
							item-value="value"
							item-text="label"
							class="h-outline"
							@change="scrubInput"
							:items="amountTypeOptions"
						>
						</v-select>
					</div>
					<v-switch
						class=""
						style="margin-top: 0px"
						v-if="lAutomations.invoiceAmount || lFeeSchedule.feeType === 'Retainer'"
						v-model="lAutomations.paymentRequiredToStart"
						inset
						flat
						dense
						hide-details
						:label="`Show ${invoiceLabel} amount on agreement`"
					></v-switch>
					<v-switch
						v-if="!paymentNodeCount"
						class=""
						style="margin-top: 0px;"
						v-model="lAutomations.approvalRequired"
						inset
						flat
						dense
						hide-details
						:label="`Approval required to send ${invoiceLabel}`"
					></v-switch>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" width="120" @click="$emit('close')">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="primary-action ml-1" width="120" @click="save()">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'DeliverableFees',

		props: ['automations', 'feeSchedule', 'currency', 'paymentNodeCount'],

		components: {},

		data: function() {
			return {
				templateService: {},
				isReady: false,
				packageList: [],
				feeTypes: [
					{ label: 'Hourly', value: 'Hourly' },
					{ label: 'Fixed Price', value: 'Fixed Price' },
					{ label: 'Recurring', value: 'Retainer' },
					{ label: 'Per Item', value: 'Per Item' },
				],
				schedule: ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Bi-Annually', 'Annually'],
				amountTypeOptions: [
					{ value: true, label: 'Percentage' },
					{ value: false, label: 'Fixed amount' },
				],
				lAutomations: null,
				lFeeSchedule: JSON.parse(JSON.stringify(this.feeSchedule)),
			};
		},

		mounted() {
			if (this.automations) {
				this.lAutomations = JSON.parse(JSON.stringify(this.automations));
			} else {
				this.lAutomations = {
					autoCreateProject: true,
					autoInvoice: true,
					invoicePercentage: true,
					invoiceAmount: 50,
					paymentRequiredToStart: true,
				};
			}
			this.getPackageList();
			this.isReady = true;
		},

		beforeDestroy() {},

		methods: {
			save: function() {
				this.$emit('change', {
					feeSchedule: this.lFeeSchedule,
					automations: this.lAutomations,
				});
				this.$emit('close');
			},

			getPackageList: function() {
				this.templateService.getDeliverablePackages().then((res) => {
					this.packageList.splice(0);
					this.packageList.push(...res.data);
				});
			},

			scrubInput: function() {
				let result = parseFloat(this.lAutomations.invoiceAmount);
				if (isNaN(result)) {
					this.lAutomations.invoiceAmount = 0;
				} else {
					if (result < 0) {
						result = 0;
					} else if (this.lAutomations.invoicePercentage && result > 100) {
						result = 100;
					}
					this.lAutomations.invoiceAmount = result;
				}
			},
		},

		computed: {
			currencySymbol: function() {
				return this.$formatters.currencySymbol(this.currency ? this.currency : this.$store.state.defaultCurrency);
			},

			taxEnabled: function() {
				return this.$store.state.podAccount.accountPreferences.invoiceUseTax;
			},

			invoiceLabel: function() {
				let feeType = this.lFeeSchedule.feeType;
				if (feeType === 'Hourly' || feeType === 'Per Item') {
					return 'deposit invoice';
				} else {
					return 'initial invoice';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	#proposal-deliverable-fees {
		width: 450px;
		background-color: var(--v-white-base);
	}
	.label {
		text-align: left;
		font-weight: bold;
	}
</style>
