<template>
	<div>
		<template>
      <v-list nav dense style="text-align: left">
				<div v-for="(page, i) in navLinks" :key="i">
					<v-list-item v-if="!page.tabs" @click="handleClick(page)">
						<v-list-item-icon>
							<v-icon class="material-symbols-outlined">{{ page.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-title v-text="page.label" />
					</v-list-item>

					<v-list-group v-else :key="page.label" no-action :value="false" @click.stop.prevent>
						<template v-slot:activator>
              <v-list-item>
                <v-list-item-icon style="margin-left: -8px">
                  <v-icon class="material-symbols-outlined">{{ page.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <div>{{ page.label }}</div>
                </v-list-item-title>
              </v-list-item>
						</template>

						<v-list-item v-for="tab in page.tabs" @click="$router.push(tab.route)" :key="tab.label">
							<v-list-item-title>{{ tab.label }}</v-list-item-title>
						</v-list-item>
					</v-list-group>
				</div>
			</v-list>
		</template>

		<div>
			<div class="workspace-settings row-format align-center pa-3" style="background-color: var(--v-white-base)">
				<div
					style="margin-left: 6px"
					class="row-format centered"
					:content="`Workspace settings`"
					v-tippy="{
						placement: 'right',
						arrow: true,
						arrowType: 'round',
						animation: 'fade',
						delay: 50,
						boundary: 'window',
					}"
				>
					<v-avatar size="20" color="var(--v-primary-base)">
						<span class="white--text brand-bold font-12">{{ initials }}</span>
					</v-avatar>
				</div>
				<settings-link
					class="nav-label ml-2"
          :label="$t('nav.logout')"
          select-value="logout"
					@selected="confirmLogout()"
					icon="logout"
				></settings-link>
			</div>
		</div>
		<div>
			<v-menu offset-y content-class="settings-menu">
				<template v-slot:activator="{ on }">
					<div
						class="workspace-settings row-format align-center pa-3"
						style="background-color: var(--v-white-base)"
						v-on="on"
					>
						<div
							style="margin-left: 6px"
							class="row-format centered"
							:content="`Workspace settings`"
							v-tippy="{
								placement: 'right',
								arrow: true,
								arrowType: 'round',
								animation: 'fade',
								delay: 50,
								boundary: 'window',
							}"
						>
							<v-avatar size="20" color="accent">
								<span class="white--text brand-bold font-12">{{ clientInitials }}</span>
							</v-avatar>
						</div>
            <div class="nav-label ml-2">{{ $t('settings.label') }}</div>
            <v-icon class="ml-auto" small>expand_more</v-icon>
					</div>
				</template>
				<settings-nav></settings-nav>
			</v-menu>
		</div>
	</div>
</template>

<script>
	import SettingsNav from '@/views/nav/settings/SettingsNav.vue';
	import SettingsLink from '@/views/nav/settings/SettingsLink.vue';
	import ConfirmModal from '@/components/ConfirmModal.vue';
	import AuthService from '@/modules/auth/AuthService';
	import PodService from '@/services/PodService';
	import NavMixin from '@/views/nav/NavMixin';

	export default {
		name: 'Tabs',

		components: { SettingsNav, SettingsLink },

		mixins: [NavMixin],

    props: {
			icon: { type: String },
			title: { type: String, required: false, default: '' },
      hyperLink: String,
		},

    data: function() {
      return {};
    },

    methods: {
      handleClick(page) {
        if (page.hyperLink) {
          window.open(page.hyperLink, '_blank');
        } else {
          this.$router.push(page.route);
        }
      },

			confirmLogout() {
				let binding = {
					icon: 'logout',
					severity: 'info',
          headingText: this.$t('nav.logout-confirm'),
        };
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.doLogout();
					}
				});
			},

			doLogout() {
				this.dialog = false;
				try {
					window.google.accounts.id.disableAutoSelect();
				} catch (err) {
					console.log(err);
				}

				AuthService.logout()
					.then(() => {
						console.log('Logout Success');
						this.podLogout();
					})
					.catch((err) => {
						console.log(err);
						this.podLogout();
					});
			},

			podLogout() {
				let podService = new PodService();
				podService
					.clearCookies()
					.then(() => {
						this.finalizeLogout();
					})
					.catch(() => {
						this.finalizeLogout();
					});
			},

			finalizeLogout() {
				this.$store.commit('logOut');
				location.reload();
			},
		},
		computed: {
      initials() {
				if (this.$store.state.loggedInUser) {
					let firstName = this.$store.state.loggedInUser.firstName;
					let lastName = this.$store.state.loggedInUser.lastName;
					let initials = '';

					if (firstName) {
						initials = initials + firstName.slice(0, 1).toUpperCase();
					}

					if (lastName) {
						initials = initials + lastName.slice(0, 1).toUpperCase();
					}

					return initials;
				} else {
					return null;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	v-list-item {
		padding-left: 0px;
	}
</style>
