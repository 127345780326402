var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[_c('v-list',{staticStyle:{"text-align":"left"},attrs:{"nav":"","dense":""}},_vm._l((_vm.navLinks),function(page,i){return _c('div',{key:i},[(!page.tabs)?_c('v-list-item',{on:{"click":function($event){return _vm.handleClick(page)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"material-symbols-outlined"},[_vm._v(_vm._s(page.icon))])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(page.label)}})],1):_c('v-list-group',{key:page.label,attrs:{"no-action":"","value":false},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',[_c('v-list-item-icon',{staticStyle:{"margin-left":"-8px"}},[_c('v-icon',{staticClass:"material-symbols-outlined"},[_vm._v(_vm._s(page.icon))])],1),_c('v-list-item-title',[_c('div',[_vm._v(_vm._s(page.label))])])],1)]},proxy:true}],null,true)},_vm._l((page.tabs),function(tab){return _c('v-list-item',{key:tab.label,on:{"click":function($event){return _vm.$router.push(tab.route)}}},[_c('v-list-item-title',[_vm._v(_vm._s(tab.label))])],1)}),1)],1)}),0)],_c('div',[_c('div',{staticClass:"workspace-settings row-format align-center pa-3",staticStyle:{"background-color":"var(--v-white-base)"}},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
						placement: 'right',
						arrow: true,
						arrowType: 'round',
						animation: 'fade',
						delay: 50,
						boundary: 'window',
					}),expression:"{\n\t\t\t\t\t\tplacement: 'right',\n\t\t\t\t\t\tarrow: true,\n\t\t\t\t\t\tarrowType: 'round',\n\t\t\t\t\t\tanimation: 'fade',\n\t\t\t\t\t\tdelay: 50,\n\t\t\t\t\t\tboundary: 'window',\n\t\t\t\t\t}"}],staticClass:"row-format centered",staticStyle:{"margin-left":"6px"},attrs:{"content":`Workspace settings`}},[_c('v-avatar',{attrs:{"size":"20","color":"var(--v-primary-base)"}},[_c('span',{staticClass:"white--text brand-bold font-12"},[_vm._v(_vm._s(_vm.initials))])])],1),_c('settings-link',{staticClass:"nav-label ml-2",attrs:{"label":_vm.$t('nav.logout'),"select-value":"logout","icon":"logout"},on:{"selected":function($event){return _vm.confirmLogout()}}})],1)]),_c('div',[_c('v-menu',{attrs:{"offset-y":"","content-class":"settings-menu"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"workspace-settings row-format align-center pa-3",staticStyle:{"background-color":"var(--v-white-base)"}},on),[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
								placement: 'right',
								arrow: true,
								arrowType: 'round',
								animation: 'fade',
								delay: 50,
								boundary: 'window',
							}),expression:"{\n\t\t\t\t\t\t\t\tplacement: 'right',\n\t\t\t\t\t\t\t\tarrow: true,\n\t\t\t\t\t\t\t\tarrowType: 'round',\n\t\t\t\t\t\t\t\tanimation: 'fade',\n\t\t\t\t\t\t\t\tdelay: 50,\n\t\t\t\t\t\t\t\tboundary: 'window',\n\t\t\t\t\t\t\t}"}],staticClass:"row-format centered",staticStyle:{"margin-left":"6px"},attrs:{"content":`Workspace settings`}},[_c('v-avatar',{attrs:{"size":"20","color":"accent"}},[_c('span',{staticClass:"white--text brand-bold font-12"},[_vm._v(_vm._s(_vm.clientInitials))])])],1),_c('div',{staticClass:"nav-label ml-2"},[_vm._v(_vm._s(_vm.$t('settings.label')))]),_c('v-icon',{staticClass:"ml-auto",attrs:{"small":""}},[_vm._v("expand_more")])],1)]}}])},[_c('settings-nav')],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }