<template>
	<div @click="$emit('click', $event)" :style="paymentStyle" :id="item.id">
    <div class="text-left column-format gap-1" v-show="!pendingCharge">
			<div ref="card" style="border-bottom: 1px solid var(--v-gray_30-base)"></div>
			<div class="row-format gap-1 mt-1">
				<v-img src="/images/payment/american_express.svg" style="max-width: 30px; margin-left: -2px"></v-img>
				<v-img src="/images/payment/visa.svg" style="max-width: 30px"></v-img>
				<v-img src="/images/payment/mastercard.svg" style="max-width: 30px"></v-img>
				<v-img src="/images/payment/discover.svg" style="max-width: 30px"></v-img>
			</div>
		</div>
		<div class="font-14 text-left column-format gap-2" v-if="pendingCharge && pendingCharge.clientPaymentMethod">
			<div>
				{{ pendingCharge.clientPaymentMethod.label }} {{ pendingCharge.clientPaymentMethod.last4 }} charged
				{{ $formatters.dollars(pendingCharge.amount, true, true, pendingCharge.currency) }}
				{{ pendingCharge.currency }} on
				{{ $DateTime.fromISO(pendingCharge.timestamp).toLocaleString($DateTime.DATETIME_MED) }}
			</div>
			<div class="row-format gap-1 mt-0">
				<v-img src="/images/payment/american_express.svg" style="max-width: 30px; margin-left: -2px"></v-img>
				<v-img src="/images/payment/visa.svg" style="max-width: 30px"></v-img>
				<v-img src="/images/payment/mastercard.svg" style="max-width: 30px"></v-img>
				<v-img src="/images/payment/discover.svg" style="max-width: 30px"></v-img>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Payment',

		props: ['agreement', 'item','tokens'],

		components: {},

		data: function() {
			return {
				stripe: null,
			};
		},

		mounted() {
			if (this.agreement.account.stripeAccountId && !this.agreement.fullyExecuted) {
				this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {
					stripeAccount: this.agreement.account.stripeAccountId,
				});
				this.elements = this.stripe.elements();
				this.card = this.elements.create('card', {
					style: {
						base: {
							color: this.$vuetify.theme.dark ? 'white' : 'black',
						},
					},
				});
				this.card.mount(this.$refs.card);
			}
		},

		beforeDestroy() {},

		methods: {
      finalize() {
				return new Promise((resolve, reject) => {
					this.item.preAuth = Number(this.tokens.get('UpFront.UnformattedTotal'));
          if(!this.item.token) {
            this.stripe
                .createToken(this.card)
                .then((res) => {
                  if (res.error) {
                    this.errorState()
                    this.$store.commit('error', res.error.message);
                    reject(res.error.message);
                  } else {
                    this.goodState();
                    this.card.update({disabled:true});
                    this.item.token = res.token.id;
                    resolve();
                  }
                })
                .catch((err) => {
                  this.errorState();
                  reject(err)
                });
          }else{
            resolve();
          }
				});
			},

      reset: function(){
        return new Promise((resolve) => {
          this.card.update({disabled:false});
          this.item.token = null;
          resolve();
        });
      },

      errorState: function(){
        document.getElementById(this.item.id).scrollIntoView({ block: 'start', behavior: 'smooth' });
        document.getElementById(this.item.id).classList.add("red-border");
      },

      goodState: function(){
        document.getElementById(this.item.id).classList.remove("red-border");
      }
		},

		computed: {
			pendingCharge: function() {
				if (this.item && this.item.pendingCharge) {
					return this.item.pendingCharge;
				} else {
					return null;
				}
			},

			paymentStyle: function() {
				let style = `width: ${this.item.scale}%; padding: ${this.item.padding}px; margin-top: ${this.item.pt}px; margin-bottom: ${this.item.pb}px; margin-left: ${this.item.pl}px; margin-right: ${this.item.pr}px; `
				if(this.item.backgroundColor){
					style = style + `background-color: ${this.item.backgroundColor}; `
				}
				if(this.item.borderColor){
					style = style + `border-color: ${this.item.borderColor}; `
				}
				if(this.item.borderWidth){
					style = style + `border-style: solid; border-width: ${this.item.borderWidth}px; `
				}
				if(this.item.borderRadius){
					style = style + `border-radius: ${this.item.borderRadius}px; `
				}
				return style;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
