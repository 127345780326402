<template>
	<div style="max-height: calc(var(--vh) - 50px); overflow-y: auto" class="show-scrollbar">
    <notifications></notifications>
	</div>
</template>

<script>
  import Notifications from '@/views/nav/Notifications';
  export default {
		name: 'RightNav',

		props: [],

		components: {
      Notifications,

		},

		data: function() {
			return {

			};
		},

		mounted(){

    },

		beforeDestroy() {},

		methods: {

		},

		computed: {

    }
	};
</script>

<style scoped lang="scss">

</style>
