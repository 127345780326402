import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class TicketService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getTicketTypes() {
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/tickets/settings`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTicketList(){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/tickets`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTicketDetail(id) {
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/tickets/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createTicket(newTicket, attachments = []){
		let clientId = store.getters.getClientId;
		let formData = new FormData();

		formData.append('newTicket',JSON.stringify(newTicket));
		attachments.forEach(a => {
			formData.append('attachments',a);
		})

		return this.httpClient
			.post(`/${clientId}/tickets`,formData,{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createComment(ticketId, comment, attachments = []){
		let clientId = store.getters.getClientId;
		let formData = new FormData();

		formData.append('comment',comment);
		attachments.forEach(a => {
			formData.append('attachments',a);
		})

		return this.httpClient
			.post(`/${clientId}/tickets/${ticketId}/comments`,formData,{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	markAsRead(ticketId){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.post(`/${clientId}/tickets/${ticketId}/read`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateComment(ticketId, commentId, comment,){
		let clientId = store.getters.getClientId;
		let formData = new FormData();

		formData.append('comment',comment);

		return this.httpClient
			.put(`/${clientId}/tickets/${ticketId}/comments/${commentId}`,formData,{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(ticketId, fileName) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/tickets/${ticketId}/files`, {
				params: {
					fileName: fileName,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
