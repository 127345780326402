export default {
	methods: {},

	computed: {
		bodyStyle: function() {
			if (!this.proposal) return '';
			if (!this.proposal.formatting) return '';
			return `font-family: ${this.proposal.formatting.bodyFont}; text-align:left; color: var(--v-gray_80-base); font-size: 16px`;
		},

		headerStyle: function() {
			if (!this.proposal) return '';
			if (!this.proposal.formatting) return '';
			return `font-size: 22px; font-family: ${this.proposal.formatting.headerFont}; font-weight: ${this.headerWeight}; text-align: ${this.proposal.formatting.headerAlignment}`;
		},

		clientName: function() {
			if (!this.proposal) return '';
			if (this.proposal.clientMini != null) {
				return this.proposal.clientMini.name;
			} else if (this.templateMode) {
				return '{{Client.Name}}';
			} else {
				return '';
			}
		},

		contractorName: function() {
			if (!this.proposal) return '';
			if (this.proposal.accountMini != null) {
				return this.proposal.accountMini.accountName;
			} else if (this.templateMode) {
				return '{{MyBusinessName}}';
			} else {
				return '';
			}
		},
	},
};
