<template>
	<v-container class="ma-0" fluid>
    <v-row>
      <v-col cols="12">
        <div class="row-format mx-8" style="flex-wrap: wrap">
        <div class="kpi-box mr-3 mt-3">
          <div class="fit text-left" style="white-space: nowrap">{{ $t('invoices.reports.total-invoiced') }}</div>
          <div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(metrics.totalInvoiced,true,true,$store.state.defaultCurrency) }}</div>
        </div>
        <div class="kpi-box mr-3 mt-3">
          <div class="fit text-left" style="white-space: nowrap">{{ $t('invoices.reports.total-payments') }}</div>
          <div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(metrics.totalReceived,true,true,$store.state.defaultCurrency) }}</div>
        </div>
        <div class="kpi-box mr-3 mt-3">
          <div class="fit text-left" style="white-space: nowrap">{{ $t('invoices.reports.avg-days') }}</div>
          <div class="font-24 brand-medium fit mt-1">{{ metrics.avgDaysToPay }}</div>
        </div>
        </div>
      </v-col>
      <v-col cols="12" v-if="depositAccount">
        <div class="row-format mx-8" style="flex-wrap: wrap">
          <div class="kpi-box mr-3 mt-3">
            <div class="fit text-left" style="white-space: nowrap">{{ $t('invoices.reports.deposits-collected') }}</div>
            <div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(depositAccount.liabilities,true,true,$store.state.defaultCurrency) }}</div>
          </div>
          <div class="kpi-box mr-3 mt-3">
            <div class="fit text-left" style="white-space: nowrap">{{ $t('invoices.reports.deposits-applied') }}</div>
            <div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(depositAccount.credits,true,true,$store.state.defaultCurrency) }}</div>
          </div>
          <div class="kpi-box mr-3 mt-3">
            <div class="fit text-left" style="white-space: nowrap">{{ $t('invoices.reports.balance-remaining') }}</div>
            <div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(depositAccount.balance,true,true,$store.state.defaultCurrency) }}</div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><invoice-by-month :invoices="invoices"></invoice-by-month></v-col>
      <v-col cols="12">
        <div class="brand-medium font-12 mb-3 font-gray_70 mt-1">{{ $t('invoices.reports.spend-by-project') }}</div>
        <spend-by-project :invoices="invoices" :projects="projects"></spend-by-project>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {DateTime} from 'luxon'
	import InvoiceByMonth from '@/modules/invoices/charts/InvoiceByMonth';
  import SpendByProject from '@/modules/invoices/charts/SpendByProject';
  export default {
		name: 'InvoiceCharts',

		props: ['invoices','projects','depositAccount'],

		components: { SpendByProject, InvoiceByMonth },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {

		},

		computed: {
      metrics: function() {
        let result = {
          totalInvoiced: 0,
          totalReceived: 0,
          avgDaysToPay: 0,
        };

        let validStatusList = ['SENT', 'PARTIAL', 'PAID', 'WRITE-OFF'];
        let totalPayDays = 0;
        let totalPayments = 0;
        let list = this.invoices.filter((i) => validStatusList.includes(i.status));

        for (let i = 0; i < list.length; i++) {
          result.totalInvoiced += list[i].total;
          result.totalReceived += list[i].paymentTotal;

          if (list[i].paymentTotal) {
            let dateSent = DateTime.fromISO(list[i].dateSent);
            let datePaid = DateTime.fromISO(list[i].payments[0].datePaid);
            totalPayDays += Math.round(datePaid.diff(dateSent, 'days').days);
            totalPayments++;
          }
        }

        result.avgDaysToPay = Math.round(totalPayDays / totalPayments);
        if (isNaN(result.avgDaysToPay)) {
          result.avgDaysToPay = '--';
        }

        return result;
      },
		},
	};
</script>

<style scoped lang="scss"></style>
