<template>
	<div id="proposal-status-menu">
		<v-btn class="secondary-action activator-button" @click="openMenu()">
			<div class="font-12 d-flex align-center">
				<v-icon small>info</v-icon>
				<div class="ml-2">{{ lastEvent }}</div>
				<div class="unread-indicator" v-if="hasUnread"></div>
			</div>
		</v-btn>

		<basic-modal :dialog="menu" :persistent="false" @close="closeMenu()">
			<div id="proposal-status-modal">
				<v-btn icon class="close-dialog" @click="closeMenu()">
					<v-icon>$close</v-icon>
				</v-btn>
				<div class="pa-4">
					<div class="modal-title">
						{{ $t('proposal.activity') }}
					</div>
					<div id="events-wrapper">
						<div v-for="item in formattedList" :key="item.timestamp" class="event-wrapper">
							<div class="timestamp">
								<v-icon size="12">$clock</v-icon>
								{{ item.timestamp | formatForTimeAgo }}
							</div>
							<div class="event">{{ item.event }}</div>
						</div>
					</div>
				</div>
			</div>
		</basic-modal>
	</div>
</template>

<script>
	// import modal from '@bit/hecticapp.common.modal';
	import BasicModal from '@/components/BasicModal';

	export default {
		name: 'StatusMenu',

		props: ['clientId', 'proposalId', 'statusItems', 'viewedTimestamps'],

		components: { BasicModal },

		data: function() {
			return {
				menu: false,
				lastReadDate: this.getLastViewedDate(),
			};
		},

		mounted() {
			this.$store.state.eventBus.$on(this.getChannelName(), this.newStatusEvent);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(this.getChannelName(), this.newStatusEvent);
		},

		methods: {
			openMenu() {
				this.menu = true;
				this.lastReadDate = new Date();
			},

			closeMenu() {
				this.menu = false;
				this.lastReadDate = new Date();
			},

			getLastViewedDate: function() {
				let timestamp = this.viewedTimestamps['user-' + this.$store.state.loggedInUser.userId];
				if (timestamp) {
					return new Date(timestamp);
				} else {
					return null;
				}
			},

			newStatusEvent: function(event) {
				if (event.userMetadata === 'EventLog') {
					event.message.timestamp = new Date().toISOString();
					this.statusItems.push(event.message);
				}
			},

			getChannelName() {
				return this.$store.getters.getMessageChannelPrefix + '.p-' + this.proposalId;
			},
		},

		computed: {
			hasUnread: function() {
				if (!this.menu && this.lastEvent !== '') {
					let event = this.statusItems[this.statusItems.length - 1];

					if (this.lastReadDate === null || new Date(event.timestamp).getTime() > this.lastReadDate.getTime()) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			},

			lastEvent: function() {
				if (this.statusItems && this.statusItems.length > 0) {
					let event = this.statusItems[this.statusItems.length - 1];
					return event.event + ' - ' + this.$DateTime.shortDate(event.timestamp);
				} else {
					return '';
				}
			},

			formattedList: function() {
				let events = [...this.statusItems];
				let result = [];
				events = events.reverse();

				for (let i = 0; i < events.length; i++) {
					let event = events[i];
					result.push(event);
				}

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	#proposal-status-menu {
		.activator-button {
			.unread-indicator {
				background-color: var(--v-primary-base);
				height: 8px;
				width: 8px;
				border-radius: 4px;
				margin-top: -6px;
			}
		}
	}

	#proposal-status-modal {
		width: 300px;
		//background-color: var(--v-white-base);
		#events-wrapper {
			.event-wrapper {
				text-align: left;
				margin-top: 8px;
				.timestamp {
					color: var(--v-gray_70-base);
					font-size: 12px;
					.v-icon {
						margin-top: -3px;
					}
				}
				.event {
          font-weight: 300;
					font-size: 14px;
				}
			}
		}
	}
</style>
