<template>
	<img :id="id" :src="href" :style="styles" />
</template>

<script>
	export default {
		name: 'PaymentImg',

		props: {
			size: { type: String, default: '24' },
			name: { type: String, required: true },
			id: String, // id for direct styling
		},

		computed: {
			href() {
				return '/images/payment/' + this.name.toLowerCase() + '.svg';
			},
			styles() {
				return 'height:' + this.size + 'px;width:' + this.size * 1.5 + 'px';
			},
		},
	};
</script>
