<template>
  <div class="deliverable" @click="$emit('open-deliverable')">
    <div class="font-14 brand-medium mb-1">{{deliverable.name}}</div>
    <!--div class="font-12 mb-1">Status: {{currentStatus.label}}</div-->
    <div class="font-12 mb-1">{{deliverable.comments.length}} {{ $t('projects.comments') }}</div>
    <div class="font-12 mb-1">{{deliverable.files.length}} {{ $t('projects.attachments') }}</div>
    <div class="font-12 mb-1" v-if="deliverable.dueDate">{{ $t('projects.due') }} {{DateTime.fromISO(deliverable.dueDate).toFormat('DD')}}</div>
    <div style="margin-top:auto!important;" class="">
      <div class="submitted" v-if="deliverable.approvalRequestedAt">{{ $t('projects.requested') }} {{DateTime.fromISO(deliverable.approvalRequestedAt).toFormat('DD')}}</div>
      <div class="approved" v-if="lastApproval && !deliverable.approvalRequired">{{ $t('projects.submitted') }} {{DateTime.fromISO(lastApproval.approvedAt).toFormat('DD')}}</div>
    </div>
  </div>
</template>

<script>
import {DateTime} from 'luxon';

export default {
  name: 'DeliverableCard',

  props: ['deliverable','statusList'],

  components: {},

  data: function() {
    return {
      DateTime:DateTime,
    };
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {},

  computed: {
    currentStatus: function(){
      return this.statusList.find(s => s.id === this.deliverable.statusId);
    },

    lastApproval: function(){
      if(this.deliverable.approvals.length){
        return this.deliverable.approvals[this.deliverable.approvals.length-1];
      } else{
        return null;
      }
    }
  },

};
</script>

<style scoped lang="scss">
  .submitted {
    background-color: var(--v-blue_10-base);
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    width: fit-content;
  }

  .approved {
    background-color: var(--v-success_10-base);
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    width: fit-content;
  }

  .status {
    background-color: var(--status-color);
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    width: fit-content;
  }

  .deliverable {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    flex: 1 1 0px;
    text-align: left;
    border: 1px solid var(--v-gray_50-base);
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px var(--v-gray_50-base);
    padding: 8px;
    cursor: pointer;
    &:hover{
      background-color: var(--v-gray_10-base);
    }
    margin: 0px 8px 8px 0px;
  }
</style>