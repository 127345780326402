import Subscribe from '@/modules/subscriptions/Subscribe';

export default [
	{
		path: '/subscribe',
		name: 'Subscribe',
		component: Subscribe,
		meta: {
			insecure: true,
		},
	},

	{
		path: '/subscriptions',
		redirect: () => {
			return { path: '/invoices', query: { v: 'subscriptions' } }
		},
	},
]