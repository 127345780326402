<template>
	<v-container fluid class="pa-0 ma-0">
		<v-row no-gutters>
			<v-col cols="12">
				<tag-user-field
					ref="tagUserField"
					:commentToEdit="null"
					@sendComment="sendComment($event)"
				/>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12">
				<div
					style="word-wrap: anywhere"
					v-for="(comment, index) in sortedActivity"
					:key="comment.id"
					:class="canEdit(comment, index) ? 'can-edit' : ''"
					@mouseover="setHoverIndex(index)"
					@mouseleave="setHoverIndex(null)"
				>
					<div v-if="comment.events && showEvents" class="font-12 text-left my-2 brand-semilight">
						<div>
							<span class="brand-medium">{{ comment.user }}</span> {{ comment.events.join(' and ') }}
						</div>
						<div style="color: var(--v-gray_70-base)">
							<v-icon size="12" color="gray_70">$clock</v-icon>{{ comment.timestamp | formatForTimeAgo }}
						</div>
					</div>

					<div v-if="comment.comment" class="mt-2 comment-box">
						<div class="row-format align-center font-12">
							<div class="ml-1">
								{{ comment.author }}
							</div>
							<v-icon size="14" class="ml-3 mr-1">$clock</v-icon>
							<div style="color: var(--v-gray_70-base)">
								{{ comment.timestamp | formatForTimeAgo }}
							</div>
							<div v-if="canEdit(comment, index)" style="margin-left: auto">
								<v-icon small color="grey" @click.native="editComment(comment, index)" class="pointer mr-2"
									>$edit</v-icon
								>
								<v-icon small color="grey" @click.native="confirmDelete(comment)" class="pointer">$delete</v-icon>
							</div>
						</div>
						<div
							v-if="editIndex !== index"
							class="pl-1 font-14 comment"
							style="width: 100%; text-align: left;"
							v-html="formatMarkdown(comment.comment)"
						/>
						<div v-if="editIndex === index" class="pl-1 comment" style="width: 100%; text-align: left;">
							<tag-user-field
								:commentToEdit="commentToEdit"
								@sendComment="sendComment($event)"
								@saveEdit="saveEdit($event)"
								@cancelEdit="cancelEdit($event)"
							/>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>

		<confirm-dialog
			:dialog="confirmDeleteDialog"
			@close="cancelDelete()"
			@confirm="deleteComment()"
			:heading-text="$t('global.confirm')"
			:yes-text="$t('global.delete')"
			:no-text="$t('global.cancel')"
			:body-text="$t('projects.delete-note')"
		/>
	</v-container>
</template>

<script>
	import TagUserField from './TagUserField';
	import marked from 'marked';
	import moment from 'moment';
	import ConfirmDialog from '@/components/ConfirmDialog';
  import ProjectService from '@/modules/projects/ProjectService';

	export default {
		name: 'Activity',

		props: ['project', 'deliverable'],

		components: {
			TagUserField,
			ConfirmDialog,
		},

		data: function() {
			return {
				hoverIndex: null,
				editIndex: null,
				commentToEdit: null,
				commentToDelete: null,
				confirmDeleteDialog: false,
				showEvents: false,
				moment: moment,
        projectService: new ProjectService(),
			};
		},

		methods: {
			checkCommentBeforeSave: function() {
				return new Promise((resolve, reject) => {
					let pendingComment = this.$refs.tagUserField.getPendingComment();
					if (pendingComment && pendingComment.length > 0) {
						this.sendComment(pendingComment)
							.then(() => {
                this.$refs.tagUserField.clearComment();
                resolve();
              })
							.catch((err) => reject(err));
					} else {
						resolve();
					}
				});
			},

			sendComment: function(comment) {
        return this.projectService.addComment(this.project.id,this.deliverable.id,{
          comment: comment,
          privateComment: false,
          sendEmail: false,
        })
			},

			confirmDelete: function(comment) {
				this.commentToDelete = comment;
				this.confirmDeleteDialog = true;
			},

			cancelDelete: function() {
				this.commentToDelete = null;
				this.confirmDeleteDialog = false;
			},

			deleteComment: function() {
				this.projectService.deleteComment(this.project.id, this.deliverable.id, this.commentToDelete.id).then(() => {
					this.commentToDelete = null;
					this.confirmDeleteDialog = false;
				});
			},

			editComment: function(comment, index) {
				this.commentToEdit = Object.assign({}, comment);
				this.editIndex = index;
			},

			cancelEdit: function() {
				this.commentToEdit = null;
				this.editIndex = null;
			},

			saveEdit: function(comment) {
				this.projectService.editComment(this.project.id, this.deliverable.id, comment).then(() => {
					let ix = this.deliverable.comments.findIndex((c) => c.id === comment.id);
					if (ix > -1) {
						this.deliverable.comments.splice(ix, 1, comment);
					} else {
						this.deliverable.comments.push(comment);
					}
					this.editIndex = null;
					this.commentToEdit = null;
				});
			},

			canEdit(comment, index) {
				if (
					this.editIndex === null &&
					comment.authorId == this.$store.state.loggedInUser.email &&
					index === this.hoverIndex
				) {
					return true;
				} else {
					return false;
				}
			},

			setHoverIndex: function(index) {
				this.hoverIndex = index;
			},

			compareDates: function(a, b) {
				if (a.timestamp > b.timestamp) {
					return -1;
				} else if (a.timestamp < b.timestamp) {
					return 1;
				} else {
					return 0;
				}
			},

			formatMarkdown: function(comment) {
        let m = marked(comment, { breaks: true });
				m = m.replaceAll('<a href', '<a target="_blank" href');
				return m;
			},

			getName: function(user) {
				let name = '';

				if (user.firstName) {
					name += user.firstName + ' ';
				}

				if (user.lastName) {
					name += user.lastName;
				}

				if (name === '') {
					name = user.email;
				}

				return name;
			},
		},

		computed: {
			sortedActivity: function() {
				let activity = [...this.deliverable.comments, ...this.deliverable.events];
				activity.sort(this.compareDates);
				return activity;
			},
		},

		watch: {},
	};
</script>

<style scoped lang="scss">
	.comment-box {
		padding: 8px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
    word-break: break-word;
	}

	.can-edit {
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.comment ::v-deep p {
		padding: 0;
		margin: 0;
	}

	.iconBlock {
		position: relative;
	}
</style>
