import HttpClient from '@/services/HttpClient';

export default class CustomFontService {
	httpClient;

	constructor(baseUrl) {
		this.httpClient = new HttpClient(baseUrl,false);
	}

	getCustomFonts(accountId) {
		return this.httpClient
			.get(`/api/insecure/${accountId}/fonts`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
