<template>
	<div class="" id="meeting-schedule">
		<div v-if="!selectedDate">
			<div class="row-format centered mt-1 mb-2">
				<div>
					<v-btn icon :disabled="disablePrevious" @click.stop="incrementMonth(-1)"><v-icon size="30">$prev</v-icon></v-btn>
				</div>
				<div class="font-16 brand-medium px-4">
					{{ monthStart.toLocaleString({ month: 'long', year: 'numeric' }) }}
				</div>
				<div>
					<v-btn icon :disabled="disableNext"><v-icon size="30" @click.stop="incrementMonth(1)">$next</v-icon></v-btn>
				</div>
			</div>
			<v-date-picker
				class="schedule-picker"
				:min="minDate"
				full-width
				:picker-date="monthStart.toISODate()"
				:style="`--light-accent:${lightAccent}; --accent:${$store.getters.getCustomBranding.primaryColor};`"
				:no-title="true"
				@click.stop
				@change="dateClicked"
				:allowed-dates="hasAvailability"
				:first-day-of-week="$store.state.firstDayOfWeek"
			></v-date-picker>
		</div>
		<div v-else class="fill-height">
			<div class="row-format centered mt-1 mb-2">
				<div>
					<v-btn icon @click.stop="incrementDay(-1)"><v-icon size="30">$prev</v-icon></v-btn>
				</div>
				<div class="font-16 brand-medium pointer px-4" @click.stop="zoomOut()">
					{{ selectedDate.toLocaleString(DateTime.DATE_HUGE) }}
				</div>
				<div>
					<v-btn icon><v-icon size="30" @click.stop="incrementDay(1)">$next</v-icon></v-btn>
				</div>
			</div>
			<div class="brand-medium my-2 pointer" @click="selectedDate = null"></div>
			<div class="row-format" style="flex-wrap: wrap" v-if="availableSlots.length">
				<div
					v-for="(slot, index) in availableSlots"
					:key="index"
					@click.stop="selectTimeSlot(slot)"
					class="slot pointer row-format centered"
					:style="
						`--accent-color: ${$store.getters.getCustomBranding.primaryColor}; --contrast-color: ${contrast}; --light-accent:${lightAccent}`
					"
				>
					<div :class="`pa-1 ${selectedSlot && selectedSlot.start === slot.start ? 'selected' : ''}`">
						{{ DateTime.fromISO(slot.start).toLocaleString(DateTime.TIME_SIMPLE) }}
					</div>
					<div
						@click.stop="timeConfirmed"
						class="selected selected-slot pa-1"
						:style="
							`overflow: hidden; ${
								selectedSlot && selectedSlot.start === slot.start
									? 'visibility:visible; width:50%'
									: 'visibility:hidden; width:0px'
							}`
						"
					>
						Selected
					</div>
				</div>
			</div>
			<div v-else class="column-format centered">
				<v-icon class="material-symbols-outlined" size="80">hourglass_disabled</v-icon>
			</div>
			<div class="mt-5 font-12" v-if="availableSlots.length">{{ Settings.defaultZone.name }}</div>
		</div>
	</div>
</template>

<script>
	import { DateTime, Settings } from 'luxon';
	import chroma from 'chroma-js';
	import timezones from '@/assets/data/timezones.json';

	export default {
		name: 'MeetingSchedule',

		props: ['meet', 'schedulerId', 'start', 'httpClient'],

		components: {},

		data: function() {
			return {
				timezones: timezones,
				DateTime: DateTime,
				selectedDate: null,
				selectedSlot: null,
				Settings: Settings,
				monthStart: DateTime.now().startOf('month'),
				monthEnd: DateTime.now().startOf('month').plus({ months: 1 }).minus({ seconds: 1 }),
				filter: null,
				timezoneMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			timeConfirmed: function() {
				this.$emit('time-confirmed', this.selectedSlot);
			},

			incrementDay: function(count) {
				this.selectedDate = this.selectedDate.plus({ days: count });
				this.selectedSlot = null;

				if(this.selectedDate < this.monthStart){
					this.incrementMonth(-1);
				}else if(this.selectedDate > this.monthEnd){
					this.incrementMonth(1);
				}
			},

			zoomOut: function() {
				this.selectedDate = null;
				this.selectedSlot = null;
				this.timeConfirmed();
			},

			incrementMonth: function(count) {
				this.monthStart = this.monthStart
					.startOf('month')
					.plus({ months: count });
				this.monthEnd = this.monthStart.plus({ months: 1 }).minus({ seconds: 1 });

				this.selectedSlot = null;
				this.$store.commit('startLoading');

				let params = {
					accountId: this.meet.account.accountId,
					id: this.meet.id,
					start: this.monthStart.toISODate(),
					end: this.monthEnd.toISODate(),
					conflictCheck: true,
				};

				this.httpClient
					.get('/api/meeting/availability', { params: params })
					.then((res) => {
						this.meet.availableMeetings.splice(0, this.meet.availableMeetings.length);
						this.meet.availableMeetings.push(...res.data);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			selectTimeSlot: function(slot) {
				this.selectedSlot = slot;
				this.timeConfirmed();
			},

			dateClicked: function(date) {
				this.selectedDate = DateTime.fromISO(date).startOf('day');
				this.selectedSlot = null;
				this.timeConfirmed();
			},

			hasAvailability(dateString) {
				let date = DateTime.fromISO(dateString).startOf('day');
				if (this.availableDates.findIndex((d) => d.toMillis() === date.toMillis()) > -1) {
					return true;
				} else {
					return false;
				}
			},

			suffix: function(num) {
				let j = num % 10,
					k = num % 100;
				if (j === 1 && k !== 11) {
					return `${num}st`;
				} else if (j === 2 && k !== 12) {
					return `${num}nd`;
				} else if (j === 3 && k !== 13) {
					return `${num}rd`;
				} else {
					return `${num}th`;
				}
			},
		},

		computed: {
			disablePrevious: function() {
				let m = this.monthStart;
				let t = DateTime.now().startOf('month');
				if (t < m) {
					return false;
				} else {
					return true;
				}
			},

			disableNext: function() {
				if (this.meet.maxMeetingDate) {
					let m = this.monthEnd;
					let t = DateTime.fromISO(this.meet.maxMeetingDate).endOf('month');
					return t <= m;
				} else {
					return false;
				}
			},

			minDate: function() {
				return DateTime.now().toISODate();
			},

			contrast: function() {
				let black = chroma.contrast(this.$store.getters.getCustomBranding.primaryColor, '#000000');
				let white = chroma.contrast(this.$store.getters.getCustomBranding.primaryColor, '#FFFFFF');
				if (black > white) {
					return '#000';
				} else {
					return '#FFF';
				}
			},

			lightAccent: function() {
				return chroma(this.$store.getters.getCustomBranding.primaryColor)
					.alpha(0.18)
					.hex();
			},

			availableSlots: function() {
				let result = [];
				if (this.selectedDate) {
					this.meet.availableMeetings.forEach((m) => {
						if (
							DateTime.fromISO(m.start)
								.startOf('day')
								.toISODate() === this.selectedDate.toISODate()
						) {
							result.push(m);
						}
					});
				}
				return result;
			},

			availableDates: function() {
				let dates = [];
				this.meet.availableMeetings.forEach((m) => {
					let date = DateTime.fromISO(m.start).startOf('day');
					let ix = dates.findIndex((d) => d.toMillis() === date.toMillis());
					if (ix === -1) {
						dates.push(date);
					}
				});
				return dates;
			},
		},
	};
</script>

<style lang="scss">
	#meeting-schedule {
		.v-date-picker-header {
			display: none !important;
		}

		.v-date-picker-table {
			height: unset !important;

			td {
				padding-bottom: 8px;
			}

			.v-btn {
				&:not(.v-btn--disabled) {
					font-weight: 800;
					font-size: 13px;
					color: var(--accent) !important;
					background-color: var(--light-accent);
				}
			}
		}

		.slot {
			min-width: 170px;
			flex: 1 1 0px;
			border: 1px solid var(--v-gray_30-base);
			border-radius: 4px;
			margin: 4px;
			color: var(--accent-color);
			font-weight: 600;
			transition: all 150ms ease;

			&:hover {
				box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px;
			}
		}

		.selected {
			width: 50%;
		}

		.selected-slot {
			background-color: var(--light-accent);
			color: var(--accent-color);
			transition: all 150ms ease;
		}

		.dot-class {
			border-radius: 20px;
			width: 20px;
			height: 20px;
		}
	}
</style>
