<template>
	<div class="confirm-dialog-div">
		<v-btn class="close-dialog" icon rounded @click.prevent="handleClose()">
			<v-icon size="20">$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title text-center mb-2 mt-4" v-if="headingText">
				<span class="pr-1">
					<v-icon :color="severity" class="mt-n1" size="24">{{ icon }}</v-icon>
				</span>
				{{ headingText }}
			</div>
			<div v-else class="mt-6"></div>
			<div class="modal-subtitle text-center" v-if="bodyText" v-html="bodyText"></div>
		</div>
		<v-form @submit.prevent="handleSubmit()" ref="confirmDialog" id="confirmDialog" lazy-validation>
			<div class="modal-footer">
				<v-btn v-if="!hideNo" min-width="100" :class="`${noIsSuperAction ? 'super-action' : 'primary-action'} mr-1`" @click.prevent="handleClose()">{{
					no
				}}</v-btn>
				<v-btn type="submit" min-width="100" elevation="0" :class="`${!noIsSuperAction ? 'super-action' : 'primary-action'} ml-1`" @click.prevent="handleSubmit()">
					{{ yes }}
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'ConfirmModal',

		props: {
			severity: { type: String, required: false, default: 'warning' }, // info, warning, error, success
			icon: { type: String, required: false, default: '$alert' }, // $alert, $info, etc.
			headingText: String,
			bodyText: String,
			hideNo: { type: Boolean, required: false, default: false },
			yesText: { type: String, required: false  },
			noText: { type: String, required: false  },
			persistent: { type: Boolean, default: false },
      noIsSuperAction: { type: Boolean, required: false, default: true },
		},

		data() {
			return {
        yes: this.yesText ? this.yesText : this.$t('global.yes'),
        no: this.noText ? this.noText : this.$t('global.no')
      };
		},

		mounted() {

    },

		methods: {
			handleSubmit() {
				this.$emit('result', true);
			},
			handleClose() {
				this.$emit('result', false);
			},
		},
	};
</script>

<style lang="scss">
	.confirm-dialog-div {
		background-color: var(--v-white-base);
		position: relative;
		border-radius: 4px !important;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
