import HttpClient from '@/services/HttpClient';

export default class ProposalViewerService {
	httpClient;
	token;

	constructor(token, podUrl) {
		this.httpClient = new HttpClient(podUrl, false);
		this.token = token;
	}

	getProposal() {
		return this.httpClient
			.get('/api/docs/proposal', {
				params: {
					token: this.token,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	executeProposal(result) {
		return this.httpClient
			.post(`/api/docs/proposal/execute?token=${this.token}`, result)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendComment(clientId,proposalId,comment){
		return this.httpClient
			.post(`/api/docs/proposal/comment?token=${this.token}`, comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateComment(clientId,proposalId,comment){
		return this.httpClient
			.put(`/api/docs/proposal/comment?token=${this.token}`,comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteComment(clientId,proposalId,commentId){
		return this.httpClient
			.delete(`/api/docs/proposal/comment?token=${this.token}&commentId=${commentId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setLastViewed(){
		return this.httpClient
			.post(`/api/docs/proposal/viewed?token=${this.token}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(clientId, proposalId, fileName) {
		return this.httpClient
			.get(`/api/docs/proposal/files`, {
				params: {
					token: this.token,
					clientId: clientId,
					proposalId: proposalId,
					fileName: fileName,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
