<template>
	<div class="new-ach-dialog-div">
		<v-container fluid class="mt-0 mb-4">
			<v-row dense>
				<v-col cols="1"></v-col>
				<v-col cols="10"><h2>{{ $t('invoices.verify-ach-account.enter-verification') }}</h2></v-col>
				<v-col cols="1"><v-icon @click="handleClose">$close</v-icon></v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<v-form ref="accountForm" @submit.prevent v-model="isValid">
						<v-text-field
							v-model="amount1"
							:label="$t('invoices.verify-ach-account.enter-deposit-1')"
							hide-details
							maxlength="2"
							prefix="$0."
							:rules="requiredRules"
							persistent-placeholder
							class="h-outline"
						></v-text-field>
						<v-text-field
							v-model="amount2"
							:label="$t('invoices.verify-ach-account.enter-deposit-2')"
							hide-details
							maxlength="2"
							prefix="$0."
							:rules="requiredRules"
							persistent-placeholder
							class="h-outline"
						></v-text-field>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
		<div style="border-top: 1px solid var(--v-gray_50-base)" class="pt-2 pb-4">
			<v-btn class="secondary-action mr-1" @click="handleClose">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="primary-action ml-2" @click="verifyAccount" :disabled="!isValid">{{ $t('global.verify') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import PaymentsService from '@/modules/invoices/PaymentsService';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'VerifyAchAccount',

		props: ['bankAccount'],

		components: {},

		data: function() {
			return {
				amount1: null,
				amount2: null,
				paymentsService: new PaymentsService(),
				isValid: false,

				requiredRules: [(v) => !!v || 'Required'],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleError: function(message) {
				this.$store.commit('stopLoading');

				let binding = {
					headingText: this.$t('error.connecting-account'),
					bodyText: message,
					hideNo: true,
					yesText: this.$t('global.okay'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, true, false);
			},

			verifyAccount: function() {
        this.$store.commit('startLoading');
				this.paymentsService
					.verifyBankAccount(this.bankAccount.id, this.amount1, this.amount2)
					.then((res) => {
            this.$store.commit('stopLoading');
						this.$emit('result',res.data);
					})
					.catch((err) => {
						this.handleError(err.response.data.message);
					});
			},

			handleClose() {
				this.$emit('result', false);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.new-ach-dialog-div {
		background-color: var(--v-white-base);
		position: relative;
		border-radius: 4px !important;
		width: 300px;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
