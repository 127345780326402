<template>
	<div class="mx-3">
		<v-data-table
			item-key="id"
			class="list-view"
			fixed-header
			:headers="headers"
			:items="subscriptionsComputed"
			:hide-default-footer="true"
			:items-per-page="-1"
      @click:row="handleItemClick($event)"
      style="cursor:pointer;"
		>
			<template v-slot:item.periodStart="{ item }">
				<div style="height: 100%" class="row-format align-center">
					<div v-if="item.periodStart && item.periodEnd">
						{{ DateTime.fromISO(item.periodStart).toLocaleString() }} -
						{{ DateTime.fromISO(item.periodEnd).toLocaleString() }}
					</div>
					<div v-else>--</div>
				</div>
			</template>

			<template v-slot:item.daysUntilDue="{ item }">
				<div style="height: 100%" class="row-format align-center">
					<div v-if="item.daysUntilDue">{{ item.daysUntilDue }}</div>
					<div v-else>--</div>
				</div>
			</template>

			<template v-slot:item.subscriptionItems="{ item }">
				<div style="height: 100%" class="row-format align-center">
					<div v-for="(subItem, index) in item.subscriptionItems" :key="item.clientMini.id + ':' + index">
						{{ subItem.quantity }} x {{ subItem.description }} @
						{{ $formatters.dollars(subItem.amount, true, true, subItem.currency) }}
					</div>
				</div>
			</template>

			<template v-slot:item.status="{ item }">
				<div style="height: 100%" class="row-format align-center">
					<v-icon v-if="item.status === 'active'" color="success" small>$check</v-icon>
					<v-icon v-else-if="item.status === 'canceled' || item.status === 'ended'" color="gray_80" small
						>$cancel</v-icon
					>
					<v-icon v-else-if="item.status === 'sent'" color="primary" small>$email</v-icon>
					<v-icon v-else color="warning" small>$alert</v-icon>

          <div v-if="item.status === 'active' && item.cancelAtPeriodEnd" class="ml-1">{{ $t('subscriptions.cancellation') }}</div>
					<div v-else class="ml-1" style="text-transform: capitalize">{{ item.status.replace('_', ' ') }}</div>
				</div>
			</template>
		</v-data-table>

    <div class="text-left">
      <v-btn class="mt-3 primary-action" v-if="subscriptionsComputed.length" @click="manageSubscriptions">{{ $t('subscriptions.manage') }}</v-btn>
    </div>
	</div>
</template>

<script>
	import SubscriptionService from '@/modules/subscriptions/SubscriptionService';
	import { DateTime } from 'luxon';
  import SubscriptionPayments from '@/modules/subscriptions/SubscriptionPayments';

	export default {
		name: 'SubscriptionList',

		props: [],

		components: {},

		data: function() {
			return {
				subscriptionService: new SubscriptionService(),
				subscriptions: [],
				requests: [],
				DateTime: DateTime,
			};
		},

		mounted() {
			this.getSubscriptions();
			this.getSubscriptionRequests();
		},

		beforeDestroy() {},

		methods: {
			getSubscriptions: function() {
				this.subscriptionService.getSubscriptions().then((res) => {
					this.subscriptions.splice(0, this.subscriptions.length);
					this.subscriptions.push(...res.data);
				});
			},

			getSubscriptionRequests: function() {
				this.subscriptionService.getSubscriptionRequests().then((res) => {
					this.requests.splice(0, this.requests.length);
					this.requests.push(...res.data);
				});
			},

      handleItemClick: function(subscription){
        if(subscription.status === 'sent'){
          document.location.href = subscription.checkoutUrl;
        }else{
          this.viewPayments(subscription);
        }
      },

      viewPayments: function(subscription){
        let binding = {
          subscription: subscription,
        };

        this.$store.state.globalModalController.openModal(SubscriptionPayments,binding);
      },

      manageSubscriptions: function(){
        this.subscriptionService.getSubscriptionPortalLink(document.location.href).then((res) => {
          document.location.href = res.data;
        })
      }
		},

		computed: {
			subscriptionsComputed: function() {
				let result = [...this.subscriptions];

				for (let i = 0; i < this.requests.length; i++) {
					let request = this.requests[i];

					let pendingSubscription = {
						id: request.id,
						clientMini: {},
						periodStart: null,
						periodEnd: null,
						daysUntilDue: null,
						subscriptionItems: [],
						checkoutUrl: request.checkoutUrl,
						status: 'sent',
					};

          for(let j=0; j < request.productSelections.length; j++){
            let product = request.productSelections[j];
            pendingSubscription.subscriptionItems.push({
              quantity: product.quantity,
              description: product.subscriptionProduct.name,
              amount: product.subscriptionProduct.prices[0].unitAmount / 100,
              currency: product.subscriptionProduct.prices[0].currency,
            });
          }

					result.push(pendingSubscription);
				}
				return result;
			},

			headers: function() {
				return [
					{ text: this.$t('stripe.subscription.period'), value: 'periodStart' },
					{ text: this.$t('stripe.subscription.days-until'), value: 'daysUntilDue' },
					{ text: this.$t('stripe.subscription.items'), value: 'subscriptionItems' },
					{ text: this.$t('stripe.subscription.status'), value: 'status' },
					{ text: '', value: 'action', align: 'right' },
				];
			},
		},
	};
</script>

<style scoped lang="scss"></style>
