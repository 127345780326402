import HttpClient from '@/services/HttpClient';

export default class AgreementViewerService {
	constructor(podUrl, token, secretKey) {
		this.token = token;
		this.podUrl = podUrl;
		this.secretKey = secretKey;
		this.httpClient = new HttpClient(podUrl, false);
	}

	getAgreement() {
		return this.httpClient
			.get(`/api/docs/agreement`, {
				params: {
					token: this.token,
					secretKey: this.secretKey,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updatePaymentIntent(amount) {
		return this.httpClient
			.get(`/api/docs/agreement/paymentIntent`, {
				params: {
					token: this.token,
					amount: amount,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	finalizeAgreement(agreement) {
		return this.httpClient
			.post(`/api/docs/agreement`, agreement, {
				params: {
					token: this.token,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	finalizeAgreementFormData(agreement){
		let formData = new FormData();
		formData.append('agreement', JSON.stringify(agreement));
		formData.append('token', this.token);

		if(agreement.fileUploads) {
			let fileUploads = agreement.fileUploads;
			fileUploads.forEach((a) => {
				formData.append('files', a);
			});
			delete agreement.fileUploads;
		}

		return this.httpClient
			.post(`/api/docs/agreement/form-data`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
