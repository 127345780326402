export default {
    data: function () {
        return {};
    },

    methods: {
        padding: function(value){
            if(value === 0){
                return 0;
            }
            if(this.pageWidth <= 400) {
                return 12;
            }else if(this.pageWidth <= 800){
                return 20;
            }else{
                return value;
            }
        }
    }
}