<template>
  <div :style="`height: calc(100vh - 55px)`" class="content show-scrollbar pa-2">
    <v-container fluid v-if="page && page.type === 'Internal'" :key="refreshKey">
      <v-row v-for="(row, index) in pageLayout" :key="index">
        <v-col v-for="col in row" :key="'key' + col.contentIndex" :md="col.cols" cols="12">
          <content-block :index="col.contentIndex" :content="page.content"></content-block>
        </v-col>
      </v-row>
    </v-container>
    <div style="width:100%; height: 100%" v-else-if="page && page.type === 'iFrame'" class="show-scrollbar">
      <iframe :src="page.hyperLink" width="100%" height="98%" style="border: none"></iframe>
    </div>
  </div>
</template>

<script>
	import ContentBlock from '@/modules/custom/ContentBlock';

	export default {
		name: 'CustomPage',

		props: ['slug'],

		components: { ContentBlock },

		data: function() {
			return {
				page: null,
        refreshKey: 0,
			};
		},

		mounted() {
			this.setupPage();
		},

		beforeDestroy() {},

		methods: {
			setupPage: function() {
        this.refreshKey++;
				this.page = this.$store.state.customPages.find((p) => p.slug === this.slug);
			},

			colsInRow: function(row, contentIndex) {
				let cols = 12 / row.cols;
				let result = [];
				let i = cols;

				while (i <= 12) {
					result.push({
						cols: cols,
						contentIndex: contentIndex.index++,
					});

					i = i + cols;
				}
				return result;
			},
		},

		watch: {
      slug: function() {
				this.setupPage();
			},
		},

		computed: {
			layoutOptions: function() {
				let options = [];
				options.push({
					label: 'Single page',
					key: '1',
					layout: [{ cols: 1 }],
				});

				options.push({
					label: 'Side-by-side',
					key: '2',
					layout: [{ cols: 2 }],
				});
				options.push({
					label: 'Header & Two-column',
					key: '1,2',
					layout: [{ cols: 1 }, { cols: 2 }],
				});
				options.push({
					label: 'Header, Two-column, Footer',
					key: '1,2,1',
					layout: [{ cols: 1 }, { cols: 2 }, { cols: 1 }],
				});
				return options;
			},

			currentLayout: function() {
				let result = this.layoutOptions.find((l) => l.key === this.page.layoutKey);
				if (!result) {
					result = this.layoutOptions[0];
				}
				return result;
			},

			pageLayout: function() {
				let result = [];
				let contentIndex = {
					index: 0,
				};
				for (let i = 0; i < this.currentLayout.layout.length; i++) {
					result.push(this.colsInRow(this.currentLayout.layout[i], contentIndex));
				}
				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
