<template>
	<div @click.stop style="margin-left: 0px; margin-right: 3px;">
		<draggable
			:list="tasks"
			tag="section"
			:animation="200"
			handle=".drag"
			@end="endDragHandler"
		>
			<div v-for="(t, index) in tasks" :key="t.id" :class="`row-format ${detailView ? 'task-item-detail' : 'task-item'}`">
				<div style="margin-top:1px">
					<v-checkbox style="margin-top: -7px;" dense :ripple="false" v-model="t.complete" :disabled="disabled" hide-details @change="$emit('tasks-updated')"></v-checkbox>
				</div>
				<div
					style="outline: none; max-width: 320px;"
					:contenteditable="!t.complete && !disabled"
					:id="`task${t.id}`"
					:class="'force-wrap edit-content ' + (t.complete ? 'item-complete' : '')"
					@keyup.delete="checkDeleteTask(t, index, $event.target.innerText)"
					@keydown.enter.exact.prevent="
						setDescription(t, $event.target.innerText);
						addNewTask(index + 1);
					"
					@blur="setDescription(t, $event)"
				>{{t.description}}</div>
				<div style="margin-left: auto" class="row-format" v-if="!disabled">
					<div v-if="detailView" class="icon mr-1 pointer" @click="deleteTask(index)"><v-icon :size="detailView ? 18 : 12">$delete</v-icon></div>
					<div class="drag icon"><v-icon :size="detailView ? 18 : 12">$drag</v-icon></div>
				</div>
			</div>
		</draggable>

		<div :class="detailView ? 'add-full' : 'add-compact'" @click.stop="addNewTask(tasks.length)" v-if="!disabled">
			<div style="width:fit-content"><v-icon size="14" class="mb-1">$plus</v-icon> {{ $t('projects.add-task') }}</div>
		</div>

	</div>
</template>

<script>
	import { v4 as uuidv4 } from 'uuid';
	import Draggable from 'vuedraggable';

	export default {
		name: 'Tasks',
		props: ['deliverable','detailView','disabled'],

		components: {
			Draggable,
		},

		data: function () {
			return {
				updateCounter: 0,
				focusId: null,
			};
		},

		methods: {
			focusEditable: function(id){
				this.focusId = id;
			},

			doFocus: function(){
				if(!this.focusId){
					return;
				}
				this.$nextTick(() => {
					const el = document.getElementById(this.focusId);
					this.focusId = null;

					if(!el){
						return;
					}

					const selection = window.getSelection();
					const range = document.createRange();
					selection.removeAllRanges();
					range.selectNodeContents(el);
					range.collapse(false);
					selection.addRange(range);
					el.focus();
				})
			},

			clearEmpty: function(){
				for(let i=(this.tasks.length-1); i >=0; i--){
					if(!this.tasks[i].description){
						this.tasks.splice(i,1);
					}
				}
			},

			checkDeleteTask: function (task, index, value) {
				if (this.$validations.isEmpty(value)) {
					task.description = null;

					if (task.deleteCheck) {
						this.deleteTask(index);
					} else {
						task.deleteCheck = true;
					}
				} else {
					task.deleteCheck = null;
				}
			},


			deleteTask: function(index){
				this.tasks.splice(index, 1);

				if(this.tasks.length === 0){
					this.$emit('empty');
				}

				this.$emit('tasks-updated');

				if (this.tasks.length > 0 && index !== 0) {
					this.focusEditable('task' + this.tasks[index - 1].id);
				}
			},

			setDescription: function (task, event) {
				if (event.target && event.target.innerText) {
					task.description = event.target.innerText;
					event.target.innerText = task.description;
					task.deleteCheck = null;

					this.$emit('tasks-updated');
				}
			},

			addNewTask: function (index) {
				let id = uuidv4().replace(/-/g, '');

				this.tasks.splice(index, 0, {
					id: id,
					complete: false,
					description: '',
				});

				this.$emit('tasks-updated');
				this.focusEditable('task' + id);
			},

			endDragHandler: function () {
				this.updateCounter++;
			},
		},

		computed: {
			tasks: function(){
				return this.deliverable.tasks;
			}
		},

		watch: {
			tasks: function(){
				this.doFocus();
			}
		}
	};
</script>

<style scoped lang="scss">
	.task-item-detail {
		transition: all ease 250ms;
		font-size: 14px;
		padding: 5px 0px 5px 2px;

		&:hover {
			background-color: var(--v-gray_10-base);

			.icon {
				visibility: visible;
			}
		}

		.icon {
			visibility: hidden;
		}
	}

	.task-item {
		border-top: 1px solid var(--v-gray_30-base);
		padding: 8px 8px 8px 8px;
		transition: all ease 250ms;
		font-size: 12px;

		&:last-child{
			border-bottom: 1px solid var(--v-gray_30-base);
		}

		&:hover {
			background-color: var(--v-gray_10-base);

			.icon {
				visibility: visible;
			}
		}

		.icon {
			visibility: hidden;
		}
	}

	.add-full {
		cursor: pointer;
		color: var(--v-gray_70-base);
		font-size: 14px;
		font-weight:500;
		padding-left: 6px;
		padding-top: 6px;
	}

	.add-compact {
		cursor: pointer;
		color: var(--v-gray_70-base);
		font-size: 12px;
		font-weight:500;
		padding: 8px 12px;
	}

	.edit-content {
		width:100%;
		outline: none;
		white-space: pre-line;
		color: var(--v-gray_80-base);
		text-align: left;
	}

	.item-complete {
		color: var(--v-gray_70-base)!important;
		text-align: left;
	}

	.drag {
		cursor: grab!important;

		&:active {
			cursor: grabbing!important;
		}
	}
</style>
