<template>
	<div :id="item.id" @click="$emit('click', $event)" :style="`padding-left: ${padding(item.pl)}px; padding-right: ${padding(item.pr)}px; padding-bottom: ${item.pb}px; padding-top: ${item.pt}px;`">
		<v-container>
			<v-row>
				<v-col :cols="cols" v-if="userSigners.length" class="column-format gap-2">
					<signer
						v-for="userSigner in userSigners"
						:key="userSigner.id"
						:agreement="agreement"
						:item="element"
						:signer="userSigner"
						:is-current-user="signer && (signer.id === userSigner.id)"
						@signed="handleSigned($event)"
					></signer>
				</v-col>
				<v-col :cols="cols" v-if="clientSigners.length" class="column-format gap-2">
					<signer
						v-for="clientSigner in clientSigners"
						:key="clientSigner.id"
						:agreement="agreement"
						:item="element"
						:signer="clientSigner"
						:is-current-user="signer && (signer.id === clientSigner.id)"
						@signed="handleSigned($event)"
					></signer>
				</v-col>
				<v-col v-if="userSigners.length === 0 && clientSigners.length === 0" class="font-gray_80 py-8">
					<v-btn @click.stop="$store.state.eventBus.$emit('agreement-show-signers')" elevation="0" color="secondary" outlined>Add signers</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import Signer from '@/modules/agreements/schema/Signer';
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'Signature',

		props: ['agreement', 'item', 'active','signer','isMobile','pageWidth'],

		mixins: [ResponsiveMixin],

		components: { Signer },

		data: function() {
			return {
				element: { ...this.item },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleSigned: function(signer) {
				if (signer.id === this.signer.id) {
					this.$emit('signed', signer);
				}
			},

      finalize: function(){
        return new Promise((resolve,reject) => {
          if(this.signer) {
            if (!this.item.signedBy.includes(this.signer.id)) {
              this.errorState();
              reject();
              return false;
            }
          }
          this.goodState();
          resolve();
        })
      },

      errorState: function(){
        document.getElementById(this.item.id).scrollIntoView({ block: 'start', behavior: 'smooth' });
        document.getElementById(this.item.id).classList.add("red-border");
      },

      goodState: function(){
        document.getElementById(this.item.id).classList.remove("red-border");
      }
		},

		computed: {
			userSigners: function() {
				if(this.item.clientOnly){
					return [];
				}else {
					return this.agreement.signers.filter((s) => s.signerType === 'USER');
				}
			},

			clientSigners: function() {
				return this.agreement.signers.filter((s) => s.signerType === 'CLIENT');
			},

			cols: function() {
        if(this.agreement.styles.pageSize === 'RESPONSIVE' && this.$vuetify.breakpoint.smAndDown){
          return 12;
        }else {
          return this.userSigners.length && this.clientSigners.length ? 6 : 12;
        }
			},
		},
	};
</script>

<style scoped lang="scss"></style>
