<template>
  <div class="tabs-component">
    <div :class="`row-format align-center ${titleClass()}`" ref="moduleTitle" v-if="title" @click="goTo(title)">
      <div><span>{{$t(title.label)}}</span></div>
      <div v-if="parentValue" class="row-format align-center">
        <v-icon size="12" color="secondary" class="mx-1">$chevronRight</v-icon>
        <div @click.stop="$router.push(parentValue.route)">{{$t(parentValue.parent)}}</div>
      </div>
      <div v-if="navDetailValue" class="row-format align-center">
        <v-icon size="12" color="secondary" class="mx-1">$chevronRight</v-icon>
        <div class="tab-item selected" style="cursor: default!important;" @click.stop>{{$t(navDetailValue)}}</div>
      </div>
    </div>
    <div class="tabs-wrapper" v-if="visibleTabs.length || menuTabs.length" ref="visibleTabs">
      <div :class="tabClass(tab)" v-for="tab in visibleTabs" :key="tab.uri" @click="goTo(tab)">
        {{ $t(tab.label) }}
      </div>
      <div class="menu-wrapper" v-if="menuTabs.length">
        <v-menu
            ref="menuTabs"
            v-model="menuTabsOpen"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <div :class="menuActivatorClass" v-on="on" ref="menuTabsActivator">
              {{ $t(moreVerb) }}
              <h-icon-3 v-if="menuTabsOpen" class="ml-1" size="10" name="arrow-up" color="var(--v-secondary-base)"></h-icon-3>
              <h-icon-3 v-else size="10" class="ml-1" name="arrow-down" color="var(--v-secondary-base)"></h-icon-3>
            </div>
          </template>
          <v-list>
            <v-list-item v-for="tab in menuTabs" :key="tab.uri" @click="goTo(tab)">
              {{ $t(tab.label) }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>


<script>
import HIcon3 from "@/components/HIcon3";
export default {
  name: 'TopNav',
  props: {
    offsetWidth: { type: Number, required: false, default: 0 }, // Subtracted from overall width
    currentPath: {type: String, required: true },
    tabConfig: { type: Object, required: true },
  },
  emits: ['set-page'],
  components: { HIcon3 },
  data() {
    return {
      availableWidth: 0,
      visibleTabs: [],
      menuTabs: [],
      title: null,
      menuTabsOpen: false,
      windowWidth: window.innerWidth,
      leftNavWidth: this.$store.state.leftNavExpanded ? 228 : 64, // See leftNavExpanded watcher below, too
    };
  },

  mounted() {
    setTimeout(() => this.onResize(),500);
    window.addEventListener('resize', this.debounce(this.onResize, 300));
  },


  beforeDestroy() {
    window.removeEventListener('resize', this.debounce(this.onResize, 300));
  },

  methods: {
    goTo(tab) {
      if (this.currentPath === tab.uri) return;
      this.$router.push(tab.uri);
    },

    tabClass(tab) {
      // console.log(page);
      let css = ['tab-item'];
      if (tab.uri === this.currentPath) {
        css.push('selected');
      }
      return css.join(' ');
    },

    titleClass() {
      let css = ['module-title'];
      if (this.title.uri && this.title.uri !== this.currentPath) {
        css.push('pointer');
      }
      return css.join(' ');
    },

    onResize() {
      this.menuTabsOpen = false;
      this.windowWidth = window.innerWidth;
      this.availableWidth = this.windowWidth - this.offsetWidth - this.leftNavWidth;
      this.setTabs();
    },

    async setTabs() {
      // Init arrays
      this.visibleTabs.splice(0, this.visibleTabs.length);
      this.menuTabs.splice(0, this.menuTabs.length);

      if (!this.tabs.length) return;


      if(!this.$store.getters.getFeatures.summaryFinancialsEnabled){
        let ix = this.tabs.findIndex(t => t.uri === '/invoices?v=reports');
        if(ix > -1){
          this.tabs.splice(ix,1);
        }
      }

      let i = 0;
      while (i < this.tabs.length) {
        let totalTabWidth;

        // Add page to tabs
        this.visibleTabs.push(this.tabs[i]);

        // Wait for DOM to update then get the total width used
        await this.$nextTick(()=> {
          totalTabWidth = this.totalTabWidth();
        });
        //console.log('totalTabWidth', totalTabWidth);

        if (!this.widthOk(i, totalTabWidth)) {
          this.visibleTabs.pop();
          break;
        }
        i++;
      }

      while (i < this.tabs.length) {
        this.menuTabs.push(this.tabs[i]);
        i++;
      }
    },

    widthOk(i, totalTabWidth) {
      if (i === this.tabs.length - 1) { // if last tab, don't include "more" button
        return totalTabWidth <= this.availableWidth;
      }
      return totalTabWidth <= this.availableWidth - this.widthOfMoreButton();
    },

    widthOfMoreButton() {
      let tabWidth = 50 + 24 + 24; // More link (approx 50px) + gaps
      // tabWidth += 40; // Shim it a bit for good measure and beauty
      return tabWidth;
    },

    totalTabWidth() {
      let tabWidth = 0;

      if (this.$refs.moduleTitle) {
        //console.log('titleWidth', this.$refs.moduleTitle.clientWidth);
        tabWidth = this.$refs.moduleTitle.clientWidth;
        tabWidth += 24; // gap
      } else {
        console.log('moduleTitle is NOT defined');
      }

      let tabs = [];
      if (this.$refs.visibleTabs) {
        tabs = Array.from(this.$refs.visibleTabs.children);
      }

      for (let v of tabs) {
        //console.log(v.innerHTML, v.clientWidth);
        tabWidth += v.clientWidth;
        tabWidth += 24; // gap
      }
      //console.log('tabWidth', tabWidth);
      return tabWidth;
    },

    debounce(func, delay) {
      let debounceTimer;
      return function() {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func.apply(context, args), delay)
      }
    }
  },

  watch: {
    tabs: {
      deep: true,
      handler(){
        setTimeout(() => this.setTabs(),100);
      }
    },

    tabConfig: {
      immediate: true,
      deep: true,
      handler(config) {
        this.tabs.splice(0, this.tabs.length);
        this.tabs.push(...config.tabs);
        this.title = config.title;
        this.setTabs();
      }
    },
    '$store.state.leftNavExpanded': {
      immediate: false,
      handler(expanded) {
        //console.log('leftNavExpanded changed', expanded);
        if (expanded) {
          this.leftNavWidth = 228;
        } else {
          this.leftNavWidth = 64;
        }
        this.onResize('leftNav');
      }
    },
  },

  computed: {
    tabs: function(){
      return this.$store.state.tabs;
    },

    navDetailValue: function(){
      if(this.title && this.title.navDetailKey){
        let value = this.$store.state.navDetailKeys.find(k => k.key === this.title.navDetailKey);
        if(value){
          return value.value;
        }
      }

      return null;
    },

    parentValue: function(){
      if(this.title && this.title.navDetailKey){
        let value = this.$store.state.navDetailKeys.find(k => k.key === this.title.navDetailKey);
        if(value && value.parent){
          return {
            parent: value.parent,
            route: value.parentRoute
          }
        }
      }

      return null;
    },

    moreVerb() {
      if (this.visibleTabs.length < this.tabs.length) {
        let tab = this.menuTabs.find((t) => t.uri === this.currentPath);
        if (tab) {
          return tab.label;
        }
      }
      return this.$t('global.more');
    },
    menuActivatorClass() {
      let css = ['tab-item', 'row-format'];
      let idx = this.menuTabs.findIndex((t) => t.uri === this.currentPath);
      if (idx > -1) {
        css.push('selected');
      }
      return css.join(' ');
    }
  },
};
</script>

<style lang="scss">
.tabs-component {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .module-title {
    font-size: 14px;
    font-weight: 500;
    color: var(--v-custom-base);
    padding-left: 12px;
  }
  .tabs-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  .tab-item {
    white-space: nowrap;
    color: var(--v-custom-base);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;

    &:not(.selected) {
      cursor: pointer;
      &:hover {
        opacity: .5;
      }
    }

    &.selected {
      color: var(--v-primary-base);
      border-bottom: 4px solid var(--v-primary-base);
      border-top: 4px solid transparent;
      height: 56px;
    }
  }
}
</style>