<template>
	<div
		:class="`${active ? 'form-item-active' : 'form-item-inactive'}`"
		:style="`color: ${styles.fontColor}!important; font-family: ${styles.font}!important`"
	>
		<component
			ref="component"
			:key="item.type"
			:is="item.type"
			:item="item"
			:styles="styles"
			:font="font"
			:active="active"
			:edit-mode="editMode"
			:account-logo="accountLogo"
			:index="index"
			:tokens="tokens"
			:invoice="invoice"
			:token-map="tokenMap"
			:in-builder="inBuilder"
			:deposit-account="depositAccount"
			:has-expenses="hasExpenses"
			:recurring="recurring"
			:payment-plan="paymentPlan"
			:invoice-service="invoiceService"
			:attachment-service="attachmentService"
			:scheduled-payments="scheduledPayments"
			@click="$emit('click', $event)"
			@delete="$emit('delete')"
			@change="$emit('change', $event)"
			@container-item-click="$emit('container-item-click', $event)"
			@container-item-update="$emit('container-item-update', $event)"
			@add-container-element="$emit('add-container-element', $event)"
			:class="item.type !== 'Container' ? 'pb-3' : ''"
		></component>
	</div>
</template>

<script>
	import Container from '@/modules/templates/invoices/schema/Container';
	import Divider from '@/modules/templates/invoices/schema/Divider';
	import FileInput from '@/modules/templates/invoices/schema/FileInput';
	import ImageBlock from '@/modules/templates/invoices/schema/ImageBlock';
	import Spacer from '@/modules/templates/invoices/schema/Spacer';
	import TextBlock from '@/modules/templates/invoices/schema/TextBlock';
	import Logo from '@/modules/templates/invoices/schema/Logo';
	import LineItems from '@/modules/templates/invoices/schema/LineItems';
	import FileAttachments from '@/modules/templates/invoices/schema/FileAttachments';

	export default {
		name: 'ItemRenderer',

		props: [
			'item',
			'styles',
			'font',
			'editMode',
			'active',
			'inContainer',
			'inBuilder',
			'accountLogo',
			'index',
			'tokens',
			'invoice',
			'tokenMap',
			'depositAccount',
			'hasExpenses',
			'recurring',
			'paymentPlan',
			'invoiceService',
			'attachmentService',
			'scheduledPayments',
		],

		components: {
			Container,
			Divider,
			FileInput,
			ImageBlock,
			Spacer,
			TextBlock,
			Logo,
			LineItems,
			FileAttachments,
		},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style lang="scss">
	.form-item-active {
		border-radius: 4px;
		box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
	}

	.form-item-inactive {
		border-radius: 4px;
	}

	.form-content {
		font-size: var(--form-font-size) !important;
		p:last-of-type {
			margin-bottom: 0px !important;
		}
	}

	.form-question {
		font-size: var(--form-font-size) !important;
		white-space: pre-wrap;
	}

	.form-input {
		input {
			font-size: var(--form-font-size) !important;
		}
		label {
			font-size: var(--form-font-size) !important;
		}
		textarea {
			font-size: var(--form-font-size) !important;
		}
	}
</style>
