<template>
	<div class="ma-4" v-if="isReady">
		<div class="mt-4">
			<v-data-table
				:headers="headers"
				:hide-default-footer="true"
				:disable-pagination="true"
				:mobile-breakpoint="200"
				:items="accounts"
				:no-data-text="$t('invoices.viewer.link-account')"
			>

        <template v-slot:item.label="{item}">
          <span style="text-transform: capitalize">{{item.label.toLowerCase()}}</span>
        </template>

        <template v-slot:item.routing="{item}">
          <span v-if="item.routing">{{item.routing}}</span>
          <span v-else>--</span>
        </template>

        <template v-slot:item.status="{ item }">
          <span style="text-transform: capitalize" v-if="item.status === 'verified'">{{ item.status }}</span>
          <v-btn class="primary-action" v-else @click="verifyAccount(item)"><v-icon>$alert</v-icon>{{ $t('global.verify') }}</v-btn>
        </template>

				<template v-slot:item.id="{ item }">
					<v-icon class="pointer" v-if="item.type === 'bank_account'" small @click="deleteAccount(item)">$delete</v-icon>
				</template>

			</v-data-table>
		</div>
		<div class="text-left mt-6 row-format gap-2">
      <v-btn class="primary-action" style="width:240px" @click="linkNewAccount()" v-if="achEnabled">
        <v-icon small class="mr-1">$plus</v-icon><span class="brand-medium">{{ $t('invoices.ach-account.new-account') }}</span>
      </v-btn>

      <v-btn class="primary-action" @click="managePaymentMethods()">
        {{ $t('invoices.ach-account.manage-payment-methods') }}
      </v-btn>
		</div>
	</div>
</template>

<script>
	import PaymentsService from '@/modules/invoices/PaymentsService';
	import ConfirmModal from '@/components/ConfirmModal';
	import NewAchAccount from '@/modules/invoices/NewAchAccount';
	import ManualAchAccount from '@/modules/invoices/ManualAchAccount';
  import VerifyAchAccount from '@/modules/invoices/VerifyAchAccount';
  import SubscriptionService from '@/modules/subscriptions/SubscriptionService';

	export default {
		name: 'AchAccounts',

		props: ['achEnabled'],

		components: {},

		data: function() {
			return {
				accounts: [],
				paymentsService: new PaymentsService(),
        subscriptionService: new SubscriptionService(),
				token: null,
				isReady: false,

				headers: [
          { text: this.$t('invoices.ach-account.bank-name'), value: 'label' },
          { text: this.$t('invoices.ach-account.routing-number'), value: 'routing' },
					{ text: this.$t('invoices.ach-account.account'), value: 'last4' },
					{ text: this.$t('invoices.ach-account.status'), value: 'status' },
					{ text: '', value: 'id' },
				],
			};
		},

		mounted() {
			this.getAccounts();
		},

		beforeDestroy() {},

		methods: {
			getAccounts: function() {
				this.paymentsService.getPaymentMethods().then((res) => {
					this.accounts.splice(0, this.accounts.length);
					this.accounts.push(...res.data);
					this.isReady = true;
				});
			},


      verifyAccount: function(bankAccount){
        let binding = {
          bankAccount: bankAccount
        };
        this.$store.state.globalModalController.openModal(VerifyAchAccount, binding, true, false).then((res) => {
          if(res) {
            this.getAccounts();
          }
        });
      },


			linkNewAccount: function() {
				this.$store.state.globalModalController.openModal(NewAchAccount, {}, true, false).then((res) => {
					if (res === 'PLAID') {
						this.linkWithPlaid();
					} else if (res === 'MANUAL') {
						this.$store.commit('startLoading');
						setTimeout(() => {
							this.$store.commit('stopLoading');
							this.openManualAchInput();
						}, 1000);
					}
				});
			},

			openManualAchInput: function() {
				this.$store.state.globalModalController.openModal(ManualAchAccount, {}, true, false).then((res) => {
					if (res) {
						this.accounts.push(res);
            this.confirmMicroDeposits();
					}
				});
			},

      confirmMicroDeposits: function(){
        let binding = {
          headingText: this.$t('invoices.ach-account.action-required'),
          hideNo: true,
          yesText: this.$t('global.ok'),
          bodyText: this.$t('invoices.ach-account.stripe-deposits'),
        };
        this.$store.state.globalModalController.openModal(ConfirmModal, binding, false, false);
      },

			linkWithPlaid: function() {
				this.$store.commit('startLoading');
				this.paymentsService.createACHLinkSession(window.location.href).then((res) => {
					this.$store.commit('stopLoading');
					console.log('ACH Link Session created', res.data);
					window.location.href = res.data.linkUri;
				});
			},

			deleteAccount: function(account) {
				let binding = {
					headingText: this.$t('global.confirm'),
					bodyText: this.$t('invoices.ach-account.confirm-ach'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding, false, false).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.paymentsService
							.deleteBankAccount(account.id)
							.then(() => this.getAccounts())
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

      managePaymentMethods: function(){
        this.subscriptionService.getSubscriptionPortalLink(document.location.href).then((res) => {
          document.location.href = res.data;
        })
      }
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
