<template>
	<div class="text-left" @click="$emit('click', $event)" v-if="isConditionMet">
		<div class="form-question">{{ item.question }} <span v-if="item.required" class="font-red brand-medium">*</span>  <v-icon v-if="showConditionalHelper" small color="gray_50">account_tree</v-icon></div>
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
			<v-radio-group
				v-model="selection"
				:key="refreshKey"
				hide-details
				dense
				:row="!item.formatVertically"
				:rules="item.required ? radioInputRequired : []"
				class="mt-0"
				@change="handleChange()"
			>
				<v-radio
					:disabled="disabled"
					class="form-checkbox form-input"
					v-for="(option, index) in item.options"
					:key="index"
					:value="option"
					:label="option"
					dense
					hide-details
				></v-radio>
			</v-radio-group>
		</v-form>
	</div>
</template>

<script>
	import RendererMixin from '@/modules/forms/v2/schema/RendererMixin';

	export default {
		name: 'Radio',

		props: ['disabled', 'item', 'styles', 'index','results','builder','container'],

		mixins: [RendererMixin],

		data: function() {
			return {
				refreshKey: 1,
				selection: [],
			};
		},

		mounted() {
			let currentResult = this.getCurrentResult(this.item.id,this.results);
			if(currentResult){
				this.selection = currentResult;
			}
      console.log(this.results)
		},

		beforeDestroy() {},

		methods: {
			handleChange: function(){
				this.$emit('input', {item: this.item, value: this.selection, index: this.index, container: this.container});
			}
		},

		computed: {
			radioInputRequired() {
				return [this.selection.length > 0 || 'Required'];
			},
		},

		watch: {
			'item.options': {
				deep: true,
				handler: function() {
					this.refreshKey++;
				},
			},
		},
	};
</script>

<style scoped lang="scss">
	.form-checkbox {
		.v-label {
			color: var(--font-color)!important;
		}
		.v-input--selection-controls__input {
			margin-right: 0px !important;
		}
	}
</style>
