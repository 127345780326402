<template>
	<div v-if="isReady">
		<div>
			<div class="content pt-2">
        <h2 class="text-left pl-6 pt-2" v-if="id ==='new'">{{pageHeader}}</h2>
				<new-ticket
					v-if="isNewTicket"
					:ticket-types="ticketTypes"
					@cancel="returnToList()"
					@new-ticket="newTicket($event)"
				></new-ticket>
				<existing-ticket v-else :id="id" :ticket-types="ticketTypes" @cancel="returnToList()"></existing-ticket>
			</div>
		</div>
	</div>
</template>

<script>
	import NewTicket from '@/modules/tickets/NewTicket';
	import ExistingTicket from '@/modules/tickets/ExistingTicket';
	import TicketService from '@/modules/tickets/TicketService';

	export default {
		name: 'TicketDetail',

		props: ['id'],

		components: {NewTicket, ExistingTicket },

		data: function() {
			return {
				ticketTypes: [],
				ticketService: new TicketService(),
				isReady: false,
			};
		},

		mounted() {
			this.getTicketSettings();
		},

		beforeDestroy() {},

		methods: {
			getTicketSettings: function() {
				this.ticketService.getTicketTypes().then((res) => {
					this.ticketTypes.splice(0, this.ticketTypes.length);
					this.ticketTypes.push(...res.data);
					this.isReady = true;
				});
			},

			returnToList: function() {
				this.$router.push('/requests');
			},

			newTicket: function(ticketId) {
				this.$router.push(`/requests/${ticketId}`);
			},
		},

		computed: {
			isNewTicket: function() {
				return this.id === 'new';
			},

			pageHeader: function() {
				if (this.id === 'new') {
					return this.$t('requests.new.heading');
				} else {
					return this.$t('requests.page-heading');
				}
			},
		},
	};
</script>

<style scoped lang="scss">

</style>
