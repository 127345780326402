<template>
	<div style="width: 100%; height: 100%" class="column-format centered">
		<div class="brand-bold font-24">{{ $t('integration.connecting') }}</div>
	</div>
</template>

<script>
	import PaymentsService from '@/modules/invoices/PaymentsService';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'PlaidOAuthHandler',

		props: [''],

		components: {},

		data: function() {
			return {
				paymentsService: new PaymentsService(),
				sessionToken: null,
				linkToken: null,
				pod: null,
			};
		},

		mounted() {
			this.sessionToken = localStorage.getItem('PlaidSessionToken');
			this.linkToken = localStorage.getItem('PlaidLinkToken');
			this.pod = localStorage.getItem('PlaidSessionPod');
			this.getAchSession();
		},

		beforeDestroy() {},

		methods: {
			getAchSession: function() {
				this.paymentsService.getACHLinkSession(this.pod, this.sessionToken).then((res) => {
					console.log('ACH Session found', res.data);

					this.achSession = res.data;
					this.processOAuthFlow();
				});
			},

			processOAuthFlow: function() {
				let plaidHandler = window.Plaid.create({
					token: this.linkToken,
					onSuccess: (token, metadata) => {
						this.plaidSuccess(token, metadata);
					},
					onLoad: () => {},
					onExit: (token, metadata) => {
						this.plaidExit(token, metadata);
					},
					onEvent: (token, metadata) => {
						this.plaidEvent(token, metadata);
					},
					receivedRedirectUri: window.location.href,
				});

				plaidHandler.open();
			},

			plaidSuccess: function(token, metadata) {
				console.log('Plaid Success');
				console.log(token);
				console.log(metadata);

				let plaidAccountId = metadata.accounts[0].id;
				this.paymentsService
					.exchangePlaidPublicToken(this.pod, this.sessionToken, token, plaidAccountId)
					.then((res) => {
						console.log('Success', res.data);
						window.location.href = this.achSession.redirectUri;
					})
					.catch((err) => {
						console.log('Failure', err);
						this.handleError(err.response.data.message);
					});
			},

			plaidError: function(err, metadata) {
				console.log('Plaid Error');
				console.log(err);
				console.log(metadata);
				this.handleError(err.error_code + ' - ' + err.error_message);
			},

			plaidExit: function(err, metadata) {
				console.log('Plaid Exit');
				console.log(err);
				console.log(metadata);
				this.handleError(err.error_code + ' - ' + err.error_message);
			},

			plaidEvent: function(eventName, metadata) {
				console.log('Plaid Event');
				console.log(eventName);
				console.log(metadata);
			},

      handleError: function(message){
        let binding = {
          headingText: this.$t('error.connecting-account'),
          bodyText: message,
          hideNo:true,
          yesText: this.$t('global.okay')
        }
        this.$store.state.globalModalController.openModal(ConfirmModal,binding,true,false).then(() => {
          window.location.href = this.achSession.redirectUri;
        })
      }
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
