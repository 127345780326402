<template>
	<div @click="$emit('click', $event)" :style="`height: ${height}px; min-height: ${height}px`"></div>
</template>

<script>
	export default {
		name: 'Spacer',

		props: ['item'],

		mixins: [],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			height: function() {
				if (this.item.height && this.item.height > 10) {
					return this.item.height;
				} else {
					return 10;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
