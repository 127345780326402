import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class PaymentsService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getStripeAccountId() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/stripeAccountId`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getPaymentMethods() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/paymentMethods`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getBankAccounts() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/bankAccounts`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	addBankAccount(stripeToken) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.post(`/${clientId}/bankAccounts`,null,{
				params: {
					stripeToken:stripeToken
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	verifyBankAccount(bankAccountId,amount1,amount2) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.post(`/${clientId}/bankAccounts/verify`,null,{
				params: {
					bankAccountId:bankAccountId,
					amount1:amount1,
					amount2:amount2
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	deleteBankAccount(bankAccountId) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.delete(`/${clientId}/bankAccounts`,{
				params: {
					bankAccountId:bankAccountId
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createACHLinkSession(redirectUri){
		let clientId = store.getters.getClientId;

		return this.httpClient
			.post(`/${clientId}/bankAccounts/session`,null,{
				params:{
					redirectUri:redirectUri
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getACHLinkSession(podUrl,sessionToken){
		let httpClient = new HttpClient(podUrl, false);
		return httpClient
			.get('/api/insecure/plaid/linkSession',{
				params:{
					sessionToken:sessionToken
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getPlaidLinkToken(podUrl,sessionToken){
		let httpClient = new HttpClient(podUrl, false);
		return httpClient
			.post('/api/insecure/plaid/token',null,{
				params:{
					sessionToken:sessionToken
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	exchangePlaidPublicToken(podUrl,sessionToken,token,plaidAccountId){
		let httpClient = new HttpClient(podUrl, false);
		return httpClient
			.post('/api/insecure/plaid/exchange',null,{
				params:{
					sessionToken:sessionToken,
					token:token,
					plaidAccountId:plaidAccountId
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
