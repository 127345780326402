import ProposalViewer from '@/modules/proposal/viewer/ProposalViewer';
import ProposalList from '@/modules/proposal/ProposalList';

export default [
	{
		path: '/agreements',
		name: 'Proposals',
		component: ProposalList,
	},
	{
		path: '/agreement',
		name: 'Proposal',
		component: ProposalViewer,
		meta: {
			insecure: true,
		},
	},
	{
		path: '/proposal',
		name: 'Proposal',
		component: ProposalViewer,
		meta: {
			insecure: true,
		},
	},
	{
		path: '/agreement/:id',
		name: 'Agreement',
		component: ProposalViewer,
		props: true,
		meta: {
			insecure: true,
		},
	},
]