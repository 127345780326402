<template>
	<v-container fluid class="pa-0 ma-0 selector_files">
		<v-row>
			<v-col cols="12" :style="headerStyle">
				<span v-html="lHeader" v-if="!editMode"></span>
				<editor
					ref="headerEditor"
					v-if="editMode"
					class="grey-dots"
					:api-key="$store.getters.getTinyMceKey"
					:initial-value="lHeader"
					:inline="true"
					:init="mceConfigHeader"
					@input="handleHeaderInput"
				></editor>
			</v-col>
			<v-col cols="12" style="text-align: left;">
				<span :style="bodyStyle">
					<span v-html="lText" v-if="!editMode"></span>
					<editor
						ref="textEditor"
						v-if="editMode"
						class="grey-dots"
						:api-key="$store.getters.getTinyMceKey"
						:initial-value="lText"
						:inline="true"
						:init="mceConfigText"
						@input="handleTextInput"
					></editor>
				</span>
			</v-col>
			<v-col cols="12" class="px-0 mx-0">
				<div class="fileWrapper">
					<div class="fileContainer" v-for="file in this.localFiles" :key="file.fileName">
						<!--						<v-img-->
						<!--							contain-->
						<!--							:src="file.fileIconUrl"-->
						<!--							width="100"-->
						<!--							@click="downloadFile(file)"-->
						<!--							style="cursor: pointer;"-->
						<!--						></v-img>-->
						<v-icon size="48" @click="downloadFile(file)">{{ iconName(file) }}</v-icon>
						<div class="font-14">{{ file.fileName }}</div>
						<div v-if="editMode" class="deleteButton">
							<v-btn @click="confirmDeleteFile(file)" icon color="gray_50" class="deleteIcon">
								<v-icon size="20">$delete</v-icon>
							</v-btn>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row v-if="editMode">
			<v-col cols="12" class="mx-0 px-0">
				<vue-dropzone
					ref="fileDropzone"
					id="dropzone"
					v-on:vdropzone-success="uploadSuccess"
					v-on:vdropzone-error="uploadFailure"
					v-on:vdropzone-sending="addFileUploadAuthHeader"
					:use-custom-slot="true"
					:options="dropzoneOptions"
				>
					<div>
						<v-icon>$vuetify.icons.upload</v-icon>
						<span class="ml-2" style="font-size: 0.8em;">{{ $t('proposal.files.upload') }}</span>
					</div>
				</vue-dropzone>
			</v-col>
		</v-row>
		<v-dialog v-model="confirmDelete" persistent max-width="290">
			<v-card class="brand-regular">
				<v-card-text class="pt-4">{{ $t('proposal.files.delete') }}</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="cancelDeleteFile()">{{ $t('global.no') }}</v-btn>
					<v-btn color="red darken-1" text @click="deleteFile()">{{ $t('global.yes') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import vueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import axios from 'axios';

	export default {
		name: 'Files',

		props: [
			'header',
			'text',
			'files',
			'id',
			'editMode',
			'clientId',
			'proposalId',
			'mceConfigHeader',
			'mceConfigText',
			'blockType',
			'proposalService',
			'headerStyle',
			'bodyStyle',
			'templateMode',
			'templateService',
		],

		components: {
			vueDropzone,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		mounted() {
			console.log(this.files);
		},

		data: function() {
			return {
				localFiles: JSON.parse(JSON.stringify(this.files)),
				fileToDelete: null,
				confirmDelete: false,
				lHeader: this.header,
				lText: this.text,
			};
		},

		methods: {
			notifyChange: function() {
				this.$emit('changed', {
					id: this.id,
					blockType: this.blockType,
					header: this.lHeader,
					text: this.lText,
					files: this.localFiles,
				});
			},

			handleHeaderInput: function() {
				this.lHeader = this.$refs.headerEditor.editor.getContent();
				this.notifyChange();
			},

			handleTextInput: function() {
				this.lText = this.$refs.textEditor.editor.getContent();
				this.notifyChange();
			},

			removeFileByName: function(fileName) {
				for (let i = 0; i < this.localFiles.length; i++) {
					if (this.localFiles[i].fileName === fileName) {
						this.localFiles.splice(i, 1);
						break;
					}
				}
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			uploadSuccess: function(file, response) {
				this.$refs.fileDropzone.removeFile(file);
				this.removeFileByName(response.fileName);
				this.localFiles.push(response);
				this.notifyChange();
			},

			uploadFailure: function(file, message) {
				this.$store.commit('error', this.$t('error.error-uploading') + message);
				this.$refs.fileDropzone.removeFile(file);
			},

			confirmDeleteFile: function(file) {
				this.fileToDelete = file;
				this.confirmDelete = true;
			},

			cancelDeleteFile: function() {
				this.fileToDelete = null;
				this.confirmDelete = false;
			},

			deleteFile: function() {
				this.deleteFileInternal()
					.then(() => {
						this.removeFileByName(this.fileToDelete.fileName);
						this.notifyChange();
						this.cancelDeleteFile();
					})
					.catch((err) => {
						this.$store.commit('error', this.$t('error.error-deleting') + err.response.data.message);
						this.cancelDeleteFile();
					});
			},

			deleteFileInternal() {
				if (this.templateMode) {
					return this.templateService.deleteFileAttachment(this.proposalId, this.fileToDelete.fileName);
				} else {
					return this.proposalService.deleteFileAttachment(this.clientId, this.proposalId, this.fileToDelete.fileName);
				}
			},

			downloadFile(s3File) {
				this.downloadFileInternal(s3File).then((res) => {
					let signedUrl = res.data.signedUrl;

					axios({
						url: signedUrl, //your url
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', s3File.fileName); //or any other extension
						document.body.appendChild(link);
						link.click();
					});
				});
			},

			downloadFileInternal(s3File) {
				if (this.templateMode) {
					return this.templateService.getSignedUrl(this.proposalId, s3File.fileName);
				} else {
					return this.proposalService.getSignedUrl(this.clientId, this.proposalId, s3File.fileName);
				}
			},

			iconName(file) {
				if (['svg', 'csv', 'pdf', 'ppt', 'zip'].includes(file.fileType.toLowerCase())) {
					return '$' + file.fileType.toLowerCase() + 'File';
				}
				return '$unknownFile';
			},
		},

		computed: {
			dropzoneOptions: function() {
				let theUrl;

				if (this.templateMode){
					theUrl = `${this.$store.getters.getBaseUrl}/templates/proposals/${this.proposalId}/files`;
				}else{
					theUrl = `${this.$store.getters.getBaseUrl}/clients/${this.clientId}/proposals/${this.proposalId}/files`;
				}

				return {
					url: theUrl,
					thumbnailWidth: 50,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: this.blockType == 'Images' ? 'image/*' : null,
					includeStyling: false,
				};
			},
		},
	};
</script>

<style lang="scss">
	.deleteButton {
		position: absolute;
		top: -10px;
		right: -10px;
		z-index: 150;
		opacity: 0;
		transition: opacity 300ms;
		&:hover {
			.v-btn .v-icon {
				color: var(--v-primary-base);
			}
		}
	}

	.deleteIcon {
		/*border: 1px solid lightgray;*/
		/*border-radius: 40px;*/
	}

	.fileWrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.fileContainer {
		width: 100px;
		text-align: center;
		margin: 10px;
		overflow-wrap: break-word;
		position: relative;
		&:hover {
			.deleteButton {
				opacity: 1;
				transition: opacity 300ms;
			}
		}
	}

	.vue-dropzone {
		position: relative;
		min-height: 75px;
		border: 1px dashed var(--v-schooner_20-lighten1);
	}

	.dz-message {
		margin: 0px !important;
	}

	.dropzone {
		margin: 0px !important;
	}
</style>
