<template>
	<div>
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
			<label v-html="htmlText" class="question"></label>
			<v-select
				outlined
				hide-details
				dense
				persistent-placeholder
				:attach="true"
				:multiple="multi"
				:rules="required ? inputRequired : []"
				@input="$emit('input', $event)"
				:items="options"
				:full-width="false"
				:disabled="previewMode"
			>
			</v-select>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'SelectInput',
		props: ['multi', 'options', 'htmlText', 'previewMode', 'required'],

		data: function () {
			return {
				isValid: false,
				inputRequired: [(v) => !!v || 'Required'],
			};
		},

		methods: {
			validate: function () {
				return this.$refs.form.validate();
			},
		},
	};
</script>

<style lang="scss"></style>
