<template>
	<div class="mt-1" v-if="timeEnabled || invoicesEnabled">
		<div class="row-format" v-if="invoicesEnabled">
			<div class="kpi-box mr-3">
				<div class="fit">{{ $t('projects.overview.total-invoiced') }}</div>
				<div class="font-24 brand-medium fit mt-1">{{ totalInvoiced }}</div>
			</div>
			<div class="kpi-box">
				<div class="fit">{{ $t('projects.overview.total-payments') }}</div>
				<div class="font-24 brand-medium fit mt-1">{{ totalReceived }}</div>
			</div>
		</div>
		<div class="row-format mt-3" v-if="timeEnabled">
			<div class="kpi-box mr-3">
				<div class="fit">{{ $t('projects.overview.estimated-hours') }}</div>
				<div class="font-24 brand-medium fit mt-1">{{ formatTimeEstimate }}</div>
			</div>
			<div class="kpi-box">
				<div class="fit">{{ $t('projects.overview.hours-worked') }}</div>
				<div class="font-24 brand-medium fit mt-1">{{formattedTimeWorked}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ProjectService from "@/modules/projects/ProjectService";

	export default {
		name: 'TimeAndMoney',

		props: ['project'],

		components: {},

		data: function () {
			return {
				projectService: new ProjectService(),
				payments: [],
				timeWorked: 0,
			};
		},

		mounted() {
			this.getInvoicesForProject();
			this.getTimeWorkedForProject()
		},

		beforeDestroy() {},

		methods: {
			getInvoicesForProject: function(){
				this.projectService.getProjectPayments(this.project.id).then((res) => {
					this.payments.splice(0,this.payments.length);
					this.payments.push(... res.data);
				})
			},

			getTimeWorkedForProject: function() {
				this.projectService.getTimeWorkedForProject(this.project.id).then((res) => {
					this.timeWorked = res.data;
				})
			}
		},

		computed: {
      timeEnabled: function(){
        return this.$store.getters.getFeatures.timeEnabled && this.project.showTimeWorkedInPortal;
      },

      invoicesEnabled: function(){
        return this.$store.getters.getFeatures.invoicesEnabled && this.$store.getters.getFeatures.summaryFinancialsEnabled;
      },

			totalInvoiced: function(){
				return this.$formatters.dollars(this.payments.reduce((sum, { amount }) => sum + amount, 0));
			},

			totalReceived: function(){
				return this.$formatters.dollars(this.payments.filter((p) => p.invoiceStatus === 'PAID').reduce((sum, { amount }) => sum + amount, 0));
			},

			formatTimeEstimate: function(){
				if(this.project.feeSchedule){
					let min =  this.project.feeSchedule.estimateMin;
					let max = this.project.feeSchedule.estimateMax;

					if(min && max){
						return min + '-' + max;
					}else if(min){
						return min;
					}else if(max){
						return max;
					}else {
						return '--'
					}
				}else{
					return '--'
				}

			},

			formattedTimeWorked: function(){
        if(this.timeWorked) {
          return this.$formatters.formatSeconds(this.timeWorked);
        }else{
          return '--'
        }
			}
		},
	};
</script>

<style scoped lang="scss">
	.kpi-box {
		flex: 1 1 0px;
		background-color: var(--v-sidebar-base);
		border-radius: 4px;
		min-height: 50px;
		padding: 12px;
		font-size: 14px;
	}
</style>
