import ProjectMain from '@/modules/projects/ProjectMain';

export default [
	{
		path: '/projects',
		name: 'Projects',
		component: ProjectMain,
		props: (route) => ({
			v: route.query.v,
		}),
	}
]