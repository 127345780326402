<template>
	<div id="sign-dialog" style="background-color: #fff" v-if="isReady">
		<div class="pa-4">
			<v-tabs @change="tabChanged" slider-size="4" slider-color="black">
				<v-tab>{{ $t('proposal.signature.type-signature') }}</v-tab>
				<v-tab>{{ $t('proposal.signature.draw-signature') }}</v-tab>
				<v-tab-item class="column-format gap-2">
					<div class="signature-block mt-4 mb-3" :style="signatureFont">
						<div class="signature">
							<input :value="`${signer.firstName} ${signer.lastName}`" disabled type="text" class="keyboardInput" />
						</div>
					</div>

					<v-select
						outlined
						hide-details
						persistent-placeholder
            dense
						:label="$t('proposal.signature.signature-font')"
						v-model="element.font"
						:items="fonts"
					>
						<template v-slot:item="{ item }">
							<span :style="`font-family: ${item}, cursive; font-size:30px`">{{
								$t('proposal.signature.signature')
							}}</span>
						</template>
						<template v-slot:selection="{ item }">
							<span :style="`font-family: ${item}, cursive; font-size:30px`">{{
								$t('proposal.signature.signature')
							}}</span>
						</template>
					</v-select>

					<div class="row-format gap-2">
						<v-text-field
								outlined
								hide-details
								persistent-placeholder
                dense
								label="Title"
								v-model="element.title"
						>
							<template #label>
								Title<span class="red--text"><strong>* </strong></span>
							</template>
						</v-text-field>
						<v-text-field
								outlined
								hide-details
								persistent-placeholder
                dense
								label="Initials"
								maxlength="3"
								v-model="element.initials"
								aria-required="true"
						>
							<template #label>
								Initials<span class="red--text"><strong>* </strong></span>
							</template>
						</v-text-field>
					</div>

				</v-tab-item>

				<v-tab-item class="column-format gap-2">
					<div
						class="signature-block mt-4 mb-3"
						@dragover="cancelDragDrop"
						@dragenter="cancelDragDrop"
						@drop="handleDrop"
					>
						<div class="instructions row-format align-center">
							<div>{{ $t('proposal.signature.draw-in-box') }}</div>
							<v-icon size="16" class="ml-auto material-symbols-outlined pointer mr-3" @click="clearDrawingPad"
								>cancel</v-icon
							>
						</div>
						<div class="signature" :style="'background-color: var(--v-gray_10-base);' + signatureFont">
							<vue-signature-pad width="476px" height="150px" ref="signaturePad"></vue-signature-pad>
						</div>
					</div>
					<v-text-field
						outlined
            dense
						hide-details
						persistent-placeholder
						label="Name"
						disabled
						:value="`${signer.firstName} ${signer.lastName}`"
					></v-text-field>
					<div class="row-format gap-2">
						<v-text-field
								outlined
                dense
								hide-details
								persistent-placeholder
								label="Title"
								v-model="element.title"
						>
							<template #label>
								Title<span class="red--text"><strong>*</strong></span>
							</template>
						</v-text-field>
						<v-text-field
								outlined
                dense
								hide-details
								persistent-placeholder
								label="Initials"
								maxlength="3"
								v-model="element.initials"
						>
							<template #label>
								Initials<span class="red--text"><strong>*</strong></span>
							</template>
						</v-text-field>
					</div>
				</v-tab-item>
			</v-tabs>
		</div>
		<div class="modal-footer">
			<v-btn class="mr-1" @click="cancelSign()" elevation="0" color="gray_20">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="ml-1" @click="confirmSign()" color="primary" :disabled="!element.initials || !element.title">{{ $t('proposal.signature.sign-accept') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import {DateTime} from 'luxon';

	export default {
		name: 'SignDialog',

		props: ['agreement', 'signer'],

		components: {},

		data: function() {
			return {
				isReady: false,
				element:  {... this.signer},
				signatureType: 'Keyboard',
				fonts: ['Arizonia', 'Charmonman', 'Herr Von Muellerhoff', 'Reenie Beanie'],
			};
		},

		mounted: function(){
			if(!this.element.initials){
				let name = this.signer.firstName + ' ' + this.signer.lastName;
				this.element.initials = '';
				name.split(" ").forEach(x => {
					this.element.initials = this.element.initials + x.charAt(0).toUpperCase();
				})
			}
			this.isReady = true;
		},

		methods: {
			tabChanged: function(tabNumber) {
				if (tabNumber === 0) {
					this.signatureType = 'Keyboard';
				} else {
					this.signatureType = 'Image';
				}
			},

			cancelDragDrop: function(e) {
				if (e.preventDefault) {
					e.preventDefault();
				}
			},

			handleDrop: function(e) {
				if (e.preventDefault) {
					e.preventDefault();
				}
				let dt = e.dataTransfer;
				let files = dt.files;
				for (let i = 0; i < files.length; i++) {
					let file = files[i];
					let reader = new FileReader();
					reader.onloadend = (e) => this.fileLoaded(e);
					reader.readAsDataURL(file);
				}
				return false;
			},

			fileLoaded: function(progressEvent) {
				this.clearDrawingPad();
				this.$refs.signaturePad.fromDataURL(progressEvent.target.result);
			},

			clearDrawingPad: function() {
				this.$refs.signaturePad.undoSignature();
			},

			confirmSign: function() {
				if (this.signatureType === 'Keyboard') {
					this.element.signatureType = 'Keyboard';
					this.element.signature = this.signer.firstName + ' ' + this.signer.lastName;
				} else {
					let pngSignature = this.$refs.signaturePad.saveSignature('image/png');

					if(pngSignature.isEmpty){
						this.$store.commit('error', this.$t('error.signature'));
						return;
					}

					this.element.signatureType = 'Image';
					this.element.signature = pngSignature.data;
				}
				this.element.timestamp = DateTime.now().toISO();
				this.$emit('signed',this.element);
			},

			cancelSign: function() {
				this.$emit('cancel');
			},
		},

		computed: {
			signatureFont: function() {
				return `font-family: ${this.element.font};`;
			},
		},
	};
</script>

<style lang="scss">
	#sign-dialog {
		.signature-block {
			background-color: var(--v-gray_20-base);
			border-radius: 4px;
			border: 1px solid var(--v-gray_50-base);
			height: 224px;
			display: flex;
			align-items: center;
			justify-content: center;
			> div.signature {
				font-size: 40px;
				border-bottom: 1px solid var(--v-gray_50-base);
			}
			position: relative;
			.instructions {
				position: absolute;
				top: 4px;
				left: 8px;
				font-weight: 500;
				font-size: 12px;
				width: 100%;
			}
		}
		.keyboardInput {
			width: 337px;
			outline: none;
			text-align: center;
		}
	}
</style>
