import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class TimerEventService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getTimerEvents(earliest, latest) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/timer`, {
				params: {
					earliest: earliest ? earliest.toISO() : null,
					latest: latest ? latest.toISO() : null,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	downloadTimerEvents(earliest, latest){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/timer/download`, {
				responseType: 'blob',
				params: {
					earliest: earliest,
					latest: latest,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
