import HttpClient from '@/services/HttpClient';
import qs from 'qs';

export default class PasswordService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(process.env.VUE_APP_BASE_AUTH_URL);
	}

	setPassword(password){
		const request = {
			password
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return this.httpClient
			.post('/auth/portal/setPassword', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	}
}
