<template>
	<div
		class="pointer v-event-draggable row-format pb-2"
		style="align-items: center; justify-content: space-between;"
		@mousedown.stop="startDrag(event)"
	>
		<div class="row-format" style="align-items: center; width: 90%;" @click.stop="editDeliverable(event)">
      <div
				style="font-size: 12px;"
				class="pl-1 eventLabel no-select"
				v-html="event.name"
				v-tippy="{ content: tippyLabel }"
			></div>
		</div>
		<div
			class="coloredCircle"
			v-tippy="{ content: status.label }"
			:style="`--circle-color: ${status.hexColor}`"
		></div>
	</div>
</template>

<script>
	export default {
		name: 'CalendarItem',

		props: ['event','status'],

		components: { },

		data: function() {
			return {};
		},

		methods: {
			startDrag: function(event) {
				this.$emit('start-drag', event);
			},

			editDeliverable: function(event) {
				this.$emit('edit-deliverable', event);
			},
		},

		computed: {
			tippyLabel: function(){
				if(this.event.project){
					return this.event.project.name + " - " + this.event.name;
				}else{
					return this.event.name;
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.coloredCircle {
		width: 12px;
		height: 12px;
		border-radius: 6px;
		background-color: var(--circle-color);
		margin-right: 4px;
	}

	.eventLabel {
		display: block;
		width: 100%;
		text-align: left;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.no-select {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	}
</style>
