<template>
  <div>
    <v-container class="pa-0 ma-0 text-left overflow-hidden" style="max-width: 425px">
      <v-row dense>
        <v-col cols="12">
          <workspace-selector></workspace-selector>
        </v-col>
        <v-col class="pa-4">
          <settings-locale :label="$t('settings.language')" :children="countries.getCountriesTranslate()" icon="translate" @selected="switchLocale($event)"></settings-locale>
          <settings-link :label="$t('settings.theme')" :children="theme" :icon="themeIcon" @selected="handleThemeSelection($event)"></settings-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Countries from '@/modules/utils/Countries.js'
import AuthService from "@/modules/auth/AuthService";
import PodService from "@/services/PodService";
import WorkspaceSelector from "@/views/nav/settings/WorkspaceSelector";
import SettingsLink from "@/views/nav/settings/SettingsLink";
import SettingsLocale from "@/views/nav/settings/SettingsLanguage.vue";

export default {
  name: "SettingsNav",

  props: [],

  components: {SettingsLink, SettingsLocale, WorkspaceSelector},

  data: function () {
    return {
      countries: new Countries(),
      theme: [
        { label: this.$t('settings.light-mode'), selectValue: 'never', icon: 'light_mode'},
        { label: this.$t('settings.dark-mode'), selectValue: 'always', icon: 'dark_mode'},
        { label: this.$t('settings.system-default'), selectValue: 'automatic', icon: 'settings_brightness' }
      ],
    }
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    handleThemeSelection: function(theme){
      localStorage.setItem('DARK_MODE', theme);
      this.$store.state.darkMode = theme;
      this.$store.state.eventBus.$emit('dark-mode-changed', theme);
    },

    switchLocale: function(lang) {
      localStorage.setItem('lang', lang);
      this.$store.state.selectLang = lang;
      this.$store.state.eventBus.$emit('lang-changed', lang);
      window.location.reload()
      console.log('Im hit!');
    },

    doLogout() {
      this.dialog = false;
      try {
        window.google.accounts.id.disableAutoSelect();
      }catch(err){
        console.log(err);
      }

      AuthService.logout()
          .then(() => {
            console.log('Logout Success');
            this.podLogout();
          })
          .catch((err) => {
            console.log(err);
            this.podLogout();
          });
    },

    podLogout() {
      let podService = new PodService();
      podService
          .clearCookies()
          .then(() => {
            this.finalizeLogout();
          })
          .catch(() => {
            this.finalizeLogout();
          });
    },


    finalizeLogout() {
      this.$store.commit('logOut');
      location.reload();
    },
  },

  computed: {
    isFullUser(){
      return this.$store.getters.isAccountFullUser;
    },

    themeIcon: function(){
      if(this.$store.state.darkMode === 'never'){
        return 'light_mode';
      }else if(this.$store.state.darkMode === 'always'){
        return 'dark_mode';
      }else{
        return 'settings_brightness'
      }
    }
  },

}
</script>

<style scoped lang="scss">

</style>