<template>
	<v-container fluid class="pa-0 ma-0 selector_text proposal-text-area">
		<v-row>
			<v-col cols="12" :style="headerStyle">
				<span v-html="lHeader" v-if="!editMode"></span>
				<editor
					ref="headerEditor"
					class="grey-dots"
					v-if="editMode"
					:api-key="$store.getters.getTinyMceKey"
					:initial-value="lHeader"
					:inline="true"
					:init="mceConfigHeader"
					@input="handleHeaderInput"
				></editor>
			</v-col>
			<v-col cols="12" style="text-align: left;">
				<span :style="bodyStyle">
					<span v-html="lText" v-if="!editMode"></span>
					<editor
						v-if="editMode"
						ref="textEditor"
						:iniline="true"
						style="padding: 4px"
						:api-key="$store.getters.getTinyMceKey"
						:initial-value="lText"
						:init="mceConfigText"
						@input="handleTextInput"
					></editor>
				</span>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		name: 'TextArea',

		props: ['header', 'text', 'id', 'editMode', 'blockType', 'mceConfigHeader', 'mceConfigText', 'headerStyle','bodyStyle'],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function () {
			return {
				lText: this.text,
				lHeader: this.header,
			};
		},

		methods: {
			handleHeaderInput: function () {
				this.lHeader = this.$refs.headerEditor.editor.getContent();
				this.notifyChange();
			},

			handleTextInput: function () {
				this.lText = this.$refs.textEditor.editor.getContent();
				this.notifyChange();
			},

			notifyChange() {
				this.$emit('changed', {
					id: this.id,
					blockType: this.blockType,
					header: this.lHeader,
					text: this.lText,
				});
			},
		},
	};
</script>

<style lang="scss">

</style>
