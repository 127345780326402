import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class FormService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getFormSubmissionList(){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/forms`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getFormSubmission(id){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/forms/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getFormRequestList(){
		let clientId = store.getters.getClientId;
		return this.httpClient
			.get(`/${clientId}/forms/requests`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
