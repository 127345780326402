import AgreementViewer from '@/modules/agreements/viewer/AgreementViewer';

export default [
	{
		path: '/document/:id',
		name: 'Agreement',
		component: AgreementViewer,
		props: (route) => ({ id: route.params.id, secretKey: route.query.secretKey }),
		meta: {
			insecure: true,
		},
	},
]