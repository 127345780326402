<template>
	<div class="font-14">
		<div v-for="item in timerEvents" :key="item.id" style="line-height: 1.3em;" class="pb-2">
			<span>{{ item.timeWorked }} &bull; {{ item.dateDisplay }}</span>
			<span v-if="item.deliverableName">&bull; {{ item.deliverableName }} </span>
			<span v-if="item.notes" style="white-space: pre-wrap">&bull; {{ item.notes }} </span>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';

	export default {
		name: 'TimeEntries',

		components: {},
		props: {
			invoice: { type: Object, required: true },
			invoiceItemId: { type: String, required: true },
			invoiceService: { type: Object, required: true },
		},

		data: function() {
			return {
				item: null,
				timerEvents: null,
			};
		},

		mounted() {
			this.getInvoiceItem();
		},

		beforeDestroy() {},

		methods: {
			getInvoiceItem() {
				this.invoiceService
					.getInvoiceItem(this.invoice.clientId, this.invoice.id, this.invoiceItemId)
					.then((res) => {
						this.item = res.data.item;
						this.timerEvents = this.fixupEventList(res.data.timerEvents);
						this.timerEvents.sort((a, b) => a.dateTime.toMillis() - b.dateTime.toMillis());
					})
					.catch((err) => {
						console.log(err);
					});
			},

			fixupEventList(list) {
				for (let i = 0; i < list.length; i++) {
					this.fixupEvent(list[i]);
				}
				return list;
			},

			fixupEvent(item) {
				let timerStart = DateTime.fromISO(item.timerStart);
				let timerEnd = timerStart.plus({seconds: item.duration});
				item.dateTime = timerStart;
				item.dateDisplay = timerStart.toLocaleString(DateTime.DATE_MED);
				item.durationHoursDecimal = timerEnd.diff(timerStart, ['hours']).values.hours; // Store as Duration in hours decimal for chart calculations
				item.duration = timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds']);
				item.timeWorked = item.duration.plus(item.duration.seconds > 29 ? 60000 : 0).toFormat('h:mm'); // Table display
				item.timeWorkedSortable = item.duration.toFormat('hh:mm:ss'); // Table sortability
				item.value = '--'; // Value of time worked in dollars. Update later
				item.valueTooltip = this.$t('timetrack.value-tooltip.default');
				item.projectName = item.projectName ?? '';
				item.deliverableName = item.deliverableName ?? '';
				item.notes = item.notes ?? '';

				return item;
			},
		},
		computed: {},
		filters: {},
		watch: {},
	};
</script>

<style lang="scss"></style>
