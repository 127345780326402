<template>
  <div style="width: 100%; height: 100%" class="column-format centered">
    <div class="brand-medium font-24">{{ $t('subscriptions.hang-tight') }}</div>
  </div>
</template>

<script>
import qs from 'qs';
import HttpClient from '@/services/HttpClient';

export default {
  name: 'Subscribe',

  props: [],

  components: {  },

  data: function() {
    return {
      httpClient: null,
      token: null,
      paymentInfo: null,
      isReady: false,
    };
  },

  mounted() {
    let queryString = qs.parse(self.location.search.slice(1));
    let request = JSON.parse(atob(queryString.token.split('.')[0]));

    let podUrl = request.podUrl;

    this.httpClient = new HttpClient(podUrl, false);
    this.token = queryString.token;

    this.initiateStripeCheckout();
  },

  beforeDestroy() {},

  methods: {
    initiateStripeCheckout: function() {
      this.$store.commit('startLoading');
      this.getStripeCheckoutByToken().then((res) => {
        this.$store.commit('stopLoading');
        let stripe = window.Stripe(this.$store.state.stripeKey, {
          stripeAccount: res.data.stripeAccountId,
        });

        stripe.redirectToCheckout({
          sessionId: res.data.checkoutSessionId,
        });
      });
    },

    getStripeCheckoutByToken: function() {
      return this.httpClient
          .get('/api/docs/subscription/checkout', {
            params: {
              token: this.token,
            },
          })
          .then((response) => Promise.resolve(response))
          .catch((error) => Promise.reject(error));
    },
  },

  computed: {
  },
};
</script>

<style scoped lang="scss">
.payment-container {
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid var(--v-white-base);
  &:hover {
    border: 1px solid var(--v-gray_30-base);
    background-color: var(--v-gray_10-base);
  }
}

.border-right {
  border-right: 1px solid var(--v-gray_50-base);
}

.border-bottom {
  border-bottom: 1px solid var(--v-gray_50-base);
}
</style>
