import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class InvoiceService {
	httpClient;
	invoice;

	constructor() {
		this.httpClient = new HttpClient();
	}

	setInvoice(invoice){
		this.invoice = invoice;
	}

	getInvoice(id) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/invoices/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getAchEnabled() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/bankAccountsEnabled`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getInvoices(earliest, latest) {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/invoices`, {
				params: {
					earliest: earliest,
					latest: latest,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDepositAccount() {
		let clientId = store.getters.getClientId;

		return this.httpClient
			.get(`/${clientId}/deposits`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(fileName) {
		return this.httpClient
			.get(`/${this.invoice.clientId}/invoices/${this.invoice.id}/files`, {
				params: {
					fileName: fileName,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	downloadInvoice(){
		return this.httpClient
			.get(`/${this.invoice.clientId}/invoices/${this.invoice.id}/download`, {
				responseType: 'blob',
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getInvoiceItem(clientId, invoiceId, invoiceItemId) {
		return this.httpClient
			.get(`/${clientId}/invoices/${invoiceId}/item/${invoiceItemId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
