<template>
	<v-container fluid style="min-height: 100%" class="show-scrollbar">
		<v-row>
			<v-col align="center">
				<gantt-chart
					:deliverables="deliverablesWithDates"
					:start-date="start"
					:end-date="end"
          :assignable-users="assignableUsers"
					@edit-deliverable="$emit('edit-deliverable', $event)"
					@dates-updated="$emit('dates-updated', $event)"
				>
				</gantt-chart>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import GanttChart from '@/modules/projects/management/gantt/GanttChart';
	import { DateTime } from 'luxon';

	export default {
		name: 'GanttView',

		props: ['deliverables', 'ganttSort', 'headerHeight','statusList','assignableUsers'],

		components: {
			GanttChart,
		},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			sortByName: function(a, b) {
				if (a.name && b.name) {
					return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
				} else if (a.name) {
					return 1;
				} else if (b.name) {
					return -1;
				} else {
					return 0;
				}
			},

			sortByStatusAndName: function(a, b) {
				let result = this.sortByStatus(a, b);
				if (result === 0) {
					result = this.sortByName(a, b);
				}
				return result;
			},

			sortByNameAndStartDate: function(a, b) {
				let result = this.sortByName(a, b);
				if (result === 0) {
					result = this.sortByStartDate(a, b);
				}
				return result;
			},

			sortByStartDateAndNameAndStatus: function(a, b) {
				let result = this.sortByStartDate(a, b);
				if (result === 0) {
					result = this.sortByName(a, b);
					if (result === 0) {
						result = this.sortByStatus(a, b);
					}
				}
				return result;
			},

			sortByStartDateAndName: function(a, b) {
				let result = this.sortByStartDate(a, b);
				if (result == 0) {
					return this.sortByName(a, b);
				} else {
					return result;
				}
			},

			sortByStatus: function(a, b) {
				let ai = this.statusList.findIndex((c) => c.id === a.statusId);
				let bi = this.statusList.findIndex((c) => c.id === b.statusId);

				if (ai < bi) {
					return -1;
				} else if (ai > bi) {
					return 1;
				} else {
					return 0;
				}
			},

			sortByStartDate: function(a, b) {
				return a.start.toMillis() - b.start.toMillis();
			},

      getDeliverableStatusById: function(id){
        return this.statusList.find(s => s.id === id);
      }
		},

		computed: {
			deliverablesWithDates: function() {
				let result = [];
				for (let i = 0; i < this.deliverables.length; i++) {
					let d = JSON.parse(JSON.stringify(this.deliverables[i]));
					if (d.startDate || d.dueDate) {
						d.start = DateTime.fromISO(d.startDate ? d.startDate : d.dueDate).startOf('day');
						d.end = DateTime.fromISO(d.dueDate ? d.dueDate : d.startDate).endOf('day');
						d.status = this.getDeliverableStatusById(d.statusId);
						result.push(d);
					}
				}

				if (this.ganttSort === 'start') {
					result.sort(this.sortByStartDateAndNameAndStatus);
				} else if (this.ganttSort === 'name') {
					result.sort(this.sortByName);
				} else if (this.ganttSort === 'status') {
					result.sort(this.sortByStatusAndName);
				}
				return result;
			},

			start: function() {
				if (this.deliverablesWithDates.length) {
					let earliest = this.deliverablesWithDates[0].start;
					for (let i = 0; i < this.deliverablesWithDates.length; i++) {
						if (this.deliverablesWithDates[i].start < earliest) {
							earliest = this.deliverablesWithDates[i].start;
						}
					}
					return earliest.startOf('month');
				}

				return DateTime.now().startOf('month');
			},

			end: function() {
				if (this.deliverablesWithDates.length) {
					let latest = this.deliverablesWithDates[0].end;
					for (let i = 0; i < this.deliverablesWithDates.length; i++) {
						if (this.deliverablesWithDates[i].end > latest) {
							latest = this.deliverablesWithDates[i].end;
						}
					}
					return latest.endOf('month');
				}
				return DateTime.now().endOf('month');
			},
		},
	};
</script>

<style scoped lang="scss"></style>
