<template>
	<div :class="`item ${active && !inContainer ? 'item-active' : 'item-inactive'}`" :id="'itemRenderer-' + item.id">
		<v-menu max-width="700" v-if="!active && editMode && !inContainer">
			<template v-slot:activator="{ on }">
				<div class="line-container">
					<div class="line"></div>
					<div class="icon" v-on="on">+</div>
				</div>
			</template>
			<div style="background-color: var(--v-white-base)" class="pl-3 pt-3 pb-3">
				<element-types :disable-drag="true" @selected="$emit('add-element-above', $event)"></element-types>
			</div>
		</v-menu>

		<div class="builder-control" v-if="active && !inContainer">
			<v-btn icon text v-tippy="{ content: 'Move Up' }" @click.stop="$emit('move-up')">
				<v-icon size="20" color="white" class="material-symbols-outlined">keyboard_arrow_up</v-icon>
			</v-btn>
			<v-btn icon text v-tippy="{ content: 'Move Down' }" @click.stop="$emit('move-down')">
				<v-icon size="20" color="white" class="material-symbols-outlined">keyboard_arrow_down</v-icon>
			</v-btn>
			<v-btn icon text v-tippy="{ content: 'Delete section' }" @click.stop="$emit('delete')">
				<v-icon size="20" color="white" class="material-symbols-outlined">delete</v-icon>
			</v-btn>
		</div>
		<component
			ref="component"
			:key="item.type"
			:is="item.type"
			:item="item"
			:agreement="agreement"
			:active="active"
			:account-logo="accountLogo"
			:index="index"
			:tokens="tokens"
			:mce-config="mceConfig"
			:client-mode="clientMode"
			:edit-mode="editMode"
			:signer="signer"
			:template-mode="templateMode"
			:page-width="pageWidth"
			:is-mobile="isMobile"
			@click="$emit('click', $event)"
			@delete="$emit('delete')"
			@change="$emit('change', $event)"
			@signed="handleSigned($event)"
			@drag-enabled="$emit('drag-enabled', $event)"
			@container-item-click="$emit('container-item-click', $event)"
			@container-item-update="$emit('container-item-update', $event)"
			@container-item-signed="$emit('container-item-signed', $event)"
			@add-container-element="$emit('add-container-element', $event)"
		></component>
		<v-menu max-width="700" v-if="!active && editMode && !inContainer">
			<template v-slot:activator="{ on }">
				<div class="line-container">
					<div class="line"></div>
					<div class="icon" v-on="on">+</div>
				</div>
			</template>
			<div style="background-color: var(--v-white-base)" class="pl-3 pt-3 pb-3">
				<element-types :disable-drag="true" @selected="$emit('add-element-below', $event)"></element-types>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import AcceptButton from './AcceptButton';
	import Button from './Button';
	import Container from './Container';
	import Divider from './Divider';
	import Footer from './Footer';
	import FormEmbed from './FormEmbed';
	import Header from './Header';
	import ImageBlock from './ImageBlock';
	import Logo from './Logo';
	import Payment from './Payment';
	import Scheduler from './Scheduler';
	import Signature from './Signature';
	import Spacer from './Spacer';
	import Terms from './Terms';
	import TextBlock from './TextBlock';
	import Services from './Services';
	import SchedulerEmbed from './SchedulerEmbed';
	import PaymentPlan from './PaymentPlan';
	import PaymentV2 from './PaymentV2';
	import ElementTypes from './ElementTypes';

	export default {
		name: 'ItemRenderer',

		props: [
			'agreement',
			'item',
			'active',
			'inContainer',
			'index',
			'tokens',
			'mceConfig',
			'editMode',
			'clientMode',
			'signer',
			'templateMode',
			'pageWidth',
			'isMobile',
		],

		components: {
			ElementTypes,
			AcceptButton,
			Button,
			Container,
			Services,
			Divider,
			Footer,
			FormEmbed,
			SchedulerEmbed,
			Header,
			ImageBlock,
			Logo,
			Payment,
			Scheduler,
			Signature,
			Spacer,
			Terms,
			TextBlock,
			PaymentPlan,
			PaymentV2,
		},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			finalize: function() {
				if (typeof this.$refs.component.finalize === 'function') {
					return this.$refs.component.finalize();
				} else {
					return true;
				}
			},

			reset: function() {
				if (typeof this.$refs.component.reset === 'function') {
					return this.$refs.component.reset();
				} else {
					return true;
				}
			},

			handleSigned: function(signer) {
				let result = {
					signer: signer,
					signature: this.item,
				};
				this.$emit('signed', result);
			},
		},

		computed: {
			accountLogo: function() {
				return this.agreement.account.accountLogo;
			},
		},
	};
</script>

<style scoped lang="scss">
	.item {
		position: relative;

		.builder-control {
			position: absolute;
			width: 40px;
			//height: 120px;
			top: 18px;
			left: -40px;
			background: var(--v-primary-base);
			//box-shadow: 0px 1px 4px rgba(16, 17, 15, 0.2);
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 8px 0;
			.v-btn:hover .v-icon {
				color: var(--v-white-base);
			}
		}

		.line-container {
			position: relative;
			width: 100%;
			height: 0; /* Adjust height as needed */
			opacity: 0;
			cursor: pointer;
			transition: all 300ms;
		}

		.line {
			width: 100%;
			height: 1px;
			background-color: var(--v-gray_50-base);
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: var(--v-white-base);
			border: 1px solid var(--v-gray_50-base);
			color: var(--v-gray_50-base);
			border-radius: 50%;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&:hover {
			.line-container {
				height: 40px;
				opacity: 1;
				transition: all 300ms;
			}
		}
	}

	.item-active {
		border-radius: 4px;
		box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
	}

	.item-inactive {
		border-radius: 4px;
	}
</style>
