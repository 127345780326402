<template>
	<div
		@click="$emit('click', $event)"
		:style="`width: 100%; text-align: ${item.alignment};`"
	>
		<img alt="image" v-if="item.url" :src="item.url" :style="imageStyle" />
		<div v-else><v-icon class="material-symbols-outlined" size="100">image</v-icon></div>
	</div>
</template>

<script>
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'ImageBlock',

		props: ['item','isMobile','pageWidth'],

		mixins: [ResponsiveMixin],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			imageStyle: function(){
				let style = `width: ${this.item.scale}%; margin: ${this.item.padding}px; padding-top: ${this.item.pt}px; padding-bottom: ${this.item.pb}px; padding-left: ${this.padding(this.item.pl)}px; padding-right: ${this.padding(this.item.pr)}px; `
				if(this.item.backgroundColor){
					style = style + `background-color: ${this.item.backgroundColor}; `
				}
				if(this.item.borderColor){
					style = style + `border-color: ${this.item.borderColor}; `
				}
				if(this.item.borderWidth){
					style = style + `border-style: solid; border-width: ${this.item.borderWidth}px; `
				}
				if(this.item.borderRadius){
					style = style + `border-radius: ${this.item.borderRadius}px; `
				}
				if(this.item.minWidth){
					style = style + `min-width: ${this.item.minWidth}px; `
				}
				if(this.item.maxWidth){
					style = style + `max-width: ${this.item.maxWidth}px; `
				}
				return style;
			}
		},
	};
</script>

<style scoped lang="scss"></style>
