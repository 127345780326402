<template>
  <div class="mx-2">
    <line-chart :chart-data="chartData" :options="options" style="height:350px;"></line-chart>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import LineChart from '@/modules/charts/LineChart';

export default {
  name: 'InvoiceByMonth',

  props: ['invoices'],

  components: {LineChart},

  data: function() {
    return {
      options: {
        showTooltips: true,
        responsive: true,
        maintainAspectRatio: false,

        elements: {
          point: {
            radius: 5,
          },
          line: {
            tension: 0.2
          }
        },
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                autoSkip: true,
                //maxTicksLimit: 2,
                maxRotation: 0,
                minRotation: 0,
                callback: function(value) {
                  return value;
                },
              },
            },
          ],
          yAxes: [
            {
              display: true,
              position: 'right',
              ticks: {
                autoSkip: true,
                callback: (value) => {
                  return this.$formatters.dollars(value,false,true,this.$store.state.defaultCurrency);
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItems) {
              return tooltipItems[0].xLabel;
            },
            label: function(tti, data) {
              let amount = new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(tti.value);
              return ` ${data.datasets[tti.datasetIndex].label}: ${amount}`;
            },
          },
        },
      },
    };
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    sortByDateSent: function(a, b) {
      if(a.dateSent && b.dateSent) {
        return a.dateSent.localeCompare(b.dateSent);
      }else if(a.dateSent){
        return -1;
      }else if(b.dateSent){
        return 1
      }else {
        return 0;
      }
    },
  },

  computed: {
    chartData: function() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: this.$t('invoices.reports.monthly-invoiced'),
            backgroundColor: 'rgb(150, 152, 146,0.4)',
            data: this.invoiced,
          },
        ],
      };
    },

    labels: function(){
      let labels = [];
      this.invoiceByMonth.forEach(i => labels.push(i.label))
      return labels;
    },

    invoiced: function(){
      let values = [];
      this.invoiceByMonth.forEach(i => values.push(i.total));
      return values;
    },

    invoiceByMonth: function() {

      if(!this.invoices.length){
        return []
      }

      let invoices = [...this.invoices];
      invoices.sort(this.sortByDateSent);

      let result = [];

      let earliest = DateTime.fromISO(invoices[0].dateSent).startOf('month');
      let latest = DateTime.fromISO(invoices[invoices.length - 1].dateSent).startOf('month');

      let start = earliest;

      while (start <= latest) {
        result.push({
          key: start.toISODate(),
          date: start,
          label: start.toFormat('MMM yy'),
          total: 0,
        });
        start = start.plus({months:1});
      }

      for (let i of invoices) {
        let key = DateTime.fromISO(i.dateSent).startOf('month').toISODate();
        let month = result.find(r => r.key === key);
        if(month) {
          month.total = month.total + i.total;
        }
      }

      return result;
    },
  },

};
</script>

<style scoped lang="scss">

</style>