<template>
	<div v-if="meetings.length">
		<div class="brand-medium pointer" @click="$router.push('/meetings/booked')">
			{{ $t('welcome.currently-have') }} <span style="color: var(--v-primary-base)">{{ meetings.length }}</span>
			{{ $tc('welcome.upcoming-meetings', meetings.length) }}.
		</div>
    <div class="column-format" style="gap: 8px; margin-top: 12px">
      <div
          v-for="meeting in meetings"
          :key="meeting.id"
          class="action-button"
          @click.stop="openScheduledMeeting(meeting)"
      >
        {{ meeting.meetingName }} {{ $t('global.on') }}
        {{ DateTime.fromISO(meeting.confirmedTime.start).toFormat('MMM dd @ hh:mma') }}
      </div>
    </div>
	</div>
</template>

<script>
	import MeetingDetail from '@/modules/meetings/MeetingDetail';
	import { DateTime } from 'luxon';

	export default {
		name: 'Meetings',

		props: ['meetings'],

		components: {},

		data: function() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openScheduledMeeting: function(meeting) {
				let binding = {
					scheduledMeeting: meeting,
				};
				this.$store.state.globalModalController.openModal(MeetingDetail, binding, false, false).then((res) => {
					if (res) {
						this.$emit('refresh');
					}
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>