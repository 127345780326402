<template>
	<div @click="$emit('click', $event)" :style="`width: 100%; text-align: ${item.alignment}`" v-if="isConditionMet">
		<img :src="accountLogo" :style="`width:${item.width}px`">
	</div>
</template>

<script>
	import RendererMixin from "@/modules/forms/v2/schema/RendererMixin";

	export default {
		name: 'ImageBlock',

		props: ['accountLogo','item', 'results','builder'],

		mixins: [RendererMixin],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
