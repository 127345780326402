import { render, staticRenderFns } from "./ElementTypes.vue?vue&type=template&id=48ed4d70&scoped=true"
import script from "./ElementTypes.vue?vue&type=script&lang=js"
export * from "./ElementTypes.vue?vue&type=script&lang=js"
import style0 from "./ElementTypes.vue?vue&type=style&index=0&id=48ed4d70&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48ed4d70",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
